import React from 'react';
import styled from 'styled-components';

const Loader = styled.div`
    position: absolute;
    background-color: #ffffff;
    left: 30%;
    top:45%;
    z-index: 20001;
    text-align: center;
    width: 40%;
`

const LoaderText = styled.div`
font-size: 12px;
font-weight: 600;
color: #222222;
font-family: 'Montserrat',sans-serif;
padding-bottom: 25px;
padding-top: 30px;
`



const RegisterbucketNoDataMessage = (props) => {
    return (    
            <Loader>
                <LoaderText>        
                    <span >Please use ‘Define Query & Apply Filters’ to search for your regulatory updates.</span>
                </LoaderText>    
            </Loader>
            );
};

export default RegisterbucketNoDataMessage;