import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
//import CSLTable from '../Common/CSLTable'
import CSLTable from '../Common/MCCTable';
import Addtag from './Addtag';
import { FaAngleDoubleRight, FaAngleDoubleLeft, FaAngleRight, FaAngleLeft, FaPlusCircle} from "react-icons/fa";


const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const Box = styled.div`
	border: 1px solid #395875;
	padding: 6px;
	height: 13px;
	border-radius: 4px;
	margin-bottom: 20px;
	background-color: #335575;
`
const TableHeaderIcon = styled.div`
    float: right;    
    margin-top: 20px;
    color: #c0c0c0;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 40px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 870px;
  padding-left: 20px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 8%;
  height: 93%;
  opacity: 0.8;
  z-index: 1;
`;

class TagManagement extends React.Component
{
	
	state = {
		available_tags: null,
		selected_tags: null,
		shiftRightobject: {},
		shiftLeftobject: {},
		selecttag: [],
		unselectedtag: [],
		cslsubcribetags: {},
		show_modal: null
	}

	selectTag = (index,selectedtag,selectedtagname,id) => (event) => {
		event.preventDefault();	
		console.log("index",index,"selectedtag",selectedtag)
        let available_tags = this.state.available_tags        
        if(selectedtag){           
			this.state.selecttag[id] = false
		 	delete this.state.shiftRightobject[id.toString()];            
         }else{            
			this.state.selecttag[id] = true		           
		    this.state.shiftRightobject[id] = selectedtagname;		         
         } 
		this.setState({available_tags: available_tags, shiftRightobject:this.state.shiftRightobject, selecttag: this.state.selecttag})  		  
	}
	unselectTag = (index,selectedtag,selectedtagname,id) => (event) => {
		event.preventDefault();
		console.log("index",id,"selectedtag",selectedtag)
        let selected_tags = this.state.selected_tags        
        if(selectedtag){           
			this.state.unselectedtag[id] = false
		 	delete this.state.shiftLeftobject[id.toString()];            
         }else{            
			this.state.unselectedtag[id] = true		           
		    this.state.shiftLeftobject[id] = selectedtagname;		         
		 }
		 console.log("unselectedtag",this.state.unselectedtag)
		this.setState({selected_tags: selected_tags, shiftLeftobject:this.state.shiftLeftobject, unselectedtag: this.state.unselectedtag})  
	}
	

	tableData = () => {
		//console.log("this.state.selecttag.length",this.state.selecttag.length)		
		let available_tags = [];		
		Object.keys(this.state.available_tags).map((d, key) => {
			//console.log("Key",key)
			let availableTagobject = {};
			availableTagobject.id = d;
			availableTagobject.tagname = this.state.available_tags[d];
			availableTagobject.selectedtag = Object.keys(this.state.selecttag).length === 0 ? false : this.state.selecttag[d] ? true : false;
			available_tags.push(availableTagobject);
		})
		//console.log("available_tags",available_tags)	
		available_tags.sort(function(a,b) {return (a.tagname > b.tagname) ? 1 : ((b.tagname > a.tagname) ? -1 : 0);} );	
		let ret = {data: [], columns: []};
		ret.columns =[{'Header' : 'Available Tags',accessor: 'available_tags', Cell: row => (
            <div onClick={this.selectTag(row.index,row.original.selectedtag,row.original.available_tags,row.original.id)} style={{backgroundColor: row.original.selectedtag ? '#ceeeed': '#ffffff'}}>
                <label >{row.original.available_tags}</label>
            </div>
         ),headerStyle: {textAlign: 'left'}},
				// {Header: 'Available Tags', accessor: 'available_tags', headerStyle: {textAlign: 'left'}},
					];
		for(let q of available_tags) {
			let elem = {
				'available_tags' : q.tagname,
				'id': q.id,
				'selectedtag': q.selectedtag				
			};
			ret.data.push(elem);
		}		 
		
		return ret;
	}
	tableData1 = () => {
		let selected_tags = [];
		Object.keys(this.state.selected_tags).map((d, key) => {
			let selectedTagobject = {};
			selectedTagobject.tagid = d;
			selectedTagobject.tagname = this.state.selected_tags[d];
			//console.log("unselectedtag",Object.keys(this.state.unselectedtag).length)
			//console.log("unselectedtag",this.state.unselectedtag)
			selectedTagobject.selectedtag = Object.keys(this.state.unselectedtag).length === 0 ? false : this.state.unselectedtag[d] ? true : false;
			selected_tags.push(selectedTagobject);
		})
		selected_tags.sort(function(a,b) {return (a.tagname > b.tagname) ? 1 : ((b.tagname > a.tagname) ? -1 : 0);} );
		//console.log("Sourav",selected_tags)
		let ret = {data: [], columns: []};
		ret.columns =[{'Header' : 'Selected', accessor: 'selected_tags', Cell: row => (
			// console.log(this.state.cslsubcribetags),	
			// console.log(row.original.id)	
			(() => {
				if(Object.keys(this.state.cslsubcribetags).length !== 0 && row.original.id in this.state.cslsubcribetags){				
					return (
						<div style={{backgroundColor: row.original.selectedtag ? '#ceeeed': '#ffffff', color: "#bfbfbf"}}>
							<label >{row.original.selected_tags}</label>
						</div>
					);
				}else{
					return (
						<div onClick={this.unselectTag(row.index,row.original.selectedtag,row.original.selected_tags,row.original.id)} style={{backgroundColor: row.original.selectedtag ? '#ceeeed': '#ffffff'}}>
							<label >{row.original.selected_tags}</label>
						</div>
					);
				}		
			})()	
            // <div onClick={this.unselectTag(row.index,row.original.selectedtag,row.original.selected_tags,row.original.id)} style={{backgroundColor: row.original.selectedtag ? '#ceeeed': '#ffffff'}}>
            //     <label >{row.original.selected_tags}</label>
            // </div>
         ),headerStyle: {textAlign: 'left'}},
						//{Header: 'Selected', accessor: 'selected_tags', headerStyle: {textAlign: 'left'}},
						 ];
		for(let q of selected_tags) {
			let elem = {
				'selected_tags' : q.tagname,
				'id': q.tagid,
				'selectedtag': q.selectedtag		
			};
			ret.data.push(elem);
		}			
		return ret;
	}
	shiftAllRight = (event) => {
		event.preventDefault();
		Object.keys(this.state.available_tags).map((d, key) => {			
			this.state.selected_tags[d] =  this.state.available_tags[d]
			delete this.state.available_tags[d.toString()]; 	
		})
		this.setState({ selected_tags: this.state.selected_tags, available_tags: this.state.available_tags})
		this.props.allSelectedTagValue(this.state.selected_tags,this.state.available_tags)
	}
	shiftAllLeft = (event) => {
		event.preventDefault();
		Object.keys(this.state.selected_tags).map((d, key) => {		
			if(Object.keys(this.state.cslsubcribetags).length !== 0 && d in this.state.cslsubcribetags){	}else{
				this.state.available_tags[d] =  this.state.selected_tags[d]
				delete this.state.selected_tags[d.toString()]; 	
			}
		})
		this.setState({ available_tags: this.state.available_tags, selected_tags: this.state.selected_tags})
		this.props.allSelectedTagValue(this.state.selected_tags,this.state.available_tags)
	}
	shiftRight = (event) => {
		event.preventDefault();		
		Object.keys(this.state.shiftRightobject).map((d, key) => {			
			this.state.selected_tags[d] =  this.state.shiftRightobject[d]
			delete this.state.available_tags[d.toString()]; 	
		})
		this.setState({ selected_tags: this.state.selected_tags, available_tags: this.state.available_tags})	
		this.props.allSelectedTagValue(this.state.selected_tags,this.state.available_tags)
	}
	shiftLeft = (event) => {
		event.preventDefault();
		Object.keys(this.state.shiftLeftobject).map((d, key) => {			
			this.state.available_tags[d] =  this.state.shiftLeftobject[d]
			delete this.state.selected_tags[d.toString()]; 	
		})
		this.setState({ available_tags: this.state.available_tags, selected_tags: this.state.selected_tags})
		this.props.allSelectedTagValue(this.state.selected_tags,this.state.available_tags)
	}
	componentDidMount()
	{		
		this.setState({available_tags: this.props.available_tags, selected_tags: this.props.selected_tags, cslsubcribetags: this.props.cslsubcribe_tags });		
	}
	AddTag = (event) => {
		event.preventDefault();
		this.setState({show_modal: 'add_tag_modal'});
	}
	closeModal = ()=> {
		this.setState({show_modal: null});
	}

	addnewTag = (tag_config) => {
		console.log("tag_config", tag_config)
		this.state.selected_tags[tag_config.id.toString()] =  tag_config.name;
		console.log("this.state.available_tags",this.state.selected_tags)
		this.setState({ selected_tags: this.state.selected_tags, show_modal: null})
		this.props.updatenewtag(this.state.selected_tags)
	}
	
    render()
    { 
		
		if(!this.state.available_tags){
            return(<div>Loading...</div>);
        }
        return (
			<div>
				{
					(() => {
						if (this.state.show_modal !== null) {
							return (<div><InactiveOverlay /><Addtag  selected_tags={this.state.selected_tags} addnewTag={this.addnewTag} closeModal={this.closeModal} /></div>);
						}
					})()
				}				
				<TableHeaderIcon><FaPlusCircle onClick={this.AddTag} style={{cursor: "pointer", color: "#04ada8", height:"16px", width:"16px"}}/></TableHeaderIcon>								
				<div style={{display: "flex"}}>
				<div style={{marginTop: "20px", backgroundColor: "#ffffff", width: "42.5%", borderRadius: "3px",padding: "15px", float: "left"}} >				
					<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={20} refreshCallback={this.refreshState} />				
				</div>
				<div style={{marginTop: "35%", backgroundColor: "#f2f2f2", borderRadius: "3px",padding: "15px", float: "left"}}>				
					<Box onClick={this.shiftAllLeft} style={{cursor: "pointer"}}><FaAngleDoubleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
					<Box onClick={this.shiftLeft} style={{cursor: "pointer"}}><FaAngleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
					<Box onClick={this.shiftRight} style={{cursor: "pointer"}}><FaAngleRight style={{color: "#ffffff"}} /><br/><br/></Box>
					<Box onClick={this.shiftAllRight} style={{cursor: "pointer"}}><FaAngleDoubleRight style={{color: "#ffffff"}} /></Box>
					
				</div>
				<div style={{marginTop: "20px", backgroundColor: "#ffffff", width: "42%", borderRadius: "3px",padding: "15px", float: "left" }}>				
					<CSLTable add={false} processData={this.tableData1} headerText={''} tableRows={20} refreshCallback={this.refreshState}  />				
				</div>
				</div>
			</div>
		);
		

    }
}
export default TagManagement;