import React from 'react';
import styled from 'styled-components';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose} from 'react-icons/io';

const PillsContainer = styled.div`
	min-height: 45px;
	width: 100%;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding-bottom: 10px;
	background-color: white;
	padding-top: 5px;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 47%;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    max-height: 250px;
`;
const Option = styled.div`
	padding: 5px 15px;
	cursor: pointer;
	color: black;
	&:focus {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
	&:active {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;
const Pill = styled.div`
	padding: 5px 35px 5px 10px;
	box-sizing: border-box;
	background-color: #00CDFF;
	color: #ffffff;
	font-size: 14px;
	margin-left: 8px;
	margin-top: 5px;
	float: left;
	border: 1px solid #2F496C;
	border-radius: 4px;
	position: relative;
`;
const PillText = styled.div`
	box-sizing: border-box;
	float: left;
`;
const PillCross = styled.div`
	box-sizing: border-box;
    position: absolute;
    top: 1px;
    right: -3px;
    cursor: pointer;
    font-size: 28px;
    margin-left: 5px;
    margin-top: 5x;
`;
const SearchBoxDiv = styled.div`
	float: left;
    margin-left: 5px;
    box-sizing: border-box;
    position: relative;
    margin-top: 5px;
`;
const SearchBox = styled.input`
	background-color: #ffffff;
	border: none;
	font-family: 'Montserrat', sans-serif;
	width: 90px;
	&:focus {
		outline-width: 0;
	}
`;


class CSLMultiSelect extends React.Component {
	state = {items: {}, selected_items: {}, searchtext: '', show_suggestion: false, suggestions: {} }

	constructor(props) {
		super(props);
	}
	componentSetState = () => {
		let suggestions = JSON.parse(JSON.stringify(this.props.items));
		let items = JSON.parse(JSON.stringify(this.props.items))
		for(let id in suggestions) {
			if(id in this.props.selectedItems){
				delete suggestions[id]
			}
		}
		let selected_items = JSON.parse(JSON.stringify(this.props.selectedItems))
		for(let id in selected_items) {
			selected_items[id].pillStyle = 'pillStyle' in selected_items[id] ? selected_items[id].pillStyle : {bgColor: '#00CDFF', color: '#ffffff', fontWeight: 500, fontSize: '14px'}
			selected_items[id].score = 'score' in selected_items[id] ? selected_items[id].score : 0
			selected_items[id].id = id
			// items[id].pillStyle = selected_items[id].pillStyle
			// items[id].score = selected_items[id].score
		}
		for(let id in suggestions) {
			suggestions[id].optionStyle = 'optionStyle' in suggestions[id] ? suggestions[id].optionStyle : {fontWeight: 500, fontSize: '14px'}
			suggestions[id].score = 'score' in suggestions[id] ? suggestions[id].score : 0
			suggestions[id].id = id
			items[id].optionStyle = suggestions[id].optionStyle
			items[id].score = suggestions[id].score
		}

		// console.log('this.props', this.props)
		this.setState({items: items, selected_items: selected_items, suggestions: suggestions, searchtext: '', original_sujjestions: JSON.parse(JSON.stringify(suggestions))})
	}
	componentDidMount = () => {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.componentSetState()
	}

	componentDidUpdate = (prev_props) => {
		if(this.props === prev_props)return
		this.componentSetState()
	}
	componentWillUnmount = () => {
	    document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = (node) => {
	    this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		// console.log('outside')
		if (this.state.show_suggestion && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({show_suggestion: false, searchtext: ""});
		}
	}

	deselectItem = (id) => (event) => {
		event.preventDefault();
		let selected_items = JSON.parse(JSON.stringify(this.state.selected_items));
		let items = JSON.parse(JSON.stringify(this.state.items));
		let suggestions = JSON.parse(JSON.stringify(this.state.suggestions));
		delete(selected_items[id])
		suggestions[id] = items[id]
		let stack = 'stack' in this.props ? this.props.stack : null
		this.props.deselectCallback(id, stack)
		this.setState({selected_items, suggestions});
	}

	searchFocused = (event) => {
		event.preventDefault();
		this.setState({show_suggestion: true})
	}

	searchData = (event) => {
		event.preventDefault();
		const search_string = event.target.value;
		const search_exp = `^${search_string}`
		const re = new RegExp(search_exp, 'gi');
		let items = JSON.parse(JSON.stringify(this.state.items));
		let selected_items = JSON.parse(JSON.stringify(this.state.selected_items));
		let suggestions = {};
		// let new_options_toshow = [];
		if (search_string === "") {
			suggestions = {};
			Object.keys(items).forEach((id) => {
				if (!(id in selected_items)) {
					suggestions[id] = this.state.original_sujjestions[id];
				}
			})
		} else {
			Object.keys(items).forEach((id) => {
				if (items[id].name.match(re) !== null && !(id in selected_items)) {
					suggestions[id] = this.state.original_sujjestions[id];
				}
			})
			// options_toshow = new_options_toshow;
		}
		// suggestions.sort((a, b) => a.name.localeCompare(b.name));
		this.setState({searchtext: search_string, suggestions: suggestions});
	}

	selectThis = (item_id) => (event) => {
		event.preventDefault();
		// console.log('item_id', item_id)
		let selected_items = JSON.parse(JSON.stringify(this.state.selected_items));
		let items = JSON.parse(JSON.stringify(this.state.items));
		let suggestions = JSON.parse(JSON.stringify(this.state.suggestions));
		delete(suggestions[item_id])
		selected_items[item_id] = items[item_id]
		let stack = 'stack' in this.props ? this.props.stack : null
		this.props.selectCallback(item_id, stack)
		this.setState({selected_items, suggestions});
	}

	nameCompare = (a,b) => {
		return a.name.localeCompare(b.name)
	}

	scoreCompare = (a,b) => {
		return b.score - a.score
	}

	render() {
		// console.log('this.state', this.state)
		if(this.state.items === null)return (<div></div>)

		let selected_items = [];
		for(let id in this.state.selected_items) {
			selected_items.push(this.state.selected_items[id])
		}
		let suggestions = [];
		for(let id in this.state.suggestions) {
			suggestions.push(this.state.suggestions[id])
		}
		selected_items.sort(this.nameCompare);
		suggestions.sort(this.nameCompare);
		selected_items.sort(this.scoreCompare);
		suggestions.sort(this.scoreCompare);

		// console.log('selected_items in render', selected_items)


		return (<div ref={this.setWrapperRef} style={{cursor: "text"}} onClick={() => {this.myInp.focus()}}>
				<PillsContainer>
					{
						selected_items.map((item) => {
							// console.log('item selected outside if', item)
							if(this.state.selected_items[item.id] !== undefined) {
								// console.log('item selected', item)
								if(item !== undefined && 'pillStyle' in item) {
									return (
										<Pill key={item.id} style={{backgroundColor: 'bgColor' in item.pillStyle ? item.pillStyle.bgColor : '#00CDFF'}}>
											<PillText style={{color:  'color' in item.pillStyle ? item.pillStyle.color : '#FFFFFF', 
															  fontSize: 'fontSize' in item.pillStyle ? item.pillStyle.fontSize : '14px', 
															  fontWeight: 'fontWeight' in item.pillStyle ? item.pillStyle.fontWeight : 500}}
											>{item.name}</PillText>
											<PillCross onClick={this.deselectItem(item.id)}><IoIosClose /></PillCross>
											<div style={{clear: "both"}}></div>
										</Pill>
									);
								}							
							}
						})
					}
					<SearchBoxDiv>
						<SearchBox ref={(ip) => this.myInp = ip} 
							onFocus={this.searchFocused} 
							value={this.state.searchtext} onChange={this.searchData} />
						</SearchBoxDiv>
					<div style={{clear: "both"}}></div>
				</PillsContainer>
				{
					(() => {
						let num_suggestions = 0
						for(let s of suggestions) {
							if(s !== undefined)num_suggestions++
						}
						if (this.state.show_suggestion) {
							return (
								<OptionsContainer>
									{
										(() => {
											if(num_suggestions === 0) {
												return <div style={{fontSize: 14, padding: '5px 15px'}}>Sorry. There are no entries matching your search string...</div>
											}
										})()
									}
									{
										suggestions.map((item) => {
											// console.log('suggestion item', item)
											if(item !== undefined && 'optionStyle' in item) {
												return (<Option style={{fontSize: 'fontSize' in item.optionStyle ? item.optionStyle.fontSize : '14px', 
																	    fontWeight: 'fontWeight' in item.optionStyle ? item.optionStyle.fontWeight : 500}}
															key={item.id} id={item.id} 
															onClick={this.selectThis(item.id)}
														>
																{item.name}
														</Option>)
											}
										})
									}
								</OptionsContainer>
							);
						}
					})()
				}
			</div>)
	}
}

export default CSLMultiSelect

