import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaPencilAlt,FaRegTrashAlt} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import AddExternalLinks from '../Modals/ExternalLinks/AddExternalLinks';
import AddEditExternalLinks from '../Modals/ExternalLinks/AddEditExternalLinks';
import AddEditKeyDates from '../Modals/KeyDates/AddEditKeyDates';
import APICall from '../../Common/APICall';
import RCMTagsView from '../Modals/RCMTagsView';
import Utils from '../../Common/Utils';
import SearchablePills from './../Common/SearchablePills';

let btoa = require('btoa');

const CSLDateCover = styled.div`
    height: 40px;
    width: 19%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 8px;
    color: #292929;
    background-color: #ffffff;
`;
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #373737;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #252525;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
`;
const RegModalNameInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 25px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSendrn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #064370;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #064370;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #292929;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #7a8da1;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto; 
    height: 40px;       
`;
const OverviewInfoHeaderNew = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
class AddRUNotification extends React.Component
{
    state = {  
        documentname: "",
        ragulatortypeName: "",  
        regulatorType: null,  
        ready: false,  
        documentype:null,
        documentTypes: null,
        issuedate: new Date(),
        externalLink: [],
        keyDatesValue: [],
        tags: [],
        searchFilterTags: [],
        clientList: [],
        showClient: false,
        rowchecked: false,
        sendClientRN: {},
        showButton:"",

        hiddenAddButton: true,
        showAddKeyDateModal: false,
        curKeyid: 0,
        content: null,

        hiddenAddButtonLink: true,
        showAddLinkModal: false,
        curLinkid: 0,
        contentLink: null,
        document_types: []
    };

    constructor(props) {
        super(props);        
    }
    componentDidMount() {
        let postData = { command: "getfeed" , feed_id: 0  };
        let api = new APICall();
        api.command(postData, this.AddFeedtag);
     }

     AddFeedtag = (result) => {
        let otags = [];
        result.tags.forEach((top) => {
            otags.push({
                name: top.TagName,
                id: top.ID,
                styledcomponent: UnselectedPill,
                selected: false
            });
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let tags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    tags.push(otags[j]);
                }
            }
        }
        let doctype = Store.getStoreData('rutypename');
        let document_types = [];
        Store.getStoreData('rutypename').forEach((dt => document_types.push({id: dt.id, name: dt.documentname, selected: false, show: true})));
        document_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        doctype.sort(function(a,b) {return (a.documentname > b.documentname) ? 1 : ((b.documentname > a.documentname) ? -1 : 0);} ); 
        let regtype = Store.getStoreData('ragulatorsname'); 
        //console.log("regtype",regtype) 
        regtype.sort(function(a,b) {return (a.ragulatorname > b.ragulatorname) ? 1 : ((b.ragulatorname > a.ragulatorname) ? -1 : 0);} );     
        this.setState({ tags: tags, searchFilterTags: tags,documentTypes: doctype, regulatorType: regtype,ready: true, document_types});
    }

    closeRegulatoryModal = (event) => {
        this.props.closeModal();    
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    ondocumentType = (event) => {
        event.preventDefault();
        let dtype = event.target.value;
        let documenttypearr = dtype.split(",");
        this.setState({[event.target.name]: documenttypearr});
    }

    onIssueDateChange = (date) => {
        let issuesdate = this.state.issuedate;
        this.setState({issuedate: date});
    }

    fetchexternalLinkValue = (linkUrl,linkDescription) => {
        console.log(linkUrl,linkDescription)
        if(linkUrl != '') {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let links = {};
            links.id = randNo; 
            links.url = linkUrl;
            links.description = linkDescription;
            this.state.externalLink.push(links);
        }
        this.setState({externalLink: this.state.externalLink, showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    fetchEditexternalLinkValue = (content) => {
        console.log("content",content)              
        if(content.url != '')
        {
            let newExLinks = [];
            this.state.externalLink.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newExLinks.push(item);
            })            
		    this.setState({externalLink: newExLinks, showAddLinkModal: false, hiddenAddButtonLink: true})
        }  
    }

    fetchKeyValue = (keyDate,keyDescription) => {
        console.log(keyDate,keyDescription)        
        // if(keyDescription != '')
        // {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let keys = {}
            keys.id = randNo; 
            keys.date = keyDate;
            keys.description = keyDescription;     
            keys.isOwn = 1;       
            this.state.keyDatesValue.push(keys)
        //}
        console.log(this.state.keyDatesValue)
        //keyDatesValue
        this.setState({keyDatesValue: this.state.keyDatesValue, showAddKeyDateModal: false, hiddenAddButton: true})
    }

    refreshEditKeyValue = (content) => {
        console.log("content",content)   
        // if(content.description != '')
        // {
            let newkeydate = [];
            this.state.keyDatesValue.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newkeydate.push(item);
            })            
		    this.setState({keyDatesValue: newkeydate, showAddKeyDateModal: false, hiddenAddButton: true})
        //}
    }

    processMykeyData = () => {
        var keysdata = this.state.keyDatesValue;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                <FaPencilAlt onClick={this.openEditKeyDateModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                <FaTimes onClick={this.deleteKeyDate(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteKeyDate = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newkeydate = [];
			for(let i=0;i<this.state.keyDatesValue.length;i++)
			{
				if(this.state.keyDatesValue[i].id !== id){
					newkeydate.push(this.state.keyDatesValue[i])
				}
			}			 
			console.log("delete===>",newkeydate);   
            this.setState({keyDatesValue: newkeydate})      
		//}         		  
    }

    openEditKeyDateModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.keyDatesValue.find((item) => {
			return item.id === id;
		})
		this.setState({content,curKeyid: id,showAddKeyDateModal: true, hiddenAddButton: false});        
	}

    processMyExternalLink = () => {
        var linksdata = this.state.externalLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        // {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {'Header' : 'URL', accessor: 'url_value', Cell: row => (
            <div>
                <a target="_blank" href={row.original.url_value}>{row.original.url_value}</a>
            </div>
          ), minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                <FaPencilAlt onClick={this.openEditExLinkModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                <FaTimes onClick={this.deleteExternalLink(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of linksdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    // 'url_value' : <a target="_blank" href={d.url}>{d.url}</a>,
                    'url_value' : d.url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteExternalLink = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newexLink = [];
			for(let i=0;i<this.state.externalLink.length;i++)
			{
				if(this.state.externalLink[i].id !== id){
					newexLink.push(this.state.externalLink[i])
				}
			}			 
			console.log("delete===>",newexLink);   
            this.setState({externalLink: newexLink})      
		//}         		  
    }

    openEditExLinkModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const contentLink = this.state.externalLink.find((item) => {
			return item.id === id;
		})        
		this.setState({contentLink,curLinkid: id,showAddLinkModal: true, hiddenAddButtonLink: false});        
    }

    selectOrUnselectTopic = (tagid) => {
        //event.preventDefault();
        //console.log("Sourav22", tagid)
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });        
    }

    saveRUNotificationForLater = (saveChangeValue) => (event) => {
        event.preventDefault();
        this.saveChange(saveChangeValue);
    }
    
    saveChange(saveForLater) {  
        console.log("sendClientRN==>",this.state.sendClientRN)      
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        if (saveForLater === false && tags.length === 0) {
            alert("Please select atleast one tag.");
            return;
        }
        if(saveForLater === false && Object.keys(this.state.sendClientRN).length === 0){
            alert("Please select atleast one client you want to Send this Notification to");
            return;
        }
        let curmonth = parseInt(this.state.issuedate.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? this.state.issuedate.getFullYear()+'/'+currmonth+'/'+this.state.issuedate.getDate() : this.state.issuedate.getFullYear()+'/0'+currmonth+'/'+this.state.issuedate.getDate();   
        let id = Utils.genKey(35);
        let documentname = this.state.documentname;
        let issuedate = dateStr;        
        //let documentype = this.state.documentype; 
        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        let regulatorname = this.state.ragulatortypeName;
        console.log("regulatorname", regulatorname)     
        if (documentname === "" || (saveForLater === false && doctypeArray.length === 0) || regulatorname === "") {
            let blanks = [];
            if (documentname === "") {blanks.push("Name")};            
            if (regulatorname === "") {blanks.push("Regulator Name")};   
            if (saveForLater === false && doctypeArray.length === 0) {blanks.push("Document Types")};         
            let msg = "";
            if (blanks.length === 1) {
                msg = `The ${blanks[0]} field is mandatory.\nThis field cannot be left blank.`;
            } else {
                blanks.forEach((blank) => {
                    msg = msg + `${blank}, `;
                })
                msg = msg.substring(0, msg.length - 2);
                msg = msg + " fields are mandatory.\nCannot be left blank.";
            }
            alert(msg);
            return false;
        }
        
        let rufeed = {};
        rufeed.tags = tags;  
        rufeed.summary = "";
        rufeed.sections = [];  
        rufeed.id = id;
        rufeed.uploadDate = new Date();
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();
        rufeed.documentName = documentname;
        rufeed.issueDate = issuedate;
        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        //rufeed.documentTypes = documentype;  
        rufeed.documentTypes = doctypeArray;      
        rufeed.ragulatorName = regulatorname; 
        rufeed.scaleOfImpact = "";
        rufeed.likelyhoodOfImplementation = "";
        rufeed.issuingBodies = [regulatorname];
        rufeed.legislativeInitiatives = [];
        rufeed.sendClientRNValue = this.state.sendClientRN;
        console.log("rufeed", rufeed);
        let postData = {"command": "savefeedRN", "id": id, "data": rufeed, "selected_topics": tags,'save_for_later' : saveForLater ? 1 : 0,'is_custom' : 1, is_add: 1, is_rn: 1, sendClientRNValue:  this.state.sendClientRN,'job_type' : 'Assign_RNNotificationtoManager'};
        console.log("postData===>",postData);
        let api = new APICall();
        api.command(postData, this.SaveFeedFetchData);
    }
    SaveFeedFetchData = (result) => {
        console.log("update data", result);
        alert(result.error_msg);
        //localStorage.clear();
        window.location.reload(true);
    }

    converRU = (event) => {
        event.preventDefault();  
        if (window.confirm('Are you sure you want to convert this RN?')) {         
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        // if (tags.length === 0) {
        //     alert("Please select atleast one tag.");
        //     return;
        // }
        let curmonth = parseInt(this.state.issuedate.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? this.state.issuedate.getFullYear()+'/'+currmonth+'/'+this.state.issuedate.getDate() : this.state.issuedate.getFullYear()+'/0'+currmonth+'/'+this.state.issuedate.getDate();   
        let id = Utils.genKey(35);
        let documentname = this.state.documentname;
        let issuedate = dateStr;        
        //let documentype = this.state.documentype;
        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }   
        let regulatorname = this.state.ragulatortypeName;
        console.log("regulatorname", regulatorname)     
        //if (documentname === "" || doctypeArray.length === 0 || regulatorname === "") {
        if (documentname === "" || regulatorname === "") {
            let blanks = [];
            if (documentname === "") {blanks.push("Name")};            
            if (regulatorname === "") {blanks.push("Regulator Name")};   
            //if (doctypeArray.length === 0) {blanks.push("Document Types")};         
            let msg = "";
            if (blanks.length === 1) {
                msg = `The ${blanks[0]} field is mandatory.\nThis field cannot be left blank`;
            } else {
                blanks.forEach((blank) => {
                    msg = msg + `${blank}, `;
                })
                msg = msg.substring(0, msg.length - 2);
                msg = msg + " fields are mandatory.\nCannot be left blank";
            }
            alert(msg);
            return false;
        }
        let rufeed = {};
        rufeed.tags = tags;  
        rufeed.summary = "";
        rufeed.sections = [];  
        rufeed.id = id;
        rufeed.uploadDate = new Date();
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();
        rufeed.documentName = documentname;
        rufeed.issueDate = issuedate;
        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        //rufeed.documentTypes = documentype;
        rufeed.documentTypes = doctypeArray;        
        rufeed.ragulatorName = regulatorname; 
        rufeed.scaleOfImpact = "";
        rufeed.likelyhoodOfImplementation = "";
        rufeed.issuingBodies = [regulatorname];
        rufeed.legislativeInitiatives = [];
        rufeed.sendClientRNValue = this.state.sendClientRN;
        console.log("rufeed", rufeed);
        let postData = {"command": "convertfeedRNtoRU", "id": id, "data": rufeed, 'is_custom' : 1, is_add: 1};
        console.log("postData===>",postData);   
        // let api = new APICall();
        // api.command(postData, this.processconvertRU); 
        } 
    }

    processconvertRU = (result) => {
        console.log("update data", result);
        alert(result.error_msg);
        //localStorage.clear();
        window.location.reload(true);
    }

    sendRN = (buttonName) => (event) => {
        event.preventDefault();
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        if (tags.length === 0) {
            alert("Please select atleast one tag.");
            return;
        }
        console.log("tags",tags)
        let postData = {"command": "tagassociatedClientContact", "tags": tags};
        console.log("postData===>",postData);   
        let api = new APICall();
        api.command(postData, this.processClientList(buttonName));
    }

    processClientList = (buttonName) => (result) =>{
        console.log("update data", result)
        var clicntListall = result.clientsList; 
        let i = 0;
        for(let d of clicntListall) {           
            i++;
            let val = 1;            
            this.state.sendClientRN[d.ClientID] = val;            
        }
        this.setState({clientList: result.clientsList,showButton: buttonName,showClient:true,rowchecked: true, sendClientRN: this.state.sendClientRN})
    }

    selectClient = (event) => {        
        let index = event.target.value;        
        var clientListAll = this.state.clientList;        
            
        if(clientListAll[index].select){
            clientListAll[index].select = false;            
            delete this.state.sendClientRN[clientListAll[index].ClientID.toString()];                    
        } else {
            clientListAll[index].select = true;            
            let userid = clientListAll[index].ClientID.toString();
            let val = 1;            
            this.state.sendClientRN[userid] = val;                            
        }
        this.setState({clientList: clientListAll, sendClientRN: this.state.sendClientRN,rowchecked: false})
        let allselectcheck = true
        for(let d of clientListAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    selectClientAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let clientListAll = this.state.clientList;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of clientListAll) {                
                let selectObject = {}           
                i++;   
                let val = 1;            
                this.state.sendClientRN[d.ClientID] = val;                          
                selectObject.ClientID = d.ClientID;
                selectObject.CompanyName = d.CompanyName;                
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,clientList: selectArray, sendClientRN: this.state.sendClientRN})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of clientListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.sendClientRN[d.ClientID.toString()];                            
                selectObject.ClientID = d.ClientID;
                selectObject.CompanyName = d.CompanyName;                
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,clientList: selectArray, sendClientRN: this.state.sendClientRN})
       }
   }

   allselectchecking = () => {
       console.log("Sourav")
   }

    processMyClientListData = () => {            
        var clientList = this.state.clientList;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectClientAll}/></div>, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectClient}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Clients', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
                      
        for(let d of clientList) {           
            i++;
            let elem = {'index' : i,
                    'id': d.ClientID, 
                    'name_value' : d.CompanyName,
                    'select' : d.select                    
                    };
            ret.data.push(elem);
        
        }
        
        return ret;
    }

    closeSendRN = (event) => {
        event.preventDefault();
        this.setState({showClient:false})
    }

    showAddKeyDate = () => {    
        //event.preventDefault();
        this.setState({ showAddKeyDateModal: true, hiddenAddButton: false,curKeyid: 0 });
    }
    closeKeyDateModal = () => {
        this.setState({ showAddKeyDateModal: false, hiddenAddButton: true});
    }

    showAddexternalLink = () => {    
        //event.preventDefault();

        this.setState({ showAddLinkModal: true, hiddenAddButtonLink: false,curLinkid: 0 });
    }
    closeExLinkModal = () => {
        this.setState({ showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    modifyEntity = (obj) => {
        console.log("obj:", obj);
        this.setState({[obj.entity]: obj.list});
    }

  render()
    {           
        if (!this.state.ready) {
			return (<div></div>);
		}             
        return (
            <RegModalContainer>
                <RegModalHeaderText>Add Regulatory Notification</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <RegModalContainertop>
                    <RegModalNameLabelDiv>Name</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <RegModalNameInput  type="text" name="documentname" value={this.state.documentname} onChange={this.handleChange}  />
                    </RegModalNameInputDiv>
                    <RegModalNameLabelDiv>Regulator Name</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <MRModalSelect name="ragulatortypeName" value={this.state.ragulatortypeName} onChange={this.handleChange}>                        
                            <option value="">Select Regulator</option>
                            {
                                this.state.regulatorType.map((reg, index) => {
                                    return (<option key={index} value={reg.ragulatorname}>{reg.knownAs}</option>);
                                })
                            }                            
                        </MRModalSelect>
                    </RegModalNameInputDiv>
                    {/* <RegModalNameLabelDiv>Document Type</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <MRModalSelect name="documentype" value={this.state.documentype} onChange={this.ondocumentType}>                        
                            <option value="">Select Type</option>
                            {
                                this.state.documentTypes.map((doc, index) => {
                                    return (<option key={index} value={doc.id}>{doc.documentname}</option>);
                                })
                            }                            
                        </MRModalSelect>
                    </RegModalNameInputDiv> */}
                    
                    <RegModalNameLabelDiv>Document Types</RegModalNameLabelDiv>
                    <SearchablePills entity="document_types" list={this.state.document_types} modifyEntity={this.modifyEntity} />
                    
                    <RegModalNameLabelDiv>Date of Issue</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <CSLDateCover>
                        <DatePicker onChange={this.onIssueDateChange}
                            clearIcon={null}
                            calendarIcon={null}
                            locale={"en-GB"}
                            value={this.state.issuedate}/>
                        </CSLDateCover>
                    </RegModalNameInputDiv>
                </RegModalContainertop>
                <RegModalContainertop>
                    <RegModalNameLabelDiv>Available Tags</RegModalNameLabelDiv>
                    <RegModalTagsDiv>
                    <RegModalNameInput style={{marginTop: "13px",marginLeft: "6px",marginBottom: "11px",height: "33px",width: "98%"}} placeholder="Start typing to search..." onChange={this.searchUsers} type="text" />
                        {
                            (() => {
                                if (this.state.tags.length !== 0) {
                                    //console.log(this.state.tags); 
                                    // this.state.tags.map((tag, index) => {
                                    //     return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                    // })
                                    return(<RCMTagsView tags={this.state.tags} selectUnselect={this.selectOrUnselectTopic}/>)                                                                                                         
                                } 
                            })()                                                
                            //if(this.state.tags.length != 0) {
                            
                            //}
                            
                        }
                    </RegModalTagsDiv>
                </RegModalContainertop>
                {
                    (() => {
                        if(this.state.externalLink.length === 0)
                        {
                            return(<SingleRowColumnContainer >                                
                                <OverviewInfoHeaderNew style={{width: "unset"}}>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                {
                                    (() => {
                                        if(this.state.hiddenAddButtonLink){
                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                        }
                                    })()
                                }                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeaderNew>
                            {
                                (() => {
                                    if (this.state.showAddLinkModal) {
                                        return (<AddEditExternalLinks  curid={this.state.curLinkid} closeExLinkModal={this.closeExLinkModal} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                    }
                                })()
                            }
                            {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                            <OverviewInfoBody style={{width: "unset"}}>
                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no External Links Available</div>
                            </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }else {
                            return(<SingleRowColumnContainer >                                
                                <OverviewInfoHeaderNew style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                    {
                                        (() => {
                                            if(this.state.hiddenAddButtonLink){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeaderNew>
                                {
                                    (() => {
                                        if (this.state.showAddLinkModal) {
                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} content={JSON.parse(JSON.stringify(this.state.contentLink))} closeExLinkModal={this.closeExLinkModal} fetchEditexternalLinkValue={this.fetchEditexternalLinkValue} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                        }
                                    })()
                                }
                                {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                                <OverviewInfoBody style={{width: "unset"}}>                                    
                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.keyDatesValue.length === 0)
                        {
                            return(<SingleRowColumnContainer >                                
                                <OverviewInfoHeaderNew style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                    {
                                        (() =>{
                                            if(this.state.hiddenAddButton){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeaderNew>
                                {
                                    (() => {
                                        if (this.state.showAddKeyDateModal) {
                                            return (<AddEditKeyDates  curid={this.state.curKeyid} closeKeyDateModal={this.closeKeyDateModal} refreshKeyValue={this.fetchKeyValue}/>);
                                        }
                                    })()
                                }                            
                            <OverviewInfoBody style={{width: "unset"}}>
                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no Key Dates Available</div>
                            </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }else {
                            return(<SingleRowColumnContainer >                                
                                <OverviewInfoHeaderNew style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                    {
                                        (() =>{
                                            if(this.state.hiddenAddButton){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeaderNew>
                                {
                                    (() => {
                                        if (this.state.showAddKeyDateModal) {
                                            return (<AddEditKeyDates  curid={this.state.curKeyid} content={JSON.parse(JSON.stringify(this.state.content))} closeKeyDateModal={this.closeKeyDateModal} refreshEditKeyValue={this.refreshEditKeyValue} refreshKeyValue={this.fetchKeyValue}/>);
                                        }
                                    })()
                                }
                                {/* <AddKeyDates refreshKeyValue={this.fetchKeyValue}/> */}
                                <OverviewInfoBody style={{width: "unset"}}>                                   
                                    <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.showButton === "SendRN"){
                            return(
                                    <RegCancelSubmitButtonOuterContainer>
                                        <RegCancelButton onClick={this.closeRegulatoryModal} disabled={this.state.showClient}>Cancel</RegCancelButton> 
                                        <RegSubmitButton onClick={this.saveRUNotificationForLater(true)} style={{ backgroundColor: this.state.showClient ? "#7dcac6" : "#37ada7"}} disabled={this.state.showClient}>Save for later</RegSubmitButton>
                                        <RegSendrn onClick={this.sendRN('SendRN')}>Send RN</RegSendrn>
                                        <RegSubmitButton onClick={this.converRU} style={{ backgroundColor: this.state.showClient ? "#7dcac6" : "#37ada7"}} disabled={this.state.showClient}>Convert to RU</RegSubmitButton>                    
                                    </RegCancelSubmitButtonOuterContainer>
                                    )
                        }else{
                            return(
                                <RegCancelSubmitButtonOuterContainer>
                                    <RegCancelButton onClick={this.closeRegulatoryModal} disabled={this.state.showClient}>Cancel</RegCancelButton> 
                                    <RegSubmitButton onClick={this.saveRUNotificationForLater(true)} style={{ backgroundColor: this.state.showClient ? "#7dcac6" : "#37ada7"}} disabled={this.state.showClient}>Save for later</RegSubmitButton>
                                    <RegSendrn onClick={this.sendRN('SendRN')} style={{ backgroundColor: this.state.showClient ? "#446f90" : "#064370"}} disabled={this.state.showClient}>Send RN</RegSendrn>
                                    <RegSubmitButton onClick={this.converRU} >Convert to RU</RegSubmitButton> 
                                    {/* <RegSubmitButton onClick={this.sendRN('ConverRN')} >Convert to RU</RegSubmitButton>                     */}
                                </RegCancelSubmitButtonOuterContainer>
                                )
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.showClient){
                            return(
                                <div style={{paddingBottom: "20px"}}>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select Client(s) you want to Send this Notification to</div> 
                                        <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody>
                                    {
                                        (() => {
                                            if(this.state.clientList.length !== 0){
                                                return(                                                   
                                                        <CSLTable add={false} processData={this.processMyClientListData} headerText={''} tableRows={10}/>
                                                )
                                            }else{
                                                return(<div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Client Available in this Tag.</div>)
                                            }
                                        })()
                                    } 
                                    </OverviewInfoBody>  
                                    <RegCancelSubmitButtonOuterContainer>
                                        <RegCancelButton onClick={this.closeSendRN}>Cancel</RegCancelButton>
                                        {
                                            (() => {
                                                if(this.state.showButton === "SendRN"){
                                                    return(
                                                        <RegSubmitButton onClick={this.saveRUNotificationForLater(false)}>Submit</RegSubmitButton>
                                                    )
                                                }else{
                                                    return(
                                                        <RegSubmitButton onClick={this.converRU}>Submit</RegSubmitButton>
                                                    )
                                                }
                                            })()
                                        } 
                                        
                                    </RegCancelSubmitButtonOuterContainer>                                 
                                </div>
                            )
                        }
                    })()
                }
            </RegModalContainer>
        );
    }
}
export default AddRUNotification;