import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/MCCTable';
import { FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import ReasonDismissalConfigModal from './ReasonDismissalConfigModal';
import CSLLoader from './../Common/CSLLoader';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderReasonCode from "./VerticalEllipsisMenuFolderReasonCode";
import AlertBox from '../Common/AlertBox';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
`;
class ReasonCodeForDismissal extends React.Component
{

	state = {
		general: null,
		reason_codes_for_dismissal: [],
		show_reason_codes_for_dismissal_array: [], 
		show_reason_config_modal: false,
		curid: 0,
		content: null,	
		isLoadedIcon: false,
		alert_param: null,
        headerText: ""
	};

	componentDidMount()
	{
		let updateReasonCode = []; 
		this.props.reason_codes_for_dismissal.forEach((item, index) => {
			if(!('isDefMultiDis' in item)){ 
				if(index === 0){
					item.isDefMultiDis = 1;
				}else{
					item.isDefMultiDis = 0;
				}	
			}
			updateReasonCode.push(item);
		})   
		
		console.log("updateReasonCode==>", updateReasonCode)
        
		this.setState({general: this.props.general,			
			reason_codes_for_dismissal: updateReasonCode,
			show_reason_codes_for_dismissal_array: updateReasonCode,	
			alert_param: null
		});		
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {	
			let updateReasonCode = []; 
			this.props.reason_codes_for_dismissal.forEach((item, index) => {
				if(!('isDefMultiDis' in item)){ 
					if(index === 0){
						item.isDefMultiDis = 1;
					}else{
						item.isDefMultiDis = 0;
					}	
				}
				updateReasonCode.push(item);
			})
			this.setState({general: this.props.general,			
				reason_codes_for_dismissal: updateReasonCode,
			    show_reason_codes_for_dismissal_array: updateReasonCode,				
				isLoadedIcon: false,
				alert_param: null
			});			
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.setState({general, isLoadedIcon: true})	 
		this.props.updateGeneral(general);
	}	

	handlenaSetDefaultCode = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_reason_codes_for_dismissal_array} = this.state;
		let reason_for_dismissal = [];
		show_reason_codes_for_dismissal_array.forEach((item) => {
			if (item.reasonID === section_id) {
				item.isDefaultcode = item.isDefaultcode === 1 ? 0 : 1; 				
			}else{
				item.isDefaultcode = 0; 	
			}
			reason_for_dismissal.push(item);
		})
		console.log("reason_for_dismissal:", reason_for_dismissal);
        this.setState({isLoadedIcon: true})
		this.props.updateReason(reason_for_dismissal);
	}
	handlenaSetDefaultMultiple = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_reason_codes_for_dismissal_array} = this.state;
		let reason_for_dismissal = [];
		show_reason_codes_for_dismissal_array.forEach((item) => {
			if (item.reasonID === section_id) {
				item.isDefMultiDis = item.isDefMultiDis === 1 ? 0 : 1; 				
			}else{
				item.isDefMultiDis = 0; 	
			}
			reason_for_dismissal.push(item);
		})
		console.log("reason_for_dismissal:", reason_for_dismissal);
        this.setState({isLoadedIcon: true})
		this.props.updateReason(reason_for_dismissal);
	}

	handlenaSetActiveInactive = (section_id) => (event) => {
		console.log("content:", section_id);
		let {show_reason_codes_for_dismissal_array} = this.state;
		let reason_for_dismissal = [];
		show_reason_codes_for_dismissal_array.forEach((item) => {
			if (item.reasonID === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 				
			}
			reason_for_dismissal.push(item);
		})
		console.log("reason_for_dismissal:", reason_for_dismissal);
        this.setState({isLoadedIcon: true})
		this.props.updateReason(reason_for_dismissal);
	}

	showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){	
			const content = this.state.reason_codes_for_dismissal.find((item) => {
				return item.reasonID === curid;
			})
            this.setState({content,curid: curid,show_reason_config_modal: true, headerText: "Edit"});
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Reason Code for Dismissal from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReasonCode, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Reason Codes for Dismissal has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

	updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

	updatedeleteReasonCode = (result, stack) => {    
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)      
		this.setState({alert_param: null, isLoadedIcon: true})  
        let newreason = [];        	
		for(let i=0;i<this.state.reason_codes_for_dismissal.length;i++)
		{
			if(this.state.reason_codes_for_dismissal[i].reasonID !== stack.id){
				newreason.push(this.state.reason_codes_for_dismissal[i])
			}
		}			 
        console.log("delete===>",newreason);   
        this.props.deleteReasonValue(newreason);           
    }	
	
	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 180, headerStyle: {textAlign: 'left'}}, 						
						{'Header' : 'Default', Cell: row => (							
							<div> 
								<Toggle
									style={{float:'left'}}
									defaultChecked={row.original.default_code === 1 ? true : false}
									icons={false}	
									onChange={this.handlenaSetDefaultCode(row.original.id)}						
								/>
							</div>
						 ), sortable: false, headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},
						{Header: 'Default (Multiple Dismiss)', accessor: 'default_multiple', minWidth: 100, Cell: row => (							
							<div> 
								<Toggle
									style={{float:'left'}}
									defaultChecked={row.original.default_multiple === 1 ? true : false}
									icons={false}	
									onChange={this.handlenaSetDefaultMultiple(row.original.id)}						
								/>
							</div>
						 ), sortable: false, headerStyle: {textAlign: 'center'},style: {textAlign: 'center'}},
						 {Header: 'Status', accessor: 'status', minWidth: 80, Cell: row => (							
							<div> 
								<Toggle
									style={{float:'left'}}
									defaultChecked={row.original.status === 1 ? true : false}
									icons={false}	
									onChange={this.handlenaSetActiveInactive(row.original.id)}						
								/>
							</div>
						 ), sortable: false, headerStyle: {textAlign: 'center'},style: {textAlign: 'center'}},
						{'Header' : 'Action', Cell: row => (							
							<div>                                
									<VerticalEllipsisMenuFolderReasonCode editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
							</div>
						  ), width: 55, sortable: false, headerStyle: {textAlign: 'center'}}, 
						];
		this.state.show_reason_codes_for_dismissal_array.forEach((item) => {			
			let elem = {id: item.reasonID, name: item.reasonName, default_code: item.isDefaultcode, default_multiple: item.isDefMultiDis, status: item.isActive, isdelete: item.isDelete};
			ret.data.push(elem);
		})        
        return ret;
	}

	addReasonConfigModal = (event) => {
		event.preventDefault();
		this.setState({show_reason_config_modal: true,curid: 0, headerText: "Add"})
	}
	closeModal = () => {		
		this.setState({show_reason_config_modal: false})
	}
	modifyReason = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {reason_codes_for_dismissal} = this.state;
		let newreason = [];
		reason_codes_for_dismissal.forEach((item) => {
			if (item.reasonID === content.reasonID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_reason_config_modal: false})
		this.props.updateReason(newreason);		
	}

	saveReasonDate = (obj) => {
		this.setState({show_reason_config_modal: false})
		this.props.saveReasonValue(obj)
	}
	

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
					(() => {
						if (this.state.show_reason_config_modal) {
							return (<ReasonDismissalConfigModal headerText={this.state.headerText} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateReasonValue={this.modifyReason} saveReasonValue={this.saveReasonDate}/>);
						}
					})()
				}
				{
					(() => {
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				} 
				<GeneralContainer>
					<GeneralInnerContainer>
						<GeneralLabel>Require Reason Codes for Dismissal</GeneralLabel>
						{
							(() => {
								if (this.state.general.require_reason_code_for_dismissal) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('require_reason_code_for_dismissal')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('require_reason_code_for_dismissal')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						{
							(() => {
								if (this.state.general.require_reason_code_for_dismissal) {
									return(<div style={{marginTop: "20px"}}>
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
												Configure Options                              
												<div style={{float:'right'}}>
													<div onClick={this.addReasonConfigModal}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
														<FaPlusCircle  /> 
													</div>                            
													<div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
														<FaEllipsisV  /> 
													</div>
												</div>
											</EmailTopHeader>										
											<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={8}/>
										</div>);
								}
							})()
						}
					</GeneralInnerContainer>
				</GeneralContainer>
				<AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default ReasonCodeForDismissal;