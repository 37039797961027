import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import Store from '../../Common/Store';
import General from './General';
import Email from './Email';
import Permissions from './Permissions';
import ReasonCode from './ReasonCode';
import ReasonCodeForDismissal from './ReasonCodeForDismissal';
import DistributionLists from './DistributionLists';
import APICall from '../../Common/APICall.js';
import { Link } from "react-router-dom";
import CSLLoader from '../Common/CSLLoader';
import FilterData from './FilterData';
import AlertBox from './../Common/AlertBox';
import Reload from '../../Common/Reload.js';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
    width: 90%;
    float: left
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

class ModuleConfigHOC extends React.Component
{
    state = {
		active_section_id: "general",
		dirty: false,
        permissions: {},
		module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        available_tags: null,
        ready: false,
        selected_tags: null,
        tagValue:null,
        tagsValueforavailable: null,
        selectedTagCountCheck: null,
        cslSubcribeTags: {},
        alert_param: null
    };

    constructor(props) {
        super(props);
        if (Store.getStoreData('role') === 'manager') {
            const postData = { command: "list_company_and_users" };
            const api = new APICall();
            api.command(postData, this.getInitialPermission);
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        // console.log("requester:", requester);
        let contactlist = JSON.parse(localStorage.getItem('contactlist'));
        let user_roles = contactlist.result.user_roles['rcm'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        for(let d of result.activeUsers){
            let cu = {};
            cu['email'] = d.EmailAddress;
            cu['user_id'] = d.ID;
            cu['name'] = d.ContactName;
            cu['role'] = d.ID in user_roles ? user_roles[d.ID] : 'no_access';
            company_users.push(cu);
        }
        console.log("company_users===>", company_users);
        console.log("result ===>", result);
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(company_users));


        let seltag = 'selected_tags' in result ? result.selected_tags : {}
        Store.updateStore('selected_tags_value', seltag)
        //alltag start
        let alltags = [];
        if(Object.keys(result.available_tags).length !== 0){
            let avaiTag = result.available_tags;
            for(let i=0;i<Object.keys(result.available_tags).length;i++){
                let alltagObject = {};
                alltagObject.id = Object.keys(result.available_tags)[i];
                alltagObject.name = avaiTag[Object.keys(result.available_tags)[i]]
                alltags.push(alltagObject);
            }
        }
        if(Object.keys(seltag).length !== 0){
            let stag = seltag;
            for(let j=0;j<Object.keys(seltag).length;j++){
                let alltagObject = {};
                alltagObject.id = Object.keys(seltag)[j];
                alltagObject.name = stag[Object.keys(seltag)[j]]
                alltags.push(alltagObject);
            }
        }
        console.log("alltags===>", alltags)
        //alltag end
        Store.updateStore('allsectortags', alltags);
        Store.updateStore('selected_available_tags', result.available_tags)
        this.setState({module_config: result.moduleconfig, requester, permissions, all_company_users, available_tags: result.available_tags, ready: true, selected_tags: result.selected_tags,
            selectedTagCountCheck: result.selected_tags,tagValue: result.selected_tags,tagsValueforavailable: result.available_tags,cslSubcribeTags: result.cslSubcribeTags});
    }

	returnSection = (section) => (event) => {
		event.preventDefault();
        //console.log("Store.getStoreData('selected_tags_value')==>", Store.getStoreData('selected_tags_value'), 'this.state.selected_tags===>', this.state.selected_tags)
        let selectedtag = Store.getStoreData('selected_tags_value');
        let selectedavailable = Store.getStoreData('selected_available_tags');
        if(Object.keys(selectedtag).length === Object.keys(this.state.selected_tags).length){
            console.log("Sourav1");
            let changevalue = 0;
            Object.keys(selectedtag).map((d, key) => {
                if(d.toString() in this.state.selected_tags){
                    changevalue = 0;
                }else{
                    changevalue = 1;
                }
            })
            console.log("changevalue",changevalue)
            if(changevalue === 1){
                //window.confirm("Changes have not been saved, are you sure you want to leave this page?")
                let alert_param = {title: 'Alert', message: 'Your changes have not been saved. Are you sure you want to continue?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                           alertHandler: this.processReturnSection, stack: {section: section, selectedtag: selectedtag, selectedavailable: selectedavailable}}
                this.setState({alert_param: alert_param})
                //this.setState({active_section_id: section, selected_tags: selectedtag, available_tags: selectedavailable, tagsValueforavailable: selectedavailable});
            }else if(changevalue === 0){
                this.setState({active_section_id: section});
            }
        }else{
            console.log("Sourav2");
            // if (window.confirm("Changes have not been saved, are you sure you want to leave this page?")) {
            //     this.setState({active_section_id: section, selected_tags: selectedtag, available_tags: selectedavailable, tagsValueforavailable: selectedavailable});
            // }
            let alert_param = {title: 'Alert', message: 'Your changes have not been saved. Are you sure you want to continue?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                           alertHandler: this.processReturnSection, stack: {section: section, selectedtag: selectedtag, selectedavailable: selectedavailable}}
            this.setState({alert_param: alert_param})
        }
        console.log("this.state.selected_tags==>", Object.keys(this.state.selected_tags).length);
        console.log("this.state.selectedTagCountCheck==>", Object.keys(selectedtag).length);
    }

    processReturnSection = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, active_section_id: stack.section, selected_tags: stack.selectedtag, available_tags: stack.selectedavailable, tagsValueforavailable: stack.selectedavailable});
    }


    getselectedTag = (section) => (result) => {
        if(Object.keys(result.selected_tags).length === Object.keys(this.state.selected_tags).length){
            let changevalue = 0;
            Object.keys(result.selected_tags).map((d, key) => {
                if(d.toString() in this.state.selected_tags){
                    changevalue = 0;
                }else{
                    changevalue = 1;
                }
            })
            console.log("changevalue",changevalue)
            if(changevalue === 1 && window.confirm("Changes have not been saved, are you sure you want to leave this page?")){
                this.setState({active_section_id: section, selected_tags: result.selected_tags});
            }else if(changevalue === 0){
                this.setState({active_section_id: section});
            }
        }else{
            if (window.confirm("Changes have not been saved, are you sure you want to leave this page?")) {

                this.setState({active_section_id: section, selected_tags: result.selected_tags});
              }else{
                  console.log("sourav2");
              }
        }
        console.log("this.state.selected_tags==>", Object.keys(this.state.selected_tags).length);
        console.log("this.state.selectedTagCountCheck==>", Object.keys(result.selected_tags).length);

    }

	updateGeneral = (obj) => {
		let {module_config, dirty} = this.state;
		console.log("old_config", this.state);
		const old_config = {...module_config};
		console.log("old_config", old_config);
		module_config.general = obj;
		const new_config = {...module_config};
		console.log("new_config", new_config);
		if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
			dirty = true;
		}
        console.log("dirty", dirty);
        this.setState({module_config});
        console.log("State=>",this.state)
    }

    saveautoforwordValue = (obj) => {
        console.log("obj==>",obj)
        let {module_config} = this.state;
        module_config.general.autoforwordContactid = obj.sendContactAutoForword;
        module_config.general.autoforwordContactListArray = obj.selectContactArray;
        module_config.distribution_list = obj.selectDistributionArray
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateAutoForword);
    }

    saveautoDismissValue = (obj) => {
        console.log("obj==>",obj)
        let {module_config} = this.state;
        module_config.general.autoDismissRegulatorName = obj;
        module_config.general.isAutoDismissRegulatorNameCheck = 1;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateAutoDismiss);
    }
    afterUpdateAutoDismiss = (result) => {
        console.log(result);
        //alert("Auto Dismiss Saved successfully")
        let alert_param = {title: 'Success', message: "Your Auto-Dismiss settings have been saved.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

    updateAfterUpdateAutoDismiss = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null, module_config: stack.module_config})
    }

    saveAutoDismissValueDocType = (obj) => {
        console.log("obj==>",obj)
        let {module_config} = this.state;
        module_config.general.autoDismissDocumentTypeIDs = obj;
        module_config.general.isAutoDismissDocumentTypesCheck = 1;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateAutoDismiss);
    }

    saveAutoDismissValueSectors = (obj) => {
        console.log("obj==>",obj)
        let {module_config} = this.state;
        module_config.general.autoDismissSectorsIDs = obj;
        module_config.general.isAutoDismissSectorsCheck = 1;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateAutoDismiss);
    }

    afterUpdateAutoForword = (result) => {
        console.log(result);
        //alert("Auto Forward configuration saved successfully.")
        let alert_param = {title: 'Success', message: "Auto Forward configuration saved successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

    updateGeneralReasonCode = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateGeneralReasonCodeDist = (obj) => {
        let {module_config} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        this.setState({module_config: module_config});
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    changeActiveInactive = (obj) => {
        let {module_config} = this.state;
        module_config.general.reason_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    afterUpdateEmailRemainder = (result) => {
        console.log(result);
       this.setState({module_config: result.result});
   }

   updateReason = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_code = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdatereasonValue);
    }
    afterUpdatereasonValue = (result) => {
        console.log(result);
        //alert("Reason Code for sharing updated successfully")
        let alert_param = {title: 'Success', message: "Reason Code for sharing updated successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

    updateReasonCodeDismissal = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_codes_for_dismissal = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterupdateReasonCodeDismissalValue);
    }
    afterupdateReasonCodeDismissalValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: "Reason Code for Dismissal updated successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
    }

    saveReasonValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.reason_code.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddReasonValue);
    }
    saveReasonDismissalValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.reason_codes_for_dismissal.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddReasonDismissalValue);
    }

    afteraddReasonDismissalValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: "Reason Code for Dismissal added successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
   }

    saveDistributionValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.distribution_list.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddDistributionValue);
    }

    afteraddDistributionValue = (result) => {
        console.log(result);
        //alert("Distribution List added successfully")
        let alert_param = {title: 'Success', message: "Your distribution list has been saved.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
   }

   updateDistributionValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.distribution_list = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateDistributionValue);
    }
    afterUpdateDistributionValue = (result) => {
        console.log(result);
        //alert("Distribution List updated successfully")
        let alert_param = {title: 'Success', message: "Your distribution list settings have been updated.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

    afteraddReasonValue = (result) => {
        console.log(result);
        //alert("Reason Code for sharing added successfully")
        let alert_param = {title: 'Success', message: "Reason Code for sharing added successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
   }

   deleteReasonValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_code = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletereasonValue);
    }
    afterDeletereasonValue = (result) => {
        console.log(result);
        //alert("Reason for removal deleted successfully")
        let alert_param = {title: 'Success', message: "Reason for removal deleted successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }
    deleteReasonDismissalValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_codes_for_dismissal = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletereasonDismissalValue);
    }
    afterDeletereasonDismissalValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: "Reason Code for Dismissal deleted successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
    }

    deleteDistributionValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.distribution_list = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletedistributionValue);
    }

    afterDeletedistributionValue = (result) => {
        console.log(result);
        //alert("Distribution List deleted successfully")
        let alert_param = {title: 'Success', message: "Distribution List deleted successfully.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

    afterUpdate = (result) => {
        console.log("result===>",result);
        //alert(result.error_msg);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdate, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
        //window.location.reload();
    }

    updateAfterUpdate = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null, module_config: stack.module_config})
        window.location.reload();
    }

    afterRoleUpdate = (result) => {
        //alert(result.error_msg);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterRoleUpdate, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateAfterRoleUpdate = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterEmailUpdate);
    }

    updateDistributionListsNew = (obj) => {
        let {module_config} = this.state;
        module_config.distribution_list = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateDistributionValue);
    }

    updatePermissions = (obj, single_obj) => {
        console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        console.log("Permission==>",permissions)
         if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
         }else{
            permissions.company_users = this.state.all_company_users;
        }
        this.setState({permissions});
    }

    updatenewtagValue = (selectedtag) => {
      console.log("selectedtag",selectedtag)
      let {module_config, changed_user_roles} = this.state;
      const api = new APICall();
      //module_config.general.availabletagsValue = selectedtag;
      module_config.general.tagValue = selectedtag;
      let postData = {command: "save_module_config", module_config: module_config};
      api.command(postData, this.afterupdatenewtag);
    }

    afterupdatenewtag = (result) => {
        console.log(result);
        //Store.updateStore('selected_available_tags', result.result.general.availabletagsValue)
        Store.updateStore('selected_tags_value', result.result.general.tagValue)
        let alert_param = {title: 'Success', message: "Your new tag has been added.", ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

    updateModuleconfig = (event) => {
        event.preventDefault();
        let {module_config, changed_user_roles} = this.state;
        const api = new APICall();
        module_config.general.tagValue = this.state.tagValue;
        module_config.general.availabletagsValue = this.state.tagsValueforavailable;
        module_config.general.addedBy = Store.getStoreData('contact');
        module_config.general.addedDate = new Date();
        console.log("tagValue==>",this.state.tagValue)
        let postData = {command: "save_module_config", module_config: module_config};
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.command(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles);
        } else {
            api.command(postData, this.afterUpdate);
        }
    }

    // closePage = (event) => {
    //     event.preventDefault();
    //     if (Store.getStoreData('role') === 'manager') {
    //         let selectedtag = Store.getStoreData('selected_tags_value');
    //         if(Object.keys(selectedtag).length === Object.keys(this.state.selected_tags).length){
    //             let changevalue = 0;
    //             Object.keys(selectedtag).map((d, key) => {
    //                 if(d.toString() in this.state.selected_tags){
    //                     changevalue = 0;
    //                 }else{
    //                     changevalue = 1;
    //                 }
    //             })
    //             console.log("changevalue",changevalue)
    //             if(changevalue === 1 && window.confirm("Changes have not been saved, are you sure you want to leave this page?")){
    //                 window.close();
    //             }else if(changevalue === 0){
    //                 window.close();
    //             }
    //         }else{
    //             if (window.confirm("Changes have not been saved, are you sure you want to leave this page?")) {
    //                 window.close();
    //             }
    //         }

    //     }else{
    //         window.close();
    //     }

    // }

    processClosePage = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        window.location.replace(stack.url);
    }

    closePage = (event) => {
        event.preventDefault();
        if (Store.getStoreData('role') === 'manager') {
            let selectedtag = Store.getStoreData('selected_tags_value');
            //let selectedavailable = Store.getStoreData('selected_available_tags');
            if(Object.keys(selectedtag).length === Object.keys(this.state.selected_tags).length){
                let changevalue = 0;
                Object.keys(selectedtag).map((d, key) => {
                    if(d.toString() in this.state.selected_tags){
                        changevalue = 0;
                    }else{
                        changevalue = 1;
                    }
                })
                console.log("changevalue",changevalue)
                if(changevalue === 1){
                    let url = "/";
                    if (this.state.requester !== "") {
                        const reqarr = this.state.requester.split("_");
                        url = `/${reqarr[0]}/${reqarr[1]}`;
                    }

                    let alert_param = {title: 'Alert', message: 'Your changes have not been saved. Are you sure you want to continue?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                       alertHandler: this.processClosePage, stack: {url: url}}
                    this.setState({alert_param: alert_param})
                    // window.location.replace(url);
                }else if(changevalue === 0){
                    let url = "/";
                    if (this.state.requester !== "") {
                        const reqarr = this.state.requester.split("_");
                        url = `/${reqarr[0]}/${reqarr[1]}`;
                    }
                    window.location.replace(url);
                }
            }else{
                //if (window.confirm("Changes have not been saved, are you sure you want to leave this page?")) {
                    let url = "/";
                    if (this.state.requester !== "") {
                        const reqarr = this.state.requester.split("_");
                        url = `/${reqarr[0]}/${reqarr[1]}`;
                    }
                    //window.location.replace(url);
                  //}
                let alert_param = {title: 'Alert', message: 'Your changes have not been saved. Are you sure you want to continue?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                  alertHandler: this.processClosePage, stack: {url: url}}
                this.setState({alert_param: alert_param})
            }

        }else{
            let url = "/";
            if (this.state.requester !== "") {
                const reqarr = this.state.requester.split("_");
                url = `/${reqarr[0]}/${reqarr[1]}`;
            }
            window.location.replace(url);
        }
    }
    getCheckSelectedTag = (result) => {
        if(Object.keys(result.selected_tags).length === Object.keys(this.state.selected_tags).length){
            let changevalue = 0;
            Object.keys(result.selected_tags).map((d, key) => {
                if(d.toString() in this.state.selected_tags){
                    changevalue = 0;
                }else{
                    changevalue = 1;
                }
            })
            console.log("changevalue",changevalue)
            if(changevalue === 1 && window.confirm("Changes have not been saved, are you sure you want to leave this page?")){
              let url = "/";
                if (this.state.requester !== "") {
                    const reqarr = this.state.requester.split("_");
                    url = `/${reqarr[0]}/${reqarr[1]}`;
                }
                window.location.replace(url);
            }else if(changevalue === 0){
                let url = "/";
                if (this.state.requester !== "") {
                    const reqarr = this.state.requester.split("_");
                    url = `/${reqarr[0]}/${reqarr[1]}`;
                }
                window.location.replace(url);
            }
        }else{
            if (window.confirm("Changes have not been saved, are you sure you want to leave this page?")) {
                let url = "/";
                if (this.state.requester !== "") {
                    const reqarr = this.state.requester.split("_");
                    url = `/${reqarr[0]}/${reqarr[1]}`;
                }
                window.location.replace(url);
              }
        }
    }
    allselectedTagValue = (selectedTagValue,availabletags) => {
        this.setState({tagValue:selectedTagValue,tagsValueforavailable: availabletags})
        //console.log("selectedTagValue",selectedTagValue)
    }

    updateEmailRemainder = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterEmailUpdate);
    }
    afterEmailUpdate = (result) => {
        // console.log(result);
        //alert(result.error_msg);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false,
                           alertHandler: this.updateAfterUpdateAutoDismiss, stack: {module_config: result.result}}
        this.setState({alert_param: alert_param})
        //this.setState({module_config: result.result});
    }

	render()
	{
        //console.log("State===>",this.state)
        if (Store.getStoreData('role') !== 'manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    {/* <div style={{float: "right"}}><a href="{url}" style={{textDecoration: "none", fontWeight: "600", color: "#DE8D8E"}}>x</a></div> */}
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if (this.state.module_config === null) {
            return (<div><CSLLoader /></div>);
        }
        // console.log("State===>",this.state)
		return (
			<div style={{padding: "10px 10px 20px 10px"}}>
                <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                	<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                		<ReviewTab onClick={this.returnSection("general")}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "email") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Email</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("reasoncode")} style={{marginTop: "2px"}}>
                        {
                            (() => {
                                if (this.state.active_section_id === "reasoncode") {
                                    return (<div style={{float: "left", fontWeight: "600"}}>Reason Codes for Sharing</div>);
                                } else {
                                    return (<div style={{float: "left"}}>Reason Codes for Sharing</div>);
                                }
                            })()
                        }
                        <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                        <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("reason_code_for_dismissal")} style={{marginTop: "2px"}}>
                        {
                            (() => {
                                if (this.state.active_section_id === "reason_code_for_dismissal") {
                                    return (<div style={{float: "left", fontWeight: "600"}}>Reason Codes for Dismissal</div>);
                                } else {
                                    return (<div style={{float: "left"}}>Reason Codes for Dismissal</div>);
                                }
                            })()
                        }
                        <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                        <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("distributionlists")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "distributionlists") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Distribution Lists</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Distribution Lists</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("filterdata")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "filterdata") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Filter Data</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Filter Data</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                	</ReviewLeftContainer>
                	<ReviewRightContainer>
                		{
                			(() => {
                                //console.log(this.state.permissions)
                				if (this.state.active_section_id === "general") {
                					return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} permissions={JSON.parse(JSON.stringify(this.state.permissions))} ditribution_lists={this.state.module_config.distribution_list} saveAutoForwordValue={this.saveautoforwordValue} saveAutoDismissValue={this.saveautoDismissValue} selected_tags={this.state.selected_tags} available_tags={this.state.available_tags} cslsubcribe_tags={this.state.cslSubcribeTags} updateGeneral={this.updateGeneral} updateGeneralnew={this.updateGeneralReasonCode} allSelectedTagValue={this.allselectedTagValue} updatenewtag={this.updatenewtagValue} />);
                				}
                                if (this.state.active_section_id === "email") {
                                    return (<Email variables={this.state.module_config.variables} email={JSON.parse(JSON.stringify(this.state.module_config.email))} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateEmail={this.updateEmail} updateGeneralSave={this.updateEmailRemainder} />);
                                }
                                if (this.state.active_section_id === "permissions") {
                                    return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
                                }
                                if (this.state.active_section_id === "reasoncode") {
                                    return (<ReasonCode general={JSON.parse(JSON.stringify(this.state.module_config.general))} reason_for_sharing={this.state.module_config.reason_code} updateGeneral={this.updateGeneralReasonCode} changeActiveInactive={this.changeActiveInactive} updateReason={this.updateReason} saveReasonValue={this.saveReasonValue} deleteReasonValue={this.deleteReasonValue}/>);
                                }
                                if (this.state.active_section_id === "reason_code_for_dismissal") {
                                    return (<ReasonCodeForDismissal general={JSON.parse(JSON.stringify(this.state.module_config.general))} reason_codes_for_dismissal={this.state.module_config.reason_codes_for_dismissal} updateGeneral={this.updateGeneralReasonCodeDist} updateReason={this.updateReasonCodeDismissal} saveReasonValue={this.saveReasonDismissalValue} deleteReasonValue={this.deleteReasonDismissalValue}/>);
                                }
                                if (this.state.active_section_id === "distributionlists") {
                                    return (<DistributionLists general={JSON.parse(JSON.stringify(this.state.module_config.general))} permissions={JSON.parse(JSON.stringify(this.state.permissions))} updateGeneral={this.updateGeneralReasonCodeDist} deleteDistributionValue={this.deleteDistributionValue} ditribution_lists={this.state.module_config.distribution_list} updateDistributionValue={this.updateDistributionValue} updateDistributionListsNew={this.updateDistributionListsNew} saveDistributionValue={this.saveDistributionValue} />);
                                }
                                if (this.state.active_section_id === "filterdata") {
                                    return (<FilterData general={JSON.parse(JSON.stringify(this.state.module_config.general))} permissions={JSON.parse(JSON.stringify(this.state.permissions))} ditribution_lists={this.state.module_config.distribution_list} saveAutoForwordValue={this.saveautoforwordValue} saveAutoDismissValue={this.saveautoDismissValue} saveAutoDismissValueDocType={this.saveAutoDismissValueDocType} saveAutoDismissValueSectors={this.saveAutoDismissValueSectors} updateGeneral={this.updateGeneralReasonCodeDist}/>);
                                }
                			})()
                		}
                        {
                            (() => {
                                if (this.state.active_section_id !== "email" && this.state.active_section_id !== "reasoncode" && this.state.active_section_id !== "distributionlists" && this.state.active_section_id !== "filterdata" && this.state.active_section_id !== "reason_code_for_dismissal") {
                                    return (
                                        <FooterButtonsContainer>
                                            <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                                            <SaveBtn onClick={this.updateModuleconfig}>Save</SaveBtn>
                                        </FooterButtonsContainer>
                                    );
                                }
                            })()
                        }
                	</ReviewRightContainer>
                	<div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
                <AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default ModuleConfigHOC;
