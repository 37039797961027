import React from 'react';
import { Icon } from 'react-icons-kit';
import {calendar} from 'react-icons-kit/fa/calendar';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import './CslDatepicker.css';

class CslDatepicker extends React.Component
{
	state = { date: new Date() };

	componentDidMount()
	{
		let curDate = new Date();
		if('updateFromDate' in this.props){
			curDate = this.props.updateFromDate;
		}
		this.setState({date: curDate})
	}

	componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if(this.props.isclear){
				console.log("Sourav2")
				let curDate = new Date();
				if('updateFromDate' in this.props){
					curDate = this.props.updateFromDate;
				}
				this.props.changeClear()
                this.setState({date: curDate})
            }
        }
    }

	onChange = (date) => {
		if (!moment.isDate(date)) {
			return;
		}
		this.setState({date});
		this.props.ondateValue(date);
    }

	// onChange = date => this.setState({ date });
	render()
	{
		return (
			<DatePicker
				onChange={this.onChange}
				value={this.state.date}
				calendarIcon={<Icon size={20} style={{color: "#2F496C"}} icon={calendar} />}
				clearIcon={null}
				format="dd/MM/y"
				className="csldatecontainer"
	        />
		);
	}
}

export default CslDatepicker;