import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge, FaBook, FaWrench } from "react-icons/fa";
import Store from '../../Common/Store'
import { TiArrowBack } from "react-icons/ti";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faHouseBlank} from "@fortawesome/pro-thin-svg-icons";

const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class RcmRegisterHeader extends React.Component
{

    redirectToModuleConfig = (event) => {
        event.preventDefault();
        const url = "/moduleconfig";
        window.open(url, "fc_mc", "width=1400, height=900");
    }


    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
        let site_layout = JSON.parse(localStorage.getItem('site_layout'));
        use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)

        const role = Store.getStoreData('role')
        return (
            <HeadContainer>
                <a href={'/'}><HomeText><span style={{fontWeight: "600"}}><FontAwesomeIcon icon={faHouseBlank} style={{marginRight: '8px'}}/>Regulatory Change Management</span></HomeText></a>
                {/*<Categories><FaCog /></Categories>*/}
                {/*<Categories><FaThLarge /></Categories>*/}
                <Categories title='Back' style={{cursor: "pointer"}}><a href={'/'}><TiArrowBack style={{color: "#337ab7"}}/></a></Categories>
                {/* {
                    (() => {
                        if(!Store.getStoreData('is_admin')) {
                            if(Store.getStoreData('role') !== "team"){
                            return <Categories style={{cursor: "pointer"}} onClick={this.setView}><FaBook style={{color: "#337ab7"}}/></Categories>
                            }
                        }
                    })()
                } */}
                { role === 'manager' &&
                    <Categories title='Module Config'><a href={'/moduleconfig'} style={{color: "black"}}><FontAwesomeIcon icon={faGear} /></a></Categories>
                }
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default RcmRegisterHeader;
