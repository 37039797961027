import React from 'react';
import styled from 'styled-components';

const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;

const RegModalNameInput = styled.input`
    height: 33px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
    margin: 13px 0px 11px 6px;
`;

const Pill = styled.div`
    background-color: #ededed;
    color: #7a8da1;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

class SearchablePills extends React.Component
{

    state = {list: null, text_value: ""};

    componentDidMount() {
        this.setState({list: this.props.list});
    }

    toggleEntity = (item_id) => (event) => {
        event.preventDefault();
        const list = JSON.parse(JSON.stringify(this.state.list));
        list.forEach((item) => {
            if (item.id === item_id) item.selected = item.selected === true ? false : true;
        })
        this.setState({list});
        const obj = {entity: this.props.entity, list: list};
        this.props.modifyEntity(obj);
    }

    searchPills = (event) => {
        event.preventDefault();
        const text_value = event.target.value;
        const list = JSON.parse(JSON.stringify(this.state.list));
        list.forEach((item) => {
            item.show = true;
            if (item.name.toLowerCase().includes(text_value.toLowerCase()) === false) {
                item.show = false;
            }
        })
        this.setState({list, text_value});
    }

    render()
    {
        if (this.state.list === null) return <div></div>
        return (
            <RegModalTagsDiv>
                <RegModalNameInput type="text" placeholder="Start typing to search..." value={this.state.text_value} onChange={this.searchPills} />
                {
                    this.state.list.map((item => {
                        if (item.show === true) {
                            if (item.selected === true) {
                                return (<Pill style={{backgroundColor: "#37ada7", color: "#ffffff"}} onClick={this.toggleEntity(item.id)}>{item.name}</Pill>);
                            } else {
                                return (<Pill onClick={this.toggleEntity(item.id)}>{item.name}</Pill>);
                            }
                        }
                    }))
                }
            </RegModalTagsDiv>
        );
    }
}

export default SearchablePills;