import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge, FaBook, FaWrench } from "react-icons/fa";
import Store from '../../Common/Store'
import { TiArrowBack } from "react-icons/ti";

const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class RcmRegisterHeader extends React.Component
{


    render()
    {
        let module_config_url = '/moduleconfig';
        let register_url_back = '/';
        return (
            <HeadContainer>
                <HomeLogo><FaHome /></HomeLogo>
                <HomeText><span style={{fontWeight: "600"}} >Regulatory Change</span></HomeText>
                <Categories><FaCog /></Categories>
                <Categories><FaThLarge /></Categories>
                <Categories style={{cursor: "pointer"}}><a href={register_url_back}><TiArrowBack style={{color: "#337ab7"}}/></a></Categories>
                {/* {
                    (() => {
                        if(!Store.getStoreData('is_admin')) {
                            if(Store.getStoreData('role') !== "team"){
                            return <Categories style={{cursor: "pointer"}} onClick={this.setView}><FaBook style={{color: "#337ab7"}}/></Categories>
                            }
                        }
                    })()
                } */}
                {
                    (() => {
                        if(!Store.getStoreData('is_admin')) {
                            return <Categories><a href={module_config_url}><FaWrench /></a></Categories>
                        }
                    })()
                }
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default RcmRegisterHeader;
