import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import Store from './../../../Common/Store';
import Dropzone from './../../Common/Dropzone';
import APICall from './../../../Common/APICall';
import AlertBox from './../../Common/AlertBox';
import moment from 'moment';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 200%;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: #04ADA8;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: block;    
    font-size: 14px;
    font-weight: 700;
    // height: 100px;    
    // width: calc(100% - 10px);
    // border-width: 1px 1px 1px 10px;    
    // border-left: 9px solid #04ADA8; 
    // border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 15px;
    float: left;
    // margin-top: 24px;
    // margin-left: 35px;  
    // color: #143151; 
    padding: 13px; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    right: 10px;
    box-sizing: border-box;
    margin-top: 14px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 13px;
    margin-bottom: 15px;
`;
const MRModalInput = styled.input`
    height: 22px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const CSLDateCover = styled.div`   
    width: 30%;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 70%;
    padding-top:7px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

class EditFollowupTaskModal extends React.Component
{
    state = {  
        ready: false,        
        documentName: '', 
        note: '',
        contacts: [],
        assigned_to: 0,
        due_date: new Date(),
        due_by: '1970-01-01',
        binFiles: [], 
        cur_files_json: null,
        alert_param: null   
     }

    constructor(props) {
		super(props);
    }
    closeFollowupModal = (event) => {
        this.props.closeModal();
    }

    componentDidMount = () => {
        console.log("cur_followup_task==>", Store.getStoreData('cur_followup_task'))
        let current_followupTask = Store.getStoreData('cur_followup_task');
        let objectData = JSON.parse(Store.getStoreData('cur_followup_task').task_json).object_data;        
        let documentName = objectData.documentName;
        let note = objectData.note;

        const isGroup = current_followupTask.company_id > 100000 ? true : false;
        let all_contacts = Store.getStoreData('contacts');
        let gc_users = Store.getStoreData('gc_users');
        let role = Store.getStoreData('role');
        let newContacts = [];
        let gcompanyUsers = {};
        if(isGroup){
            let gcid = (parseInt(current_followupTask.company_id)%100000);
            let gc_company = Store.getStoreData('gc_company'); 
            for(let gc of gc_company) {            
                if(parseInt(gc.id) === parseInt(gcid)){
                    gcompanyUsers = gc.users; 
                }
            }
            all_contacts.forEach((item) => {
                if(item.ID in gcompanyUsers){
                    newContacts.push(item); 
                }
            }) 
        }else{
            newContacts = all_contacts;
        } 
        let updateContact = [];
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            newContacts.forEach((item) => {
                if(item.ID in gc_users){
                    updateContact.push(item); 
                }
            })                                                                     
        }else{                                    
            updateContact = newContacts;
        }
        console.log("contacts===>", updateContact)
        let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );

        let assigned_to = current_followupTask.cur_assigned_to;

        Store.updateStore('cur_assign_userid', assigned_to)

        let due_date = new Date(current_followupTask.due_date);
        let due_by =  moment(current_followupTask.due_date).format('YYYY/MM/DD');
        console.log("due_date", due_date)

        let task_json = JSON.parse(current_followupTask.task_json);
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        } 

        this.setState({ready: true, documentName, note, contacts, assigned_to, due_date, due_by, cur_files_json})
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }

    onDateChange = (date) => {
        if (!moment.isDate(date)) {
			return;
		}
        let due_by =  moment(date).format('YYYY/MM/DD');
        this.setState({due_by, due_date: date});
    }

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})               
    }

    updateTask = (event) => {
        event.preventDefault(); 

        if(this.state.assigned_to === 0){
            let alert_param = {title: 'ERROR', message: 'Please select a user to assign the task to.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        } 

        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];        
        
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);  
        task_json['object_data'].documentName = this.state.documentName;
        task_json['object_data'].note = this.state.note;
        task_json['object_data'].due_by = this.state.due_by;
        let cur_assign_userid = Store.getStoreData('cur_assign_userid');
        if(cur_assign_userid.toString() !==  this.state.assigned_to.toString()){
            task_json['object_data'].reAssignDate = moment().unix();
        }        
        task_json['task_data'].cur_assigned_to = this.state.assigned_to;
        task_json['task_data'].due_date = this.state.due_by;
        task_json['action_data'] = { "action": "COMP_RCM_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_RCM_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.note };
        let postData = {command: "update_rcm_followup_task", task_json: task_json, task_id: followupid, bin_files: bin_files };
        console.log(postData); 
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);       
    } 

    processEditFollowupTask = (result) => {        
        let alert_param = {title: 'Success', message: "The follow-up task has been updated sucessfully.", ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateProcessAddFollowupTask, stack: {}}
        this.setState({alert_param: alert_param}) 
    }

    updateProcessAddFollowupTask = (result, stack) => {        
        this.setState({alert_param: null})
        window.location.reload(true);      
    }

    render() { 
        if(!this.state.ready){
            return(<div></div>)
        } 
        return(<div>
            <MRModalContainer style={{top: 'ischeckRegulatory' in this.props ? "18%" : "0%"}}>
                <MRModalHeader>
                    <MRModalHeaderText>Edit follow up task</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeFollowupModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                    
                </MRModalHeader>
                <MRModalBody>
                    <MRModalLabel>Subject</MRModalLabel>
                    <MRModalInput type="text" name="documentName" onChange={this.handleChange} value={this.state.documentName} />
                    <MRModalLabel>Note</MRModalLabel>
                    <MRModalTextarea rows="6" name="note" onChange={this.handleChange} value={this.state.note} />
                    <MRModalLabel>Assign To</MRModalLabel>
                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to} >
                    <option key={0} value={0}>Select</option>
                    {
                        this.state.contacts.map((contact, index) => {
                            return (<option key={index} value={contact.ID}>{contact.ContactName}</option>);
                        })
                    }
                    </MRModalSelect>
                    <MRModalLabel>Due By</MRModalLabel>
                    <CSLDateCover style={{display: "inline-block"}}>
                        <DatePicker
                                onChange={this.onDateChange}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}         
                                value={this.state.due_date}
                        />
                    </CSLDateCover>
                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                    <ModalNameInputDiv>
                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={true}/>
                    </ModalNameInputDiv>
                </MRModalBody>
                <div style={{height: '50px', paddingRight: "12px",marginTop: "15px", float: "right"}}>
                    <MRModalNextBtn onClick={this.updateTask}>Update Task</MRModalNextBtn>
                    <MRModalCancelBtn onClick={this.closeFollowupModal}>Close</MRModalCancelBtn>
                </div>
            </MRModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>)  
    }
}

export default EditFollowupTaskModal;