import React, { Component } from "react";
import styled from "styled-components";
import CSLTable from '../../../Common/MCCTable'
import CSLLoader from '../../../Common/CSLLoader';
import Store from '../../../../Common/Store';
import moment from 'moment';
import {IoIosArrowUp, IoIosArrowDown, IoIosArrowBack} from 'react-icons/io';
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import APICall from './../../../../Common/APICall';
import RegulatoryTeamModal from './../../../Modals/RegulatoryTeamModal';
import FollowupTaskModal from './../../FollowupTaskModal';
import AdvancedFilterMyTasks from './Filter/AdvancedFilterMyTasks';

const FilterContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 2px 2px 2px 2px;
  background-color: #e7f0f7;
  box-sizing: border-box;
`;
const FilterInnerContainer = styled.div`  
  display: flex;
  float: right;
  padding-top: 24px;
  padding-right: 2%;
`;

const FilterInnerContainerRight= styled.div`
    width: 63%;    
    display: inline-block;
    float: right;
    padding-top: 27px;
`;

const FilterInnerContainerLeft = styled.div`
    width: 50%;
    display: inline-block;
    float: left;
    padding-top: 7px;
    padding-left: 2%;
`;
const PendingContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;
const PendingInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #ffffff;    
    padding: 30px;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const MRButtonDel = styled.button`
    box-sizing: border-box;
    background-color: #DB6170;
    color: #ffffff;
    border: 1px solid #DB6170;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const EmailTopHeader = styled.div`
    font-weight: 600;	
	margin-top: 5px;
	width:100%;
	height: 50px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;    
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
`;

const Rectangle = styled.div`
    width: 42px;
    height: 42px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 13px;
`;
const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;  
  margin-right:15px;
  opacity: 1 ; 
`;
const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  margin-right:15px;  
  opacity: 1 ; 
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 4px 9px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 4px 9px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const Saved = styled.span`
  height: 15px;
  width:15px;
  background-color: rgb(27, 52, 81); 
  margin-right:15px; 
  opacity: 1;
`;
const InProgress = styled.span`
  height: 15px;
  width:15px;
  background-color: #FFD11A; 
  margin-right:15px; 
  opacity: 1;
`;
const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;  
  margin-right:15px;
  opacity: 1 ; 
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 130%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  //left: -2%;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

class TeamAllRus extends Component {
  state = {  
    ready: false,
    doctype: {},
    isLoadedIcon: false,    
    shareWithMeRCTasks: [],
    followupRCtasks: [],
    is_show_pending_toggle: true,   
    modal_view: null,
    isShowfilter_my_tasks: false,
    regulatorObject: {},       
  };

  componentDidMount(){
    console.log("Team")
    const documentType = Store.getStoreData('rutypenameAll');
    let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
    let doctype = {};
    for(let i=0; i < documentType.length; i++) {
        doctype[documentType[i].id] = documentType[i].documentname;
    }

    let shareWithMeRCTasks = Store.getStoreData('shareWithMeRCTasks_update');
    let followupRCtasks = Store.getStoreData('followupRCtasks_update');
    let shareWithMeRCTasks_update = [];
    let followupRCtasks_update = [];
    let filter_followupRCtasks = [];
    if('regName' in this.props && this.props.regName !== ''){
        for(let shar of shareWithMeRCTasks){
            if(shar[8] in regulatorObject && regulatorObject[shar[8]] === this.props.regName){
                shareWithMeRCTasks_update.push(shar);
            }
        }
        for(let foll of followupRCtasks){
            if(foll[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                if(foll[6] in regulatorObject && regulatorObject[foll[6]] === this.props.regName){
                    followupRCtasks_update.push(foll);
                }
            }
            if(foll[6] in regulatorObject && regulatorObject[foll[6]] === this.props.regName){
                filter_followupRCtasks.push(foll);
            }
        }   
    }else{
        shareWithMeRCTasks_update = Store.getStoreData('shareWithMeRCTasks_update');
        // followupRCtasks_update = Store.getStoreData('followupRCtasks_update');     
        
        for(let fol of followupRCtasks){
            if(fol[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                
                followupRCtasks_update.push(fol);                
            }
        }        
        filter_followupRCtasks = Store.getStoreData('followupRCtasks_update');
    } 

    Store.updateStore('filterMyTasks', filter_followupRCtasks)
    Store.updateStore('filterClearMyTasks', followupRCtasks_update)
    
    this.setState({doctype, shareWithMeRCTasks: shareWithMeRCTasks_update, 
        followupRCtasks: followupRCtasks_update, regulatorObject,
        ready: true})
}

componentDidUpdate(prev_props) {
    if(this.props !== prev_props && this.props.alertParam !== null) {
        const documentType = Store.getStoreData('rutypenameAll');
        let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let doctype = {};
        for(let i=0; i < documentType.length; i++) {
            doctype[documentType[i].id] = documentType[i].documentname;
        }        
        let shareWithMeRCTasks = Store.getStoreData('shareWithMeRCTasks_update');
        let followupRCtasks = Store.getStoreData('followupRCtasks_update');
        let shareWithMeRCTasks_update = [];
        let followupRCtasks_update = [];
        let filter_followupRCtasks = [];
        if('regName' in this.props && this.props.regName !== ''){
            for(let shar of shareWithMeRCTasks){
                if(shar[8] in regulatorObject && regulatorObject[shar[8]] === this.props.regName){
                    shareWithMeRCTasks_update.push(shar);
                }
            }
            for(let foll of followupRCtasks){
                if(foll[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                    if(foll[6] in regulatorObject && regulatorObject[foll[6]] === this.props.regName){
                        followupRCtasks_update.push(foll);
                    }
                }
                if(foll[6] in regulatorObject && regulatorObject[foll[6]] === this.props.regName){
                    filter_followupRCtasks.push(foll);
                }
            }        
        }else{
            shareWithMeRCTasks_update = Store.getStoreData('shareWithMeRCTasks_update');
            // followupRCtasks_update = Store.getStoreData('followupRCtasks_update');  
            for(let fol of followupRCtasks){
                if(fol[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                
                    followupRCtasks_update.push(fol);                
                }
            }        
            filter_followupRCtasks = Store.getStoreData('followupRCtasks_update');      
        } 

        Store.updateStore('filterMyTasks', filter_followupRCtasks)
        Store.updateStore('filterClearMyTasks', followupRCtasks_update)
        
        this.setState({doctype, shareWithMeRCTasks: shareWithMeRCTasks_update, 
            followupRCtasks: followupRCtasks_update, regulatorObject,
            ready: true})              
    }
}

processShareWithMeTask = () => {
    var sharetasks = this.state.shareWithMeRCTasks; 
    const has_gc = Store.getStoreData('has_gc')
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},                         
                {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
                    <div>
                        {
                            (() => {
                                if(row.original.externalLink !== ''){
                                    return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                                }else{
                                    return(<div>{row.original.document_name}</div>)
                                }
                            })()
                            
                        }                    
                    </div>
                ), width: 750, headerStyle: {textAlign: 'left'}},
                {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},                         
                {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                {Header: 'Issue Date', accessor: 'issue_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }},
                {Header: 'Assigned Date', accessor: 'assigned_date', minWidth: 80, headerStyle: {textAlign: 'center'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }, style: {textAlign: 'center'}},
                {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }, style: {textAlign: 'center'}},
                {'Header' : 'Action', headerStyle: {textAlign: 'center'}, width: 120, Cell: row => (
                    <div>                    
                        <MRButton onClick={this.showRCMtaskModal(row.original.task_id, 'mySharetask')}>Process</MRButton>
                    </div>
                )}
                ];

    let gc_assoc = Store.getStoreData('gc_assoc');            
    let gc_company = "";
    for(let d of sharetasks) {
        j++; 
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : ""; 

        let dNames = "";                   
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]                    
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }    

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs'); 
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }

        let elem = {
            'index' : j,
            'task_id': d[0],
            'externalLink': exterlink,
            'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
            'document_types' : dNames,
            'company_name' : gc_company,                
            'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
            'issue_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
            'assigned_date': moment.unix(d[10]).format('DD/MM/YYYY'),                       
            'added_date': moment.unix(d[11]).format('DD/MM/YYYY')                          
        };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.assigned_date === b.assigned_date) { //issue_date change to assigned_date
            return 0;
        }
        let adue = a.assigned_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);            
        let  bbdue = b.assigned_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];    
    let s = 0;                   
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })    
    ret.data = sarr;    
    return ret;
}

showRCMtaskModal = (task_id, tableType) => (event) =>{  
    event.preventDefault();
    Store.updateStore('current_task_id', task_id);
    let postData = { "command": "gettask", "task_id" : task_id };
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processshowRCMtask(tableType));    
}
processshowRCMtask = (tableType) => (result) => {
    console.log('result==>', result)        
    let parent_task_id = result.result.task.parenttask.parent_task_id;
    let task = JSON.parse(result.result.task.parenttask.task_json);
    Store.updateStore('this_task_detail', result.result);
    Store.updateStore('parent_task_id', parent_task_id);
    Store.updateStore('table_type', tableType);
    Store.updateStore('childtasks', result.result.task['childtask'].result);
    Store.updateStore('task', task.object_data);
    Store.updateStore('current_task', task);        
    if(tableType === 'mySharetask'){
        this.updatemyShareTask();            
    }        
}

updatemyShareTask(){        
    let task = Store.getStoreData('this_task_detail');
    let task_id = task.task.parenttask.id;
    let task_json = JSON.parse(task.task.parenttask.task_json);        
    let userID = Store.getStoreData('loggedincontact');
    if(userID in task_json.object_data.shareUserAcknowledge && task_json.object_data.shareUserAcknowledge[userID].cur_view === "Unopened"){            
        let val = {date: task_json.object_data.shareUserAcknowledge[userID].date, status: task_json.object_data.shareUserAcknowledge[userID].status, OpenedDate:  Date.now(), cur_view: "Opened",responsedate: Date.now()};
        task_json.object_data.shareUserAcknowledge[userID] = val 
        task_json['object_data'].shareUserAcknowledge = task_json.object_data.shareUserAcknowledge;        
        task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareUserAcknowledge: task_json.object_data.shareUserAcknowledge[userID]};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };            
        const api = new APICall();
        api.command(postData, this.processupdatecurview);
    }else if(userID in task_json.object_data.shareUserInfo && task_json.object_data.shareUserInfo[userID].cur_view === "Unopened"){                  
        let val = {date: task_json.object_data.shareUserInfo[userID].date, status: task_json.object_data.shareUserInfo[userID].status,OpenedDate:  Date.now(), cur_view: "Opened",responsedate: Date.now()};
        task_json.object_data.shareUserInfo[userID] = val 
        task_json['object_data'].shareUserInfo = task_json.object_data.shareUserInfo;        
        task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareUserInfo: task_json.object_data.shareUserInfo[userID]};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        const api = new APICall();
        api.command(postData, this.processupdatecurview);         
    }else{            
        this.setState({isLoadedIcon: false, modal_view: 'sharetaskModal'})
    }        
}

processupdatecurview = (result) => {        
    let task = Store.getStoreData('this_task_detail');
    let task_id = task.task.parenttask.id;
    let postData = { "command": "gettask", "task_id" : task_id };
    const api = new APICall();
    api.command(postData, this.processupadteCurrentData);
}
processupadteCurrentData = (result) => {
    Store.updateStore('this_task_detail', result.result);
    this.setState({isLoadedIcon: false, modal_view: 'sharetaskModal'})
} 

processFollowupTaskData = () => {
    var followuptask = this.state.followupRCtasks;          
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[  {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                    {Header: 'Task', accessor: 'task', width: 750, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Regulator Name', accessor: 'regulatory_change', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Assigned Date', accessor: 'assigned_date', minWidth: 100, headerStyle: {textAlign: 'left'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Due Date', accessor: 'assigned_duedate', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Issue Date', accessor: 'issue_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},
                    {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},  
                    {Header: 'Status', accessor: 'status', width: 100, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                    {'Header' : 'Action',Cell: row => (
                        <div>
                            <MRButton onClick={this.viewAssignTask(row.original.id)}>View</MRButton>
                        </div>
                     ), width: 100, headerStyle: {textAlign: 'left'}}                          
                 ];
    for(let d of followuptask) {           
        j++; 
        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs'); 
        let regulatorNamenew = "";
        if(d[6] in regulatorObject){
            regulatorNamenew = regulatorObject[d[6]];
        }

        let text = "";
        let color = "";
        if(d[9] === "Pending"){
            text = d[9];
            color = '#2E8B57'// : '#FF0000';
        }else if(d[9] === "Overdue") {
            text = d[9];
            color = '#FF0000';
        }else if(d[9] === "Completed"){
            text = d[9];
            color = '#2E8B57';
        }

        let elem = {'index' : j,
                'id': d[0], 
                'task' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
                'regulatory_change' : regulatorNamenew.replace(/'+/g,"'"),
                'assigned_to' : Store.getStoreData('contact'),                
                //'assigned_date': d[10],
                'assigned_date': moment.unix(d[10]).format('DD/MM/YYYY'),
                'assigned_duedate': d[8],
                'status':  {text: text, color: color},
                'status_value': text,
                'issue_date': moment(d[11], 'YYYY/MM/DD').format('DD/MM/YYYY'),
                'added_date': moment.unix(d[12]).format('DD/MM/YYYY')                  
               };           
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.assigned_duedate === b.assigned_duedate) {
            return 0;
        }
        let adue = a.assigned_duedate.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);            
        let  bbdue = b.assigned_duedate.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];    
    let s = 0;                   
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })    
    ret.data = sarr;    
    return ret;
}

viewAssignTask = (followupid) => (event) => {
    event.preventDefault();
    let postData = { command: "get_subtask", task_id: followupid};
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.openFollowupModal);  
}
openFollowupModal = (result) => {
    console.log("followup_task",result.result.task);
    if(result.result.task.length !== 0){
        let currentfollowupData = result.result.task.subtask;
        let followupid = result.result.task.subtask.id;
        let task_json = JSON.parse(currentfollowupData.task_json);        
        if('cur_view' in task_json.object_data && task_json.object_data.cur_view === "Unopened"){
            task_json['object_data'].cur_view = "Opened";
            task_json['object_data'].OpenedDate = Date.now();
            let postData = {command: "update_rcm_followup_task", task_json: task_json, task_id: followupid,bin_files: [] };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.processupdatecurviewFoll(followupid));
        }else{            
            Store.updateStore('cur_followup_task', result.result.task.subtask)
            Store.updateStore('cur_parentTask', result.result.task.parenttask)
            this.setState({ modal_view: 'view_followup_task', isLoadedIcon: false})
        }
    }        
}

openFollowupModalNew = (result) => {
    Store.updateStore('cur_followup_task', result.result.task.subtask)
    Store.updateStore('cur_parentTask', result.result.task.parenttask)
    this.setState({ modal_view: 'view_followup_task',isLoadedIcon: false}) 
}
processupdatecurviewFoll = (followupid) => (result) => {  
    let postData = { command: "get_subtask", task_id: followupid};
    let api = new APICall();
    api.command(postData, this.openFollowupModalNew);         
} 


switchRadio = (event) => {
    // event.preventDefault();
    if (this.state.is_show_pending_toggle === true) {            
        this.setState({is_show_pending_toggle: false});
    } else {            
        this.setState({is_show_pending_toggle: true});
    }
}

backToDashboard = (event) => {
    this.props.backToDashboard()
}

closeModal = () => {
    this.setState({ modal_view: null });
}

toggleFilter_my_tasks = (event) => {
    event.preventDefault();
    let isShowfilter_my_tasks = this.state.isShowfilter_my_tasks ? false : true;
    this.setState({isShowfilter_my_tasks});
}

filterSubmitMyTasks = (filterNewRusList) => {   
    this.setState({followupRCtasks: filterNewRusList})
} 

  render() {
    let role = Store.getStoreData('role');
    if(!this.state.ready){
        return(<div></div>)
    }
    return (<div>
        {
            (() => {                    
                if(this.state.isLoadedIcon){
                    return <div><CSLLoader style={{position: 'absolute'}}/></div>
                }
            })()
        } 
        <FilterContainer>
            <FilterInnerContainerLeft>  
                <div style={{display: "inline-block", width: "10%", float: "left"}}>
                    <Rectangle><div style={{cursor: "pointer",fontSize: "35px",color: "#282828c9"}} onClick={this.backToDashboard}><IoIosArrowBack/></div></Rectangle>
                </div>   
                <div style={{display: "inline-block", width: "30%", float: "left"}}>
                    <p style={{ color: "#435a73", fontSize: "22px", fontWeight: 700 }}>{this.props.headerText}</p>            
                </div>      
                
            </FilterInnerContainerLeft>            
        </FilterContainer>            
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'my_rus_team'){
                    return(<PendingContainer>
                        <PendingInnerContainer> 
                            <div style={{marginTop: "20px"}}>    
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>My Regulatory Updates
                                <div style={{float:'right'}}>                        
                                    {/* <div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}}>
                                        <IoIosArrowUp />
                                    </div> */}
                                </div>
                                </EmailTopHeader>                           
                                <CSLTable 
                                    processData={this.processShareWithMeTask} 
                                    tableRows={10}
                                    headerText={''}   
                                /> 
                            </div>                     
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }        
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'my_tasks_team'){
                    return(<PendingContainer>
                        <PendingInnerContainer> 
                            <div style={{marginTop: "20px"}}>    
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>My Tasks
                                <div style={{float:'right'}}>                        
                                {
                                    (() => {
                                        if(!this.state.isShowfilter_my_tasks){
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_my_tasks}>
                                                        <IoIosArrowDown />
                                                    </div>)
                                        }else{
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_my_tasks}>
                                                        <IoIosArrowUp />
                                                    </div>)
                                        }
                                    })()
                                }
                                </div>
                                </EmailTopHeader>       
                                {
                                    (() => {
                                        if(this.state.isShowfilter_my_tasks){
                                            return(<AdvancedFilterMyTasks doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitMyTasks}/>)
                                        }
                                    })()
                                }                     
                                <CSLTable 
                                    processData={this.processFollowupTaskData} 
                                    tableRows={10}
                                    headerText={''}   
                                /> 
                            </div>                     
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }    
        {
            (() => {
                if(this.state.modal_view !== null){                                
                    switch(this.state.modal_view) {
                        case 'sharetaskModal' : return <div><InactiveOverlay /><RegulatoryTeamModal closeModal={this.closeModal}/></div>; break;
                        case 'view_followup_task' : return <div><InactiveOverlay /><FollowupTaskModal closeModal={this.closeModal}/></div>; break;
                        // case 'taskModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'900px'}}><InactiveOverlay /><RegulatoryTeamModal closeModal={this.closeModal}/></div>; break;
                        // case 'mytaskModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><RegulatoryMytaskTeamModal closeModal={this.closeModal}/></div>; break;
                        
                    }
                }
            })()
        }    
        </div>);
  }
}

export default TeamAllRus;
