import Store from './Store.js';
import moment from 'moment';

class CardLoader {
    dataCardCoutAll(alltask, module_config){         
        let newRCtasks = alltask.newTasks;
        let pendingRCtasks = alltask.pendingTasks;
        let autoForwordFeedsAndRU = alltask.autoforwordfeedTasks;
        let saveRCtasks = alltask.saveTasks;        
        let followupRCtasks = alltask.followupTasks;
        let dismissFeedsAndRUtasks = alltask.dismissTasks;
        let all_followuptasks = alltask.all_followup;

        let res = {all_task_count: 0, pending_tasks_count: 0, followupTasks_count: 0, dismissTaskCount: 0}
        let totalRcForAll = 0;
        if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "this_month"){            
            let startOfMonth = moment().startOf('month'); 
            startOfMonth.set('hour', 0);
            startOfMonth.set('minute', 0);
            startOfMonth.set('second', 0);
            startOfMonth = startOfMonth.unix();  
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();            
            console.log("startOfMonth===>",startOfMonth,"currentdate===>",currentdate) 

            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= startOfMonth && n[10] <= currentdate) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= startOfMonth && n[12] <= currentdate) totalRcForAll = totalRcForAll + 1;
                }
            }
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {                     
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= startOfMonth && p[12] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= startOfMonth && p[14] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                    }
                }
            }
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= startOfMonth && actionDate <= currentdate){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            } 
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= startOfMonth && a[14] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            }
            for(let s of saveRCtasks) {                     
                if (s[9] >= startOfMonth && s[9] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            }
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= startOfMonth && d[12] <= currentdate){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= startOfMonth && d[10] <= currentdate) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }            
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "last_month"){
            let prevMonthF = moment().subtract(1, 'months').startOf('month');
            prevMonthF.set('hour', 0);
            prevMonthF.set('minute', 0);
            prevMonthF.set('second', 0);
            prevMonthF = prevMonthF.unix();  
            let prevMonthL = moment().subtract(1, 'months').endOf('month').endOf('day');
            prevMonthL.set('hour', 23);
            prevMonthL.set('minute', 59);
            prevMonthL.set('second', 59);
            prevMonthL = prevMonthL.unix();          
            console.log("prevMonthFirstDay===>",prevMonthF,"prevMonthLastDay===>",prevMonthL)    
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= prevMonthF && n[10] <= prevMonthL) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= prevMonthF && n[12] <= prevMonthL) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= prevMonthF && p[12] <= prevMonthL) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= prevMonthF && p[14] <= prevMonthL) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= prevMonthF && actionDate <= prevMonthL){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= prevMonthF && a[14] <= prevMonthL) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= prevMonthF && s[9] <= prevMonthL) totalRcForAll = totalRcForAll + 1; 
            }
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= prevMonthF && d[12] <= prevMonthL){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= prevMonthF && d[10] <= prevMonthL) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }              
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "last_thirty_days"){
            let lastThirty = moment().subtract(30, 'days');     
            lastThirty.set('hour', 0);
            lastThirty.set('minute', 0);
            lastThirty.set('second', 0);
            lastThirty = lastThirty.unix();       
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();   
            console.log("lastThirty===>",lastThirty,"currentdate===>",currentdate)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= lastThirty && n[10] <= currentdate) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= lastThirty && n[12] <= currentdate) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= lastThirty && p[12] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= lastThirty && p[14] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= lastThirty && actionDate <= currentdate){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= lastThirty && a[14] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= lastThirty && s[9] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= lastThirty && d[12] <= currentdate){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= lastThirty && d[10] <= currentdate) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            } 
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "last_twelve_month"){
            let lastTwelve = moment().subtract(12, 'months'); 
            lastTwelve.set('hour', 0);
            lastTwelve.set('minute', 0);
            lastTwelve.set('second', 0);
            lastTwelve = lastTwelve.unix();            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();   
            console.log("lastTwelveMonth===>",lastTwelve,"currentDateFinalnew===>",currentdate)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= lastTwelve && n[10] <= currentdate) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= lastTwelve && n[12] <= currentdate) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= lastTwelve && p[12] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= lastTwelve && p[14] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= lastTwelve && actionDate <= currentdate){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= lastTwelve && a[14] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= lastTwelve && s[9] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= lastTwelve && d[12] <= currentdate){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= lastTwelve && d[10] <= currentdate) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }  
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "this_year"){
            let thisyear = moment().startOf('year');  
            thisyear.set('hour', 0);
            thisyear.set('minute', 0);
            thisyear.set('second', 0);
            thisyear = thisyear.unix();            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();
            console.log("thisyearfirstday===>",thisyear,"currentDateFinalnew===>",currentdate)   
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= thisyear && n[10] <= currentdate) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= thisyear && n[12] <= currentdate) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= thisyear && p[12] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= thisyear && p[14] <= currentdate) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= thisyear && actionDate <= currentdate){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= thisyear && a[14] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= thisyear && s[9] <= currentdate) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= thisyear && d[12] <= currentdate){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= thisyear && d[10] <= currentdate) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }        
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "last_year"){
            let lastyear = moment().subtract(1, 'year').startOf('year');    
            lastyear.set('hour', 0);
            lastyear.set('minute', 0);
            lastyear.set('second', 0);
            lastyear = lastyear.unix();  
            let lastyearend = moment().subtract(1, 'year').endOf('year').endOf('day');    
            lastyearend.set('hour', 23);
            lastyearend.set('minute', 59);
            lastyearend.set('second', 59);
            lastyearend = lastyearend.unix();            
            console.log("lastyearfirstday===>",lastyear,"lastyearendday===>",lastyearend)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= lastyear && n[10] <= lastyearend) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= lastyear && n[12] <= lastyearend) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= lastyear && p[12] <= lastyearend) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= lastyear && p[14] <= lastyearend) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= lastyear && actionDate <= lastyearend){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= lastyear && a[14] <= lastyearend) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= lastyear && s[9] <= lastyearend) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= lastyear && d[12] <= lastyearend){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= lastyear && d[10] <= lastyearend) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }               
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "quarter_one"){
            let firstJana = moment().startOf('year'); 
            firstJana.set('hour', 0);
            firstJana.set('minute', 0);
            firstJana.set('second', 0);
            firstJana = firstJana.unix();    
            let lastmarch = moment().month(2).endOf('month').endOf('day');  
            lastmarch.set('hour', 23);
            lastmarch.set('minute', 59);
            lastmarch.set('second', 59);
            lastmarch = lastmarch.unix();              
            console.log("currentyearfirstday===>",firstJana,"currentyearmarchlasttday++>", lastmarch)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= firstJana && n[10] <= lastmarch) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= firstJana && n[12] <= lastmarch) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= firstJana && p[12] <= lastmarch) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= firstJana && p[14] <= lastmarch) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= firstJana && actionDate <= lastmarch){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstJana && a[14] <= lastmarch) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstJana && s[9] <= lastmarch) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstJana && d[12] <= lastmarch){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= firstJana && d[10] <= lastmarch) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }   
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "quarter_two"){
            let firstapril = moment().month(3).startOf('month');  
            firstapril.set('hour', 0);
            firstapril.set('minute', 0);
            firstapril.set('second', 0);
            firstapril = firstapril.unix();
            let lastjune = moment().month(5).endOf('month').endOf('day');    
            lastjune.set('hour', 23);
            lastjune.set('minute', 59);
            lastjune.set('second', 59);
            lastjune = lastjune.unix();              
            console.log("currentyearfirstapril===>",firstapril,"currentyearlastjune++>", lastjune)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= firstapril && n[10] <= lastjune) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= firstapril && n[12] <= lastjune) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= firstapril && p[12] <= lastjune) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= firstapril && p[14] <= lastjune) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= firstapril && actionDate <= lastjune){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstapril && a[14] <= lastjune) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstapril && s[9] <= lastjune) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstapril && d[12] <= lastjune){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= firstapril && d[10] <= lastjune) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            } 
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "quarter_three"){
            let firstjuly = moment().month(6).startOf('month');           
            firstjuly.set('hour', 0);
            firstjuly.set('minute', 0);
            firstjuly.set('second', 0);
            firstjuly = firstjuly.unix();
            let lastsep = moment().month(8).endOf('month').endOf('day');   
            lastsep.set('hour', 23);
            lastsep.set('minute', 59);
            lastsep.set('second', 59);
            lastsep = lastsep.unix(); 
            console.log("currentyearfirstjuly===>",firstjuly,"currentyearlastsep++>", lastsep)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= firstjuly && n[10] <= lastsep) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= firstjuly && n[12] <= lastsep) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= firstjuly && p[12] <= lastsep) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= firstjuly && p[14] <= lastsep) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= firstjuly && actionDate <= lastsep){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstjuly && a[14] <= lastsep) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstjuly && s[9] <= lastsep) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstjuly && d[12] <= lastsep){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= firstjuly && d[10] <= lastsep) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }             
        }else if('general' in module_config && module_config.general.enable_data_cards === true && module_config.general.select_timespan === "quarter_four"){
            let firstoct = moment().month(9).startOf('month')       
            firstoct.set('hour', 0);
            firstoct.set('minute', 0);
            firstoct.set('second', 0);
            firstoct = firstoct.unix();
            let lastdec = moment().month(11).endOf('month').endOf('day')
            lastdec.set('hour', 23);
            lastdec.set('minute', 59);
            lastdec.set('second', 59);
            lastdec = lastdec.unix(); 
            console.log("currentyearfirstoct===>",firstoct,"currentyearlastdec++>", lastdec)
            for(let n of newRCtasks) {  
                if(n[11] === 0){              
                    if (n[10] >= firstoct && n[10] <= lastdec) totalRcForAll = totalRcForAll + 1; 
                }else{
                    if (n[12] >= firstoct && n[12] <= lastdec) totalRcForAll = totalRcForAll + 1;
                }
            }  
            let pendingCreatetdDate = {}
            for(let p of pendingRCtasks) {    
                if(p[13] === 0){
                    pendingCreatetdDate[p[0].toString()] = p[12];
                    if (p[12] >= firstoct && p[12] <= lastdec) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }else{
                    pendingCreatetdDate[p[0].toString()] = p[14];
                    if (p[14] >= firstoct && p[14] <= lastdec) {
                        totalRcForAll = totalRcForAll + 1; 
                        res.pending_tasks_count = res.pending_tasks_count + 1;
                    }
                }
            } 
            let parentTaskObject = {};  
            for(let d of all_followuptasks){
                parentTaskObject[d[5].toString()] = d[6];
            }
            if(Object.keys(parentTaskObject).length !== 0){
                for(let prt in parentTaskObject){
                    if(prt in pendingCreatetdDate){
                        let actionDate = pendingCreatetdDate[prt]; 
                        if(actionDate >= firstoct && actionDate <= lastdec){                            
                            res.followupTasks_count = res.followupTasks_count + 1; 
                        }
                    }
                }                                
            }
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstoct && a[14] <= lastdec) totalRcForAll = totalRcForAll + 1; 
            } 
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstoct && s[9] <= lastdec) totalRcForAll = totalRcForAll + 1; 
            }  
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstoct && d[12] <= lastdec){                    
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1;
                }
                if(!d[11] && d[10] >= firstoct && d[10] <= lastdec) {
                    totalRcForAll = totalRcForAll + 1;
                    res.dismissTaskCount = res.dismissTaskCount + 1; 
                }
            }
        }        
        res.all_task_count = totalRcForAll;
        console.log('totalRcForAll==>', totalRcForAll)
        if(Store.getStoreData('data_card_value') === null){
            Store.updateStore('data_card_value', res);
        }else if(Store.getStoreData('data_card_value').all_task_count !== res.all_task_count){
            Store.updateStore('data_card_value', res);
        }else if(Store.getStoreData('data_card_value').pending_tasks_count !== res.pending_tasks_count){
            console.log("all_pending==>", Store.getStoreData('data_card_value').pending_tasks_count, res.pending_tasks_count)
            Store.updateStore('data_card_value', res);
        }else if(Store.getStoreData('data_card_value').followupTasks_count !== res.followupTasks_count){
            Store.updateStore('data_card_value', res);
        }else if(Store.getStoreData('data_card_value').dismissTaskCount !== res.dismissTaskCount){
            Store.updateStore('data_card_value', res);
        }
        console.log('data_card_value==>', res)
        return true
    }

    divideDashboardCardCoutAll(alltask, module_config, regulatorName, select_timespan){         
        let autoForwordFeedsAndRU = Store.getStoreData('autoForwordFeedsAndRU');    
        let autoForwordFeedsAndRU_update = [];    
        let pendingRCtasks = Store.getStoreData('pendingRCtasks'); 
        let pendingRCtasks_update = [];
        let dismissFeedsAndRUtasks = Store.getStoreData('dismissFeedsAndRUtasks');
        let dismissFeedsAndRUtasks_update = [];
        let newRCtasks = Store.getStoreData('newRCtasks');
        let newRCtasks_update = [];
        let saveRCtasks = Store.getStoreData('saveRCtasks');
        let saveRCtasks_update = [];
        let shareWithMeRCTasks = Store.getStoreData('shareWithMeRCTasks');
        let shareWithMeRCTasks_update = [];
        let followupRCtasks = Store.getStoreData('followupRCtasks');
        let followupRCtasks_update = [];
        let new_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let inprogress_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let dismissed_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let custom_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let saved_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let my_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let my_tasks = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        console.log("regulatorName==>", regulatorName)
        let userrole = Store.getStoreData('role');  
        if(select_timespan === "this_month"){   
            let startOfMonth = moment().startOf('month');
            startOfMonth.set('hour', 0);
            startOfMonth.set('minute', 0);
            startOfMonth.set('second', 0);
            startOfMonth = startOfMonth.unix();  
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();            
            console.log("startOfMonth===>",startOfMonth,"currentdate===>",currentdate)   
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= startOfMonth && a[14] <= currentdate){   
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= startOfMonth && p[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }                    
                }                
            }            
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= startOfMonth && d[12] <= currentdate){   
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= startOfMonth && d[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= startOfMonth && d[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= startOfMonth && n[10] <= currentdate){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= startOfMonth && n[12] <= currentdate) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= startOfMonth && s[9] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= startOfMonth && m[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= startOfMonth && f[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) { 
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                                my_tasks.totalCount = my_tasks.totalCount + 1;
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                            my_tasks.totalCount = my_tasks.totalCount + 1;
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_month"){   
            let prevMonthF = moment().subtract(1, 'months').startOf('month');
            prevMonthF.set('hour', 0);
            prevMonthF.set('minute', 0);
            prevMonthF.set('second', 0);
            prevMonthF = prevMonthF.unix();  
            let prevMonthL = moment().subtract(1, 'months').endOf('month').endOf('day');
            prevMonthL.set('hour', 23);
            prevMonthL.set('minute', 59);
            prevMonthL.set('second', 59);
            prevMonthL = prevMonthL.unix();          
            console.log("prevMonthFirstDay===>",prevMonthF,"prevMonthLastDay===>",prevMonthL)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= prevMonthF && a[14] <= prevMonthL){
                    new_rus.totalCount = new_rus.totalCount + 1;
                    autoForwordFeedsAndRU_update.push(a); 
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= prevMonthF && p[12] <= prevMonthL) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }            
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= prevMonthF && d[12] <= prevMonthL){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= prevMonthF && d[10] <= prevMonthL) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= prevMonthF && d[12] <= prevMonthL) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= prevMonthF && n[10] <= prevMonthL){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= prevMonthF && n[12] <= prevMonthL) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= prevMonthF && s[9] <= prevMonthL) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= prevMonthF && m[10] <= prevMonthL) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= prevMonthF && f[10] <= prevMonthL) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {  
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                                my_tasks.totalCount = my_tasks.totalCount + 1;
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                            my_tasks.totalCount = my_tasks.totalCount + 1;
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_thirty_days"){   
            let lastThirty = moment().subtract(30, 'days');     
            lastThirty.set('hour', 0);
            lastThirty.set('minute', 0);
            lastThirty.set('second', 0);
            lastThirty = lastThirty.unix();       
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();   
            console.log("lastThirty===>",lastThirty,"currentdate===>",currentdate)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= lastThirty && a[14] <= currentdate){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= lastThirty && p[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            console.log("regulatorKnownasObjectInprogress===>", regulatorKnownasObjectInprogress)
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= lastThirty && d[12] <= currentdate){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= lastThirty && d[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= lastThirty && d[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= lastThirty && n[10] <= currentdate){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= lastThirty && n[12] <= currentdate) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= lastThirty && s[9] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= lastThirty && m[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= lastThirty && f[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {  
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                                my_tasks.totalCount = my_tasks.totalCount + 1;
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{  
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                            my_tasks.totalCount = my_tasks.totalCount + 1;
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_twelve_month"){   
            let lastTwelve = moment().subtract(12, 'months'); 
            lastTwelve.set('hour', 0);
            lastTwelve.set('minute', 0);
            lastTwelve.set('second', 0);
            lastTwelve = lastTwelve.unix();            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();   
            console.log("lastTwelveMonth===>",lastTwelve,"currentDateFinalnew===>",currentdate)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= lastTwelve && a[14] <= currentdate){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= lastTwelve && p[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }            
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= lastTwelve && d[12] <= currentdate){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= lastTwelve && d[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= lastTwelve && d[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= lastTwelve && n[10] <= currentdate){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= lastTwelve && n[12] <= currentdate) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= lastTwelve && s[9] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= lastTwelve && m[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject'); 
            console.log("followupRCtasks===>", followupRCtasks)           
            for(let f of followupRCtasks) {                     
                if (f[10] >= lastTwelve && f[10] <= currentdate) {                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){    
                            my_tasks.totalCount = my_tasks.totalCount + 1;
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }                    
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "this_year"){   
            let thisyear = moment().startOf('year');  
            thisyear.set('hour', 0);
            thisyear.set('minute', 0);
            thisyear.set('second', 0);
            thisyear = thisyear.unix();            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();
            console.log("thisyearfirstday===>",thisyear,"currentDateFinalnew===>",currentdate) 
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= thisyear && a[14] <= currentdate){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= thisyear && p[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= thisyear && d[12] <= currentdate){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= thisyear && d[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= thisyear && d[12] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= thisyear && n[10] <= currentdate){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= thisyear && n[12] <= currentdate) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= thisyear && s[9] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= thisyear && m[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= thisyear && f[10] <= currentdate) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {  
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{ 
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                            my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_year"){   
            let lastyear = moment().subtract(1, 'year').startOf('year');    
            lastyear.set('hour', 0);
            lastyear.set('minute', 0);
            lastyear.set('second', 0);
            lastyear = lastyear.unix();  
            let lastyearend = moment().subtract(1, 'year').endOf('year').endOf('day');    
            lastyearend.set('hour', 23);
            lastyearend.set('minute', 59);
            lastyearend.set('second', 59);
            lastyearend = lastyearend.unix();            
            console.log("lastyearfirstday===>",lastyear,"lastyearendday===>",lastyearend)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= lastyear && a[14] <= lastyearend){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= lastyear && p[12] <= lastyearend) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            console.log("regulatorKnownasObjectInprogress===>", regulatorKnownasObjectInprogress)
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= lastyear && d[12] <= lastyearend){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= lastyear && d[10] <= lastyearend) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= lastyear && d[12] <= lastyearend) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= lastyear && n[10] <= lastyearend){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= lastyear && n[12] <= lastyearend) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= lastyear && s[9] <= lastyearend) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= lastyear && m[10] <= lastyearend) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= lastyear && f[10] <= lastyearend) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {  
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{     
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                            my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_one"){   
            let firstJana = moment().startOf('year'); 
            firstJana.set('hour', 0);
            firstJana.set('minute', 0);
            firstJana.set('second', 0);
            firstJana = firstJana.unix();    
            let lastmarch = moment().month(2).endOf('month').endOf('day');  
            lastmarch.set('hour', 23);
            lastmarch.set('minute', 59);
            lastmarch.set('second', 59);
            lastmarch = lastmarch.unix();              
            console.log("currentyearfirstday===>",firstJana,"currentyearmarchlasttday++>", lastmarch)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstJana && a[14] <= lastmarch){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= firstJana && p[12] <= lastmarch) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            console.log("regulatorKnownasObjectInprogress===>", regulatorKnownasObjectInprogress)
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstJana && d[12] <= lastmarch){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= firstJana && d[10] <= lastmarch) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= firstJana && d[12] <= lastmarch) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= firstJana && n[10] <= lastmarch){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= firstJana && n[12] <= lastmarch) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstJana && s[9] <= lastmarch) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstJana && m[10] <= lastmarch) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstJana && f[10] <= lastmarch) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{      
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                            my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_two"){   
            let firstapril = moment().month(3).startOf('month');  
            firstapril.set('hour', 0);
            firstapril.set('minute', 0);
            firstapril.set('second', 0);
            firstapril = firstapril.unix();
            let lastjune = moment().month(5).endOf('month').endOf('day');    
            lastjune.set('hour', 23);
            lastjune.set('minute', 59);
            lastjune.set('second', 59);
            lastjune = lastjune.unix();              
            console.log("currentyearfirstapril===>",firstapril,"currentyearlastjune++>", lastjune)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstapril && a[14] <= lastjune){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= firstapril && p[12] <= lastjune) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            console.log("regulatorKnownasObjectInprogress===>", regulatorKnownasObjectInprogress)
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstapril && d[12] <= lastjune){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= firstapril && d[10] <= lastjune) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= firstapril && d[12] <= lastjune) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= firstapril && n[10] <= lastjune){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= firstapril && n[12] <= lastjune) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstapril && s[9] <= lastjune) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstapril && m[10] <= lastjune) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstapril && f[10] <= lastjune) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {                    
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{            
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                            my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_three"){   
            let firstjuly = moment().month(6).startOf('month');           
            firstjuly.set('hour', 0);
            firstjuly.set('minute', 0);
            firstjuly.set('second', 0);
            firstjuly = firstjuly.unix();
            let lastsep = moment().month(8).endOf('month').endOf('day');   
            lastsep.set('hour', 23);
            lastsep.set('minute', 59);
            lastsep.set('second', 59);
            lastsep = lastsep.unix(); 
            console.log("currentyearfirstjuly===>",firstjuly,"currentyearlastsep++>", lastsep)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstjuly && a[14] <= lastsep){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= firstjuly && p[12] <= lastsep) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            console.log("regulatorKnownasObjectInprogress===>", regulatorKnownasObjectInprogress)
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstjuly && d[12] <= lastsep){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= firstjuly && d[10] <= lastsep) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= firstjuly && d[12] <= lastsep) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= firstjuly && n[10] <= lastsep){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= firstjuly && n[12] <= lastsep) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstjuly && s[9] <= lastsep) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstjuly && m[10] <= lastsep) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstjuly && f[10] <= lastsep) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {                    
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{            
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                            my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_four"){   
            let firstoct = moment().month(9).startOf('month')       
            firstoct.set('hour', 0);
            firstoct.set('minute', 0);
            firstoct.set('second', 0);
            firstoct = firstoct.unix();
            let lastdec = moment().month(11).endOf('month').endOf('day')
            lastdec.set('hour', 23);
            lastdec.set('minute', 59);
            lastdec.set('second', 59);
            lastdec = lastdec.unix(); 
            console.log("currentyearfirstoct===>",firstoct,"currentyearlastdec++>", lastdec)
            //New_RUs Start          
            let regulatorKnownasObjectNewRus = Store.getStoreData('regulatorKnownasObject');
            for(let a of autoForwordFeedsAndRU) {                     
                if (a[14] >= firstoct && a[14] <= lastdec){
                    new_rus.totalCount = new_rus.totalCount + 1; 
                    autoForwordFeedsAndRU_update.push(a);
                    if(a[8] in regulatorName){
                        regulatorKnownasObjectNewRus[regulatorName[a[8]]] = regulatorKnownasObjectNewRus[regulatorName[a[8]]] + 1
                    }
                }
            }            
            for(let k in regulatorKnownasObjectNewRus){
                if(regulatorKnownasObjectNewRus[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectNewRus[k];
                    new_rus.data.push(regulator_data);
                }
            }
            //New_RUs Start     
            //In_progress_RUs Start     
            let regulatorKnownasObjectInprogress = Store.getStoreData('regulatorKnownasObject');
            for(let p of pendingRCtasks) {                 
                if (p[11] !== 'Complete' && p[12] >= firstoct && p[12] <= lastdec) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (p[1] !== Store.getStoreData('companyid')) {                    
                            inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                            pendingRCtasks_update.push(p);
                            if(p[8] in regulatorName){
                                regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                            }
                        }  
                    }else{            
                        inprogress_rus.totalCount = inprogress_rus.totalCount + 1; 
                        pendingRCtasks_update.push(p);
                        if(p[8] in regulatorName){
                            regulatorKnownasObjectInprogress[regulatorName[p[8]]] = regulatorKnownasObjectInprogress[regulatorName[p[8]]] + 1
                        }
                    }
                }                
            }
            console.log("regulatorKnownasObjectInprogress===>", regulatorKnownasObjectInprogress)
            for(let k in regulatorKnownasObjectInprogress){
                if(regulatorKnownasObjectInprogress[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectInprogress[k];
                    inprogress_rus.data.push(regulator_data);
                }
            }
            //In_progress_RUs End    
            //dismissed_RUs Start     
            let regulatorKnownasObjectDismissed = Store.getStoreData('regulatorKnownasObject');
            for(let d of dismissFeedsAndRUtasks) {                     
                if (d[11] && d[12] >= firstoct && d[12] <= lastdec){                    
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 0 && d[10] >= firstoct && d[10] <= lastdec) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
                if(!d[11] && d[15] === 1 && d[12] >= firstoct && d[12] <= lastdec) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (d[1] !== Store.getStoreData('companyid')) {                    
                            dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                            dismissFeedsAndRUtasks_update.push(d)
                            if(d[8] in regulatorName){
                                regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                            }
                        }  
                    }else{            
                        dismissed_rus.totalCount = dismissed_rus.totalCount + 1;
                        dismissFeedsAndRUtasks_update.push(d)
                        if(d[8] in regulatorName){
                            regulatorKnownasObjectDismissed[regulatorName[d[8]]] = regulatorKnownasObjectDismissed[regulatorName[d[8]]] + 1
                        }
                    }
                }
            }  
            
            for(let k in regulatorKnownasObjectDismissed){
                if(regulatorKnownasObjectDismissed[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectDismissed[k];
                    dismissed_rus.data.push(regulator_data);
                }
            }
            //dismissed_RUs End    
            //custom_rus Start     
            let regulatorKnownasObjectCustom = Store.getStoreData('regulatorKnownasObject');
            for(let n of newRCtasks) {                     
                if(n[11] === 0){              
                    if (n[10] >= firstoct && n[10] <= lastdec){
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    } 
                }else{
                    if (n[12] >= firstoct && n[12] <= lastdec) {
                        if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                            if (n[1] !== Store.getStoreData('companyid')) {                    
                                custom_rus.totalCount = custom_rus.totalCount + 1;
                                newRCtasks_update.push(n);
                                if(n[8] in regulatorName){
                                    regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                                }
                            }  
                        }else{            
                            custom_rus.totalCount = custom_rus.totalCount + 1;
                            newRCtasks_update.push(n);
                            if(n[8] in regulatorName){
                                regulatorKnownasObjectCustom[regulatorName[n[8]]] = regulatorKnownasObjectCustom[regulatorName[n[8]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectCustom){
                if(regulatorKnownasObjectCustom[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectCustom[k];
                    custom_rus.data.push(regulator_data);
                }
            }
            //custom_rus End    
            //saved_rus Start     
            let regulatorKnownasObjectSaved = Store.getStoreData('regulatorKnownasObject');            
            for(let s of saveRCtasks) {                     
                if (s[9] >= firstoct && s[9] <= lastdec) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (s[1] !== Store.getStoreData('companyid')) {                    
                            saved_rus.totalCount = saved_rus.totalCount + 1;
                            saveRCtasks_update.push(s);
                            if(s[8] in regulatorName){
                                regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                            }
                        }  
                    }else{            
                        saved_rus.totalCount = saved_rus.totalCount + 1;
                        saveRCtasks_update.push(s);
                        if(s[8] in regulatorName){
                            regulatorKnownasObjectSaved[regulatorName[s[8]]] = regulatorKnownasObjectSaved[regulatorName[s[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectSaved){
                if(regulatorKnownasObjectSaved[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectSaved[k];
                    saved_rus.data.push(regulator_data);
                }
            }
            //saved_rus End    
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstoct && m[10] <= lastdec) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (m[1] !== Store.getStoreData('companyid')) {                    
                            my_rus.totalCount = my_rus.totalCount + 1;
                            shareWithMeRCTasks_update.push(m)
                            if(m[8] in regulatorName){
                                regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                            }
                        }  
                    }else{            
                        my_rus.totalCount = my_rus.totalCount + 1;
                        shareWithMeRCTasks_update.push(m)
                        if(m[8] in regulatorName){
                            regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstoct && f[10] <= lastdec) {
                    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){  
                        if (f[1] !== Store.getStoreData('companyid')) {                    
                            followupRCtasks_update.push(f);
                            if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                                my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                                if(f[6] in regulatorName){
                                    regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                                }
                            }
                        }  
                    }else{            
                        followupRCtasks_update.push(f);
                        if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){                  
                            my_tasks.totalCount = my_tasks.totalCount + 1;                                    
                            if(f[6] in regulatorName){
                                regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                            }
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }
        
        Store.updateStore('new_rus', new_rus)
        Store.updateStore('autoForwordFeedsAndRU_update', autoForwordFeedsAndRU_update)
        Store.updateStore('pendingRCtasks_update', pendingRCtasks_update)
        Store.updateStore('dismissFeedsAndRUtasks_update', dismissFeedsAndRUtasks_update)
        Store.updateStore('newRCtasks_update', newRCtasks_update)
        Store.updateStore('saveRCtasks_update', saveRCtasks_update)
        Store.updateStore('shareWithMeRCTasks_update', shareWithMeRCTasks_update)
        Store.updateStore('followupRCtasks_update', followupRCtasks_update)
        Store.updateStore('inprogress_rus', inprogress_rus)
        Store.updateStore('dismissed_rus', dismissed_rus)
        Store.updateStore('custom_rus', custom_rus)
        Store.updateStore('saved_rus', saved_rus)
        Store.updateStore('my_rus', my_rus)
        Store.updateStore('my_tasks', my_tasks)
        console.log("new_rus_count==>", new_rus)
        console.log("inprogress_rus==>", inprogress_rus)
        console.log("dismissed_rus==>", dismissed_rus)
        console.log("custom_rus==>", custom_rus)
        console.log("saved_rus==>", saved_rus)
        console.log("my_rus==>", my_rus)
        console.log("my_tasks==>", my_tasks)
        return true;
    }
    divideDashboardCardCoutAllTeam(alltask, module_config, regulatorName, select_timespan){ 
        let shareWithMeRCTasks = Store.getStoreData('shareWithMeRCTasks');
        let shareWithMeRCTasks_update = [];
        let followupRCtasks = Store.getStoreData('followupRCtasks');
        let followupRCtasks_update = [];        
        let my_rus = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        let my_tasks = {totalCount: 0, data: [["Regulator", "Number of Regulator"]]}
        console.log("regulatorName==>", regulatorName)
        if(select_timespan === "this_month"){   
            let startOfMonth = moment().startOf('month');
            startOfMonth.set('hour', 0);
            startOfMonth.set('minute', 0);
            startOfMonth.set('second', 0);
            startOfMonth = startOfMonth.unix();  
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();            
            console.log("startOfMonth===>",startOfMonth,"currentdate===>",currentdate)  
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= startOfMonth && m[10] <= currentdate) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= startOfMonth && f[10] <= currentdate) {                    
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_month"){   
            let prevMonthF = moment().subtract(1, 'months').startOf('month');
            prevMonthF.set('hour', 0);
            prevMonthF.set('minute', 0);
            prevMonthF.set('second', 0);
            prevMonthF = prevMonthF.unix();  
            let prevMonthL = moment().subtract(1, 'months').endOf('month').endOf('day');
            prevMonthL.set('hour', 23);
            prevMonthL.set('minute', 59);
            prevMonthL.set('second', 59);
            prevMonthL = prevMonthL.unix();          
            console.log("prevMonthFirstDay===>",prevMonthF,"prevMonthLastDay===>",prevMonthL)              
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= prevMonthF && m[10] <= prevMonthL) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= prevMonthF && f[10] <= prevMonthL) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_thirty_days"){   
            let lastThirty = moment().subtract(30, 'days');     
            lastThirty.set('hour', 0);
            lastThirty.set('minute', 0);
            lastThirty.set('second', 0);
            lastThirty = lastThirty.unix();       
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();   
            console.log("lastThirty===>",lastThirty,"currentdate===>",currentdate)            
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= lastThirty && m[10] <= currentdate) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= lastThirty && f[10] <= currentdate) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_twelve_month"){   
            let lastTwelve = moment().subtract(12, 'months'); 
            lastTwelve.set('hour', 0);
            lastTwelve.set('minute', 0);
            lastTwelve.set('second', 0);
            lastTwelve = lastTwelve.unix();            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();   
            console.log("lastTwelveMonth===>",lastTwelve,"currentDateFinalnew===>",currentdate)             
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= lastTwelve && m[10] <= currentdate) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= lastTwelve && f[10] <= currentdate) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "this_year"){   
            let thisyear = moment().startOf('year');  
            thisyear.set('hour', 0);
            thisyear.set('minute', 0);
            thisyear.set('second', 0);
            thisyear = thisyear.unix();            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            currentdate = currentdate.unix();
            console.log("thisyearfirstday===>",thisyear,"currentDateFinalnew===>",currentdate)             
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= thisyear && m[10] <= currentdate) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= thisyear && f[10] <= currentdate) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "last_year"){   
            let lastyear = moment().subtract(1, 'year').startOf('year');    
            lastyear.set('hour', 0);
            lastyear.set('minute', 0);
            lastyear.set('second', 0);
            lastyear = lastyear.unix();  
            let lastyearend = moment().subtract(1, 'year').endOf('year').endOf('day');    
            lastyearend.set('hour', 23);
            lastyearend.set('minute', 59);
            lastyearend.set('second', 59);
            lastyearend = lastyearend.unix();            
            console.log("lastyearfirstday===>",lastyear,"lastyearendday===>",lastyearend)            
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= lastyear && m[10] <= lastyearend) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= lastyear && f[10] <= lastyearend) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_one"){   
            let firstJana = moment().startOf('year'); 
            firstJana.set('hour', 0);
            firstJana.set('minute', 0);
            firstJana.set('second', 0);
            firstJana = firstJana.unix();    
            let lastmarch = moment().month(2).endOf('month').endOf('day');  
            lastmarch.set('hour', 23);
            lastmarch.set('minute', 59);
            lastmarch.set('second', 59);
            lastmarch = lastmarch.unix();              
            console.log("currentyearfirstday===>",firstJana,"currentyearmarchlasttday++>", lastmarch)            
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstJana && m[10] <= lastmarch) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstJana && f[10] <= lastmarch) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_two"){   
            let firstapril = moment().month(3).startOf('month');  
            firstapril.set('hour', 0);
            firstapril.set('minute', 0);
            firstapril.set('second', 0);
            firstapril = firstapril.unix();
            let lastjune = moment().month(5).endOf('month').endOf('day');    
            lastjune.set('hour', 23);
            lastjune.set('minute', 59);
            lastjune.set('second', 59);
            lastjune = lastjune.unix();              
            console.log("currentyearfirstapril===>",firstapril,"currentyearlastjune++>", lastjune)            
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstapril && m[10] <= lastjune) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstapril && f[10] <= lastjune) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_three"){   
            let firstjuly = moment().month(6).startOf('month');           
            firstjuly.set('hour', 0);
            firstjuly.set('minute', 0);
            firstjuly.set('second', 0);
            firstjuly = firstjuly.unix();
            let lastsep = moment().month(8).endOf('month').endOf('day');   
            lastsep.set('hour', 23);
            lastsep.set('minute', 59);
            lastsep.set('second', 59);
            lastsep = lastsep.unix(); 
            console.log("currentyearfirstjuly===>",firstjuly,"currentyearlastsep++>", lastsep)            
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstjuly && m[10] <= lastsep) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstjuly && f[10] <= lastsep) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        }else if(select_timespan === "quarter_four"){   
            let firstoct = moment().month(9).startOf('month')       
            firstoct.set('hour', 0);
            firstoct.set('minute', 0);
            firstoct.set('second', 0);
            firstoct = firstoct.unix();
            let lastdec = moment().month(11).endOf('month').endOf('day')
            lastdec.set('hour', 23);
            lastdec.set('minute', 59);
            lastdec.set('second', 59);
            lastdec = lastdec.unix(); 
            console.log("currentyearfirstoct===>",firstoct,"currentyearlastdec++>", lastdec)            
            //my_rus Start     
            let regulatorKnownasObjectMy = Store.getStoreData('regulatorKnownasObject');            
            for(let m of shareWithMeRCTasks) {                     
                if (m[10] >= firstoct && m[10] <= lastdec) {
                    my_rus.totalCount = my_rus.totalCount + 1;
                    shareWithMeRCTasks_update.push(m)
                    if(m[8] in regulatorName){
                        regulatorKnownasObjectMy[regulatorName[m[8]]] = regulatorKnownasObjectMy[regulatorName[m[8]]] + 1
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMy){
                if(regulatorKnownasObjectMy[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMy[k];
                    my_rus.data.push(regulator_data);
                }
            }
            //my_rus End    
            //my_tasks Start     
            let regulatorKnownasObjectMyTasks = Store.getStoreData('regulatorKnownasObject');            
            for(let f of followupRCtasks) {                     
                if (f[10] >= firstoct && f[10] <= lastdec) {
                    followupRCtasks_update.push(f);
                    if(f[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                        my_tasks.totalCount = my_tasks.totalCount + 1;
                        if(f[6] in regulatorName){
                            regulatorKnownasObjectMyTasks[regulatorName[f[6]]] = regulatorKnownasObjectMyTasks[regulatorName[f[6]]] + 1
                        }
                    }
                }
            }
            
            for(let k in regulatorKnownasObjectMyTasks){
                if(regulatorKnownasObjectMyTasks[k] !== 0){
                    let regulator_data = [];
                    regulator_data[0] = k;
                    regulator_data[1] = regulatorKnownasObjectMyTasks[k];
                    my_tasks.data.push(regulator_data);
                }
            }
            //my_tasks End    
        } 
        
        Store.updateStore('shareWithMeRCTasks_update', shareWithMeRCTasks_update)
        Store.updateStore('followupRCtasks_update', followupRCtasks_update)
        Store.updateStore('my_rus', my_rus)
        Store.updateStore('my_tasks', my_tasks)        
        console.log("my_rus==>", my_rus)
        console.log("my_tasks==>", my_tasks)
        return true;
    }
}
export default new CardLoader();