import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import { IoIosArrowUp} from "react-icons/io";
import CSLTable from '../Common/CSLTable';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const RegModalNameLabelDiv = styled.div`
    color: #252525;
    font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;    
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto; 
    height: 20px;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

class AutoDismissGeneralDocType extends React.Component
{
	state = { ready: false, docTypeAll: [], rowchecked: false, autoDismissDocumentTypeIDs: {}};

	componentDidMount()
	{
        let allselectcheckContact = true;
        let autoDismissDocumentTypeIDs = this.props.general.autoDismissDocumentTypeIDs;
        let docTypeAll = Store.getStoreData('rutypename');        
        docTypeAll.forEach((value) => {  
            if(this.props.general.isAutoDismissDocumentTypesCheck !== 0){
                value.select = false;
                if(value.id in autoDismissDocumentTypeIDs){                           
                    value.select = true;
                    autoDismissDocumentTypeIDs[value.id] = value.documentname;
                }
            }else{
                value.select = true;
                autoDismissDocumentTypeIDs[value.id] = value.documentname;
            }
        })
        docTypeAll.forEach((user) => {
            if(!user.select){
                allselectcheckContact = false
            }
        })
        console.log("autoDismissDocumentTypeIDs",autoDismissDocumentTypeIDs)
        this.setState({docTypeAll: docTypeAll, rowchecked: allselectcheckContact, autoDismissDocumentTypeIDs: autoDismissDocumentTypeIDs, ready: true})
    }

    selectDocTypeAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let docTypeAll = this.state.docTypeAll;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of docTypeAll) {                
                let selectObject = {}           
                i++;   
                // let val = 1;            
                this.state.autoDismissDocumentTypeIDs[d.id] = d.documentname;                          
                selectObject.id = d.id;                
                selectObject.documentname = d.documentname;                              
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,docTypeAll: selectArray, autoDismissDocumentTypeIDs: this.state.autoDismissDocumentTypeIDs})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of docTypeAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.autoDismissDocumentTypeIDs[d.id.toString()];                            
                selectObject.id = d.id;                
                selectObject.documentname = d.documentname;                    
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,docTypeAll: selectArray, autoDismissDocumentTypeIDs: this.state.autoDismissDocumentTypeIDs})
       }
   }

   selectDocType = (event) => {        
        let index = event.target.value;        
        var docTypeAll = this.state.docTypeAll;        
            
        if(docTypeAll[index].select){
            docTypeAll[index].select = false;            
            delete this.state.autoDismissDocumentTypeIDs[docTypeAll[index].id.toString()];                    
        } else {
            docTypeAll[index].select = true;            
            let userid = docTypeAll[index].id.toString();                 
            this.state.autoDismissDocumentTypeIDs[userid] = docTypeAll[index].documentname;                            
        }
        this.setState({docTypeAll: docTypeAll, rowchecked: false, autoDismissDocumentTypeIDs: this.state.autoDismissDocumentTypeIDs})
        let allselectcheck = true
        for(let d of docTypeAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    processDocumentTypeData = () => {            
        var documentTypeList = this.state.docTypeAll; 
        //console.log("regulatorList==>", documentTypeList)     
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectDocTypeAll}/></div>, sortable: false, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectDocType}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},   
                    {Header: 'Document Types', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
        
        documentTypeList.sort(function(a,b) {return (a.documentname > b.documentname) ? 1 : ((b.documentname > a.documentname) ? -1 : 0);} ); 
        for(let d of documentTypeList) {     
            //console.log("regulatorList==>", d)      
            i++;
            let elem = {'index' : i,
                    'id': d.id,
                    'name_value' : d.documentname,
                    'select' : d.select                    
                    };
            ret.data.push(elem);        
        }        
        return ret;
    }

    submitAutoDismissData = (event) => {
        event.preventDefault();
        console.log("autoDismissRegulatorName==>",this.state.autoDismissDocumentTypeIDs) 
        this.props.saveAutoDismissValueDocType(this.state.autoDismissDocumentTypeIDs);
    }

    

	render()
	{  
        if(!this.state.ready){
            return(<div></div>)
        }      
		return (
            <div>
                <div style={{marginTop: "20px"}}>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Document Types</div> 
                    <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processDocumentTypeData} headerText={''} tableRows={5}/>                   
                </OverviewInfoBody> 
                </div>                               
                <div style={{float: "right",marginTop: "-20px"}}>                        
                    <SaveBtn onClick={this.submitAutoDismissData}>Save Auto Dismiss Data</SaveBtn>
                </div>
                
            </div>			
		);
	}
}

export default AutoDismissGeneralDocType;