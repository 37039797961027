import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import { IoIosArrowUp} from "react-icons/io";
import CSLTable from '../Common/CSLTable';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const RegModalNameLabelDiv = styled.div`
    color: #252525;
    font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;    
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto; 
    height: 20px;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

class AutoForwordGenaral extends React.Component
{
	state = {sendContactAutoForword: {},selectContactArray: [], selectDistributionArray: [], showCSLTable:false,rowchecked: false,rowcheckedD: false};

	componentDidMount()
	{
        let selectContactArray = [];
        let allselectcheckContact = true;
        let sendContactAutoForword = {}
        if('autoforwordContactListArray' in this.props.general && 'autoforwordContactid' in this.props.general){
            selectContactArray = this.props.general.autoforwordContactListArray;
            // console.log("selectContactArray==>", selectContactArray)
            let selectContactArray_save = this.props.general.autoforwordContactListArray;
            let selectContactArray_save_object = {};
            selectContactArray_save.forEach((user) => {
                selectContactArray_save_object[user.user_id.toString()] = 1;
            })
            let roleUser = []
            if('company_users' in this.props.permissions){
                roleUser = this.props.permissions.company_users;
            }
            let com_roles = Store.getStoreData('userroles');
            roleUser.forEach((user) => {     
                if (com_roles[user.user_id.toString()] !== 'no_access' && !(user.user_id in selectContactArray_save_object)) {           
                    user.select = false;
                    selectContactArray.push(user);                           
                }         
            })
            selectContactArray.forEach((user) => {
                if(!user.select){
                    allselectcheckContact = false
                }
            })

            console.log("selectContactArray==>", selectContactArray)
            console.log("autoforwordContactid==>", this.props.general.autoforwordContactid)
            sendContactAutoForword = this.props.general.autoforwordContactid;
        }else{
            let roleUser = []
            if('company_users' in this.props.permissions){
                roleUser = this.props.permissions.company_users;
            }
            let com_roles = Store.getStoreData('userroles');
            roleUser.forEach((user) => {     
                if (com_roles[user.user_id.toString()] !== 'no_access' ) {           
                    user.select = true;
                    selectContactArray.push(user);
                    sendContactAutoForword[user.user_id]=1;       
                }         
            })
        }
        let selectDistributionArray = [];
        let allselectcheck = true
        if('autoforwordContactid' in this.props.general){
            if(this.props.general.enable_distribution_lists === true && this.props.ditribution_lists.length !== 0){
                console.log("ditribution_lists", this.props.ditribution_lists)            
                this.props.ditribution_lists.forEach((distr) => {                
                    selectDistributionArray.push(distr);                     
                    if(!distr.select){
                        allselectcheck = false
                    }              
                })
            }
        }else{
            if(this.props.general.enable_distribution_lists === true && this.props.ditribution_lists.length !== 0){
                console.log("ditribution_lists", this.props.ditribution_lists)            
                this.props.ditribution_lists.forEach((distr) => {                
                    selectDistributionArray.push(distr);
                    for(let id of Object.keys(distr.assingnedUserID)){
                        sendContactAutoForword[id]=1;
                    }  
                    if(!distr.select){
                        allselectcheck = false
                    }              
                })
            }
        }
        console.log("sendContactAutoForword===>",sendContactAutoForword)
        this.setState({selectContactArray: selectContactArray,selectDistributionArray: selectDistributionArray, showCSLTable: true,rowchecked: allselectcheckContact,rowcheckedD: allselectcheck,sendContactAutoForword: sendContactAutoForword})
        
    }

    selectContact = (event) => {        
        let index = event.target.value;        
        var contactListAll = this.state.selectContactArray;        
            
        if(contactListAll[index].select){
            contactListAll[index].select = false;            
            delete this.state.sendContactAutoForword[contactListAll[index].user_id.toString()];                    
        } else {
            contactListAll[index].select = true;            
            let userid = contactListAll[index].user_id.toString();
            let val = 1;            
            this.state.sendContactAutoForword[userid] = val;                            
        }
        this.setState({selectContactArray: contactListAll, sendContactAutoForword: this.state.sendContactAutoForword,rowchecked: false})
        let allselectcheck = true
        for(let d of contactListAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    selectContactAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let contactListAll = this.state.selectContactArray;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of contactListAll) {                
                let selectObject = {}           
                i++;   
                let val = 1;            
                this.state.sendContactAutoForword[d.user_id] = val;                          
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,selectContactArray: selectArray, sendContactAutoForword: this.state.sendContactAutoForword})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of contactListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.sendContactAutoForword[d.user_id.toString()];                            
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,selectContactArray: selectArray, sendContactAutoForword: this.state.sendContactAutoForword})
       }
   }
    
    processMyContactListData = () => {            
        var contactList = this.state.selectContactArray;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectContactAll}/></div>, sortable: false, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectContact}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Clients', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
                      
        for(let d of contactList) {           
            i++;
            let elem = {'index' : i,
                    'id': d.user_id, 
                    'name_value' : d.name,
                    'select' : d.select                    
                    };
            ret.data.push(elem);
        
        }
        
        return ret;
    }

    selectDistribution = (event) => {        
        let index = event.target.value;        
        var contactListAll = this.state.selectDistributionArray;        
            
        if(contactListAll[index].select){
            contactListAll[index].select = false;  
            for(let id of Object.keys(contactListAll[index].assingnedUserID)){
                delete this.state.sendContactAutoForword[id.toString()]; 
            }              
        } else {
            contactListAll[index].select = true;  
            let val = 1;            
            for(let id of Object.keys(contactListAll[index].assingnedUserID)){
                this.state.sendContactAutoForword[id]=val;
            }                                    
        }
        this.setState({selectDistributionArray: contactListAll, sendContactAutoForword: this.state.sendContactAutoForword,rowcheckedD: false})
        let allselectcheck = true
        for(let d of contactListAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowcheckedD: true})
        }
    }

    selectDistributionAll = (event) => {
        let rowcheckedD = this.state.rowcheckedD;
        rowcheckedD = rowcheckedD ? false : true;        
        let distributionListAll = this.state.selectDistributionArray;         
        
       if(rowcheckedD) {   
            let i = 0;            
            let selectArray = [];
            for(let d of distributionListAll) {                
                let selectObject = {}           
                i++;   
                let val = 1;            
                for(let id of Object.keys(d.assingnedUserID)){
                    this.state.sendContactAutoForword[id]=val;
                }                     
                selectObject.assingnedUserID = d.assingnedUserID;
                selectObject.curgcid = d.curgcid;
                selectObject.distributionID = d.distributionID;
                selectObject.distributionItem = d.distributionItem; 
                selectObject.distributionName = d.distributionName;                
                selectObject.select = true;                               
                selectArray.push(selectObject)
            }
            this.setState({rowcheckedD: rowcheckedD,selectDistributionArray: selectArray, sendContactAutoForword: this.state.sendContactAutoForword})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of distributionListAll) {                
                let selectObject = {}           
                i++; 
                for(let id of Object.keys(d.assingnedUserID)){
                    delete this.state.sendContactAutoForword[id.toString()]; 
                }                                            
                selectObject.assingnedUserID = d.assingnedUserID;
                selectObject.curgcid = d.curgcid;
                selectObject.distributionID = d.distributionID;
                selectObject.distributionItem = d.distributionItem; 
                selectObject.distributionName = d.distributionName;                
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            this.setState({rowcheckedD: rowcheckedD,selectDistributionArray: selectArray, sendContactAutoForword: this.state.sendContactAutoForword})
       }
   }

    processMyDistributionListData = () => {            
        var contactList = this.state.selectDistributionArray;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowcheckedD} onClick={this.selectDistributionAll}/></div>, sortable: false, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectDistribution}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Distributions list', accessor: 'distribution_name', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
                      
        for(let d of contactList) {           
            i++;
            let elem = {'index' : i,
                    'id': d.distributionID, 
                    'distribution_name' : d.distributionName,
                    'select' : d.select                    
                    };
            ret.data.push(elem);
        
        }
        
        return ret;
    }

    submitAutoForwordData = (event) => {
        event.preventDefault();
        console.log("selectDistributionArray==>",this.state.selectDistributionArray)
        // console.log("ditribution_lists==>",this.props.ditribution_lists)
        console.log("selectContactArray",this.state.selectContactArray)
        
        let sendContactAutoForwordid = {};
        this.state.selectContactArray.forEach((user) => {
            if(user.select){
                sendContactAutoForwordid[user.user_id] = 1;
            }
        })

        this.state.selectDistributionArray.forEach((usernew) => {
            if(usernew.select){
                for(let id of Object.keys(usernew.assingnedUserID)){
                    sendContactAutoForwordid[id]=1;
                }                
            }
        })
        console.log("sendContactAutoForword",sendContactAutoForwordid)
        let autofObject = {}
        autofObject.selectDistributionArray = this.state.selectDistributionArray;
        autofObject.selectContactArray = this.state.selectContactArray;
        autofObject.sendContactAutoForword = sendContactAutoForwordid;            
        this.props.saveAutoForwordValue(autofObject);
        
        
    }

	render()
	{
        if (!this.state.showCSLTable) {
			return (<div>Loading...</div>);
		}
		return (
            <div>
                <div style={{marginTop: "20px"}}>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Forward to all contacts</div> 
                    <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processMyContactListData} headerText={''} tableRows={5}/>                   
                </OverviewInfoBody> 
                </div>
                {
                    (() => {
                        if(this.props.general.enable_distribution_lists === true && this.props.ditribution_lists.length !== 0){
                            return(
                                <div style={{marginTop: "15px"}}>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Forward to all Distribution List</div> 
                                        <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody>
                                        <CSLTable add={false} processData={this.processMyDistributionListData} headerText={''} tableRows={5}/>                    
                                    </OverviewInfoBody> 
                                </div>
                            )
                        }
                    })()
                }                
                <div style={{float: "right",marginTop: "-20px"}}>                        
                    <SaveBtn onClick={this.submitAutoForwordData}>Save Auto Forward Data</SaveBtn>
                </div>
                
            </div>			
		);
	}
}

export default AutoForwordGenaral;