import React, { Component } from "react";
import { Chart } from "react-google-charts";

class UIPieChart extends Component { 
  
  constructor(props) {
    super(props);
  }

  handleShowModal = (Chart) => {
    const data = this.props.data;    
    if(typeof Chart.chartWrapper.getChart().getSelection()[0] !== 'undefined'){
      this.props.fetchValue(data[Chart.chartWrapper.getChart().getSelection()[0].row + 1][0])
    }    
  }

  render() {  
    return <Chart chartType="PieChart" data={this.props.data} options={this.props.options} width={"290px"} height={"168px"} 
            chartEvents={[
              {
                eventName: "select",
                callback: this.handleShowModal                
              }
            ]}      
          />;
  }
}
export default UIPieChart;
