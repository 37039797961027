import React from 'react';
import { Icon } from 'react-icons-kit';
import {androidRemove} from 'react-icons-kit/ionicons/androidRemove';
import styled from 'styled-components';
import CslDropDown from './CslDropDown';
import CslDatepicker from './CslDatepicker';
import moment from 'moment';
import Store from '../../../../../Common/Store';

const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding: 15px 20px;
`;
const FilterText = styled.div`
	float: left;
`;
const FilterChevron = styled.div`
	float: right;
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const Section45 = styled.div`
	float: left;
	width: 45%;
	box-sizing: border-box;
`;
const Section40 = styled.div`
	float: left;
	width: 40%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	padding-right: 20px;
	box-sizing: border-box;
`;
const Section10 = styled.div`
	float: left;
	width: 10%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 10px;
`;
const FieldLabel = styled.div`
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding-bottom: 15px;
`;
const FilterClearBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	float: left;
    padding: 12px 13%;
    cursor: pointer;
`;
const FilterApplyBtn = styled.button`
	background-color: #4FB2AF;
	border: 1px solid #4FB2AF;
	border-radius: 4px;
	color: #ffffff;
	float: right;
    padding: 12px 18%;
    cursor: pointer;
`;

class AdvancedFilterMyTasks extends React.Component
{

	state = {ready: false,
		isclear: false,
		statusData: [],
		selectedStatusData: {},
		giftsFilterStatus: "",
		filterAssignFromDate: new Date(),
		filterAssignToDate: new Date(),
		filterDueFromDate: new Date(),
		filterDueToDate: new Date(),
		filterIssueFromDate: new Date(),
		filterIssueToDate: new Date(),
		filterAddedFromDate: new Date(),
		filterAddedToDate: new Date()
	};

	componentDidMount(){	
		let filterAddedFromDate = new Date();
		let filterAddedToDate = new Date();
		let select_timespan = Store.getStoreData('defaultTimePeriod');
		if(select_timespan === "this_month"){   
			let startOfMonth = moment().startOf('month');
            startOfMonth.set('hour', 0);
            startOfMonth.set('minute', 0);
            startOfMonth.set('second', 0);            
			filterAddedFromDate = new Date(startOfMonth);
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);                 
			filterAddedToDate = new Date(currentdate);
		}else if(select_timespan === "last_month"){   
            let prevMonthF = moment().subtract(1, 'months').startOf('month');
            prevMonthF.set('hour', 0);
            prevMonthF.set('minute', 0);
            prevMonthF.set('second', 0);
            filterAddedFromDate = new Date(prevMonthF);
            let prevMonthL = moment().subtract(1, 'months').endOf('month').endOf('day');
            prevMonthL.set('hour', 23);
            prevMonthL.set('minute', 59);
            prevMonthL.set('second', 59);
            filterAddedToDate = new Date(prevMonthL);
		}else if(select_timespan === "last_thirty_days"){   
            let lastThirty = moment().subtract(30, 'days');     
            lastThirty.set('hour', 0);
            lastThirty.set('minute', 0);
            lastThirty.set('second', 0);
            filterAddedFromDate = new Date(lastThirty);     
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            filterAddedToDate = new Date(currentdate);
		}else if(select_timespan === "last_twelve_month"){   
            let lastTwelve = moment().subtract(12, 'months'); 
            lastTwelve.set('hour', 0);
            lastTwelve.set('minute', 0);
            lastTwelve.set('second', 0);
            filterAddedFromDate = new Date(lastTwelve);            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            filterAddedToDate = new Date(currentdate); 
		}else if(select_timespan === "this_year"){   
            let thisyear = moment().startOf('year');  
            thisyear.set('hour', 0);
            thisyear.set('minute', 0);
            thisyear.set('second', 0);
            filterAddedFromDate = new Date(thisyear);  
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            filterAddedToDate = new Date(currentdate);
		}else if(select_timespan === "last_year"){   
            let lastyear = moment().subtract(1, 'year').startOf('year');    
            lastyear.set('hour', 0);
            lastyear.set('minute', 0);
            lastyear.set('second', 0);
            filterAddedFromDate = new Date(lastyear);   
            let lastyearend = moment().subtract(1, 'year').endOf('year').endOf('day');    
            lastyearend.set('hour', 23);
            lastyearend.set('minute', 59);
            lastyearend.set('second', 59);
            filterAddedToDate = new Date(lastyearend);
		}else if(select_timespan === "quarter_one"){   
            let firstJana = moment().startOf('year'); 
            firstJana.set('hour', 0);
            firstJana.set('minute', 0);
            firstJana.set('second', 0);
            filterAddedFromDate = new Date(firstJana);  
            let lastmarch = moment().month(2).endOf('month').endOf('day');  
            lastmarch.set('hour', 23);
            lastmarch.set('minute', 59);
            lastmarch.set('second', 59);
            filterAddedToDate = new Date(lastmarch); 
		}else if(select_timespan === "quarter_two"){   
            let firstapril = moment().month(3).startOf('month');  
            firstapril.set('hour', 0);
            firstapril.set('minute', 0);
            firstapril.set('second', 0);
            filterAddedFromDate = new Date(firstapril);
            let lastjune = moment().month(5).endOf('month').endOf('day');    
            lastjune.set('hour', 23);
            lastjune.set('minute', 59);
            lastjune.set('second', 59);
            filterAddedToDate = new Date(lastjune);   
		}else if(select_timespan === "quarter_three"){   
            let firstjuly = moment().month(6).startOf('month');           
            firstjuly.set('hour', 0);
            firstjuly.set('minute', 0);
            firstjuly.set('second', 0);
            filterAddedFromDate = new Date(firstjuly);
            let lastsep = moment().month(8).endOf('month').endOf('day');   
            lastsep.set('hour', 23);
            lastsep.set('minute', 59);
            lastsep.set('second', 59);
            filterAddedToDate = new Date(lastsep); 
		}else if(select_timespan === "quarter_four"){   
            let firstoct = moment().month(9).startOf('month')       
            firstoct.set('hour', 0);
            firstoct.set('minute', 0);
            firstoct.set('second', 0);
            filterAddedFromDate = new Date(firstoct);
            let lastdec = moment().month(11).endOf('month').endOf('day')
            lastdec.set('hour', 23);
            lastdec.set('minute', 59);
            lastdec.set('second', 59);
            filterAddedToDate = new Date(lastdec); 
		}  
		
		let statusData = [
			{id: 0, label: "select status"},			
			{id: 1, label: "Pending"},
			{id: 2, label: "Overdue"},					
			{id: 3, label: "Completed"}					
		]
		let selectedStatusData = {id: 0, label: "select status"}
		
		this.setState({ready: true, filterAddedFromDate, filterAddedToDate, statusData, selectedStatusData,
			filterAssignFromDate: filterAddedFromDate,
			filterAssignToDate: filterAddedToDate,
			filterDueFromDate: filterAddedFromDate,
			filterDueToDate: filterAddedToDate,
			filterIssueFromDate: filterAddedFromDate,
			filterIssueToDate: filterAddedToDate
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {	
			let statusData = [
				{id: 0, label: "select status"},			
				{id: 1, label: "Pending"},
				{id: 2, label: "Overdue"},					
				{id: 3, label: "Completed"}					
			]
			let selectedStatusData = {id: 0, label: "select status"}
			
			this.setState({ready: true, statusData, selectedStatusData})
		}
	}
	
	changeClear = () => {
		this.setState({isclear: false})
	}

	statusValue = (selected_options) => {
		console.log("selected_options",selected_options)
		let filterStatus = "";
		if(selected_options.id !== 0){
			filterStatus = selected_options.label;
		}
		this.setState({giftsFilterStatus: filterStatus})        
	}
	
	onAssignFromDate = (date) => {
        this.setState({filterAssignFromDate: date});		
	}
	onAssignToDate = (date) => {
        this.setState({filterAssignToDate: date});
    }
	onDueFromDate = (date) => {
        this.setState({filterDueFromDate: date});		
	}
	onDueToDate = (date) => {
        this.setState({filterDueToDate: date});
    }
	onIssueFromDate = (date) => {
        this.setState({filterIssueFromDate: date});		
	}
	onIssueToDate = (date) => {
        this.setState({filterIssueToDate: date});
    }
	onAddedFromDate = (date) => {
        this.setState({filterAddedFromDate: date});		
	}
	onAddedToDate = (date) => {
        this.setState({filterAddedToDate: date});
    }

	filterClear = () => { 
		this.setState({ isclear: true})		
		this.props.filterSubmitValue(Store.getStoreData('filterClearMyTasks'))		
	}

	filterSubmit = () => {
        let myTasksAll = Store.getStoreData('filterMyTasks');		
        //console.log("inProRusAll",inProRusAll);
		let filterStatus = [];
        if (this.state.giftsFilterStatus === "") {
            filterStatus = myTasksAll;
        } else {			
            myTasksAll.forEach((item) => {  
                if (item[9] === this.state.giftsFilterStatus) {
                    filterStatus.push(item);
                }
            })
        }        	

		let assignFormdatetoDate = [];
        if (this.state.filterAssignFromDate === "" && this.state.filterAssignToDate === "") {
            assignFormdatetoDate = filterStatus;
        }else{
            filterStatus.forEach((item) => {                
                let assignDate = item[10];
				let fromDateValue = this.state.filterAssignFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterAssignToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("assignDate",assignDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(assignDate >= fromDate && assignDate <= toDate){
                    assignFormdatetoDate.push(item);
                }
            })
        }	
		
		let dueFormdatetoDate = [];
        if (this.state.filterDueFromDate === "" && this.state.filterDueToDate === "") {
            dueFormdatetoDate = assignFormdatetoDate;
        }else{
            assignFormdatetoDate.forEach((item) => {                
                let dueDate = moment(item[8], 'DD/MM/YYYY').unix();
				let fromDateValue = this.state.filterDueFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterDueToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("dueDate",dueDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(dueDate >= fromDate && dueDate <= toDate){
                    dueFormdatetoDate.push(item);
                }
            })
        }
		
		let issueFormdatetoDate = [];
        if (this.state.filterIssueFromDate === "" && this.state.filterIssueToDate === "") {
            issueFormdatetoDate = dueFormdatetoDate;
        }else{
            dueFormdatetoDate.forEach((item) => {                
                let issueDate = moment(item[11], 'YYYY/MM/DD').unix();
				let fromDateValue = this.state.filterIssueFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterIssueToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("issueDate",issueDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(issueDate >= fromDate && issueDate <= toDate){
                    issueFormdatetoDate.push(item);
                }
            })
        }

		let addedFormdatetoDate = [];
        if (this.state.filterAddedFromDate === "" && this.state.filterAddedToDate === "") {
            addedFormdatetoDate = issueFormdatetoDate;
        }else{
            issueFormdatetoDate.forEach((item) => {
                // let task_json = JSON.parse(item.task_json); 
                let createdDate = item[10];
				let fromDateValue = this.state.filterAddedFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterAddedToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("createdDate",createdDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(createdDate >= fromDate && createdDate <= toDate){
                    addedFormdatetoDate.push(item);
                }
            })
        }		
        
        console.log("addedFormdatetoDate",addedFormdatetoDate)
        this.props.filterSubmitValue(addedFormdatetoDate)
        
    }	

	render()
	{
		if(!this.state.ready){
			return(<div></div>)
		}
		return (
			<div>	
				<FilterBody>
					<Section20>
						<FieldLabel>Status</FieldLabel>
						<CslDropDown allData={this.state.statusData} selectedAllData={this.state.selectedStatusData} statusValue={this.statusValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
					</Section20>
					<Section40>
						<FieldLabel>Assigned Date</FieldLabel>
						<Section45><CslDatepicker updateFromDate={this.state.filterAssignFromDate} ondateValue={this.onAssignFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<Section10><Icon icon={androidRemove}/></Section10>
						<Section45><CslDatepicker updateFromDate={this.state.filterAssignToDate} ondateValue={this.onAssignToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<div style={{clear: "both"}}></div>
					</Section40> 
					<div style={{clear: "both"}}></div>
					<Section40 style={{marginTop: "10px"}}>
						<FieldLabel style={{marginTop: "10px"}}>Due Date</FieldLabel>
						<Section45><CslDatepicker updateFromDate={this.state.filterDueFromDate} ondateValue={this.onDueFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<Section10><Icon icon={androidRemove}/></Section10>
						<Section45><CslDatepicker updateFromDate={this.state.filterDueToDate} ondateValue={this.onDueToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<div style={{clear: "both"}}></div>
					</Section40> 
					<Section40 style={{marginTop: "10px"}}>
						<FieldLabel style={{marginTop: "10px"}}>Issue Date</FieldLabel>
						<Section45><CslDatepicker updateFromDate={this.state.filterIssueFromDate} ondateValue={this.onIssueFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<Section10><Icon icon={androidRemove}/></Section10>
						<Section45><CslDatepicker updateFromDate={this.state.filterIssueToDate} ondateValue={this.onIssueToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<div style={{clear: "both"}}></div>
					</Section40> 
					<div style={{clear: "both"}}></div>				
					<Section40>
						<FieldLabel style={{marginTop: "10px"}}>Added Date</FieldLabel>
						<Section45><CslDatepicker updateFromDate={this.state.filterAddedFromDate} ondateValue={this.onAddedFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<Section10><Icon icon={androidRemove}/></Section10>
						<Section45><CslDatepicker updateFromDate={this.state.filterAddedToDate} ondateValue={this.onAddedToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<div style={{clear: "both"}}></div>
					</Section40>					
					<Section20 style={{float: "right"}}>
						<div style={{paddingTop: "100px"}}>
							<FilterClearBtn onClick={this.filterClear}>Clear</FilterClearBtn>
							<FilterApplyBtn onClick={this.filterSubmit}>Apply</FilterApplyBtn>
							<div style={{clear: "both"}}></div>
						</div>
					</Section20>
					<div style={{clear: "both"}}></div>  
				</FilterBody>
			</div>
		);
	}
}

export default AdvancedFilterMyTasks;