import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';

const RUThumbnailsContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
`;
const RUThumbnail = styled.div`
    float: left;
    box-sizing: border-box;
    margin-left: 10px;
    width: calc(25% - 10px);
    height: 200px;
    background-color: #ffffff;
    border-radius: 3px;
`;
const RUThumbnailHeader = styled.div`
    padding: 20px 20px 0px 20px;
    font-weight: 600;
    color: #b2b3b3;
`;
const RUThumbnailbody = styled.div`
    padding: 20px;
    font-weight: 600;
    font-size: 66px;
    color: #7a8da1;
    text-align: center;
`;
// const RUThumbnailFooter = styled.div`
//     padding: 20px;
// `;
class RUThumbnails extends React.Component
{
    state = {
        isLoaded: false,
        feedscount: null
    };

    constructor(props) {
        super(props);
        this.component_id = Store.registerCallBack('feedscount', this.updatefeedsCount);
    }
    updatefeedsCount = () => {
        const feedscount = Store.getStoreData('feedscount');
        
        this.setState({ feedscount: feedscount, isLoaded: true });
    }

    componentDidMount() {
        const feedscount = Store.getStoreData('feedscount');        
        this.setState({ feedscount: feedscount, isLoaded: true });
    }

    componentWillUnmount() {
        Store.deRegisterCallback('updatefeedsCount', this.component_id);
    }
    
    render()
    {   
        console.log("feedscount==>")
        if (this.state.isLoaded === false) {
            return <div>Loading...</div>
        }

        let rufeeds_count = this.state.feedscount;
        // console.log(rufeeds_count.totalfeeds);
        return (
            <RUThumbnailsContainer>
                <RUThumbnail style={{marginLeft: "0px"}}>
                    <RUThumbnailHeader>Regulatory Changes Received</RUThumbnailHeader>
                    <RUThumbnailbody>{rufeeds_count.totalfeeds}</RUThumbnailbody>
                </RUThumbnail>
                <RUThumbnail>
                    <RUThumbnailHeader>Regulatory Changes Pending</RUThumbnailHeader>
                    <RUThumbnailbody>{rufeeds_count.pendingfeeds}</RUThumbnailbody>
                </RUThumbnail>
                <RUThumbnail>
                    <RUThumbnailHeader>Regulatory Changes Actioned</RUThumbnailHeader>
                    <RUThumbnailbody>{rufeeds_count.actionedfeeds}</RUThumbnailbody>
                </RUThumbnail>
                <RUThumbnail style={{width: "25%"}}>
                    <RUThumbnailHeader>Regulatory Changes Dismissed</RUThumbnailHeader>
                    <RUThumbnailbody>{rufeeds_count.dismissedfeeds}</RUThumbnailbody>
                </RUThumbnail>
                <div style={{clear: "both"}}></div>
            </RUThumbnailsContainer>
        );
    }
}
export default RUThumbnails;