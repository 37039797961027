import React from 'react';
import styled from 'styled-components';
import {FaTimes,FaSearch} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import ShareInfoAndActionModal from '../Modals/ShareInfoAndActionModal';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;    
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #949494;
    font-size: 14px;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const RegModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #979797;
`;
const RegModalIssueDateInput = styled.input`
    height: 30px;
    width: 90%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-top: 8px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegModalNameInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisalButtonDiv = styled.div`
    display: block;
    box-sizing: border-box;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegMarkForActionButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #d5d5d5;
    color: #757575;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    margin-left: 5px;
    font-size: 10px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegShareButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062;
    color: #ffffff;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-size: 11px;
    margin-left: 5px;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 5px;
    /* float: right; */
    cursor: pointer;
`;
const StatusBadge = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 200px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:default;
`;
const RegDismissalReason = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #a6a6a6;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;    
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
class UserViewModal extends React.Component
{
    state = {  
        userid: 0,
        viewModal: null,
        emailLogData: {}      
    };
    constructor(props) {
        super(props);
      }
    componentDidMount() {  
        this.setState({userid: this.props.userid})
    } 

    closeUserViewModal = (event) => {   
        this.props.closeModal();
    }
    closeModal = () => {
        this.setState({ viewModal: null})
    }

    processMyopenedTask = () => { 
        let openedTasks = Store.getStoreData('get_cur_user_info').openShareTasks;       
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'RU Name', accessor: 'runame', minWidth: 180, headerStyle: {textAlign: 'left'}},
                    {Header: 'Type', accessor: 'type', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator', accessor: 'regulator', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Send Date', accessor: 'senddate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Opened Date', accessor: 'opendate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                        <MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id)}>View</MRButton>
                    ), width: 120, headerStyle: {textAlign: 'center'}}
                ];
        for(let d of openedTasks) {           
            i++; 
            let object_json = JSON.parse(d.task_json).object_data;  
            let sendDate="--/--/----";
            let openDate="--/--/----";            
            if('shareUserInfo' in object_json && this.state.userid in object_json.shareUserInfo && 'OpenedDate' in object_json.shareUserInfo[this.state.userid]){
                if('date' in object_json.shareUserInfo[this.state.userid]){
                    const sendDate_obj = new Date(object_json.shareUserInfo[this.state.userid].date);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json.shareUserInfo[this.state.userid]){
                    const openDate_obj = new Date(object_json.shareUserInfo[this.state.userid].OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }              
            }else if('shareUserAcknowledge' in object_json && this.state.userid in object_json.shareUserAcknowledge && 'OpenedDate' in object_json.shareUserAcknowledge[this.state.userid]){
                if('date' in object_json.shareUserAcknowledge[this.state.userid]){
                    const sendDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].date);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json.shareUserAcknowledge[this.state.userid]){
                    const openDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }                
            }else{
                //console.log("Sourav+++++")
                const sendDate_obj = new Date(d.due_date);
                const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                const sendDate_at_year = sendDate_obj.getFullYear();
                sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;

                const openDate_obj = new Date(d.due_date);
                const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                const openDate_at_year = openDate_obj.getFullYear();
                openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
            } 

            let documentTypes = "documentTypes" in object_json && object_json.documentTypes === null ? [] : object_json.documentTypes;             
            let docType = "";
            docType = documentTypes;
            if(!isNaN(documentTypes)) {
                // const documentType = Store.getStoreData('rutypename');
                const documentType = Store.getStoreData('rutypenameAll');
                let doctype = {};
                for(let i=0; i < documentType.length; i++) {
                    doctype[documentType[i].id] = documentType[i].documentname;
                } 
                docType = doctype[documentTypes]
            } 
            let regulatorName = "";
            if('ragulatorName' in object_json){
                regulatorName = object_json.ragulatorName;
            }                
            let elem = {
                    'id': d.id,
                    'runame' : object_json.documentName,
                    'type' : docType,
                    'regulator' : regulatorName,
                    'senddate' : sendDate,
                    'opendate' : openDate                                                          
                   };
            ret.data.push(elem);
        }
        Store.updateStore('user_open_tasks', ret.data)
        return ret;
    }

    exportToCSVOpenTask  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('user_open_tasks');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",runame: "RU Name",type: "Type",regulator:"Regulator",senddate: "Send Date", opendate: "Open Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "runame" : cur_table_data[k].runame , 
                "type" : cur_table_data[k].type ,  
                "regulator" : cur_table_data[k].regulator ,             
                "senddate" : cur_table_data[k].senddate , 
                "opendate" : cur_table_data[k].opendate , 
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)        
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0); 
            container.pop() 
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8," 
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "user_open_tasks.csv");
        document.body.appendChild(link); 
        link.click();
      }

    viewEmailNotification = (taskid) => {        
        let postData = {command: "view_user_email", task_id: taskid, contact_id: this.state.userid};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterviewemailLog);    
    }
    afterviewemailLog = (result) => {
        console.log("Completed:", result);  
        this.setState({emailLogData: result.emaillog, viewModal: 'shareInfoAndAction'})     
    }

    processMyactionTask = () => { 
        let actionTasks = Store.getStoreData('get_cur_user_info').actionShareTasks;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'RU Name', accessor: 'runame', minWidth: 180, headerStyle: {textAlign: 'left'}},
                    {Header: 'Type', accessor: 'type', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator', accessor: 'regulator', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Send Date', accessor: 'senddate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Opened Date', accessor: 'opendate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Actioned Date', accessor: 'actiondate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                        <MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id)}>View</MRButton>
                    ), width: 120, headerStyle: {textAlign: 'center'}}
                ];
        for(let d of actionTasks) {           
            i++; 
            let object_json = JSON.parse(d.task_json).object_data; 
            let sendDate="--/--/----";
            let openDate="--/--/----";
            let actionDate="--/--/----";
            if('shareUserInfo' in object_json && this.state.userid in object_json.shareUserInfo){
                if('date' in object_json.shareUserInfo[this.state.userid]){
                    const sendDate_obj = new Date(object_json.shareUserInfo[this.state.userid].date);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json.shareUserInfo[this.state.userid]){
                    const openDate_obj = new Date(object_json.shareUserInfo[this.state.userid].OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }
                if('markforactionDate' in object_json.shareUserInfo[this.state.userid]){
                    const markforactionDate_obj = new Date(object_json.shareUserInfo[this.state.userid].markforactionDate);
                    const markforactionDate_at_day = markforactionDate_obj.getDate() < 10 ? "0"+markforactionDate_obj.getDate().toString() : markforactionDate_obj.getDate();
                    const markforactionDate_at_month = markforactionDate_obj.getMonth()+1 < 10 ? `0${(markforactionDate_obj.getMonth() + 1).toString()}` : markforactionDate_obj.getMonth()+1;
                    const markforactionDate_at_year = markforactionDate_obj.getFullYear();
                    actionDate = `${markforactionDate_at_day}/${markforactionDate_at_month}/${markforactionDate_at_year}`;
                }
            }else if('shareUserAcknowledge' in object_json && this.state.userid in object_json.shareUserAcknowledge){
                if('date' in object_json.shareUserAcknowledge[this.state.userid]){
                    const sendDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].date);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json.shareUserAcknowledge[this.state.userid]){
                    const openDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }
                if('markforactionDate' in object_json.shareUserAcknowledge[this.state.userid]){
                    const markforactionDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].markforactionDate);
                    const markforactionDate_at_day = markforactionDate_obj.getDate() < 10 ? "0"+markforactionDate_obj.getDate().toString() : markforactionDate_obj.getDate();
                    const markforactionDate_at_month = markforactionDate_obj.getMonth()+1 < 10 ? `0${(markforactionDate_obj.getMonth() + 1).toString()}` : markforactionDate_obj.getMonth()+1;
                    const markforactionDate_at_year = markforactionDate_obj.getFullYear();
                    actionDate = `${markforactionDate_at_day}/${markforactionDate_at_month}/${markforactionDate_at_year}`;
                }
            }else{
                if('uploadDate' in object_json){
                    const sendDate_obj = new Date(object_json.uploadDate);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json){
                    const openDate_obj = new Date(object_json.OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }
                if('markforactionDate' in object_json){
                    const markforactionDate_obj = new Date(object_json.markforactionDate);
                    const markforactionDate_at_day = markforactionDate_obj.getDate() < 10 ? "0"+markforactionDate_obj.getDate().toString() : markforactionDate_obj.getDate();
                    const markforactionDate_at_month = markforactionDate_obj.getMonth()+1 < 10 ? `0${(markforactionDate_obj.getMonth() + 1).toString()}` : markforactionDate_obj.getMonth()+1;
                    const markforactionDate_at_year = markforactionDate_obj.getFullYear();
                    actionDate = `${markforactionDate_at_day}/${markforactionDate_at_month}/${markforactionDate_at_year}`;
                }
            }    
            let documentTypes = "documentTypes" in object_json && object_json.documentTypes === null ? [] : object_json.documentTypes;             
            let docType = "";
            docType = documentTypes;
            if(!isNaN(documentTypes)) {
                //const documentType = Store.getStoreData('rutypename');
                const documentType = Store.getStoreData('rutypenameAll');
                let doctype = {};
                for(let i=0; i < documentType.length; i++) {
                    doctype[documentType[i].id] = documentType[i].documentname;
                } 
                docType = doctype[documentTypes]
            } 
            console.log("object_json++++",object_json)
            let regulatorName = "";
            if('ragulatorName' in object_json){
                regulatorName = object_json.ragulatorName;
            }  
            let ruName = "";  
            if('documentName' in object_json){
                ruName = object_json.documentName;
            }
            if('documentname' in object_json){
                ruName = object_json.documentname;
            }            
            let elem = {
                    'id': d.id,
                    'runame' : ruName,
                    'type' : docType,
                    'regulator' : regulatorName,
                    'senddate' : sendDate,
                    'opendate' : openDate,
                    'actiondate' : actionDate                                                         
                   };
            ret.data.push(elem);
        }
        Store.updateStore('user_action_tasks', ret.data)
        return ret;
    }

    exportToCSVActionTask  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('user_action_tasks');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",runame: "RU Name",type: "Type",regulator:"Regulator",senddate: "Send Date", opendate: "Open Date", actiondate: "Action Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "runame" : cur_table_data[k].runame , 
                "type" : cur_table_data[k].type ,  
                "regulator" : cur_table_data[k].regulator ,             
                "senddate" : cur_table_data[k].senddate , 
                "opendate" : cur_table_data[k].opendate , 
                "actiondate" : cur_table_data[k].actiondate,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)        
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0); 
            container.pop() 
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8," 
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "user_action_tasks.csv");
        document.body.appendChild(link); 
        link.click();
      }

    processMydismissTask = () => { 
        let dissmissedTasks = Store.getStoreData('get_cur_user_info').dismissedTasks;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'RU Name', accessor: 'runame', minWidth: 180, headerStyle: {textAlign: 'left'}},
                    {Header: 'Type', accessor: 'type', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator', accessor: 'regulator', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Send Date', accessor: 'senddate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Opened Date', accessor: 'opendate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Actioned Date', accessor: 'actiondate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                        <MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id)}>View</MRButton>
                    ), width: 120, headerStyle: {textAlign: 'center'}}
                ];
        for(let d of dissmissedTasks) {           
            i++; 
            let object_json = JSON.parse(d.task_json).object_data;
            let action_data = JSON.parse(d.task_json).action_data;
            let sendDate="--/--/----";
            let openDate="--/--/----";
            let actionDate="--/--/----";
            if('actiondate' in action_data[action_data.length - 1]){
                const dismissDate_obj = new Date(action_data[action_data.length - 1].actiondate);
                const dismissDate_at_day = dismissDate_obj.getDate() < 10 ? "0"+dismissDate_obj.getDate().toString() : dismissDate_obj.getDate();
                const dismissDate_at_month = dismissDate_obj.getMonth()+1 < 10 ? `0${(dismissDate_obj.getMonth() + 1).toString()}` : dismissDate_obj.getMonth()+1;
                const dismissDate_at_year = dismissDate_obj.getFullYear();
                actionDate = `${dismissDate_at_day}/${dismissDate_at_month}/${dismissDate_at_year}`;
            }   
            let documentTypes = "documentTypes" in object_json && object_json.documentTypes === null ? [] : object_json.documentTypes;             
            let docType = "";
            docType = documentTypes;
            if(!isNaN(documentTypes)) {
                //const documentType = Store.getStoreData('rutypename');
                const documentType = Store.getStoreData('rutypenameAll');
                let doctype = {};
                for(let i=0; i < documentType.length; i++) {
                    doctype[documentType[i].id] = documentType[i].documentname;
                } 
                docType = doctype[documentTypes]
            } 
            let regulatorName = "";
            if('ragulatorName' in object_json){
                regulatorName = object_json.ragulatorName;
            }                
            let elem = {
                    'id': d.id,
                    'runame' : object_json.documentName,
                    'type' : docType,
                    'regulator' : regulatorName,
                    'senddate' : sendDate,
                    'opendate' : openDate,
                    'actiondate' : actionDate                                                          
                   };
            ret.data.push(elem);
        }
        Store.updateStore('user_dismiss_tasks', ret.data)
        return ret;
    }

    exportToCSVDissmissTask  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('user_dismiss_tasks');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",runame: "RU Name",type: "Type",regulator:"Regulator",senddate: "Send Date", opendate: "Open Date", actiondate: "Action Date"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "runame" : cur_table_data[k].runame , 
                "type" : cur_table_data[k].type ,  
                "regulator" : cur_table_data[k].regulator ,             
                "senddate" : cur_table_data[k].senddate , 
                "opendate" : cur_table_data[k].opendate , 
                "actiondate" : cur_table_data[k].actiondate,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)        
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0); 
            container.pop() 
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8," 
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "user_dismiss_tasks.csv");
        document.body.appendChild(link); 
        link.click();
      }

    processMyOutstandingTask = () => { 
        let outstandingTasks = Store.getStoreData('get_cur_user_info').outstandingTasks;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: 'RU Name', accessor: 'runame', minWidth: 180, headerStyle: {textAlign: 'left'}},
                    {Header: 'Type', accessor: 'type', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator', accessor: 'regulator', minWidth: 120, headerStyle: {textAlign: 'left'}},
                    {Header: 'Send Date', accessor: 'senddate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Opened Date', accessor: 'opendate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Actioned Date', accessor: 'actiondate', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                        <div>
                        {
                            (() => {
                                //console.log("row.original.status===>",row.original.status)
                                if(row.original.status !== 'Unopened') {
                                    return (<MRButton style={{cursor:"pointer"}} onClick={() => this.viewEmailNotification(row.original.id)}>View</MRButton>)

                                }else{
                                    return (<MRButton style={{cursor:"pointer"}} onClick={() => this.resendEmailtoUser(row.original.id)}>Resend</MRButton>)
                                }
                            })()
                        } 
                        </div>
                    ), width: 120, headerStyle: {textAlign: 'center'}}
                ];
        for(let d of outstandingTasks) {           
            i++; 
            let object_json = JSON.parse(d.task_json).object_data;
            let sendDate="--/--/----";
            let openDate="--/--/----";
            let actionDate="--/--/----";
            let status = "";
            if('shareUserInfo' in object_json && this.state.userid in object_json.shareUserInfo && 'OpenedDate' in object_json.shareUserInfo[this.state.userid]){
                if('date' in object_json.shareUserInfo[this.state.userid]){
                    const sendDate_obj = new Date(object_json.shareUserInfo[this.state.userid].date);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json.shareUserInfo[this.state.userid]){
                    const openDate_obj = new Date(object_json.shareUserInfo[this.state.userid].OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }
                if('markforactionDate' in object_json.shareUserInfo[this.state.userid]){
                    const markforactionDate_obj = new Date(object_json.shareUserInfo[this.state.userid].markforactionDate);
                    const markforactionDate_at_day = markforactionDate_obj.getDate() < 10 ? "0"+markforactionDate_obj.getDate().toString() : markforactionDate_obj.getDate();
                    const markforactionDate_at_month = markforactionDate_obj.getMonth()+1 < 10 ? `0${(markforactionDate_obj.getMonth() + 1).toString()}` : markforactionDate_obj.getMonth()+1;
                    const markforactionDate_at_year = markforactionDate_obj.getFullYear();
                    actionDate = `${markforactionDate_at_day}/${markforactionDate_at_month}/${markforactionDate_at_year}`;
                }
                if('cur_view' in object_json.shareUserInfo[this.state.userid]){
                    status = object_json.shareUserInfo[this.state.userid].cur_view;
                }
            }else if('shareUserAcknowledge' in object_json && this.state.userid in object_json.shareUserAcknowledge && 'OpenedDate' in object_json.shareUserAcknowledge[this.state.userid]){
                if('date' in object_json.shareUserAcknowledge[this.state.userid]){
                    const sendDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].date);
                    const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                    const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                    const sendDate_at_year = sendDate_obj.getFullYear();
                    sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
                }
                if('OpenedDate' in object_json.shareUserAcknowledge[this.state.userid]){
                    const openDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].OpenedDate);
                    const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                    const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                    const openDate_at_year = openDate_obj.getFullYear();
                    openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                }
                if('markforactionDate' in object_json.shareUserAcknowledge[this.state.userid]){
                    const markforactionDate_obj = new Date(object_json.shareUserAcknowledge[this.state.userid].markforactionDate);
                    const markforactionDate_at_day = markforactionDate_obj.getDate() < 10 ? "0"+markforactionDate_obj.getDate().toString() : markforactionDate_obj.getDate();
                    const markforactionDate_at_month = markforactionDate_obj.getMonth()+1 < 10 ? `0${(markforactionDate_obj.getMonth() + 1).toString()}` : markforactionDate_obj.getMonth()+1;
                    const markforactionDate_at_year = markforactionDate_obj.getFullYear();
                    actionDate = `${markforactionDate_at_day}/${markforactionDate_at_month}/${markforactionDate_at_year}`;
                }
                if('cur_view' in object_json.shareUserAcknowledge[this.state.userid]){
                    status = object_json.shareUserAcknowledge[this.state.userid].cur_view;
                }
            }else{
                
                //console.log("Sourav+++++")
                const sendDate_obj = new Date(d.due_date);
                const sendDate_at_day = sendDate_obj.getDate() < 10 ? "0"+sendDate_obj.getDate().toString() : sendDate_obj.getDate();
                const sendDate_at_month = sendDate_obj.getMonth()+1 < 10 ? `0${(sendDate_obj.getMonth() + 1).toString()}` : sendDate_obj.getMonth()+1;
                const sendDate_at_year = sendDate_obj.getFullYear();
                sendDate = `${sendDate_at_day}/${sendDate_at_month}/${sendDate_at_year}`;
            
                const openDate_obj = new Date(d.due_date);
                const openDate_at_day = openDate_obj.getDate() < 10 ? "0"+openDate_obj.getDate().toString() : openDate_obj.getDate();
                const openDate_at_month = openDate_obj.getMonth()+1 < 10 ? `0${(openDate_obj.getMonth() + 1).toString()}` : openDate_obj.getMonth()+1;
                const openDate_at_year = openDate_obj.getFullYear();
                openDate = `${openDate_at_day}/${openDate_at_month}/${openDate_at_year}`;
                
            }   
            let documentTypes = "documentTypes" in object_json && object_json.documentTypes === null ? [] : object_json.documentTypes;             
            let docType = "";
            docType = documentTypes;
            if(!isNaN(documentTypes)) {
                //const documentType = Store.getStoreData('rutypename');
                const documentType = Store.getStoreData('rutypenameAll');
                let doctype = {};
                for(let i=0; i < documentType.length; i++) {
                    doctype[documentType[i].id] = documentType[i].documentname;
                } 
                docType = doctype[documentTypes]
            } 
            let regulatorName = "";
            if('ragulatorName' in object_json){
                regulatorName = object_json.ragulatorName;
            }                
            let elem = {
                    'id': d.id,
                    'runame' : object_json.documentName,
                    'type' : docType,
                    'regulator' : regulatorName,
                    'senddate' : sendDate,
                    'opendate' : openDate,
                    'actiondate' : actionDate,
                    'status': status                                                           
                   };
            ret.data.push(elem);
        }
        Store.updateStore('user_outstanding_tasks', ret.data)
        return ret;
    }

    exportToCSVOutstandingTask  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('user_outstanding_tasks');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {id: "1234",runame: "RU Name",type: "Type",regulator:"Regulator",senddate: "Send Date", opendate: "Open Date", actiondate: "Action Date", status: "Status"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "runame" : cur_table_data[k].runame , 
                "type" : cur_table_data[k].type ,  
                "regulator" : cur_table_data[k].regulator ,             
                "senddate" : cur_table_data[k].senddate , 
                "opendate" : cur_table_data[k].opendate , 
                "actiondate" : cur_table_data[k].actiondate,
                "status" : cur_table_data[k].status,
                "id" :  cur_table_data[k].id ,
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];
        console.log(cur_table_data.length)        
        for(let i = 0; i<  cur_table_data_change.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0); 
            container.pop() 
          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8," 
            + cur_table_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "user_outstanding_tasks.csv");
        document.body.appendChild(link); 
        link.click();
      }

    resendEmailtoUser = (taskid) =>{        
        let postData = {command: "resend_email_to_share_user", task_id: taskid, send_mail: true, contact_id: this.state.userid,system_id: 1001};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);       
    }
    afterresendCompleted = (result) => {
        // console.log("Completed:", result);
        alert('Resend mail successfully.');
        //window.location.reload(true);
    }

    render()
    { 
        // let headerSummary = "Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Petierunt uti sibi concilium totius Galliae in diem certam indicere. Nihil hic munitissimus habendi senatus locus, nihil horum?";
        let shareOpenTasks = Store.getStoreData('get_cur_user_info').openShareTasks;
        let actionShareTasks = Store.getStoreData('get_cur_user_info').actionShareTasks;
        let dismissedTasks = Store.getStoreData('get_cur_user_info').dismissedTasks;
        let outstandingTasks = Store.getStoreData('get_cur_user_info').outstandingTasks;
        return (            
            <RegModalContainer>
                <RegModalHeaderText>{this.props.userName}</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeUserViewModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "30px"}}/>
                <RegDismisOuterContainer>                           
                    <OverviewInfoBody style={{marginBottom: "10px"}}>
                    <div style={{marginBottom: "15px"}}><span style={{fontWeight: "bold",color: "#616161"}}>You are viewing the RU Statistics for {this.props.userName}.</span></div> 
                    {/* <div dangerouslySetInnerHTML={{__html: headerSummary}} style={{textAlign: "justify",color: "#727272"}}></div> */}
                    </OverviewInfoBody>
                </RegDismisOuterContainer>
            <hr style={{borderTop: "0px solid #ccc"}}/>
            {
                (() => {
                    if(shareOpenTasks.length !== 0){
                        return(<RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Opened ( {this.props.allcountinfo.open} )</div>                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>  
                            <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                            <CSLTable add={false} processData={this.processMyopenedTask} headerText={''} tableRows={5} refreshCallback={this.refreshCallback} />
                            </OverviewInfoBody>
                            <RegCancelSubmitButtonOuterContainer>                                
                                <RegSubmitButton onClick={this.exportToCSVOpenTask}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>         
                        </RegDismisOuterContainer>)
                    }else{
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Opened ( {this.props.allcountinfo.open} )</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no opened task</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }                   
                })()
            }
            {
                (() => {
                    if(actionShareTasks.length !== 0){
                        return(<RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Actioned ( {this.props.allcountinfo.action} )</div>                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>  
                            <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                            <CSLTable add={false} processData={this.processMyactionTask} headerText={''} tableRows={5} refreshCallback={this.refreshCallback} />
                            </OverviewInfoBody> 
                            <RegCancelSubmitButtonOuterContainer>                                
                                <RegSubmitButton onClick={this.exportToCSVActionTask}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>        
                        </RegDismisOuterContainer>)
                    }else{
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Actioned ( {this.props.allcountinfo.action} )</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no action task</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }                   
                })()
            }
            {
                (() => {
                    if(dismissedTasks.length !== 0){
                        return(<RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Dismissed ( {this.props.allcountinfo.dismiss} )</div>                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>  
                            <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                            <CSLTable add={false} processData={this.processMydismissTask} headerText={''} tableRows={5} refreshCallback={this.refreshCallback} />
                            </OverviewInfoBody>
                            <RegCancelSubmitButtonOuterContainer>                                
                                <RegSubmitButton onClick={this.exportToCSVDissmissTask}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>         
                        </RegDismisOuterContainer>)
                    }else{
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Dismissed ( {this.props.allcountinfo.dismiss} )</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no dismiss task</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }                   
                })()
            }
            {
                (() => {
                    if(outstandingTasks.length !== 0){
                        return(<RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Outstanding ( {this.props.allcountinfo.outstanding} )</div>                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>  
                            <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                            <CSLTable add={false} processData={this.processMyOutstandingTask} headerText={''} tableRows={5} refreshCallback={this.refreshCallback} />
                            </OverviewInfoBody>
                            <RegCancelSubmitButtonOuterContainer>                                
                                <RegSubmitButton onClick={this.exportToCSVOutstandingTask}>Export</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer>         
                        </RegDismisOuterContainer>)
                    }else{
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Outstanding ( {this.props.allcountinfo.outstanding} )</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no outstanding task</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }                   
                })()
            }
            <RegCancelSubmitButtonOuterContainer>
                <RegCancelButton onClick={this.closeUserViewModal}>Close</RegCancelButton>
            </RegCancelSubmitButtonOuterContainer>
            {
                (() => {
                    if(this.state.viewModal !== null) {
                        switch(this.state.viewModal) {                            
                            case 'shareInfoAndAction' : return <ShareInfoAndActionModal refreshstate={this.refreshstate} emailLogData={this.state.emailLogData} closeModal={this.closeModal}/>; break;
                        }                        
                    }
                })()
            }
        </RegModalContainer>);
    }
}

export default UserViewModal;