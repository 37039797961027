import React from 'react';
import Utils from '../../Common/Utils.js';
import Store from '../../Common/Store.js';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";
import { GoAlert } from "react-icons/go";

const InactiveOverlay = styled.div`
display: block;
box-sizing: border-box;
height: 100vh;
width: 100vw;
background-color: #9dc3e838;
position: fixed;
left: 0px;
top: 0px;
z-index: 40000;
opacity: 0.8;
`;

const Loader = styled.div`
    position: absolute;
    background-color: #ffffff;
    left: 38%;
    top:30%;
    z-index: 20001;
    text-align: center;
    width: 27%;
`

const LoaderText = styled.div`
font-size: 12px;
font-weight: 600;
color: #222222;
font-family: 'Montserrat',sans-serif;
padding-bottom: 25px;
padding-top: 30px;
`



const Registerbucketloader = (props) => {
return (
<div>


<Loader>
    <LoaderText>
        <img src={Utils.baseImage()} />
        <br /><br /><br />
        {/* <span style={{fontSize : 20, fontWeight: 600}}>Sit tight...</span><br /><br /><br />         */}
        <span >Searching...please wait, this will only take a moment.</span>
    </LoaderText>
    <span ><ClipLoader  size={25} color={"#04ada8"} loading={true} /></span>
</Loader>
</div>
);
};

export default Registerbucketloader;