import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import {FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import moment from 'moment';
import CSLLoader from './../Common/CSLLoader';
import APICall from './../../Common/APICall';
import RegulatoryClientModal from './../Modals/RegulatoryClientModal';
import AlertBox from './../Common/AlertBox';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;    
    color: #484848;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;

const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 98%;
    margin-top: 10px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #EEEEEE;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
    margin-left: 5px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 8px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 8px;
    /* float: right; */
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 200%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;  
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;  
    font-family: 'Montserrat', sans-serif; 
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;
class RegisterPendingtaskModal extends React.Component
{
    state = {
        modal_view: null,
        isLoadedIcon: false,
        ready: false,
        documentname: '',
        summary: '',
        docType: '',
        upload_date: '',
        section: [],
        externalLink: [],
        keyDatesValue: [], 
        isReasonForDismissalShow: false,
        dismiss_note: '',
        alert_param: null,
        reason_for_dismissal: [],
        selected_reason_for_dismissal: '',
        isShowDismissalDropdown: false
    }

    componentDidMount() {        
        let objectdata = Store.getStoreData('task');   
        let documentname = objectdata.documentName.replace(/(--quote--)/g, "'").replace(/(--and--)/g, "&");
        let summary = objectdata.summary;

        const documentType = Store.getStoreData('rutypenameAll');
        let doctypenew = {};
        for(let i=0; i < documentType.length; i++) {
            doctypenew[documentType[i].id] = documentType[i].documentname;
        } 
        let documentTypes = objectdata.documentTypes;    
        let dNames = "";
        if (documentTypes !== null) {
            let i=0;
            for (let doc of documentTypes){
                i++;
                if(doc in doctypenew){
                    dNames += doctypenew[doc]
                    if(i <= documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }

        const upload_date_obj = new Date(objectdata.uploadDate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        let upload_date = `${upload_day}/${upload_month}/${upload_year}`;

        let section = objectdata.sections;   

        let externalLinks = [];        
        if("externalLinks" in objectdata) {         
            let externalLinksAll = objectdata.externalLinks;
            externalLinksAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                externalLinks.push(item);
            })               
        } 

        let keyDates = [];
        if("keyDates" in objectdata) {    
            let keyDatesAll = objectdata.keyDates;
            keyDatesAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                keyDates.push(item);
            }) 
        }          

        let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        let reason_for_dismissal = [{'isActive': 1, 'isDefaultcode': 0, 'isDelete': 0, 'reasonID': 1111, 'reasonName': "Other"}];   
        let selected_reason_for_dismissal = "";     
        for(let d of reasondismissal){
            if(d.isActive === 1 && d.isDelete === 0){
                reason_for_dismissal.push(d);
            }
            if(d.isDelete === 0 && d.isDefaultcode === 1){
                selected_reason_for_dismissal = d.reasonName;
            }
        }
        reason_for_dismissal.sort(function(a,b) {return (a.reasonName > b.reasonName) ? 1 : ((b.reasonName > a.reasonName) ? -1 : 0);} );

        console.log("reason_for_dismissal===>", reason_for_dismissal)

        this.setState({
            documentname,
            summary,
            docType: dNames,
            upload_date,
            section,
            externalLink: externalLinks,
            keyDatesValue: keyDates,
            reason_for_dismissal,
            selected_reason_for_dismissal,
            ready: true
        })
    }

    closeRegulatoryModal = (event) => {
        this.props.closeModal();
    }
    processMyExternalLink = () => {
        var linksdata = this.state.externalLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Link', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Comment', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'url_value' : <a target="_blank" href={d.url}>{d.url}</a>,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    processMykeyData = () => {
        var keysdata = this.state.keyDatesValue;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of keysdata) {           
            i++;         
            
            let regu_pub_date_auto = "";            
            let dateformatCheck = moment(d.date, 'DD/MM/YYYY',true).isValid();
            if(dateformatCheck){
                regu_pub_date_auto = d.date;
            }else if(d.date !== null){                
                let issue_date_str_auto = d.date ? d.date : '1970-01-01T00:00:00.000Z';
                var dd_issueDate_auto = parseInt(issue_date_str_auto.substring(8,12)) < 10 ? "0"+parseInt(issue_date_str_auto.substring(8,12)) : parseInt(issue_date_str_auto.substring(8,12));
                var mm_issueDate_auto = parseInt(issue_date_str_auto.substring(5,7)) < 10 ? "0"+parseInt(issue_date_str_auto.substring(5,7)) : parseInt(issue_date_str_auto.substring(5,7));
                var yyyy_issueDate_auto = parseInt(issue_date_str_auto.substring(0,4));               
                regu_pub_date_auto = `${dd_issueDate_auto}/${mm_issueDate_auto}/${yyyy_issueDate_auto}`;                
            }

            let elem = {'index' : i, 
                    'date_value' : regu_pub_date_auto,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    viewPendingTask = () => { 
        this.setState({ modal_view: 'taskModal', isLoadedIcon: false}) 
    }    

    closeModal = () => {
        this.setState({ modal_view: null });
    }

    addResonforDismiss = (event) => {
        event.preventDefault();    
        if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal && Store.getStoreData('module_config').general.require_reason_code_for_dismissal){
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 1}}
            this.setState({alert_param: alert_param})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === true && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 2}}
            this.setState({alert_param: alert_param})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === true){
            this.setState({isShowDismissalDropdown: true, isReasonForDismissalShow: false})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
            this.setState({isShowDismissalDropdown: false, isReasonForDismissalShow: true})
        }
        //this.setState({isReasonForDismissalShow: true})    
    }

    submitResonforDismissForDropdown = (event) => {
        event.preventDefault();   
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 3}}
        this.setState({alert_param: alert_param})
    }

    submitResonforDismissNormal = (event) => {
        event.preventDefault();   
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 4}}
        this.setState({alert_param: alert_param})
    }

    dismissRUTask = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})  

        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;        
    
        let task_json = JSON.parse(task.task.parenttask.task_json);    

        //rcm v5
        let dissmiss_reason_text = "";
        let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        if(stack.isChecking === 1){
            for(let d of reasondismissal){
                if(d.isActive === 1 && d.isDelete === 0 && d.isDefaultcode === 1){
                    dissmiss_reason_text = d.reasonName;
                }
            }
        }else if(stack.isChecking === 2){
            dissmiss_reason_text = "N/A"
        }else if(stack.isChecking === 3){
            dissmiss_reason_text = this.state.selected_reason_for_dismissal;
            if(this.state.selected_reason_for_dismissal === 'Other'){
                dissmiss_reason_text = this.state.dismiss_note;
            }
            
        }else if(stack.isChecking === 4){
            dissmiss_reason_text = this.state.dismiss_note;
        }
        //rcm v5

        task_json['action_data'] = {task_id: Store.getStoreData('current_task_id'), actiondate: Date.now(), cur_lane: "COMP_RCM_DISMISS", assigned_to: 0, action: "COMP_RCM_DISMISS", note: dissmiss_reason_text, performed_by: Store.getStoreData('loggedincontact')};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processsSaveClientRegulatorychange);
    }    

    processsSaveClientRegulatorychange = (result) => {
        let alert_param = {title: 'Success', message: 'The regulatory change has been dismissed.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.processSaveClientRegulatorychange, stack: {}}
        this.setState({alert_param: alert_param}) 
    }

    processSaveClientRegulatorychange = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }

    cancelDismissReson = (event) => {
        event.preventDefault();    
        this.setState({isReasonForDismissalShow: false, isShowDismissalDropdown: false})    
    }
    
    handleOnChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        if(!this.state.ready){
            return(<div></div>)
        }        
        
        return(<div>
            {
                (() => {                    
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }            
            <RegModalContainer>
            {
                (() => {
                    if(this.state.modal_view !== null){                                
                        switch(this.state.modal_view) {    
                            case 'taskModal' : return <div><InactiveOverlay /><RegulatoryClientModal closeModal={this.closeModal}/></div>; break;
                        }
                        
                    }
                })()
            }
            <RegModalHeaderText>RC Viewer</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <RegDismisOuterContainer>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.state.documentname}</div>                    
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>           
                <OverviewInfoBody>
                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(this.state.summary))}} style={{textAlign: "justify"}}></div>                
                <div style={{marginTop: "30px", display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type:</span> {this.state.docType}</div>
                <div style={{marginTop: "30px", display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date:</span> {this.state.upload_date}</div>                               
                </OverviewInfoBody>
            </RegDismisOuterContainer>    
            {
                this.state.section.map((section) => {
                    return (
                        <RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>                                                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>
                            <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>                                 
                                
                            </OverviewInfoBody>
                        </RegDismisOuterContainer>                                    
                    )
                })
            }
            
            {
                (() => {
                
                    if(this.state.externalLink.length !== 0) {   
                        
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>           
                            </RegDismisOuterContainer>
                            </div>)   
                    }else {
                        return(<div>
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>           
                                <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Useful Links Available</div>
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()                
            }

                {
                    (() => {                            
                        if(this.state.keyDatesValue.length !== 0) {                             
                            return(<div>
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader> 
                                    <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>          
                                        <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                    </OverviewInfoBody>
                                </RegDismisOuterContainer>
                                </div>)
                        }else {
                            return(<div>
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>           
                                    <OverviewInfoBody style={{backgroundColor: "#ffffff"}}>
                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Key Dates Available</div>
                                    </OverviewInfoBody>
                                </RegDismisOuterContainer>
                            </div>)
                        }
                        
                    })()                
                } 
                <RegCancelSubmitButtonOuterContainer>    
                    {
                        (() => {
                            if(this.state.isReasonForDismissalShow || this.state.isShowDismissalDropdown){
                                return(<div>
                                    <RegCancelButton style={{backgroundColor: "#D8D8D8",border: "1px solid #D8D8D8",color: "#919191"}}>Cancel</RegCancelButton>
                                    <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton>
                                    <RegSubmitButton style={{backgroundColor: "#D8D8D8",border: "1px solid #D8D8D8",color: "#919191"}}>Process</RegSubmitButton>
                                </div>)
                            }else{
                                return(<div>
                                    <RegCancelButton onClick={this.closeRegulatoryModal}>Cancel</RegCancelButton>
                                    <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton>
                                    <RegSubmitButton onClick={this.viewPendingTask}>Process</RegSubmitButton>
                                </div>)
                            }
                        })()
                    }
                    
                </RegCancelSubmitButtonOuterContainer> 
                {
                    (() => {
                        if(this.state.isReasonForDismissalShow){
                            return(<div><RegModalContainertop>
                                <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                                <RegModalNameInputDiv>
                                    <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                </RegModalNameInputDiv>
                            </RegModalContainertop>
                            <RegCancelSubmitButtonOuterContainer>    
                                <RegCancelButton onClick={this.cancelDismissReson}>Cancel</RegCancelButton>                                
                                <RegSubmitButton onClick={this.submitResonforDismissNormal}>Save</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer> 
                            <div style={{clear: "both"}}></div>
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.isShowDismissalDropdown){
                            return(<div><RegModalContainertop style={{gridTemplateColumns: "20% calc(80% - 15px)"}}>
                                <RegModalNameLabelDiv style={{paddingTop: "10px"}}>Reason For Dismissal</RegModalNameLabelDiv>                                
                                <MRModalSelect style={{width: "50%"}} name="selected_reason_for_dismissal" value={this.state.selected_reason_for_dismissal} onChange={this.handleOnChange}> 
                                    {
                                        this.state.reason_for_dismissal.map((doc, index) => {
                                            return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                        })
                                    }                            
                                </MRModalSelect>  
                                <RegModalNameLabelDiv></RegModalNameLabelDiv>  
                                {
                                    (() => {
                                        if(this.state.selected_reason_for_dismissal === 'Other'){
                                            return(
                                                <RegModalNameInputDiv>
                                                    <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                                </RegModalNameInputDiv>
                                            )
                                        }
                                    })()
                                }                                
                            </RegModalContainertop>
                            <RegCancelSubmitButtonOuterContainer>    
                                <RegCancelButton onClick={this.cancelDismissReson}>Cancel</RegCancelButton>                                
                                <RegSubmitButton onClick={this.submitResonforDismissForDropdown}>Save</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer> 
                            <div style={{clear: "both"}}></div>
                            </div>)
                        }
                    })()
                }
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>)
    }
}
export default RegisterPendingtaskModal