import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import CSLTable from '../Common/CSLTable';
import UserViewModal from './UserViewModal';
import CSLbucketloader from '../Common/CSLbucketloader';
import Utils from '../../Common/Utils';
import CSLRegister from '../../Components/Common/CSLRegister';
import CSLLoader from '../../Components/Common/CSLLoader';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 130px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
`; 

const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;    
    margin-top: 30px;  
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  
`;
const HeaderButton = styled.button`
    box-sizing: border-box;
    background-color: #EBEBEB;
    color: #606575;
    border: 1px solid #CECECE;
    width: 100px;
    font-size: 13px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 0 4px #CECECE;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;    
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    width: 97.6%;
    margin: 0 auto;
    margin-bottom: 30px;    
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;
    width: 30%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 200%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;  
  top: -111px;
  left: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

class RegisterUserView extends React.Component
{ 
    state = {
        filter: {
          All: 1,
          Inprogress: 0, 
          Pending: 0,
          Completed: 0,
        },
        ready: false,
        task_list: null,
        modal_view:null,
        all_count: 0,
        pending_count: 0,
        inprogress_count: 0,
        completed_count: 0,
        taskdata: {},
        userViewData: [],
        userName: "",
        allcountinfo: { open: 0,action: 0,dismiss: 0,outstanding:0},
        userid: 0,
        filters: {},
        header_buttons: [],
        fields: [],
        applied_filters: {},
        isLoadedIcon: false
    };
    
    userView = () => {
        this.props.modalView('adminmanager');
    }

    // componentDidMount() {        
    //   let postData = { "command": "get_rcm_registry_user" };
    //   let api = new APICall();
    //   api.command(postData, this.processRegisterUserIndex);
    // } 

    componentDidMount() { 
      this.processDataRefresh()        
    } 

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.processDataRefresh()
        }
    }

    processDataRefresh = () => {        
      let api = new APICall();
      let postData = {};    
      if(Store.getStoreData('userViewData') === null) {
          postData['command'] = 'get_rcm_registry_user';
          console.log("postdata", postData)
          api.command(postData, this.processregisterVuewUserRCM)
      }else{
          let filters = {
            name: {name: 'name', 'nickname': 'Name', type: 'text', options: {},selections: {text: ''}, size: 3}
          }

          let fields = []
          fields.push({accessor: 'name', nickname: 'Name', type: 'alpha', width: 4})
          fields.push({accessor: 'opened', nickname: 'Opened', type: 'numeric', width:1})
          fields.push({accessor: 'actioned', nickname: 'Actioned', type: 'numeric', width:1})
          fields.push({accessor: 'dismissed', nickname: 'Dismissed', type: 'numeric', width:1})
          fields.push({accessor: 'outstanding', nickname: 'Outstanding', type: 'numeric', width:1})
          fields.push({accessor: 'reminders', nickname: 'Reminders', type: 'numeric', width:1})
          fields.push({accessor: 'avgnoofdays', nickname: 'Average Days', type: 'numeric', width:1})
          let header_buttons = []
          header_buttons.push({display: 'Team View', disabled: true, color: '#D4D5D8'})
          header_buttons.push({display: 'User View'})
          header_buttons.push({display: 'Manager View', processClick: this.userView})
          let prefered_columns = {
            'name' : 'Name',
            'opened' : 'Opened',
            'actioned' : 'Actioned',
            'dismissed' : 'Dismissed',
            'outstanding' : 'Outstanding',
            'reminders' : 'Reminders',
            'avgnoofdays' : 'Average Days'
          }
          this.setState({
                          view_count: this.state.view_count + 1, 
                          filters: filters, 
                          applied_filters: JSON.parse(JSON.stringify(filters)), 
                          fields: fields, 
                          header_buttons:header_buttons,
                          prefered_columns: prefered_columns
                        });
      }  
      this.setState({ready: true})
  }

    processregisterVuewUserRCM = (result) => {
        console.log("result===>",result)
        Utils.log("userViewData", result)
        Store.updateStore('userViewData', result.userViewlist);
        let filters = {
          name: {name: 'name', 'nickname': 'Name', type: 'text', options: {},selections: {text: ''}, size: 3}
        }

        let fields = []
        fields.push({accessor: 'name', nickname: 'Name', type: 'alpha', width: 4})
        fields.push({accessor: 'opened', nickname: 'Opened', type: 'numeric', width:1})
        fields.push({accessor: 'actioned', nickname: 'Actioned', type: 'numeric', width:1})
        fields.push({accessor: 'dismissed', nickname: 'Dismissed', type: 'numeric', width:1})
        fields.push({accessor: 'outstanding', nickname: 'Outstanding', type: 'numeric', width:1})
        fields.push({accessor: 'reminders', nickname: 'Reminders', type: 'numeric', width:1})
        fields.push({accessor: 'avgnoofdays', nickname: 'Average Days', type: 'numeric', width:1})
        let header_buttons = []
        header_buttons.push({display: 'Team View', disabled: true, color: '#D4D5D8'})
        header_buttons.push({display: 'User View'})
        header_buttons.push({display: 'Manager View', processClick: this.userView})
        let prefered_columns = {
          'name' : 'Name',
          'opened' : 'Opened',
          'actioned' : 'Actioned',
          'dismissed' : 'Dismissed',
          'outstanding' : 'Outstanding',
          'reminders' : 'Reminders',
          'avgnoofdays' : 'Average Days'
        }
        this.setState({
                        view_count: this.state.view_count + 1, 
                        filters: filters, 
                        applied_filters: JSON.parse(JSON.stringify(filters)), 
                        fields: fields, 
                        header_buttons:header_buttons,
                        prefered_columns: prefered_columns
                      }); 
        //this.setState({view_count: this.state.view_count + 1}); 
    }

    // processRegisterUserIndex = (result) => {
    //   console.log("result",result);  
    //   this.setState({userViewData: result.userViewlist, ready: true})

    // }

    handleBtn = (event) => {
        event.preventDefault();        
    }

    processAcceptanceQueueData = () => {   
        let userView = Store.getStoreData('userViewData') === null ? [] : Store.getStoreData('userViewData');  
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[                    
                    {Header: 'Name', accessor: 'name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Opened', accessor: 'opened', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Actioned', accessor: 'actioned', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Dismissed', accessor: 'dismissed', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Outstanding', accessor: 'outstanding', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Reminders', accessor: 'reminders', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Avg # of days', accessor: 'avgnoofdays', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                      <div>
                          <MRButton style={{cursor:"pointer"}} onClick={this.goToTaskUrl(row.original.id,row.original.name,row.original.opened,row.original.actioned,row.original.dismissed,row.original.outstanding)}>View</MRButton>
                      </div>
                    ), width: 120, headerStyle: {textAlign: 'center'}},
                      
                    ]; 
            for(let d of userView) {           
              i++;
              let openRU = (d.openedRU.toString().length<2 ? "0"+d.openedRU : d.openedRU)+" of "+(d.totalRU.toString().length<2 ? "0"+d.totalRU : d.totalRU);
              let actionRU = (d.actionedRU.toString().length<2 ? "0"+d.actionedRU : d.actionedRU)+" of "+(d.actiontotalRU.toString().length<2 ? "0"+d.actiontotalRU : d.actiontotalRU);
              let dismissedRU = (d.dismissedRU.toString().length<2 ? "0"+d.dismissedRU : d.dismissedRU)+" of "+(d.totalRU.toString().length<2 ? "0"+d.totalRU : d.totalRU);
              let outstandingRU = (d.outstandingRU.toString().length<2 ? "0"+d.outstandingRU : d.outstandingRU)+" of "+(d.totalRU.toString().length<2 ? "0"+d.totalRU : d.totalRU);
              let remindersRU = d.remindersRU.toString().length<2 ? "0"+d.remindersRU : d.remindersRU;
              let avgNoofDaysRU = d.avgNoofDays.toFixed(2).toString();
              let elem = {'index' : i,
                      'id': d.id, 
                      'name' : d.userName,
                      'opened' : openRU,
                      'actioned' : actionRU,
                      'dismissed' : dismissedRU,
                      'outstanding': outstandingRU,
                      'reminders': remindersRU,
                      'avgnoofdays': avgNoofDaysRU                                                             
                      };
              if(this.filterRow(elem))ret.data.push(elem);
          }    
        return ret;      
      }

      goToTaskUrl = (userid,name,openedRU,actionedRU,dismissedRU,outstandingRU) => (event) => {
        event.preventDefault();
        console.log("userid",userid) 
        let allcountinfo = { open: openedRU,action: actionedRU,dismiss: dismissedRU,outstanding:outstandingRU} 
        let postData = { "command": "get_user_view_by_id", contact_id: userid };
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.processUserView(name,allcountinfo,userid));
                 
      } 
      processUserView = (username,allcountinfo,userid) => (result) => {
        console.log("result",result); 
        Store.updateStore('get_cur_user_info',result) 
        this.setState({modal_view: "UserViewModal",userName: username, allcountinfo, userid: userid,isLoadedIcon: false})  
      }

      filterRow = (row) => {

        let filters = this.state.applied_filters
        if(Object.keys(filters).length === 0)return true
        let ret = filters.name.selections.text === '' ? true : row.name.toLowerCase().search(filters.name.selections.text.toLowerCase()) >= 0
        return ret
    }


    applyFilter = (filters) => {
      let applied_filters = JSON.parse(JSON.stringify(filters))
      this.setState({applied_filters})
    }

    closeModal = () => {
        this.setState({ modal_view: null });
      }
    
    render()
    { 
      return (
        <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
          {/* {
              (() => {
                  if(this.state.isLoadedIcon){
                      return <div><CSLLoader style={{position: 'absolute'}}/></div>
                  }
              })()
          } */}
          <CSLRegister 
              headerText={'Regulatory Change Register'}
              preferedColumns={this.state.prefered_columns}
              headerButtons={this.state.header_buttons}
              activeView={'user'}
              export={true}
              processData={this.processAcceptanceQueueData}
              refreshCallback={this.refreshCallback}
              exportFilename={"Register"} 
              filters={this.state.filters}
              applyFilter={this.applyFilter}
              fields={this.state.fields}
              module={{display: 'Regulatory Change', id: 'rcm'}}
          />
           <RegDismisOuterContainer>
           {
                (() => {
                    if(this.state.modal_view !== null) {
                        switch(this.state.modal_view) {
                            case 'UserViewModal' : return <div><InactiveOverlay /><UserViewModal userName={this.state.userName} allcountinfo={this.state.allcountinfo} userid={this.state.userid} closeModal={this.closeModal}/></div>; break;                                    
                        }                        
                    }
                })()
            }  
           </RegDismisOuterContainer>
           </div>
        );
    }
   
}

export default RegisterUserView;