import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import ProcessDismissedRN from './ProcessDismissedRN';


const RUPButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
class DismissedRN extends React.Component
{

    state = {
        isLoaded: false,
        dismissedrnfeeds: null,
        modal_view: null,
        doctypeObject: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const feedsdata = Store.getStoreData('dismissed_rn_feeds');
        //const documentType = Store.getStoreData('rutypename');
        const documentType = Store.getStoreData('rutypenameAll');
        let doctype = {};
        for(let i=0; i < documentType.length; i++) {
            doctype[documentType[i].id] = documentType[i].documentname;
        }
        //console.log(doctype);

        this.setState({ dismissedrnfeeds: feedsdata,doctypeObject: doctype,isLoaded: true});
        //console.log('state', this.state);
    }

    processMyFeedsData = () => {
        console.log("feedsdata", "feedsdata");
        let ret = {data: [], columns: []};
        let feedsdata = this.state.dismissedrnfeeds;
        //console.log("feedsdata=====>",feedsdata);
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Name', accessor: 'document_name', minWidth: 180, headerStyle: {textAlign: 'left'}},
                        {Header: 'Regulator Name', accessor: 'regulator_name', width: 160, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Types', accessor: 'document_types', width: 150, headerStyle: {textAlign: 'left'}},
                        {Header: 'Date of Issue', accessor: 'issuing_date', width: 165, headerStyle: {textAlign: 'left'}},
                        {Header: 'Action', accessor: 'action', width: 110, headerStyle: {textAlign: 'left'}}];

        for(let d of feedsdata) {
            //let json_feed = JSON.parse(d.feed_json);
            //let update_json_feed = JSON.parse(d.feed_update_json);
            i++;
            let documentTypes = d.documentTypes;
            //console.log("documentTypes======>",documentTypes)
            let dtype = "";
            let dNames = "";
            if (documentTypes !== null) {
                let i=0;
                for (let doc of documentTypes){
                    i++;
                    if(doc in this.state.doctypeObject){
                        dNames += this.state.doctypeObject[doc]
                        if(i <= documentTypes.length - 1) {
                            dNames += ', '
                        }
                    }
                }
            }
            //let doctypename = this.state.doctypeObject[dtype];
            let issueDate = d.issueDate;
            const issue_obj = new Date(issueDate);
            const issue_day = issue_obj.getDate() < 10 ? "0"+issue_obj.getDate().toString() : issue_obj.getDate();
            const issue_month = issue_obj.getMonth()+1 < 10 ? `0${(issue_obj.getMonth() + 1).toString()}` : issue_obj.getMonth()+1;
            const issue_year = issue_obj.getFullYear();
            issueDate = `${issue_day}/${issue_month}/${issue_year}`;
            let action = <RUPButton value={d.id} onClick={this.showRUNotificationModal(d.change_action)}>{d.change_action === 0 ? 'Process' : 'View'}</RUPButton>;
            let elem = {
                    'index' : i,
                    'document_name' : d.documentName,
                    'regulator_name' : d.ragulatorName,
                    'document_types' : dNames,
                    'issuing_date' : issueDate,
                    'action' : action
                   };
           ret.data.push(elem);
        }
        return ret;
    }

    showRUNotificationModal = (changeActionValue) => (event) => {
        event.preventDefault();
        let url = Store.getStoreData('api_url');
        console.log("url",url)
        let is_admin = Store.getStoreData('is_admin');
        let is_debug = Store.getStoreData('is_debug');
        let postData = { "command" : "getfeed" , "feed_id": event.target.value, 'env' : is_admin ? 'csl' : 'select', "is_rn": 1};
        console.log("postData", postData);
		let httpVerb = { method: "POST", mode: "cors", headers: {"Content-Type": "application/x-www-form-urlencoded"}, body: "jsondata=" + JSON.stringify(postData) };
        fetch(url, httpVerb)
        .then((res) => res.json())
        .then(result => {
            console.log("result+++",result)
            Store.updateStore('rn_cur_feed', JSON.parse(result.feed.feed_update_json));
            Store.updateStore('current_activityLog', result.feed.activity_log);
            Store.updateStore('istask_action', result.feed.change_action);
            Store.updateStore('is_custom', result.feed.is_custom);
            Store.updateStore('istask_dismiss', result.feed.isdismiss);
            Store.updateStore('tags', result.tags);
            Store.updateStore('changeActionValue', changeActionValue);
            this.setState({modal_view: 'process_dismissed_regulatory_modal'})
        })
    }


    closeModal = () => {
        this.setState({ modal_view: null });
    }

    render()
    {
        // console.log("STATE===>",this.state.modal_view);
        if(this.state.isLoaded === false) {
            return <div>Loading...</div>
        }
        return (
            <IndexContainer>
                <CSLTable add={false} processData={this.processMyFeedsData} tableRows={10} headerText={'Dismissed Regulatory Notifications'} />
                {
                  (() => {
                        if(this.state.modal_view !== null){
                            switch(this.state.modal_view){
                                case 'process_dismissed_regulatory_modal': return <ProcessDismissedRN closeModal={this.closeModal}/>; break;
                            }
                        }
                        })()
                }
            </IndexContainer>
        );
    }
}
export default DismissedRN;
