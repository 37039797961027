import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import moment from 'moment';


const RUPButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
class CSLAuditlogs extends React.Component
{
    
    state = {
        isLoaded: false,
        dismissedrnfeeds: null,
        modal_view: null,
        doctypeObject: null
    };

    constructor(props) {
        super(props);        
    }    

    componentDidMount() {      
        this.setState({ isLoaded: true});        
    }    

    processMyFeedsData = () => {        
        let ret = {data: [], columns: []};   
        let taskData = Store.getStoreData('activitylog_data') === null ? [] : Store.getStoreData('activitylog_data');     
        var task_action_arr = []; 
        var task_action = {};
        for(let i = 0 ; i < taskData.length ; i ++){            
            var action_data = JSON.parse(taskData[i].activity_log);  
            for(let j= 0 ;j < action_data.length; j++){
                task_action.activity_type = action_data[j].activity_type;
                task_action.changed_date = action_data[j].changed_date;   
                task_action.changedby_name = action_data[j].changedby_name;
                task_action.title = action_data[j].title;  
                task_action.changedby_id = action_data[j].changedby_id;
                task_action_arr.push(task_action);
                task_action = {};               
            }            
        }        
        console.log("feedsdata=====>",task_action_arr);
        let i = 0;
        ret.columns =[  {Header: 'Activity Type', accessor: 'activity_type', minWidth: 100, headerStyle: {textAlign: 'left'}},
                        {Header: 'RU/RN Title', accessor: 'title', width: 320, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Changed By', accessor: 'changed_by', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Changed Date', accessor: 'changed_date', width: 165, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                              return 0;
                            }
                            let aReverse = 0;
                            let bReverse = 0;
                            let adue = a.split("/");
                            aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                          }}];

        for(let d of task_action_arr) {            
            i++;    
            //let changedate = moment.unix(d.changed_date).format('DD/MM/YYYY')   
            let changedate = moment.unix(parseInt(d.changed_date)).format('DD/MM/YYYY') 
            //console.log("changedate==>", changedate)  
            let activityStatus = d.activity_type;
            if(d.activity_type === "Saved RU"){
                activityStatus = "Edited RU";
            }
            if(d.activity_type === "Saved RN"){
                activityStatus = "Edited RN";
            }
            let elem = {
                    'index' : i, 
                    'activity_type' : activityStatus,
                    'title' : d.title,
                    'changed_by' : d.changedby_name,
                    'changed_date' : changedate.toString()                    
                   };
           ret.data.push(elem);
        //    let sarr = [];
        //     ret.data.forEach((item) => {
        //         if (item.activity_type !== "Saved RU" && item.activity_type !== "Saved RN") {
        //             sarr.push(item);
        //         }
        //     })
        //     ret.data = sarr;
        }        
        return ret;
    } 

    render()
    {        
        if(this.state.isLoaded === false) {
            return <div>Loading...</div>
        }
        return (
            <IndexContainer>                
                <CSLTable add={false} processData={this.processMyFeedsData} tableRows={10} headerText={'Regulatory Change Management Activity Log'} />                          
            </IndexContainer>
        );
    }
}
export default CSLAuditlogs;