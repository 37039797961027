import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import { IoIosArrowUp} from "react-icons/io";
import CSLTable from '../Common/CSLTable';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const RegModalNameLabelDiv = styled.div`
    color: #252525;
    font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;    
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto; 
    height: 20px;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

class AutoDismissSectors extends React.Component
{
	state = { ready: false, sectorsAll: [], rowchecked: false, autoDismissSectorsIDs: {}};

	componentDidMount()
	{
        let allselectcheckContact = true;
        let autoDismissSectorsIDs = this.props.general.autoDismissSectorsIDs;
        //let sectorsAll = Store.getStoreData('allsectortags');
        let sectorsAll = this.props.currentTagsAll;
        console.log("sectorsAll===>", sectorsAll)        
        sectorsAll.forEach((value) => {  
            if(this.props.general.isAutoDismissSectorsCheck !== 0){
                value.select = false;
                if(value.id in autoDismissSectorsIDs){                           
                    value.select = true;
                    autoDismissSectorsIDs[value.id] = value.name;
                }
            }else{
                value.select = true;
                autoDismissSectorsIDs[value.id] = value.name;
            }
        })
        sectorsAll.forEach((user) => {
            if(!user.select){
                allselectcheckContact = false
            }
        })
        console.log("autoDismissSectorsIDs",autoDismissSectorsIDs)
        this.setState({sectorsAll: sectorsAll, rowchecked: allselectcheckContact, autoDismissSectorsIDs: autoDismissSectorsIDs, ready: true})
    }

    selectSectorsAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let sectorsAll = this.state.sectorsAll;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of sectorsAll) {                
                let selectObject = {}           
                i++;   
                // let val = 1;            
                this.state.autoDismissSectorsIDs[d.id] = d.name;                          
                selectObject.id = d.id;                
                selectObject.name = d.name;                              
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,sectorsAll: selectArray, autoDismissSectorsIDs: this.state.autoDismissSectorsIDs})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of sectorsAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.autoDismissSectorsIDs[d.id.toString()];                            
                selectObject.id = d.id;                
                selectObject.name = d.name;                    
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,sectorsAll: selectArray, autoDismissSectorsIDs: this.state.autoDismissSectorsIDs})
       }
   }

   selectSectors = (event) => {        
        let index = event.target.value;        
        var sectorsAll = this.state.sectorsAll;        
            
        if(sectorsAll[index].select){
            sectorsAll[index].select = false;            
            delete this.state.autoDismissSectorsIDs[sectorsAll[index].id.toString()];                    
        } else {
            sectorsAll[index].select = true;            
            let userid = sectorsAll[index].id.toString();                 
            this.state.autoDismissSectorsIDs[userid] = sectorsAll[index].name;                            
        }
        this.setState({sectorsAll: sectorsAll, rowchecked: false, autoDismissSectorsIDs: this.state.autoDismissSectorsIDs})
        let allselectcheck = true
        for(let d of sectorsAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    processSectorsData = () => {            
        var sectorsList = this.state.sectorsAll; 
        //console.log("regulatorList==>", documentTypeList)     
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectSectorsAll}/></div>, sortable: false, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectSectors}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},   
                    {Header: 'Sectors', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
        
        sectorsList.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        for(let d of sectorsList) {     
            //console.log("regulatorList==>", d)      
            i++;
            let elem = {'index' : i,
                    'id': d.id,
                    'name_value' : d.name,
                    'select' : d.select                    
                    };
            ret.data.push(elem);        
        }        
        return ret;
    }

    submitAutoDismissData = (event) => {
        event.preventDefault();
        console.log("autoDismissRegulatorName==>",this.state.autoDismissSectorsIDs) 
        this.props.saveAutoDismissValueSectors(this.state.autoDismissSectorsIDs);
    }

    

	render()
	{  
        if(!this.state.ready){
            return(<div></div>)
        }      
		return (
            <div>
                <div style={{marginTop: "20px"}}>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Sectors</div> 
                    <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processSectorsData} headerText={''} tableRows={5}/>                   
                </OverviewInfoBody> 
                </div>                               
                <div style={{float: "right",marginTop: "-20px"}}>                        
                    <SaveBtn onClick={this.submitAutoDismissData}>Save Auto Dismiss Data</SaveBtn>
                </div>
                
            </div>			
		);
	}
}

export default AutoDismissSectors;