import React from 'react';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosTrash} from 'react-icons/io';
import { Icon } from 'react-icons-kit';
import {androidRemove} from 'react-icons-kit/ionicons/androidRemove';
import styled from 'styled-components';
import CslTypeAhead from './CslTypeAhead';
import CslDropDown from './CslDropDown';
import CslDatepicker from './CslDatepicker';
import moment from 'moment';
import Store from '../../../../../Common/Store';
import APICall from './../../../../../Common/APICall';
import CardLoader from './../../../../../Common/CardLoader';
import IndexLoader from './../../../../../Common/IndexLoader';
import CSLLoader from './../../../../Common/CSLLoader';

const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding: 15px 20px;
`;
const FilterText = styled.div`
	float: left;
`;
const FilterChevron = styled.div`
	float: right;
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const Section45 = styled.div`
	float: left;
	width: 45%;
	box-sizing: border-box;
`;
const Section40 = styled.div`
	float: left;
	width: 40%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	padding-right: 20px;
	box-sizing: border-box;
`;
const Section10 = styled.div`
	float: left;
	width: 10%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 10px;
`;
const FieldLabel = styled.div`
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding-bottom: 15px;
`;
const FilterClearBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	float: left;
    padding: 12px 13%;
    cursor: pointer;
`;
const FilterApplyBtn = styled.button`
	background-color: #4FB2AF;
	border: 1px solid #4FB2AF;
	border-radius: 4px;
	color: #ffffff;
	float: right;
    padding: 12px 18%;
    cursor: pointer;
`;

class AdvancedFilterInProRus extends React.Component
{

	state = {ready: false,
		isclear: false,
		statusData: [],
		selectedStatusData: {},
		giftsFilterStatus: "",
		company: null,
		filterCompany: {},
		doc_type: null,
		filterDoc_type: {},
		regulator: null,
		filterRegulator: {},
		filterIssueFromDate: new Date(),
		filterIssueToDate: new Date(),
		filterAddedFromDate: new Date(),
		filterAddedToDate: new Date(),
		isLoadedIcon: false		
	};

	componentDidMount(){		
		let inProRus = Store.getStoreData('filterInProgressRus');		
		let type = {}
		let regulator_object = {};
		let company_object = {};
		for(let n of inProRus){
			let dtypenameNew = "";     
			for (let doc of n[7]){                    
				if(doc in this.props.doctype){                        
					dtypenameNew = this.props.doctype[doc]  
					type[doc.toString()] = dtypenameNew.toString()                     
				}
			} 
			let regulatorNamenew = "";
			if(n[8] in this.props.regulatorObject){
				regulatorNamenew = this.props.regulatorObject[n[8]];
				regulator_object[n[8].toString()] = regulatorNamenew.toString()
			}
			if(n[1] in Store.getStoreData('gcid_and_gcname')){
				company_object[n[1].toString()] = Store.getStoreData('gcid_and_gcname')[n[1]]
			} 
			
		}
		let doc_type = [];
		for(let t in type){
			let doc_type_object = {};
			doc_type_object.id = t
			doc_type_object.label = type[t]
			doc_type.push(doc_type_object)
		}
		let regulator = [];
		for(let r in regulator_object){
			let regulator_object_object = {};
			regulator_object_object.id = r
			regulator_object_object.label = regulator_object[r]
			regulator.push(regulator_object_object)
		}
		let company = [];
		for(let r in company_object){
			let com_object = {};
			com_object.id = r
			com_object.label = company_object[r]
			company.push(com_object)
		}

		let filterAddedFromDate = new Date();
		let filterAddedToDate = new Date();
		let select_timespan = Store.getStoreData('defaultTimePeriod');
		if(select_timespan === "this_month"){   
			let startOfMonth = moment().startOf('month');
            startOfMonth.set('hour', 0);
            startOfMonth.set('minute', 0);
            startOfMonth.set('second', 0);            
			filterAddedFromDate = new Date(startOfMonth);
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);                 
			filterAddedToDate = new Date(currentdate);
		}else if(select_timespan === "last_month"){   
            let prevMonthF = moment().subtract(1, 'months').startOf('month');
            prevMonthF.set('hour', 0);
            prevMonthF.set('minute', 0);
            prevMonthF.set('second', 0);
            filterAddedFromDate = new Date(prevMonthF);
            let prevMonthL = moment().subtract(1, 'months').endOf('month').endOf('day');
            prevMonthL.set('hour', 23);
            prevMonthL.set('minute', 59);
            prevMonthL.set('second', 59);
            filterAddedToDate = new Date(prevMonthL);
		}else if(select_timespan === "last_thirty_days"){   
            let lastThirty = moment().subtract(30, 'days');     
            lastThirty.set('hour', 0);
            lastThirty.set('minute', 0);
            lastThirty.set('second', 0);
            filterAddedFromDate = new Date(lastThirty);     
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            filterAddedToDate = new Date(currentdate);
		}else if(select_timespan === "last_twelve_month"){   
            let lastTwelve = moment().subtract(12, 'months'); 
            lastTwelve.set('hour', 0);
            lastTwelve.set('minute', 0);
            lastTwelve.set('second', 0);
            filterAddedFromDate = new Date(lastTwelve);            
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            filterAddedToDate = new Date(currentdate); 
		}else if(select_timespan === "this_year"){   
            let thisyear = moment().startOf('year');  
            thisyear.set('hour', 0);
            thisyear.set('minute', 0);
            thisyear.set('second', 0);
            filterAddedFromDate = new Date(thisyear);  
            let currentdate = moment();
            currentdate.set('hour', 23);
            currentdate.set('minute', 59);
            currentdate.set('second', 59);
            filterAddedToDate = new Date(currentdate);
		}else if(select_timespan === "last_year"){   
            let lastyear = moment().subtract(1, 'year').startOf('year');    
            lastyear.set('hour', 0);
            lastyear.set('minute', 0);
            lastyear.set('second', 0);
            filterAddedFromDate = new Date(lastyear);   
            let lastyearend = moment().subtract(1, 'year').endOf('year').endOf('day');    
            lastyearend.set('hour', 23);
            lastyearend.set('minute', 59);
            lastyearend.set('second', 59);
            filterAddedToDate = new Date(lastyearend);
		}else if(select_timespan === "quarter_one"){   
            let firstJana = moment().startOf('year'); 
            firstJana.set('hour', 0);
            firstJana.set('minute', 0);
            firstJana.set('second', 0);
            filterAddedFromDate = new Date(firstJana);  
            let lastmarch = moment().month(2).endOf('month').endOf('day');  
            lastmarch.set('hour', 23);
            lastmarch.set('minute', 59);
            lastmarch.set('second', 59);
            filterAddedToDate = new Date(lastmarch); 
		}else if(select_timespan === "quarter_two"){   
            let firstapril = moment().month(3).startOf('month');  
            firstapril.set('hour', 0);
            firstapril.set('minute', 0);
            firstapril.set('second', 0);
            filterAddedFromDate = new Date(firstapril);
            let lastjune = moment().month(5).endOf('month').endOf('day');    
            lastjune.set('hour', 23);
            lastjune.set('minute', 59);
            lastjune.set('second', 59);
            filterAddedToDate = new Date(lastjune);   
		}else if(select_timespan === "quarter_three"){   
            let firstjuly = moment().month(6).startOf('month');           
            firstjuly.set('hour', 0);
            firstjuly.set('minute', 0);
            firstjuly.set('second', 0);
            filterAddedFromDate = new Date(firstjuly);
            let lastsep = moment().month(8).endOf('month').endOf('day');   
            lastsep.set('hour', 23);
            lastsep.set('minute', 59);
            lastsep.set('second', 59);
            filterAddedToDate = new Date(lastsep); 
		}else if(select_timespan === "quarter_four"){   
            let firstoct = moment().month(9).startOf('month')       
            firstoct.set('hour', 0);
            firstoct.set('minute', 0);
            firstoct.set('second', 0);
            filterAddedFromDate = new Date(firstoct);
            let lastdec = moment().month(11).endOf('month').endOf('day')
            lastdec.set('hour', 23);
            lastdec.set('minute', 59);
            lastdec.set('second', 59);
            filterAddedToDate = new Date(lastdec); 
		}  
		
		let statusData = [
			{id: 0, label: "select status"},			
			{id: 1, label: "Overdue"},
			{id: 2, label: "Pending"},
			{id: 3, label: "In Progress"}			
		]
		let selectedStatusData = {id: 0, label: "select status"}
		
		this.setState({ready: true, company, doc_type, regulator, filterAddedFromDate, filterAddedToDate, statusData, selectedStatusData,
			filterIssueFromDate: filterAddedFromDate,
			filterIssueToDate: filterAddedToDate})
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {									
			let inProRus = Store.getStoreData('filterInProgressRus');		
			let type = {}
			let regulator_object = {};
			let company_object = {};
			for(let n of inProRus){
				let dtypenameNew = "";     
				for (let doc of n[7]){                    
					if(doc in this.props.doctype){                        
						dtypenameNew = this.props.doctype[doc]  
						type[doc.toString()] = dtypenameNew.toString()                     
					}
				} 
				let regulatorNamenew = "";
				if(n[8] in this.props.regulatorObject){
					regulatorNamenew = this.props.regulatorObject[n[8]];
					regulator_object[n[8].toString()] = regulatorNamenew.toString()
				}
				if(n[1] in Store.getStoreData('gcid_and_gcname')){
					company_object[n[1].toString()] = Store.getStoreData('gcid_and_gcname')[n[1]]
				} 
				
			}
			let doc_type = [];
			for(let t in type){
				let doc_type_object = {};
				doc_type_object.id = t
				doc_type_object.label = type[t]
				doc_type.push(doc_type_object)
			}
			let regulator = [];
			for(let r in regulator_object){
				let regulator_object_object = {};
				regulator_object_object.id = r
				regulator_object_object.label = regulator_object[r]
				regulator.push(regulator_object_object)
			}
			let company = [];
			for(let r in company_object){
				let com_object = {};
				com_object.id = r
				com_object.label = company_object[r]
				company.push(com_object)
			}

			let statusData = [
				{id: 0, label: "select status"},			
				{id: 1, label: "Overdue"},
				{id: 2, label: "Pending"},
				{id: 3, label: "In Progress"}			
			]
			let selectedStatusData = {id: 0, label: "select status"}
			
			this.setState({ready: true, company, doc_type, regulator, statusData, selectedStatusData})
		}
	}

	ricipientValueCompany = (selected_options) => {        
        let ricipientObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                ricipientObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("ricipientObject",ricipientObject)        
        this.setState({filterCompany: ricipientObject})
    }
	ricipientValueDocType = (selected_options) => {        
        let ricipientObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                ricipientObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("ricipientValueDocType",ricipientObject)        
        this.setState({filterDoc_type: ricipientObject})
    }
	ricipientValueRegulator = (selected_options) => {        
        let ricipientObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                ricipientObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("ricipientValueRegulator",ricipientObject)        
        this.setState({filterRegulator: ricipientObject})
    }
	changeClear = () => {
		this.setState({isclear: false})
	}	

	onIssueFromDate = (date) => {
        this.setState({filterIssueFromDate: date});		
	}
	onIssueToDate = (date) => {
        this.setState({filterIssueToDate: date});
    }
	
	onAddedFromDate = (date) => {
        this.setState({filterAddedFromDate: date});		
	}
	onAddedToDate = (date) => {
        this.setState({filterAddedToDate: date});
    }

	filterClear = () => { 
		this.setState({ isclear: true, 
			filterCompany: {},
			filterDoc_type: {},
			filterRegulator: {},
		})		
		this.props.filterSubmitValue(Store.getStoreData('filterInProgressRus'))		
	}

	filterSubmit_new = () => {
		let postData = { command: "filter_ru_tasks", filterType: 'Inpro'};
        let api = new APICall();   
		this.setState({isLoadedIcon: true})     
        api.command(postData, this.afterFiletrSubmit); 
	}

	afterFiletrSubmit = (result) => {
		let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
		let timePeriod = Store.getStoreData('defaultTimePeriod');
		console.log("result===>", result)
		Store.updateStore('pendingRCtasks', result['tasks'].pendingTasks);
        Store.updateStore('dismissFeedsAndRUtasks', result['tasks'].dismissTasks);
        Store.updateStore('followupRCtasks', result['tasks'].followupTasks);
        Store.updateStore('myRCtasks', result['tasks'].mytasks);
        Store.updateStore('newRCtasks', result['tasks'].newTasks);
        Store.updateStore('pendingRCtasks', result['tasks'].pendingTasks);
        Store.updateStore('saveRCtasks', result['tasks'].saveTasks);
        Store.updateStore('shareWithMeRCTasks', result['tasks'].shareWithMeTasks);		

		IndexLoader.divideAutoForwordFeedsAndRUIndex(result['tasks'].autoforwordfeedTasks)
        CardLoader.divideDashboardCardCoutAll([], {}, regulatorObject, timePeriod)

		let pendingRCtasks = Store.getStoreData('pendingRCtasks_update');  
		let pendingRCtasks_updated = [];

		if('regName' in this.props && this.props.regName !== ''){			
			for(let pen of pendingRCtasks){
				if(pen[8] in regulatorObject && regulatorObject[pen[8]] === this.props.regName){
					pendingRCtasks_updated.push(pen);
				}
			}			
		}else{			
			pendingRCtasks_updated = Store.getStoreData('pendingRCtasks_update');	
		} 

		Store.updateStore('filterInProgressRus', pendingRCtasks_updated)

		//filter section start

		let inProRusAll = Store.getStoreData('filterInProgressRus');		
        //console.log("inProRusAll",inProRusAll);
		let filterStatus = [];
        if (this.state.giftsFilterStatus === "") {
            filterStatus = inProRusAll;
        } else {			
            inProRusAll.forEach((item) => {  
                if (item[11] === this.state.giftsFilterStatus) {
                    filterStatus.push(item);
                }
            })
        }
        let filterCompanys = [];
        if (Object.keys(this.state.filterCompany).length === 0) {
            filterCompanys = filterStatus;
        } else {			
            filterStatus.forEach((item) => {                
                let companyid = item[1];                           
                if (companyid.toString() in this.state.filterCompany) {
                    filterCompanys.push(item);
                }
            })
        }
        let filterDocumentTypes = [];
        if (Object.keys(this.state.filterDoc_type).length === 0) {
            filterDocumentTypes = filterCompanys;
        } else {			
			let feedid_object = {}
            filterCompanys.forEach((item) => {                
                for (let doc of item[7]){                    
					if(doc in this.state.filterDoc_type){                        
						feedid_object[item[2]] = 1               
					}
				}
            })
			for(let d of filterCompanys){
				if(d[2] in feedid_object){
					filterDocumentTypes.push(d)
				}
			}
        }

		let filterRegulators = [];
        if (Object.keys(this.state.filterRegulator).length === 0) {
            filterRegulators = filterDocumentTypes;
        } else {			
			filterDocumentTypes.forEach((item) => { 
                if (item[8] in this.state.filterRegulator) {
                    filterRegulators.push(item);
                }
            })
        }
		let issueFormdatetoDate = [];
        if (this.state.filterIssueFromDate === "" && this.state.filterIssueToDate === "") {
            issueFormdatetoDate = filterRegulators;
        }else{
            filterRegulators.forEach((item) => {
                // let task_json = JSON.parse(item.task_json); 
                let issueDate = moment(item[9], 'YYYY/MM/DD').unix();
				let fromDateValue = this.state.filterIssueFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterIssueToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("issueDate",issueDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(issueDate >= fromDate && issueDate <= toDate){
                    issueFormdatetoDate.push(item);
                }
            })
        }		

		let addedFormdatetoDate = [];
        if (this.state.filterAddedFromDate === "" && this.state.filterAddedToDate === "") {
            addedFormdatetoDate = issueFormdatetoDate;
        }else{
            issueFormdatetoDate.forEach((item) => {
                // let task_json = JSON.parse(item.task_json); 
                let createdDate = item[12];
				let fromDateValue = this.state.filterAddedFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterAddedToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("issueDate",createdDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(createdDate >= fromDate && createdDate <= toDate){
                    addedFormdatetoDate.push(item);
                }
            })
        }		
        
        console.log("addedFormdatetoDate",addedFormdatetoDate)
		this.setState({isLoadedIcon: false})
        this.props.filterSubmitValue(addedFormdatetoDate)
		//filter section end
	}	

	filterSubmit = () => {
		this.setState({isLoadedIcon: true})
        let inProRusAll = Store.getStoreData('filterInProgressRus');		
        //console.log("inProRusAll",inProRusAll);
		let filterStatus = [];
        if (this.state.giftsFilterStatus === "") {
            filterStatus = inProRusAll;
        } else {			
            inProRusAll.forEach((item) => {  
                if (item[11] === this.state.giftsFilterStatus) {
                    filterStatus.push(item);
                }
            })
        }
        let filterCompanys = [];
        if (Object.keys(this.state.filterCompany).length === 0) {
            filterCompanys = filterStatus;
        } else {			
            filterStatus.forEach((item) => {                
                let companyid = item[1];                           
                if (companyid.toString() in this.state.filterCompany) {
                    filterCompanys.push(item);
                }
            })
        }
        let filterDocumentTypes = [];
        if (Object.keys(this.state.filterDoc_type).length === 0) {
            filterDocumentTypes = filterCompanys;
        } else {			
			let feedid_object = {}
            filterCompanys.forEach((item) => {                
                for (let doc of item[7]){                    
					if(doc in this.state.filterDoc_type){                        
						feedid_object[item[2]] = 1               
					}
				}
            })
			for(let d of filterCompanys){
				if(d[2] in feedid_object){
					filterDocumentTypes.push(d)
				}
			}
        }

		let filterRegulators = [];
        if (Object.keys(this.state.filterRegulator).length === 0) {
            filterRegulators = filterDocumentTypes;
        } else {			
			filterDocumentTypes.forEach((item) => { 
                if (item[8] in this.state.filterRegulator) {
                    filterRegulators.push(item);
                }
            })
        }
		let issueFormdatetoDate = [];
        if (this.state.filterIssueFromDate === "" && this.state.filterIssueToDate === "") {
            issueFormdatetoDate = filterRegulators;
        }else{
            filterRegulators.forEach((item) => {
                // let task_json = JSON.parse(item.task_json); 
                let issueDate = moment(item[9], 'YYYY/MM/DD').unix();
				let fromDateValue = this.state.filterIssueFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterIssueToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("issueDate",issueDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(issueDate >= fromDate && issueDate <= toDate){
                    issueFormdatetoDate.push(item);
                }
            })
        }		

		let addedFormdatetoDate = [];
        if (this.state.filterAddedFromDate === "" && this.state.filterAddedToDate === "") {
            addedFormdatetoDate = issueFormdatetoDate;
        }else{
            issueFormdatetoDate.forEach((item) => {
                // let task_json = JSON.parse(item.task_json); 
                let createdDate = item[12];
				let fromDateValue = this.state.filterAddedFromDate;
				fromDateValue.setHours(0);
                fromDateValue.setMinutes(0);
                fromDateValue.setSeconds(0);
				let fromDate = moment(fromDateValue).unix()

                let toDateValue = this.state.filterAddedToDate;
                toDateValue.setHours(23);
                toDateValue.setMinutes(59);
                toDateValue.setSeconds(59);
				let toDate = moment(toDateValue).unix()
				console.log("issueDate",createdDate)
                console.log("fromDate=>", fromDate)
				console.log("toDate=>",toDate)
                if(createdDate >= fromDate && createdDate <= toDate){
                    addedFormdatetoDate.push(item);
                }
            })
        }		
        
        console.log("addedFormdatetoDate",addedFormdatetoDate)
		this.setState({isLoadedIcon: false})
        this.props.filterSubmitValue(addedFormdatetoDate)        
    }

	statusValue = (selected_options) => {
		console.log("selected_options",selected_options)
		let filterStatus = "";
		if(selected_options.id !== 0){
			filterStatus = selected_options.label;
		}
		this.setState({giftsFilterStatus: filterStatus})        
	}

	render()
	{
		if(!this.state.ready){
			return(<div></div>)
		}
		return (
			<div>	
				{
					(() => {                    
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				}
				<FilterBody>
					<Section20>
						<FieldLabel>Status</FieldLabel>
						<CslDropDown allData={this.state.statusData} selectedAllData={this.state.selectedStatusData} statusValue={this.statusValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
					</Section20>
					<Section40>
						<FieldLabel>Company</FieldLabel>
						{
							(() => {								
								if (this.state.company !== null) {
									return <CslTypeAhead data={this.state.company} ricipientValue={this.ricipientValueCompany} isclear={this.state.isclear} changeClear={this.changeClear}/>
								}
							})()
						}
					</Section40> 
					<Section40>
						<FieldLabel>Document Type</FieldLabel>
						{
							(() => {								
								if (this.state.doc_type !== null) {
									return <CslTypeAhead data={this.state.doc_type} ricipientValue={this.ricipientValueDocType} isclear={this.state.isclear} changeClear={this.changeClear}/>
								}
							})()
						}
					</Section40> 
					<div style={{clear: "both"}}></div>
					<Section40 style={{marginTop: "10px"}}>
						<FieldLabel>Regulator</FieldLabel>
						{
							(() => {								
								if (this.state.regulator !== null) {
									return <CslTypeAhead data={this.state.regulator} ricipientValue={this.ricipientValueRegulator} isclear={this.state.isclear} changeClear={this.changeClear}/>
								}
							})()
						}
					</Section40>  
					<Section40>
						<FieldLabel style={{marginTop: "10px"}}>Issue Date</FieldLabel>
						<Section45><CslDatepicker updateFromDate={this.state.filterIssueFromDate} ondateValue={this.onIssueFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<Section10><Icon icon={androidRemove}/></Section10>
						<Section45><CslDatepicker updateFromDate={this.state.filterIssueToDate} ondateValue={this.onIssueToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<div style={{clear: "both"}}></div>
					</Section40> 
					<div style={{clear: "both"}}></div>
					<Section40>
						<FieldLabel style={{marginTop: "10px"}}>Added Date</FieldLabel>
						<Section45><CslDatepicker updateFromDate={this.state.filterAddedFromDate} ondateValue={this.onAddedFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<Section10><Icon icon={androidRemove}/></Section10>
						<Section45><CslDatepicker updateFromDate={this.state.filterAddedToDate} ondateValue={this.onAddedToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
						<div style={{clear: "both"}}></div>
					</Section40>
					<Section20 style={{float: "right"}}>
						<div style={{paddingTop: "100px"}}>
							<FilterClearBtn onClick={this.filterClear}>Clear</FilterClearBtn>
							<FilterApplyBtn onClick={this.filterSubmit}>Apply</FilterApplyBtn>
							<div style={{clear: "both"}}></div>
						</div>
					</Section20>
					<div style={{clear: "both"}}></div>  
				</FilterBody>
			</div>
		);
	}
}

export default AdvancedFilterInProRus;