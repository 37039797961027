import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge, FaBook, FaWrench } from "react-icons/fa";
import Store from '../Common/Store'
import { TiArrowBack } from "react-icons/ti";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faSquareList, faGear, faClipboardList } from "@fortawesome/pro-thin-svg-icons";
import {Link} from "react-router-dom";

const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class RcmHeader extends React.Component
{
    setView = (event) => {
        event.preventDefault();
        //Store.updateStore('prev_view', this.props.from_view);
        this.props.refreshParentState('register_index');
        Store.updateStore('is_register', true);
    }
    setHomeView = (event) => {
        event.preventDefault();
        this.props.refreshParentState('select_index');
        Store.updateStore('is_register', false);
    }

    redirectToModuleConfig = (event) => {
        event.preventDefault();
        const url = "/moduleconfig";
        window.open(url, "fc_mc", "width=1400, height=900");
    }

    showRCMRegister = () => {
        let register_url = '/register' ;
        window.location.href = register_url;
    }

    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
        let site_layout = JSON.parse(localStorage.getItem('site_layout'));
        use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)
        let module_config_url = '/moduleconfig';
        let module_config_url_csl = '/config';
        let csl_home = '/ru';
        let register_url = '/register';
        const role = Store.getStoreData('role')
        console.log(role)
        return (
            <HeadContainer>
                <Link to={'/'}><HomeText><span style={{fontWeight: "600"}}><FontAwesomeIcon icon={faHouseBlank} style={{marginRight: '8px'}}/>Regulatory Change Management</span></HomeText></Link>

                { role === 'manager' &&
                    <Categories title='Module Config'><Link to={'/moduleconfig'} style={{color: 'black'}}><FontAwesomeIcon icon={faGear} /></Link></Categories>
                }
                { role === 'manager' &&
                    <Categories title='Audit Logs'><a href={process.env.REACT_APP_AUDIT_URL} style={{cursor: 'pointer', color: 'black'}}><FontAwesomeIcon icon={faSquareList} /></a></Categories>
                }
                { (role === 'manager' || role === 'rcm_gatekeeper') &&
                    <Categories title='Register'><Link onClick={this.showRCMRegister} style={{color: 'black'}}><FontAwesomeIcon icon={faClipboardList} /></Link></Categories>
                }
                {/*
                    (() => {
                        if(Store.getStoreData('is_admin')){
                            return(<Categories style={{cursor: "pointer"}}><a href={module_config_url_csl}><FaCog style={{color: "#337ab7"}}/></a></Categories>)
                        }else{
                            return(<Categories><FaCog /></Categories>)
                        }
                    })()
                */}
                {/*
                    (() => {
                        if(Store.getStoreData('is_admin') === true && this.props.current_tab === "config"){
                            return(<Categories style={{cursor: "pointer"}}><a href={csl_home}><TiArrowBack style={{color: "#337ab7"}}/></a></Categories>)
                        }
                    })()
                */}


                {/*
                    (() => {
                        if (Store.getStoreData('is_register')) {
                            return(<Categories style={{cursor: "pointer"}} onClick={this.setHomeView}><TiArrowBack style={{color: "#337ab7"}}/></Categories>)
                        }
                    })()
                */}
                {/* {
                    (() => {
                        if(!Store.getStoreData('is_admin')) {
                            if(Store.getStoreData('role') !== "team"){
                            return <Categories style={{cursor: "pointer"}} > <a href={register_url}><FaBook style={{color: "#337ab7"}}/></a></Categories>
                            }
                        }
                    })()
                } */}
                {/* {
                    (() => {
                        if(!Store.getStoreData('is_admin')) {
                            if(Store.getStoreData('role') === "team"){
                                return <Categories><a href={module_config_url}><FaWrench /></a></Categories>
                            }
                        }
                    })()
                }                 */}
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default RcmHeader;
