import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store.js';
import RUFullPills from '../Partials/RUFullPills';
import Utils from '../../Common/Utils';
import ChildTasksRUTable from '../Partials/ChildTasksRUTable';
import DatePicker from 'react-date-picker';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import CSLLoader from './../Common/CSLLoader';
import AlertBox from './../Common/AlertBox';
import '../../App.css';
import moment from 'moment';
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 0%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #252525;
`;
const RegModalIssueDateInput = styled.input`
    height: 30px;
    width: 90%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    margin-top: 8px;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #252525;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegModalNameInput = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisalButtonDiv = styled.div`
    display: block;
    box-sizing: border-box;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
`;
const RegMarkForActionButton = styled.button`
display: inline-block;
box-sizing: border-box;
background-color: #37ada7;
color: #ffffff;
padding: 0px 20px;
height: 35px;
border-radius: 4px;
border: 1px solid #37ada7;
margin-left: 5px;
font-size: 11px;
margin-left: 5px;
/* float: right; */
cursor: pointer;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegDismissalReason = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const RegShareButton = styled.button`
display: inline-block;
box-sizing: border-box;
background-color: #234062;
color: #ffffff;
padding: 0px 20px;
height: 35px;
border-radius: 4px;
border: 1px solid #dadada;
margin-left: 5px;
font-size: 11px;
margin-left: 5px;
/* float: right; */
cursor: pointer;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #343434;
    font-weight: 600;
    font-size: 17px;
`;
class RegulatoryShareModal extends React.Component
{
    state = {
        resonforaction: false,
        reasonforcomplete: false,
        followuptask:false,
        date: new Date(),
        dismiss_note: "",
        complete_note: "",
        assigned_to: '',
        lane: "COMP_RCM_ACTION",
        date: new Date(),
        assign_note: "",
        due_by: '1970-01-01',
        child_task: [],
        foracknow: false,
        modChildTaskarray: [],
        bottomButtonShow: false,
        groupcompanyName: "",
        isLoaderIcon: false,
        alert_param: null,
        isGroup: false,
        gcompanyUsers: {},
    };

    constructor(props) {
        super(props);
        //this.component_id = Store.registerCallBack('childtasks', this.GetFollowuptasks);
      }

    //   componentWillUnmount() {
    //     Store.deRegisterCallback('childtasks', this.component_id);
    //     }

    //   GetFollowuptasks = () => {
    //     let childtasks = Store.getStoreData('childtasks');
    //     this.setState({child_task: childtasks});
    //     console.log("child task ----->", childtasks);
    //   }
    componentDidMount() {
        let task = Store.getStoreData('this_task_detail');
        let task_json = JSON.parse(task.task.parenttask.task_json);
        console.log(task_json)
        let userID = Store.getStoreData('loggedincontact');
        if(userID in task_json.object_data.shareUserAcknowledge){
            console.log(task_json.object_data.shareUserAcknowledge[userID].status)
            if(task_json.object_data.shareUserAcknowledge[userID].status === "Open"){
                this.setState({foracknow: true});
            }
            //this.setState({foracknow: true});
        }
        let modChildTaskarray = [];
        let child_task = Store.getStoreData('childtasks');
        let loginId = Store.getStoreData('loggedincontact');
        if(child_task.length !== 0){
            for(let i=0;i<child_task.length;i++){
                if(child_task[i].contact_id === loginId){
                    modChildTaskarray.push(child_task[i]);
                }
            }
        }
        let date = new Date();
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        console.log("dateStr",dateStr)
        //start-group-company
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gcid = (parseInt(task_json.task_data.group_company_id)%100000)
        console.log("gcid",gcid);
        console.log("gc_assoc",gc_assoc);
        let gcname = "";
        if(gcid in gc_assoc){
            gcname = gc_assoc[gcid];
        }
        console.log("gcname",gcname)
        let isGroup = false
        let companyUsers = {};
        if('group_company_id' in task_json.task_data &&  task_json.task_data.group_company_id !== 0){
            let gc_company = Store.getStoreData('gc_company');
            //console.log("gc_company", gc_company);
            for(let gc of gc_company) {
                if(parseInt(gc.id) === parseInt(gcid)){
                    companyUsers = gc.users;
                }
            }
            console.log("gc_company", companyUsers);
            isGroup = true;
        }
        this.setState({modChildTaskarray, due_by: dateStr,groupcompanyName: gcname, isGroup: isGroup, gcompanyUsers: companyUsers})
    }
    closeRegulatoryModal = (event) => {
        if (document.referrer.endsWith("/tmview")) {
            // window.location = '/select/microservice/mstop/tmview';
            this.props.closeTaskModal();
        } else {
            this.props.closeModal();
        }
        //this.props.closeModal();
    }
    addResonforDismiss = (event) => {
        event.preventDefault();
        let child_task = Store.getStoreData('childtasks');
        let child_task_incomplete = 0;
        for(let i=0; i<child_task.length; i++){
            if(child_task[i].cur_lane === "COMP_RCM_FOLLOWUP_ASSIGNMENT"){
                child_task_incomplete = child_task_incomplete + 1;
            }
        }
        let problems = false
        let message = '';
        if(child_task_incomplete > 0) {
            //alert("One or more followup tasks are still pending. You cannot dismiss or complete this task now");
            message = 'One or more followup tasks are still pending. You cannot dismiss or complete this task now.';
            problems = true;
        }
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})
        }else{
            if(Store.getStoreData('parent_task_id') > 0) {
                this.setState({resonforaction: true, lane: "COMP_RCM_FOLLOWUP_DISMISS"})
            }else{
                this.setState({resonforaction: true, followuptask: false, lane: "COMP_RCM_DISMISS"});
            }
        }
    }

    aftercheckvalidationAll = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    addFollowupActionsection = (event) => {
        event.preventDefault();
        let child_task = this.state.modChildTaskarray;
        console.log("child_task",this.state.modChildTaskarray);

        let child_task_incomplete = 0;
        for(let i=0; i<child_task.length; i++){
            if(child_task[i].cur_lane == "COMP_RCM_FOLLOWUP_ASSIGNMENT"){
                child_task_incomplete = child_task_incomplete + 1;
            }
        }
        let problems = false
        let message = '';
        if(child_task_incomplete > 0) {
            //alert("One or more followup tasks are still pending. You cannot dismiss or complete this task now");
            message = 'One or more followup tasks are still pending. You cannot dismiss or complete this task now.';
            problems = true;
        }
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})
        }else{
            if(Store.getStoreData('parent_task_id') > 0 ) {
                this.setState({resonforaction: false,bottomButtonShow: true, reasonforcomplete: true, dismiss_note: "", lane: "COMP_RCM_FOLLOWUP_COMPLETE"});
            } else {
                this.setState({resonforaction: false,bottomButtonShow: true, reasonforcomplete: true, followuptask: true, dismiss_note: "", lane: "COMP_RCM_COMPLETE"});
            }
        }
    }
    addFollowupAssignection = (event) => {
        event.preventDefault();
        this.setState({resonforaction: false,bottomButtonShow: true, followuptask: true, reasonforcomplete: false, dismiss_note: "", lane: "COMP_RCM_FOLLOWUP"});
    }

    closeRegulatoryButtomModal = (event) => {
        event.preventDefault();
        this.setState({bottomButtonShow: false})
    }

    handleOnChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    updateFollowups = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        this.setState({child_task : result.result });
    }

    checkvalidation = (result, stack) => {
        this.setState({alert_param: null})
    }


    saveClientRegulatorychange = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        if (this.state.lane === "COMP_RCM_ACTION") {
            //alert("Please complete or dismiss before saving");
            message += 'Please complete or dismiss before saving.<br>';
            problems = true;
        }
        if (this.state.lane === "COMP_RCM_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for dismissal");
            message += 'Please provide a reason for dismissal.<br>';
            problems = true;
        }
        if (this.state.lane === "COMP_RCM_FOLLOWUP" && this.state.assign_note === "") {
            //alert("Please provide a Assignment note for Follow up task");
            message += 'Please provide a Assignment note for Follow up task.<br>';
            problems = true;
        }
        if (this.state.lane === "COMP_RCM_FOLLOWUP_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for followup task dismissal");
            message += 'Please provide a reason for followup task dismissal.<br>';
            problems = true;
        }

        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false,
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})
        }else{
            let rufeed = Store.getStoreData('task');
            let regulatorName = "";
            if('ragulatorName' in rufeed){
                regulatorName = rufeed.ragulatorName.replace(/(&rsquo;)/g, "\'");
                regulatorName = regulatorName.replace(/'+/g,"'");
            }

            if(this.state.lane === "COMP_RCM_FOLLOWUP")
            {
                if(this.state.assigned_to === ''){
                    let alert_param = {title: 'ERROR', message: 'Please select a user to assign the task to.', ok_text: 'Ok', confirm: false,
                                   alertHandler: this.checkvalidation, stack: {}}
                    this.setState({alert_param: alert_param})
                    return;
                }
                let ref_id = Utils.genKey(12);
                let parenttaskdetails = Store.getStoreData('this_task_detail');
                let task_json_parent = JSON.parse(parenttaskdetails.task.parenttask.task_json);
                let parrent_task_created_date = moment(parenttaskdetails.task.parenttask.created_at).unix();

                let task = {
                    "task_data": { "module_id": "rcm", "last_action": "COMP_RCM_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assigned_to, 'task_type' : 'COMP_RCM_FOLLOWUP', 'parent_task_id' : Store.getStoreData('current_task_id'), 'cur_lane': 'COMP_RCM_FOLLOWUP_ASSIGNMENT', due_date: this.state.due_by},
                    "object_data": {documentName: rufeed.documentName,documentTypes: rufeed.documentTypes,ragulatorName: regulatorName, uploadDate: rufeed.uploadDate, summary :rufeed.summary, note: this.state.assign_note, due_by: this.state.due_by, cur_view: 'Unopened','ptAddedDate': parrent_task_created_date, issueDate: task_json_parent.object_data.issueDate},
                    "action_data": [{ "action": "COMP_RCM_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_RCM_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.assign_note }]
                };

                let postData = { "command": "createfollowuptask", "task": task, bin_files: [], parentTaskId: Store.getStoreData('current_task_id') };
                console.log("task follow up", postData);
                let api = new APICall();
                this.setState({isLoaderIcon: true})
                api.command(postData, this.processAddFollowupTask);
            }
            else{
                //console.log("Sourav",this.state.lane)
                let task = Store.getStoreData('this_task_detail');
                let task_id = task.task.parenttask.id;
                let task_json = JSON.parse(task.task.parenttask.task_json);
                //console.log("task_json1",task_json)
                let userID = Store.getStoreData('loggedincontact');
                let shareObjectType = "";
                let actionData = null;
                if(userID in task_json.object_data.shareUserInfo){
                    shareObjectType = "For_Information"
                    actionData = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", completedShareObjectID: userID,completedShareUserInfoID: userID};
                }else{
                    shareObjectType = "For_Acknowledgement"
                    actionData = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", completedShareObjectID: userID,completedShareUserAcknowledgeID: userID};
                }
                let updateShareObject = task_json.object_data.shareobject;
                delete updateShareObject[userID.toString()];
                // let updateShareUserInfo = task_json.object_data.shareUserInfo;
                // delete updateShareUserInfo[userID.toString()];
                let shareObjectCompleteArray = [];
                if('shareobjectcomplete' in task_json.object_data) {
                    shareObjectCompleteArray = task_json.object_data.shareobjectcomplete;
                    let shareObject = {id: userID,
                        complete_note: this.state.complete_note,
                        shareUserType: shareObjectType}
                    shareObjectCompleteArray.push(shareObject);
                }else{
                    shareObjectCompleteArray.push({id: userID,
                                                    complete_note: this.state.complete_note,
                                                shareUserType: shareObjectType})
                }
                if(userID in task_json.object_data.shareUserAcknowledge){
                    let val = {date: task_json.object_data.shareUserAcknowledge[userID].date, status: "sendAcknowledge", OpenedDate:  task_json.object_data.shareUserAcknowledge[userID].OpenedDate, completedDate: Date.now(),cur_view: "Completed",responsedate: Date.now()};
                    //let val = {date: Date.now(),status: "sendAcknowledge",cur_view: "Completed",responsedate: ""};
                    task_json.object_data.shareUserAcknowledge[userID] = val
                    task_json['object_data'].shareUserAcknowledge = task_json.object_data.shareUserAcknowledge;
                }else if(userID in task_json.object_data.shareUserInfo){
                    let val = {date: task_json.object_data.shareUserInfo[userID].date, status: "Open", OpenedDate:  task_json.object_data.shareUserInfo[userID].OpenedDate,  completedDate: Date.now(), cur_view: "Completed",responsedate: Date.now()};
                    //let val = {date: Date.now(),status: "Open",cur_view: "Completed",responsedate: ""};
                    task_json.object_data.shareUserInfo[userID] = val
                    task_json['object_data'].shareUserInfo = task_json.object_data.shareUserInfo;
                }
                task_json['task_data'].actiondate = Date.now();
                task_json['object_data'].shareobject = updateShareObject;
                task_json['object_data'].shareobjectcomplete = shareObjectCompleteArray;
                if(actionData !== null){
                    task_json['action_data'] = actionData;
                }
                //console.log("task_json2",task_json)
                let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
                taskjson = taskjson.replace(/'+/g,"'");
                taskjson = JSON.parse(taskjson);
                let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
                console.log(postData);
                const api = new APICall();
                this.setState({isLoaderIcon: true})
                api.command(postData, this.processAddCompleteTask);
            }
        }
    }
    processAddCompleteTask = (result) => {
        //alert("This Regulatory Change has been successfully completed.");
        let alert_param = {title: 'Success', message: 'This Regulatory Change has been successfully completed.', ok_text: 'OK', confirm: false,
                           alertHandler: this.updateProcessAddCompleteTask, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateProcessAddCompleteTask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
              window.location = '/';
            }else{
              window.location = process.env.REACT_APP_TMVIEW_URL;
            }
          } else {
            window.location.reload(true);
        }
    }

    processAddFollowupTask = (result) => {
        //alert("This Regulatory Change has been successfully marked for action.");
        let alert_param = {title: 'Success', message: 'The regulatory change has been marked for action.', ok_text: 'OK', confirm: false,
        alertHandler: this.updateProcessAddFollowupTask, stack: {}}
        this.setState({alert_param: alert_param})
    }

    updateProcessAddFollowupTask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let task_json = JSON.parse(task.task.parenttask.task_json);
        let userID = Store.getStoreData('loggedincontact');
        if(userID in task_json.object_data.shareUserAcknowledge && task_json.object_data.shareUserAcknowledge[userID].cur_view === "Opened"){
            let val = {date: task_json.object_data.shareUserAcknowledge[userID].date, status: "sendAcknowledge", OpenedDate:  task_json.object_data.shareUserAcknowledge[userID].OpenedDate, markforactionDate: Date.now(),cur_view: "Marked for action",responsedate: Date.now()};
            //let val = {date: Date.now(),status: "sendAcknowledge",cur_view: "Marked for action",responsedate: ""};
            task_json.object_data.shareUserAcknowledge[userID] = val
            task_json['object_data'].shareUserAcknowledge = task_json.object_data.shareUserAcknowledge;
            task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareUserAcknowledge: task_json.object_data.shareUserAcknowledge[userID]};
            console.log("task_json2",task_json)
            let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
            taskjson = taskjson.replace(/'+/g,"'");
            taskjson = JSON.parse(taskjson);
            let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.processupdatecurview);
        }else if(userID in task_json.object_data.shareUserInfo && task_json.object_data.shareUserInfo[userID].cur_view === "Opened"){
            //console.log("shareUserInfo==>",task_json.object_data.shareUserInfo[userID].cur_view === "Unopened")
            let val = {date: task_json.object_data.shareUserInfo[userID].date, status: "Open", OpenedDate:  task_json.object_data.shareUserInfo[userID].OpenedDate,  markforactionDate: Date.now(), cur_view: "Marked for action",responsedate: Date.now()};
            //let val = {date: Date.now(),status: "Open",cur_view: "Marked for action",responsedate: ""};
            task_json.object_data.shareUserInfo[userID] = val
            task_json['object_data'].shareUserInfo = task_json.object_data.shareUserInfo;
            task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareUserInfo: task_json.object_data.shareUserInfo[userID]};
            console.log("task_json2",task_json)
            let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
            taskjson = taskjson.replace(/'+/g,"'");
            taskjson = JSON.parse(taskjson);
            let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.processupdatecurview);
        }else{
            if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                  window.location = '/';
                }else{
                  window.location = process.env.REACT_APP_TMVIEW_URL;
                }
              } else {
                window.location.reload(true);
            }
        }
    }

    processupdatecurview = (result) => {
        console.log(result)
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
              window.location = '/';
            }else{
              window.location = process.env.REACT_APP_TMVIEW_URL;
            }
          } else {
            window.location.reload(true);
        }
        //window.location.reload(true);
    }

    onDateChange = (date) => {
        if (!moment.isDate(date)) {
			return;
		}
        // event.preventDefault();

        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();

        // console.log('date', date);
        // console.log('dateStr', dateStr);
        this.setState({due_by: dateStr, date: date});
    }

    processMyExternalLink = () => {
        let rufeed = Store.getStoreData('task');
        var linksdata = rufeed.externalLinks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {
            i++;
            //console.log("window.location.protocol===>",window.location.protocol)
            let url = <a style={{display: "table-cell"}} href={d.url} target="_blank" >{d.url}</a>
            let elem = {'index' : i,
                    'url_value' : url,
                    'description_value' : d.description
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    processMykeyData = () => {
        let rufeed = Store.getStoreData('task');
        var keysdata = rufeed.keyDates;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of keysdata) {
            i++;
            let elem = {'index' : i,
                    'date_value' : d.date,
                    'description_value' : d.description
                   };
            ret.data.push(elem);
        }
        return ret;
    }
    sendAcknowledgementUser = (event) => {
        event.preventDefault();
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let task_json = JSON.parse(task.task.parenttask.task_json);
        //console.log("task_json1",task_json)
        let userID = Store.getStoreData('loggedincontact');
        if(userID in task_json.object_data.shareUserAcknowledge){
            let val = {date: task_json.object_data.shareUserAcknowledge[userID].date, status: "sendAcknowledge", OpenedDate:  task_json.object_data.shareUserAcknowledge[userID].OpenedDate, sendAcknowledgeDate: Date.now(),cur_view: "Opened",responsedate: Date.now()};
            //let val = {date: Date.now(),status: "sendAcknowledge",cur_view: "Opened",responsedate: ""};
            task_json.object_data.shareUserAcknowledge[userID] = val
        }
        task_json['object_data'].shareUserAcknowledge = task_json.object_data.shareUserAcknowledge;
        task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", sendAcknowledge: task_json.object_data.shareUserAcknowledge[userID], sendAcknowledgeID: userID};

        //console.log("task_json2",task_json)
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.processAcknowledgeConfirm);
    }
    processAcknowledgeConfirm = (result) => {
        //alert("This has been successfully acknowledged");
        this.setState({foracknow: false})
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let postData = { "command": "gettask", "task_id" : task_id };
        const api = new APICall();
        api.command(postData, this.processupadteCurrentData);
    }
    processupadteCurrentData = (result) => {
        Store.updateStore('this_task_detail', result.result);
    }

    render()
    {
        let rufeed = Store.getStoreData('task');
        //console.log("task===>",rufeed);
        let childtasks = Store.getStoreData('childtasks');
        console.log('childtasks', childtasks);
        let updatedFollowups = Store.getStoreData('updated_followups');
        if(updatedFollowups !== null)childtasks = updatedFollowups;
        let parent_task_id = Store.getStoreData('parent_task_id');
		const issue_date_obj = new Date(rufeed.issueDate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_day}/${issue_month}/${issue_year}`;
        //console.log("modificationDate date", rufeed.modificationDate);
        let mod_date = "N/A"
        if('modificationDate' in rufeed) {
        let modificationDate = rufeed.modificationDate;
        var dd_modification = parseInt(modificationDate.substring(8,12));
        var mm_modification = parseInt(modificationDate.substring(5,7));
        var yyyy_modification = parseInt(modificationDate.substring(0,4));
        const mod_date_obj = new Date(yyyy_modification, mm_modification-1, dd_modification);
		const mod_day = mod_date_obj.getDate() < 10 ? "0"+mod_date_obj.getDate().toString() : mod_date_obj.getDate();
		const mod_month = mod_date_obj.getMonth()+1 < 10 ? `0${(mod_date_obj.getMonth() + 1).toString()}` : mod_date_obj.getMonth()+1;
		const mod_year = mod_date_obj.getFullYear();
         mod_date = `${mod_day}/${mod_month}/${mod_year}`;
        }
        let upload_date = "N/A";
        if('uploadDate' in rufeed){
        let uploadDate = rufeed.uploadDate;
        //console.log("rufeed.issueDate",rufeed.uploadDate)
        var dd_upload = parseInt(uploadDate.substring(8,12));
        var mm_upload = parseInt(uploadDate.substring(5,7));
        var yyyy_upload = parseInt(uploadDate.substring(0,4));
        const upload_date_obj = new Date(yyyy_upload, mm_upload-1, dd_upload);
		const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
		const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
		const upload_year = upload_date_obj.getFullYear();
         upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        }
        let prdate = "N/A";
        if (rufeed.publicResponseDate !== null) {
            let publicResponseDate = rufeed.publicResponseDate;

            const pr_date_obj = new Date(publicResponseDate);
            const pr_day = pr_date_obj.getDate() < 10 ? "0"+pr_date_obj.getDate().toString() : pr_date_obj.getDate();
            const pr_month = pr_date_obj.getMonth()+1 < 10 ? `0${(pr_date_obj.getMonth() + 1).toString()}` : pr_date_obj.getMonth()+1;
            const pr_year = pr_date_obj.getFullYear();
            prdate = `${pr_day}/${pr_month}/${pr_year}`;
        }
        let docType = "";
        // docType = rufeed.documentTypes;
        // if(!isNaN(rufeed.documentTypes)) {
        //     //const documentType = Store.getStoreData('rutypename');
        //     const documentType = Store.getStoreData('rutypenameAll');
        //     let doctype = {};
        //     for(let i=0; i < documentType.length; i++) {
        //         doctype[documentType[i].id] = documentType[i].documentname;
        //     }
        //     docType = doctype[rufeed.documentTypes]
        // }
        let dNames = "";
        if(rufeed.documentTypes !== null) {
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            }
            let i=0;
            for (let doc of rufeed.documentTypes){
                i++;
                if(doc in doctype){
                    dNames += doctype[doc]
                    if(i <= rufeed.documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }
        let task = Store.getStoreData('this_task_detail');
        let task_json = JSON.parse(task.task.parenttask.task_json);
        console.log(task_json)
        let userID = Store.getStoreData('loggedincontact');
        let header_text = "Assign Shared Task";
        if(userID in task_json.object_data.shareUserAcknowledge){
            header_text = "Acknowledgement Required";
        }
        return (<div>
        <RegModalContainer>
            {
                (() => {
                    if(this.state.isLoaderIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <RegModalHeaderText>{header_text}</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
            {
                (() => {
                    if(this.state.groupcompanyName !== ""){
                        return(<div style={{float: "left"}}><MRModalHeaderText style={{marginLeft: "27px",fontSize: "14px",color: "#676767"}}>Company Name - {this.state.groupcompanyName}</MRModalHeaderText></div>)
                    }
                })()
            }

            <div style={{clear: "both"}}></div>
            <RegDismisOuterContainer>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{rufeed.documentName}</div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                    <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(rufeed.summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>
                    <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {dNames}</div>
                    <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {upload_date}</div>
                </OverviewInfoBody>
            </RegDismisOuterContainer>
            {
                rufeed.sections.map((section) => {
                    return (
                        <RegDismisOuterContainer>
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>
                            <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>

                            </OverviewInfoBody>
                        </RegDismisOuterContainer>
                    )
                })
            }
            {
                (() => {
                    if('externalLinks' in rufeed) {
                        if(rufeed.externalLinks.length !== 0) {
                            return(<div>
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody>
                                        <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                    </OverviewInfoBody>
                                </RegDismisOuterContainer>
                                </div>)
                        }
                    }
                })()
            }
            {
                (() => {
                    if('keyDates' in rufeed) {
                        if(rufeed.keyDates.length !== 0) {
                            return(<div>
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody>
                                        <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                    </OverviewInfoBody>
                                </RegDismisOuterContainer>
                                </div>)
                        }
                    }
                })()
            }
            {
                (() => {
                    if(parent_task_id === 0){
                        return(<RegDismisOuterContainer>
                                    <RegDismisInnerContainer style={{backgroundColor: "#f7f7f7"}}>
                                        <ChildTasksRUTable add={false} headertext = "Associated RC Tasks" followup_tasks={this.state.modChildTaskarray}/>
                                    </RegDismisInnerContainer>
                                </RegDismisOuterContainer>);
                    }
                })()
            }
            {
                (() => {
                    if(this.state.foracknow) {
                        return(<div>
                            <RegCancelSubmitButtonOuterContainer>
                                <RegMarkForActionButton onClick={this.sendAcknowledgementUser}>Send for Acknowledgement</RegMarkForActionButton>
                            </RegCancelSubmitButtonOuterContainer>
                        </div>)
                    }else{
                        return(<div>
                            <RegDismisOuterContainer>
                                <RegDismisInnerContainer>
                                    <RegDismisalButtonDiv>
                                        {
                                            (() => {
                                                if(parent_task_id === 0){
                                                    return(<div>
                                                        {/* <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton> */}
                                                        <RegCancelButton onClick={this.closeRegulatoryModal}>Cancel</RegCancelButton>
                                                        <RegMarkForActionButton onClick={this.addFollowupActionsection}>Complete</RegMarkForActionButton>
                                                        {/* <RegMarkForActionButton onClick={this.addFollowupAssignection}>Mark For Action</RegMarkForActionButton> */}
                                                        <RegShareButton onClick={this.addFollowupAssignection}>Mark For Action</RegShareButton>
                                                        </div>
                                                        );
                                                }else{
                                                    return(<div>
                                                        <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton>
                                                        <RegMarkForActionButton onClick={this.addFollowupActionsection}>Complete</RegMarkForActionButton>
                                                    </div>)
                                                }
                                            })()
                                        }
                                    </RegDismisalButtonDiv>
                                    {
                                        (() => {
                                            if(this.state.bottomButtonShow){
                                            if(this.state.resonforaction){
                                                return (<RegModalContainertop>
                                                    <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                                                    <RegModalNameInputDiv>
                                                        <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                                    </RegModalNameInputDiv>
                                                </RegModalContainertop>)
                                            }else if(this.state.reasonforcomplete){
                                                return (<RegModalContainertop>
                                                    <RegModalNameLabelDiv>Complete Note</RegModalNameLabelDiv>
                                                    <RegModalNameInputDiv>
                                                        <RegDismissalReason name="complete_note" onChange={this.handleOnChange} value={this.state.complete_note}></RegDismissalReason>
                                                    </RegModalNameInputDiv>
                                                </RegModalContainertop>)
                                            }
                                            else if(this.state.followuptask && this.state.lane === 'COMP_RCM_FOLLOWUP')
                                            {
                                                let all_contacts = Store.getStoreData('contacts');
                                                let newContacts = [];
                                                if(this.state.isGroup){
                                                    all_contacts.forEach((item) => {
                                                        if(item.ID in this.state.gcompanyUsers){
                                                            newContacts.push(item);
                                                        }
                                                    })
                                                }else{
                                                    newContacts = all_contacts;
                                                }

                                                let contacts = newContacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );


                                                return(<RegModalContainertop>
                                                    <RegModalNameLabelDiv>Remedial/Followup Tasks</RegModalNameLabelDiv>
                                                    <RegModalTagsDiv style={{width: "90%",marginLeft: "55px"}}>
                                                        <RegModalIssueDateDiv>
                                                            <div style={{float: "left", width: "50%"}}>
                                                                <RegModalIssueDateLabel>Assign To</RegModalIssueDateLabel>
                                                                <TermInputDiv>
                                                                    {/* <RegModalNameInput style={{marginTop: "8px",marginBottom: "10px"}} type="text" defaultValue={contact} readOnly/> */}
                                                                    <MRModalSelect name="assigned_to" onChange={this.handleOnChange} value={this.state.assigned_to}>
                                                                        <option key={0} value="">Select</option>
                                                                        {
                                                                            contacts.map((contact, index) => {
                                                                                return (<option key={index} value={contact.ID}>{contact.ContactName}</option>);
                                                                            })
                                                                        }
                                                                    </MRModalSelect>
                                                                </TermInputDiv>
                                                            </div>
                                                            <div style={{float: "left", width: "calc(50% - 15px)", marginLeft: "15px"}}>
                                                                <RegModalIssueDateLabel>Due By</RegModalIssueDateLabel>
                                                                <TermInputDiv>
                                                                    <CSLDateCover>
                                                                        <DatePicker
                                                                                onChange={this.onDateChange}
                                                                                clearIcon={null}
                                                                                calendarIcon={null}
                                                                                locale={"en-GB"}
                                                                                value={this.state.date}
                                                                                />
                                                                    </CSLDateCover>
                                                                </TermInputDiv>
                                                            </div>
                                                            <div style={{clear: "both"}}></div>
                                                            <RegModalIssueDateLabel>Note</RegModalIssueDateLabel>
                                                            <RegModalNameInputDiv>
                                                                <RegDismissalReason style={{marginTop: "10px"}} name="assign_note" onChange={this.handleOnChange} value={this.state.assign_note}></RegDismissalReason>
                                                            </RegModalNameInputDiv>
                                                        </RegModalIssueDateDiv>
                                                    </RegModalTagsDiv>
                                                </RegModalContainertop>)
                                            }
                                            }
                                        })()
                                    }
                                </RegDismisInnerContainer>
                            </RegDismisOuterContainer>
                            {
                                (() => {
                                    if(this.state.bottomButtonShow){
                                        return(
                                            <RegCancelSubmitButtonOuterContainer>
                                                <RegCancelButton onClick={this.closeRegulatoryButtomModal}>Cancel</RegCancelButton>
                                                {/* <RegSubmitButton onClick={this.saveClientRegulatorychange}>Save</RegSubmitButton> */}
                                                {
                                                    (() => {
                                                        if(childtasks.length === 0 || parent_task_id === 0){
                                                            return (<RegSubmitButton onClick={this.saveClientRegulatorychange}>Save</RegSubmitButton>);
                                                        }
                                                    })()
                                                }
                                            </RegCancelSubmitButtonOuterContainer>
                                        )
                                    }
                                })()
                            }
                        </div>)
                    }
                })()
            }

        </RegModalContainer>
        <AlertBox alertParam = {this.state.alert_param}/>
        </div>);
    }
}

export default RegulatoryShareModal;
