import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import AutoDismissGeneralCSL from './AutoDismissGeneralCSL';

const IndexContainer = styled.div`
    padding-top: 8px;    
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #282828;	
`;
const GeneralContainer = styled.div`
	width: 100%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
class ModuleConfigCSL extends React.Component
{
	state = {general: null, module_config: null};

	componentDidMount()
	{
        let general = Store.getStoreData('module_config_csl') === null ? null : Store.getStoreData('module_config_csl').general;
        let module_config = Store.getStoreData('module_config_csl') === null ? null : Store.getStoreData('module_config_csl');
		this.setState({general: general, module_config: module_config});	
	}

	// componentDidUpdate(prevProps)
	// {
	// 	if (prevProps !== this.props) {
	// 		this.setState({general: this.props.general});
	// 	}
	// }

	switchRadio = (key) => (event) => {
		//console.log("TAGState===>",key)
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;        
        let {module_config} = this.state;
        module_config.general = general;	
        const postData = {command: "save_module_config_csl", module_config: module_config};	
        console.log("postData==>", postData)
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }
    
    afterUpdate = (result) => {
        console.log(result);           
        this.setState({module_config: result.result, general: result.result.general}); 
   }

	switchRadioNew = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralnew(general);
	}
	handleChange = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = event.target.value;
		//console.log("general",general)
		this.props.updateGeneralnew(general);
    }

    saveautoDismissValue = (obj) => {        
        let {module_config} = this.state;
        module_config.general.autoDismissRegulatorName = obj;
        module_config.general.isAutoDismissRegulatorNameCheck = 1;        
        const postData = {command: "save_module_config_csl", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateAutoDismiss);
    }

    afterUpdateAutoDismiss = (result) => {
        //console.log(result);
        alert("Auto Dismiss Saved successfully")           
        this.setState({module_config: result.result, general: result.result.general}); 
    }

	
	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<IndexContainer>
                <GeneralContainer>
				    <GeneralInnerContainer>
                        <GeneralLabel>Auto Forward Enabled</GeneralLabel>
                        {						
                            (() => {
                                if (this.state.general.enable_auto_forward) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio onClick={this.switchRadio('enable_auto_forward')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio onClick={this.switchRadio('enable_auto_forward')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                        {/* <GeneralLabel style={{marginTop: "20px"}}>Auto Dismiss</GeneralLabel>
                        {						
                            (() => {
                                if (this.state.general.enable_auto_dismiss) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio onClick={this.switchRadio('enable_auto_dismiss')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio onClick={this.switchRadio('enable_auto_dismiss')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.general.enable_auto_dismiss){
                                    return(<AutoDismissGeneralCSL general={this.state.general} saveAutoDismissValue={this.saveautoDismissValue}/>)
                                }
                            })()
                        } */}
                    </GeneralInnerContainer>
                </GeneralContainer>
            </IndexContainer>
		);
	}
}

export default ModuleConfigCSL;