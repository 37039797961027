import Store from './Store.js';
import moment from 'moment';

class IndexLoader {
    divideAllTasks(tasks, role, contact_id, module_config, users, user_roles, pendingtasksdata){
        let newtasks = [];
        let pendingtasks = pendingtasksdata;
        let all_followup = [];
        let followup_allforManager = {};  
        let mySaveRU = []; 
        let share_with_me = [];
        let followuptasks = []; 
        let dismisstasks = []; 
        let mytasks = [];
        let dataCard_newtasks = [];
        let datacardValue = {all_task_count: 0, pending_tasks_count: 0, followupTasks_count: 0, dismissTaskCount: 0, subcribedTagsCount: 0, newtaskcount: 0, mytaskscount: 0,sharewithmecount: 0} 

        for (const task of tasks) {
            let cur_assign_to = task[4];
            let task_type = task[5];
            let cur_lane = task[6];
            let created_by = task[7];  
            
            let newtaskObject = {};
            newtaskObject.id = task[0];
            newtaskObject.company_id = task[1];
            newtaskObject.parent_task_id = task[2];
            newtaskObject.ref_id = task[3];
            newtaskObject.cur_assigned_to = task[4];                        
            newtaskObject.task_type = task[5];
            newtaskObject.cur_lane = task[6];
            newtaskObject.contact_id = task[7];
            newtaskObject.last_action = task[8];
            newtaskObject.due_date = task[9];
            newtaskObject.created_at = task[10];
            newtaskObject.child_task_ids = task[11];
            let task_json = {object_data: {},task_data: {}};                            
            task_json.object_data.documentTypes = task[12];
            task_json.object_data.ragulatorName = task[13];
            task_json.object_data.externalLinks = task[14];
            task_json.object_data.documentName = task[15];
            task_json.object_data.issueDate = task[16];
            task_json.object_data.AutoFordardJwGSelectdata = task[17];
            task_json.object_data.state = task[18];
            task_json.object_data.tags = task[19];
            task_json.object_data.createdDate = task[20];
            task_json.object_data.uploadDate = task[21];                            
            task_json.task_data.actiondate = task[22];
            newtaskObject.task_json = JSON.stringify(task_json);  
            //if(is_active === 1) {
                switch(role) {
                    case "manager":	   
                        if((created_by === contact_id || created_by === 0 || (created_by in user_roles && (user_roles[created_by] === 'rcm_gatekeeper' || user_roles[created_by] === 'manager'))) && (cur_assign_to === 0 && cur_lane === 'COMP_RCM_ASSIGNMENT' && task_type === 'COMP_RCM')){    
                            newtasks.push(newtaskObject);
                        }
                        if((created_by === contact_id || created_by === 0 ) && cur_assign_to === 0 && cur_lane === 'COMP_RCM_ASSIGNMENT' && task_type === 'COMP_RCM'){                            
                            dataCard_newtasks.push(newtaskObject);
                        }
                        // if(cur_lane === 'COMP_RCM_ACTION' && task_type === 'COMP_RCM'){                            
                        //     pendingtasks.push(task);
                        // }
                        if(task_type === 'COMP_RCM_FOLLOWUP'){
                            all_followup.push(newtaskObject);
                        }                        
                        if(cur_lane === 'COMP_RCM_SAVED' && task_type === 'COMP_RCM'){                            
                            mySaveRU.push(newtaskObject);
                        }
                        if(cur_assign_to === contact_id && task_type === 'COMP_RCM_FOLLOWUP'){                            
                            followuptasks.push(newtaskObject);
                        }
                        if(cur_lane === 'COMP_RCM_DISMISS' && task_type === 'COMP_RCM'){                            
                            dismisstasks.push(newtaskObject);
                        }                        
                    break;
                    case "rcm_gatekeeper":                        
                        if((created_by === contact_id || created_by === 0 || (created_by in user_roles && (user_roles[created_by] === 'manager' || user_roles[created_by] === 'rcm_gatekeeper'))) && (cur_assign_to === 0 && cur_lane === 'COMP_RCM_ASSIGNMENT' && task_type === 'COMP_RCM')){                            
                            newtasks.push(newtaskObject);
                        }
                        // if(cur_lane === 'COMP_RCM_ACTION' && task_type === 'COMP_RCM'){                            
                        //     pendingtasks.push(task);
                        // }
                        if(task_type === 'COMP_RCM_FOLLOWUP'){
                            all_followup.push(newtaskObject);
                        }
                        if(cur_lane === 'COMP_RCM_SAVED' && task_type === 'COMP_RCM'){                            
                            mySaveRU.push(newtaskObject);
                        }
                        if(cur_assign_to === contact_id && task_type === 'COMP_RCM_FOLLOWUP'){                            
                            followuptasks.push(newtaskObject);
                        }
                        if(cur_lane === 'COMP_RCM_DISMISS' && task_type === 'COMP_RCM'){                            
                            dismisstasks.push(newtaskObject);
                        }                        
                    break;
                    case "team":
                        if(created_by === contact_id && cur_lane === 'COMP_RCM_ASSIGNMENT' && task_type === 'COMP_RCM'){                            
                            mytasks.push(newtaskObject);
                        }
                        // if(cur_assign_to === 0 && cur_lane === 'COMP_RCM_ACTION' && task_type === 'COMP_RCM'){                            
                        //     pendingtasks.push(task);
                        // }
                        if(cur_assign_to === contact_id && task_type === 'COMP_RCM_FOLLOWUP'){                            
                            followuptasks.push(newtaskObject);
                        }                    
                }
            //}
        }
        // followup status check
        //console.log("followup", all_followup.length)
        for(let i=0;i<all_followup.length;i++){
            let status = "Pending";
            if(all_followup[i].last_action === 'COMP_RCM_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(all_followup[i].last_action === 'COMP_RCM_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            let due_date_str = all_followup[i].due_date ? all_followup[i].due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            var due_date_status = new Date(yyyy, mm-1, dd);

            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();

            let text = due_date_status_final > today ? status : 'Overdue';                                        
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';                                            
            }else if(status === "Completed"){
                text = status;                                            
            } 
           followup_allforManager[all_followup[i].id] = text;      
        }
        //console.log("pendingtasks===>", pendingtasks.length)
        // followup status check end
        // Share with me Task Start
        let update_pendingtasks = [];
        if(pendingtasks.length !== 0)
        { 
            for (const ptask of pendingtasks) {       
                let newtaskObject = {};
                newtaskObject.id = ptask[0];
                newtaskObject.company_id = ptask[1];
                newtaskObject.parent_task_id = ptask[2];
                newtaskObject.ref_id = ptask[3];
                newtaskObject.cur_assigned_to = ptask[4];                        
                newtaskObject.task_type = ptask[5];
                newtaskObject.cur_lane = ptask[6];
                newtaskObject.contact_id = ptask[7];
                newtaskObject.last_action = ptask[8];
                newtaskObject.due_date = ptask[9];
                newtaskObject.created_at = ptask[10];
                newtaskObject.child_task_ids = ptask[11];
                let task_json = {object_data: {},task_data: {}};                            
                task_json.object_data.documentTypes = ptask[12];
                task_json.object_data.ragulatorName = ptask[13];
                task_json.object_data.externalLinks = ptask[14];
                task_json.object_data.documentName = ptask[15];
                task_json.object_data.issueDate = ptask[16];
                task_json.object_data.AutoFordardJwGSelectdata = ptask[17];
                task_json.object_data.state = ptask[18];
                task_json.object_data.tags = ptask[19];
                task_json.object_data.createdDate = ptask[20];
                task_json.object_data.uploadDate = ptask[21];                            
                task_json.task_data.actiondate = ptask[22];
                task_json.object_data.shrUsrAckStus = ptask[23];                            
                task_json.object_data.shareobject = ptask[24];
                newtaskObject.task_json = JSON.stringify(task_json);
                update_pendingtasks.push(newtaskObject)

                if (ptask[24] !== null) {
                    if (ptask[24][contact_id] === 1) {
                        share_with_me.push(newtaskObject);
                    }
                }
            }  
        }

        console.log("share_with_me===>", share_with_me.length)
        // Share with me Task end       
        //console.log("newtasks", newtasks.length)
        //console.log(`store size (kb) in new Task,`,parseInt(JSON.stringify(Store.getStoreData('new_tasks')).length));
        if(Store.getStoreData('new_tasks') === null) {
            Store.updateStore('new_tasks', newtasks);            
        }else if(Store.getStoreData('new_tasks').length !==  newtasks.length){
            Store.updateStore('new_tasks', newtasks);
        }else if(JSON.stringify(Store.getStoreData('new_tasks')) !== JSON.stringify(newtasks)){
            Store.updateStore('new_tasks', newtasks);
        }  
        
        

        if(Store.getStoreData('pending_tasks') === null && Store.getStoreData('followup_allforManager') === null) {
            Store.updateStore('pending_tasks', update_pendingtasks);
            Store.updateStore('followup_allforManager', followup_allforManager); 
        }else if(Store.getStoreData('pending_tasks').length !==  update_pendingtasks.length){  
            Store.updateStore('pending_tasks', update_pendingtasks);
            Store.updateStore('followup_allforManager', followup_allforManager);
        }else if(JSON.stringify(Store.getStoreData('pending_tasks')) !== JSON.stringify(update_pendingtasks)){            
            Store.updateStore('pending_tasks', update_pendingtasks);
            Store.updateStore('followup_allforManager', followup_allforManager);
        }else if(Store.getStoreData('followup_allforManager').length !==  followup_allforManager.length){            
            Store.updateStore('followup_allforManager', followup_allforManager);
        }else if(JSON.stringify(Store.getStoreData('followup_allforManager')) !== JSON.stringify(followup_allforManager)){            
            Store.updateStore('followup_allforManager', followup_allforManager);
        }

        let modifySaveRecord = [];
        let autoforwardSaveRecord = [];
        mySaveRU.forEach((task) => {
            const task_json = JSON.parse(task.task_json);
            if ("AutoFordardJwGSelectdata" in task_json.object_data && task_json.object_data.AutoFordardJwGSelectdata !== null) {                
                autoforwardSaveRecord.push(task);                
            }else if(task.contact_id === contact_id){
                modifySaveRecord.push(task); 
            }
        })

        if(Store.getStoreData('mySaveRU') === null) {
            Store.updateStore('mySaveRU', modifySaveRecord);
        }else if(Store.getStoreData('mySaveRU').length !==  modifySaveRecord.length){
            Store.updateStore('mySaveRU', modifySaveRecord);
        }else if(JSON.stringify(Store.getStoreData('mySaveRU')) !== JSON.stringify(modifySaveRecord)){
            Store.updateStore('mySaveRU', modifySaveRecord);
        }

        if(Store.getStoreData('autoforwardSaveRU') === null) {
            Store.updateStore('autoforwardSaveRU', autoforwardSaveRecord);
        }else if(Store.getStoreData('autoforwardSaveRU').length !==  autoforwardSaveRecord.length){
            Store.updateStore('autoforwardSaveRU', autoforwardSaveRecord);
        }else if(JSON.stringify(Store.getStoreData('autoforwardSaveRU')) !== JSON.stringify(autoforwardSaveRecord)){
            Store.updateStore('autoforwardSaveRU', autoforwardSaveRecord);
        }

        if(Store.getStoreData('share_with_me') === null) {
            Store.updateStore('share_with_me', share_with_me);
        }else if(Store.getStoreData('share_with_me').length !==  share_with_me.length){
            Store.updateStore('share_with_me', share_with_me);
        }else if(JSON.stringify(Store.getStoreData('share_with_me')) !== JSON.stringify(share_with_me)){
            Store.updateStore('share_with_me', share_with_me);
        }

        if(Store.getStoreData('followup_tasks') === null) {
            Store.updateStore('followup_tasks', followuptasks);
        }else if(Store.getStoreData('followup_tasks').length !==  followuptasks.length){
            Store.updateStore('followup_tasks', followuptasks);
        }else if(JSON.stringify(Store.getStoreData('followup_tasks')) !== JSON.stringify(followuptasks)){
            Store.updateStore('followup_tasks', followuptasks);
        }

        if(Store.getStoreData('dismiss_task') === null) {
            Store.updateStore('dismiss_task', dismisstasks);
        }else if(Store.getStoreData('dismiss_task').length !==  dismisstasks.length){
            Store.updateStore('dismiss_task', dismisstasks);
        }else if(JSON.stringify(Store.getStoreData('dismiss_task')) !== JSON.stringify(dismisstasks)){
            Store.updateStore('dismiss_task', dismisstasks);
        }
        // if(Store.getStoreData('data_card_value') === null) {
        //     Store.updateStore('data_card_value', datacardValue);
        // }        
        if(Store.getStoreData('my_tasks') === null) {
            Store.updateStore('my_tasks', mytasks);
        }else if(Store.getStoreData('my_tasks').length !==  mytasks.length){
            Store.updateStore('my_tasks', mytasks);
        }else if(JSON.stringify(Store.getStoreData('my_tasks')) !== JSON.stringify(mytasks)){
            Store.updateStore('my_tasks', mytasks);
        }

        if(Store.getStoreData('dataCard_all_followup') === null){
            Store.updateStore('dataCard_all_followup', all_followup);
        }else if(JSON.stringify(Store.getStoreData('dataCard_all_followup')) !== JSON.stringify(all_followup)){
            Store.updateStore('dataCard_all_followup', all_followup);
        }

        if(Store.getStoreData('dataCard_newtasks') === null){
            Store.updateStore('dataCard_newtasks', dataCard_newtasks);
        }else if(JSON.stringify(Store.getStoreData('dataCard_newtasks')) !== JSON.stringify(dataCard_newtasks)){
            Store.updateStore('dataCard_newtasks', dataCard_newtasks);
        }        

        //shareUserList and shareAcknowledgeMent
        let shareUserList = [];
        let shareAcknowledgeMent = [];
        // for(let k=0; k<users.length;k++)
        // {            
        //     if(users[k].ID in user_roles && user_roles[users[k].ID] !== 'no_access'){
        //         let data={};
        //         let datanew={};
        //         data.id = users[k].ID;
        //         data.name = users[k].ContactName;
        //         data.email = users[k].EmailAddress;                            
        //         data.role = user_roles[users[k].ID];
        //         data.select = false;
        //         data.foracknowledgement = true;
        //         data.forinformation = true; 
        //         data.foracknowledgementCheck = false;
        //         data.forinformationCheck = false;            
        //         shareUserList.push(data);

        //         datanew.id = users[k].ID;
        //         datanew.name = users[k].ContactName;
        //         datanew.email = users[k].EmailAddress;                
        //         datanew.role = user_roles[users[k].ID];
        //         datanew.select = true;
        //         datanew.foracknowledgement = false;
        //         datanew.forinformation = true; 
        //         datanew.foracknowledgementCheck = true;
        //         datanew.forinformationCheck = false;            
        //         shareAcknowledgeMent.push(datanew);
        //     }
        // }
        for(let k in users){
            if(k in user_roles && user_roles[k] !== 'no_access'){
                let data={};
                let datanew={};
                data.id = users[k].ID;
                data.name = users[k].ContactName;
                data.email = users[k].EmailAddress;                            
                data.role = user_roles[users[k].ID];
                data.select = false;
                data.foracknowledgement = true;
                data.forinformation = true; 
                data.foracknowledgementCheck = false;
                data.forinformationCheck = false;            
                shareUserList.push(data);

                datanew.id = users[k].ID;
                datanew.name = users[k].ContactName;
                datanew.email = users[k].EmailAddress;                
                datanew.role = user_roles[users[k].ID];
                datanew.select = true;
                datanew.foracknowledgement = false;
                datanew.forinformation = true; 
                datanew.foracknowledgementCheck = true;
                datanew.forinformationCheck = false;            
                shareAcknowledgeMent.push(datanew);
            }
        }

        if(Store.getStoreData('shareuserlist') === null) {
            Store.updateStore('shareuserlist', shareUserList);
        }
        if(Store.getStoreData('shareAcknowledgeMentList') === null) {
            Store.updateStore('shareAcknowledgeMentList', shareAcknowledgeMent);
        }

        return true;
    }
    

    divideCSLAllTasks(feedAllTasks){
        //console.log("feedAllTasks==>",feedAllTasks)
        let ru_feeds = [];
        let rn_feeds = [];
        let activityLogData = [];
        let dismissed_rn_feeds = [];
        let tfeeds = 0;
        let pfeeds = 0;
        let afeeds = 0;
        let dfeeds = 0;
        let feed_count = {};

        for (const feed of feedAllTasks) {
            let is_rn = feed.is_rn;
            let is_select = feed.is_select;
            let isdismiss = feed.isdismiss;
            let activityLog = feed.activity_log;
            if(is_rn === 0 && is_select === 0){                            
                ru_feeds.push(feed);
            }
            if(is_rn === 1 && isdismiss === 0){                            
                rn_feeds.push(feed);
            }
            if(is_rn === 1 && isdismiss === 1){                            
                dismissed_rn_feeds.push(feed);
            }
            if(activityLog !== null){
                activityLogData.push(feed)
            }
        }
        
        if (ru_feeds.length != 0) {
            tfeeds = ru_feeds.length;
            for (let feed of ru_feeds) {
                //console.log(feed);
                if (feed.isdismiss == 1) {
                    dfeeds = dfeeds + 1;
                }
                if (feed.change_action == 1) {
                    afeeds = afeeds + 1;
                }
            }
        }
        pfeeds = tfeeds - dfeeds - afeeds;
        feed_count.totalfeeds = tfeeds;
        feed_count.pendingfeeds = pfeeds;
        feed_count.actionedfeeds = afeeds;
        feed_count.dismissedfeeds = dfeeds;        

        if(Store.getStoreData('feeds') === null) {
            Store.updateStore('feeds', ru_feeds);
        }
        if(Store.getStoreData('rn_feeds') === null) {
            Store.updateStore('rn_feeds', rn_feeds);
        }
        if(Store.getStoreData('feedscount') === null) {
            Store.updateStore('feedscount', feed_count);
        }
        if(Store.getStoreData('dismissed_rn_feeds') === null) {
            Store.updateStore('dismissed_rn_feeds', dismissed_rn_feeds);
        }
        if(Store.getStoreData('activitylog_data') === null) {
            Store.updateStore('activitylog_data', activityLogData);
        }
    }

    divideAllTasksIndex(users, user_roles){   
        let shareUserList = [];
        let shareAcknowledgeMent = [];
        
        for(let k in users){
            if(users[k.toString()].IsActive === true && users[k.toString()].IsDeleted === false && k in user_roles && user_roles[k] !== 'no_access'){
                let data={};
                let datanew={};
                data.id = users[k].ID;
                data.name = users[k].ContactName;
                data.email = users[k].EmailAddress;                            
                data.role = user_roles[users[k].ID];
                data.select = false;
                data.foracknowledgement = true;
                data.forinformation = true; 
                data.foracknowledgementCheck = false;
                data.forinformationCheck = false;            
                shareUserList.push(data);

                datanew.id = users[k].ID;
                datanew.name = users[k].ContactName;
                datanew.email = users[k].EmailAddress;                
                datanew.role = user_roles[users[k].ID];
                datanew.select = true;
                datanew.foracknowledgement = false;
                datanew.forinformation = true; 
                datanew.foracknowledgementCheck = true;
                datanew.forinformationCheck = false;            
                shareAcknowledgeMent.push(datanew);
            }
        }

        if(Store.getStoreData('shareuserlist') === null) {
            Store.updateStore('shareuserlist', shareUserList);
        }
        if(Store.getStoreData('shareAcknowledgeMentList') === null) {
            Store.updateStore('shareAcknowledgeMentList', shareAcknowledgeMent);
        }
        return true;
    }

    divideAutoForwordFeedsAndRUIndex(autoforwordfeedAllTask){  
        let autoForwordFeedsAndRU = [];
        let autoForwordFeedsAndRU_save = Store.getStoreData('saveRCtasks') !== null ? Store.getStoreData('saveRCtasks') : [];
        for(let d of autoforwordfeedAllTask){
            if(!d[15]){
                autoForwordFeedsAndRU.push(d)
            }else{
                if(Store.getStoreData('has_gc') && 'general' in Store.getStoreData('module_config') && Store.getStoreData('module_config').general.assign_automated_news_feed_to_group_companies === true){
                    if(Store.getStoreData('role') === 'manager'){                                    
                        let single_save_task = [];   
                        single_save_task[0] = 0;
                        single_save_task[1] = d[1];
                        single_save_task[2] = d[2];
                        single_save_task[3] = 0;
                        single_save_task[4] = "";

                        single_save_task[5] = d[5];
                        single_save_task[6] = d[6];
                        single_save_task[7] = d[7];
                        single_save_task[8] = d[8]; 
                        single_save_task[9] = d[14];                   
                        single_save_task[10] = d[16];                         
                        single_save_task[11] = "Pending" 
                        single_save_task[12] = 1;
                        single_save_task[13] = d[18];
                        autoForwordFeedsAndRU_save.push(single_save_task)
                    }
                }else{
                    let single_save_task = [];   
                    single_save_task[0] = 0;
                    single_save_task[1] = d[1];
                    single_save_task[2] = d[2];
                    single_save_task[3] = 0;
                    single_save_task[4] = "";

                    single_save_task[5] = d[5];
                    single_save_task[6] = d[6];
                    single_save_task[7] = d[7];
                    single_save_task[8] = d[8]; 
                    single_save_task[9] = d[14];                   
                    single_save_task[10] = d[16];                     
                    single_save_task[11] = "Pending" 
                    single_save_task[12] = 1;
                    single_save_task[13] = d[18];
                    autoForwordFeedsAndRU_save.push(single_save_task)
                }                 
            }
        }
        Store.updateStore('autoForwordFeedsAndRU', autoForwordFeedsAndRU)        
        Store.updateStore('saveRCtasks', autoForwordFeedsAndRU_save)        
        return true;
    }

    divideFilterlogTasks(tasks, jwgAutoDismissData){
        let filterlogArray = [];
        for (const task of tasks) {
            const task_json = JSON.parse(task.task_json);
            if("siFilterlog" in task_json.object_data && task_json.object_data.siFilterlog !== null){
                let filterlogObject = {};
                filterlogObject.id = task.id;                
                let docname = "";
                if ("documentName" in task_json.object_data && task_json.object_data.documentName !== null) {
                    docname = task_json.object_data.documentName;
                }
                filterlogObject.title = docname;
                let doctype = [];
                if ("documentTypes" in task_json.object_data && task_json.object_data.documentTypes !== null) {
                    doctype = task_json.object_data.documentTypes;
                }
                filterlogObject.document_type = doctype;
                let regula = "";
                if ("ragulatorName" in task_json.object_data && task_json.object_data.ragulatorName !== null) {
                    regula = task_json.object_data.ragulatorName;
                }
                filterlogObject.regulator = regula;

                const created_at_date_obj = new Date(task_json.object_data.uploadDate);
                const created_at_day = created_at_date_obj.getDate() < 10 ? "0"+created_at_date_obj.getDate().toString() : created_at_date_obj.getDate();
                const created_at_month = created_at_date_obj.getMonth()+1 < 10 ? `0${(created_at_date_obj.getMonth() + 1).toString()}` : created_at_date_obj.getMonth()+1;
                const created_at_year = created_at_date_obj.getFullYear();
                const created_at_date = `${created_at_day}/${created_at_month}/${created_at_year}`;

                filterlogObject.sendDate = created_at_date;

                let source = "";
                if("is_rn" in task_json.object_data && task_json.object_data.is_rn !== null && task_json.object_data.is_rn === 0){
                    source = "CSL";
                }

                filterlogObject.source = source;
                filterlogObject.isAutoForwardDismissData = false;
                filterlogObject.feed_id = '';
                filterlogArray.push(filterlogObject);                
            }
        }
        for(let k of jwgAutoDismissData) {
            let update_json_feed = JSON.parse(k.feed_update_json);
            let filterlogObject = {};
            filterlogObject.id = k.id;                
            let docname = "";
            if ("documentName" in update_json_feed && update_json_feed.documentName !== null) {
                docname = update_json_feed.documentName;
            }
            filterlogObject.title = docname;

            let doctype = [];
            if ("documentTypes" in update_json_feed && update_json_feed.documentTypes !== null) {
                doctype = update_json_feed.documentTypes;
            }
            filterlogObject.document_type = doctype;

            let regula = "";
            if ("ragulatorName" in update_json_feed && update_json_feed.ragulatorName !== null) {
                let updatedRegulatrName = update_json_feed.ragulatorName;
                if(updatedRegulatrName === "European Insurance and Occupational Pensions Autho"){
                    updatedRegulatrName = "European Insurance and Occupational Pensions Authority"
                }
                if(updatedRegulatrName === "Information Commissioner"){
                    updatedRegulatrName = "Information Commissioner's Office"
                }
                regula = updatedRegulatrName;
            }
            filterlogObject.regulator = regula;

            const created_at_date_obj = new Date(k.created_at);
            const created_at_day = created_at_date_obj.getDate() < 10 ? "0"+created_at_date_obj.getDate().toString() : created_at_date_obj.getDate();
            const created_at_month = created_at_date_obj.getMonth()+1 < 10 ? `0${(created_at_date_obj.getMonth() + 1).toString()}` : created_at_date_obj.getMonth()+1;
            const created_at_year = created_at_date_obj.getFullYear();
            const created_at_date = `${created_at_day}/${created_at_month}/${created_at_year}`;

            filterlogObject.sendDate = created_at_date;

            filterlogObject.source = "Automated Feed";
            filterlogObject.isAutoForwardDismissData = true;
            filterlogObject.feed_id = k.feed_id;
            filterlogArray.push(filterlogObject); 
        }
        console.log("filterlogArray==>", filterlogArray)
        Store.updateStore('filterlogData', filterlogArray)
        return true;
    }
}

export default new IndexLoader();