import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import ReactTable from 'react-table';
import Pagination from "./Pagination";

import CSLReportModal from './CSLReportModal';
import Utils from '../../Common/Utils';
import PdfGenerator from './PdfGenerator';

import AdvanceFilter from './../MongodbRegister/Filter/AdvanceFilter'
import 'react-table/react-table.css'
import "./table_styles.css";
import Registerbucketloader from './../Common/Registerbucketloader';
import RegisterbucketNoDataMessage from './../Common/RegisterbucketNoDataMessage';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import XcelLoader from './../../Common/XcelLoader';
import moment from 'moment';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;    
    margin-top: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  
`;

const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const RegisterButton = styled.button`
    box-sizing: border-box;
    background-color: #EBEBEB;
    color: black;
    border: 1px solid #CECECE;
    width: 100px;
    font-size: 13px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 0 4px #CECECE;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #282828;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
`

const HeaderSelectText = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; 
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

class CSLRegisterTable extends React.Component
{
	state = {show_filter: true, filters: {}, show_report: false, generate: false, groupby_selections: [], sortby_selected: {accessor: 'none', order: 'Descending'}, selections_gathered: [], toggle_state: true, toggle_state_adfilter: true,
registerbucLoader: false, orientation:'portrait', tilte_pdf : null,}

	data = {}

	noOps = () => {

	}

	componentDidMount() {
        let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
        let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
        console.log("orientation",orientation)
        if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state, registerbucLoader: this.props.registerbucLoader,orientation, tilte_pdf});
        console.log("State",this.state)
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
            let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
            let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
            console.log("orientation",orientation)

            if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state, registerbucLoader: this.props.registerbucLoader, orientation, tilte_pdf});
        }
    }

    changeSort = (sortby) => {
		//console.log("this.state.data", this.props.processData().data)
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.sortby = sortby;             
        this.setState({data: this.data, sortby_selected: sortby.selected});
	}

	changeGroup = (groupby) => {
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.groupby = groupby;
        this.setState({data: this.data, groupby_selections: groupby.selections});
	}

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});		
	}

	hideReport = () => {
		this.setState({show_report: false})
	}

	reportCallback = () => {
		this.setState({show_report: true})
	}

	filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

	exportToCSV = () => (event) => {
        event.preventDefault();
        let fileName = "export";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();

        let headerCSV = this.props.preferedColumns;
        console.log("headerCSV",headerCSV)
        let data= tableData.data;
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
            let cont = {};
            
            Object.entries(headerCSV).forEach(([key, value]) => {
                // console.log("data[k][key]",data[k][key])
                    // cont[key] = data[k][key].replace(/,/g, ' ');
                    cont[key] = data[k][key].replace(/,/g, ' ').replace(/-/g, '-');
            })            
            csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        console.log("csv_data_final_arr",csv_data_final_arr)
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();    
    }

    exportToExcel = () => (event) => {  
        event.preventDefault();
        let wb = {SheetNames:[], Sheets:{}};        
        let open_tasks = Store.getStoreData('tbl_cur_rcmregister') === null ? [] : Store.getStoreData('tbl_cur_rcmregister');
        wb.SheetNames.push("Register"); 
        wb.Sheets["Register"] = XLSX.utils.json_to_sheet(XcelLoader.rcm_register_export_tasks(open_tasks)); 
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);  
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Register' + '-' + tm + fileExtension); 
    }  

    goToReport = () => (event) => {
        event.preventDefault();
        //this.props.goToReport();
        this.setState({show_report: true})
    }

    toggleOrientation = (mode) => {
        this.setState({orientation: mode});
    }

	render() {
		let tableData = this.props.processData();
		//console.log('tableData', tableData);
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
		return (

		<div style={{height: '100%', marginLeft: 10, marginRight: 10}}>
			<OverviewInfoHeader>
                      <div style={{float: "left", color: "#282828", fontWeight: "600"}}>{this.props.headerText}</div>
                      {
                      	this.props.headerButtons.map((item, key) => {
                      		return (<RegisterButton key={key} 
                      			onClick = {'processClick' in item ? item.processClick : this.noOps}
                      			style={{
                      				float: 'right',
                      				color: 'color' in item ? item.color : 'black',
                      				backgroundColor: 'bgcolor' in item ? item.bgcolor : '#EBEBEB',
                      				cursor: 'processClick' in item ? 'pointer' : 'default'
                      			}}
                      			disabled={'disabled' in item && item.disabled}>{item.display}</RegisterButton>)
                      	})
                      }
                      <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
			{
				(() => {
					const has_gc = Store.getStoreData('has_gc')
					let cur_role = Store.getStoreData('role');
					if (cur_role === 'manager' && has_gc === true && "activeView" in this.props && this.props.activeView === "manager" && "toggle_text" in this.props === true) {
						return (
							<div style={{padding: "10px 25px 28px 24px", backgroundColor: "#ffffff"}}>
							<div style={{float: "right"}}>
								<div style={{float: "left", marginTop: "4px"}}>{this.props.toggle_text}</div>
								<div style={{float: "right", marginLeft: "5px"}}>
								{
									(() => {
										if (this.state.toggle_state === true) {
											return (
												<div>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.props.toggleCallback}>No</UnselectedRadio>
												</div>
											);
										} else {
											return (
												<div>
													<UnselectedRadio onClick={this.props.toggleCallback}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</div>
											);
										}
									})()
								}
								</div>
								<div style={{clear: "both"}}></div>
							</div>
							</div>
						);
					}
				})()
			}
			<div style={{clear: "both"}}></div>	
			{
				(() => {
					if ("filters" in this.props === true && this.state.toggle_state_adfilter === true) {
						console.log("Sourav 111")
						return (
							<AdvanceFilter 
								filters={this.props.filters} 
								applyFilter={this.props.applyFilter} 
								clearAdvancedFilter={this.props.clearAdvancedFilter}
								show_all={true}
							/>
						);
					}
				})()
			}
            <TableContainer>
            <div style={{marginTop: '5px', width:'100%'}}>
                <div  style={{position: 'relative'}}>
                {
                    (() => {
                            if(this.state.registerbucLoader) {
                                return <Registerbucketloader style={{position: 'absolute'}} />
                            }
                    })()
                }
                {
                    (() => {
                            if(this.state.registerbucLoader === false && tableData.data.length === 0) {
                                return <RegisterbucketNoDataMessage style={{position: 'absolute'}} />
                            }
                    })()
                }
                <div style={{clear: "both", height: '3px'}}></div>
                <ReactTable
                    PaginationComponent={Pagination}
                    data={tableData.data}
                    columns={tableData.columns}
                    defaultPageSize={parseInt(row_count)}
                    filterable={true}
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        // console.log('id',id);
                        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                    }}
                />
                </div>
                
                {
                    (() => {
                        if ('export' in this.props && this.props.export === true) {                            
                            // return (<ExportButton  onClick={this.exportToCSV()}>Export</ExportButton>);                                                        
                            return (<ExportButton  onClick={this.exportToExcel()}>Export</ExportButton>);                                                        
                        }
                    })()
                }
                {
                    (() => {
                        if ('reportButton' in this.props && this.props.reportButton === true) {                            
                            return (<ExportButton  onClick={this.goToReport()}>Preview Report</ExportButton>);                                                        
                        }
                    })()
                }
                <div style={{clear: "both", marginBottom: "20px"}}></div>    
                {
                    (() => {
                        if(this.state.show_report) {
                            let fields = this.props.fields                            
                            let data = this.props.data
                            data.data = this.props.processData().data
                            //console.log("Registerdata",data)
                            this.data = data;                             
                            //console.log("this.data",this.data)
                            return (
                                    <div style={{position:'absolute', top:'0px', width:'100%'}}>
                                        <InactiveOverlay />
                                        <CSLReportModal
                                            data={this.data}
                                            changeSort={this.changeSort}
                                            changeGroup={this.changeGroup}
                                            hideReport={this.hideReport}
                                            toggleGenerate={this.toggleGenerate}
                                            toggleOrientation={this.toggleOrientation}                                            
                                        />
                                    </div>
                                );
                        }
                    })()
                }   
                {
                    (() => {
                        if (this.state.generate) {
                            return (
                                <div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
                                    <InactiveOverlay style={{zIndex: "1010"}} />
                                    <PdfGenerator
                                    filters={this.state.filters} 
                                    // pills={this.state.pill_filters} 
                                    toggleGenerate={this.toggleGenerate} 
                                    contactName={Store.getStoreData('contact')} 
                                    tiltePDF={this.state.tilte_pdf}
                                    orientation={this.state.orientation} />
                                </div>
                            );
                        }
                    })()
                }         
            </div>
            </TableContainer>
		</div>
		)
	}
}

export default CSLRegisterTable