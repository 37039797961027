import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall.js';
import styled from 'styled-components';
import Utils from '../../Common/Utils';

const EmailContainer = styled.div`
	//width: 67%;
	position: relative;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;

const CancelBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;


class Addtag extends React.Component
{
	state = {name: ""};

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	handleChange = (event) => {
		event.preventDefault();
		let {name} = this.state;
		name = event.target.value;
		this.setState({name});
	}


	AddNewTag = (event) => {
		event.preventDefault();		
        let tag_config = {};
        let randNo = Math.floor(Math.random()*(99999999-10000000+1)+10000000);
    	tag_config['id'] = randNo;
    	tag_config['name'] = this.state.name;
        tag_config['own_tag'] = 1;
        console.log("tag_config", tag_config)
        this.props.addnewTag(tag_config)
	}

	render()
	{
		if (this.state.content === null) {
			return (<div>Loading...</div>);
		}

		return (
			<EmailContainer>
				<div style={{float: "left"}}>Add Tag</div>
				<div style={{float: "right"}}><FaTimes style={{cursor: "pointer"}} onClick={this.closeModal} /></div>
				<div style={{clear: "both"}}></div>
				<div style={{marginTop: "20px"}}>
					<div style={{float: "left", width: "20%", fontWeight: "600", color: "#222222", fontSize: "15px", paddingTop: "8px"}}>Tag Name</div>
					<div style={{float: "left", width: "80%", paddingRight: "20px", boxSizing: "border-box"}}>
						<EmailInput name="name" value={this.state.name} onChange={this.handleChange} />
					</div>
					<div style={{clear: "both"}}></div>
				</div>
				<div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
				<div style={{textAlign: "right", marginTop: "20px"}}>
					<CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
					<SubmitBtn style={{marginLeft: "15px"}} onClick={this.AddNewTag}>Submit</SubmitBtn>
				</div>
			</EmailContainer>
		);
	}
}

export default Addtag;