import React from 'react';
import {FaTimes} from 'react-icons/fa';
import styled from 'styled-components';
import AlertBox from './../Common/AlertBox'
import Toggle from 'react-toggle';
import "./ReactToggle.css";

const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

class ReasonDismissalConfigModal extends React.Component
{
	state = {ready: false,reason_name: "",isActive: 1,isDefaultcode: 0, isDefMultiDis: 0, isDelete: 0,reasonid: null, alert_param: null};

	componentDidMount()
	{        
        if(this.props.curid !== 0) {
            console.log("content==>",this.props.content)            
            this.setState({ready:true,reasonid: this.props.content.reasonID,reason_name: this.props.content.reasonName,
            isActive: this.props.content.isActive,isDelete: this.props.content.isDelete,isDefaultcode: this.props.content.isDefaultcode,
            isDefMultiDis: this.props.content.isDefMultiDis})
        }else{
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            this.setState({ready:true,reasonid: randNo})
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    
    submitReasonRemoval = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        if(this.state.reason_name === "")
        {            
            message = 'Please provide a reason name.';
            problems = true; 
        }
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{ 
            let resonObject = {}
            resonObject.reasonID = this.state.reasonid;
            resonObject.reasonName = this.state.reason_name;
            resonObject.isDefaultcode = this.state.isDefaultcode;
            resonObject.isDefMultiDis = this.state.isDefMultiDis;
            resonObject.isActive = parseInt(this.state.isActive);
            resonObject.isDelete = parseInt(this.state.isDelete);
            //console.log("resonObject==>",resonObject)
            if(this.props.curid !== 0) {
                this.props.updateReasonValue(resonObject);
            }else{                
                this.props.saveReasonValue(resonObject);
            } 
        }               
    }

    aftercheckvalidationAll = (result, stack) => {  
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    handleToggleChange = (arg)=>{
		let {isActive} = this.state;
		isActive = isActive === 1 ? 0 : 1;		
		this.setState({isActive})
	}

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}

		return (<div>
			<EmailContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.headerText} Reason Codes for Dismissal</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>            
                <MRModalBody>
                    <MRModalLabel style={{display: "inline-block"}}>Name</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                    <MRModalInput name="reason_name" value={this.state.reason_name} onChange={this.handleChange}/>
                    
                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>Status </MRModalLabel>
                    
                    <Toggle name="isActive"
					    defaultChecked={this.state.isActive === 1 ? true : false} 
					    icons={false}
					    onChange={()=> this.handleToggleChange('isActive')} />

                    <div style={{float: "right",marginTop: "30px"}}>
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitReasonRemoval}>Submit</SaveBtn>
                    </div>
                </MRModalBody>                
                <div style={{clear: "both"}}></div> 
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default ReasonDismissalConfigModal;