import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
class ShareTasksRUTable extends React.Component
{
    state = {submitedShareUserList: []}

    constructor(props) {
        super(props);
        console.log("shareUserAcknowledge==>", this.props.shareUserAcknowledge);
        console.log("submitedShareUserList==>", this.props.submitedShareUserList);
        console.log("shareUserInfo==>", this.props.shareUserInfo);
        
    }
    componentDidMount()
    {
        let submitedShareUserList = JSON.parse(JSON.stringify(this.props.submitedShareUserList));
        let shareUserAcknowledge = this.props.shareUserAcknowledge;
        let shareUserInfo = this.props.shareUserInfo;
        submitedShareUserList.forEach((item) => {
            if (item.id.toString() in shareUserAcknowledge) {
                console.log("status==>",shareUserAcknowledge[item.id.toString()])
                let statusValue = "No"
                if(shareUserAcknowledge[item.id.toString()].status === "sendAcknowledge"){
                    statusValue = "No"
                    if(shareUserAcknowledge[item.id.toString()].cur_view === "Dismiss"){
                        statusValue = "Yes (Dismissed)"
                    }
                    if(shareUserAcknowledge[item.id.toString()].cur_view === "Completed"){
                        statusValue = "Yes"
                    }
                }
                item.acknowledgment = statusValue;
                item.acknowledgment_required = "Yes";
            }else{                
                item.acknowledgment_required = "No";
                item.acknowledgment = "-";
            }
        });
        console.log("modified submitedShareUserList", submitedShareUserList);
        this.setState({submitedShareUserList})
    }
    processChildFeedsData = () => {
        let ret = {data: [], columns: [], row_count: 0};
        let feedsdata = this.state.submitedShareUserList;
        let role = Store.getStoreData('role');
        let gc_users = Store.getStoreData('gc_users');
        let feedsdata_update = [];
        console.log("feedsdata =>", feedsdata );
        if(role === 'rcm_gatekeeper'){
            for(let k of feedsdata){
                if(k.id in gc_users){
                    feedsdata_update.push(k)
                }
            }
        }else{
            feedsdata_update = feedsdata;
        }
        let i = 0;
        console.log("feedsdata length =>", feedsdata_update.length );
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Email Address', accessor: 'email', minWidth: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Role', accessor: 'role', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Acknowledgment required', accessor: 'acknowledgment_required', width: 100, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Acknowledged', accessor: 'acknowledgment', width: 150, headerStyle: {textAlign: 'left'}}
                    ];

        for(let d of feedsdata_update) {
            i++;
            let role = "";
            if(d.role === 'rcm_gatekeeper'){
                role = "RCM Gatekeeper"
            }else if(d.role === 'manager'){
                role = "Admin Manager"
            }else if(d.role === 'team'){
                role = "Team"
            }            
            let elem = {
                    'index' : i,
                    'id': d.id, 
                    'name' : d.name,
                    'email' : d.email,
                    'role' : role,
                    'acknowledgment_required' : d.acknowledgment_required,
                    'acknowledgment' : d.acknowledgment                   
                   };
            ret.data.push(elem);
        }
        ret.row_count = i;
        return ret;
    }
    render()
    {
        if(this.props.submitedShareUserList.length !== 0){
            return(<div>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared with</div>                                
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>          
                <CSLTable add={false} processData={this.processChildFeedsData} tableRows={5} headerText={''} />
                </OverviewInfoBody>
                </div>);
        }else{
            return(<div>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Shared with</div>                                
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>           
                <OverviewInfoBody>
                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Shared Tasks Available</div>
                </OverviewInfoBody>
                </div>);
        }        
    }
}
export default ShareTasksRUTable;