import React from 'react';
import Store from './Common/Store.js';
import APICall from './Common/APICall';
import APIContact from './Common/APIContact';
import Utils from './Common/Utils.js';
import ComDecom from './Common/ComDecom.js';
import Index from './Components/Index';
import FeedIndex from './Components/FeedIndex';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import RegisterIndex from './Components/GenericRegister/RegisterIndex';
import IndexLoader from './Common/IndexLoader.js';
import CardLoader from './Common/CardLoader.js';
import CSLLoader from './Components/Common/CSLLoader';
import AlertBox from './Components/Common/AlertBox';
import CSLPageloader from './Components/Common/CSLPageloader';
import Reload from './Common/Reload.js';
import EventBus from './Common/EventBus.js';
import ApiContext from "./Components/contexts/ApiContext";

import authService from 'mcc-front-aux/dist/authService'

const moment = require('moment');
const last_action_time = moment().unix()
class AppContent extends React.Component
{

  state = { ready: false,refreshCount: 0, alert_param: null, task_loading: false, from_register: false, rcmIndexSelect:null};
  constructor(props) {
    super(props);
    Utils.setEnv('rcm');
    Store.updateStore('module_name', 'rcm');
    this.callContact();
  }

  callContact() {
    if(!Store.getStoreData('is_admin')) {
      let windowUrl = window.location.href;
      if(windowUrl.indexOf('register') === -1){
        let postData = { command: "contactlist"};
        let api = new APIContact();
        let j = api.command(postData, this.processContactData);
        //console.log("return true", j)
      }
    }
  }

  componentDidMount() {
    let windowUrl = window.location.href;
    let pieces = windowUrl.split("/");
    let is_task_id = pieces[pieces.length-1];
    console.log("is_task_id", is_task_id, windowUrl)
    EventBus.registerEvent('system_err', 'AppContent', this.system_err)

    if(windowUrl.indexOf('register') != -1){
      this.setState({from_register: true})
    }
    if(windowUrl.indexOf('moduleconfig') != -1) {
      this.moduleIndexapi();
      console.log("moduleIndexapi")
    }else if(windowUrl.indexOf('register') != -1 || (is_task_id !=="" && !isNaN(is_task_id))){
      this.taskindexApi();
      //console.log("TaskCall")
    }else if(windowUrl.indexOf('feeds') != -1){
      this.taskindexApi();
      console.log("FeedsCall")
    }else{
      console.log("NormalCall")
      this.callApi();
    }
  }

  system_err = (error) => {
    let alert_param = {
                            title: 'System Error',
                            message: error,
                            ok_text: 'OK',
                            confirm: false,
                            alertHandler: this.alertHandler,
                            stack: {id: 0}
                          }
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
    }

  filterlogIndexapi(){
    let postData = { command: "filterlogindex" };
    let api = new APICall();
    api.command(postData, this.processFilterlogIndex);
  }

  processFilterlogIndex = (result) => {
    console.log("result processFilterlogIndex", result)
    if(result.error_code === 0) {
      if(Store.getStoreData('is_admin')) {
      } else {
        if (result.result['role'] === 'team' || result.result['role'] === 'team') {
          let alert_param = {title: 'System Error', message: 'You are not authorised to access this page. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
                  alertHandler: this.alertHandler, stack: {id: 0}}
          this.setState({ ready: true, alert_param: alert_param});
        }else{
            Store.updateStore('role', result.result['role']);
            Store.updateStore('rutypenameAll', result.result['ru_types_all']);
            IndexLoader.divideFilterlogTasks(result.result['tasks'], result.result['jwg_feeds_autoforword_dismissdata'])
            const documentType = result.result.ru_types_all;
              let doctypenew = {};
              for(let i=0; i < documentType.length; i++) {
                  doctypenew[documentType[i].id.toString()] = documentType[i].documentname;
              }
            Store.updateStore('doctypeObject', doctypenew);

            let allregulator = result.result['regulator_type'];
              let  regulatorObject = {}
              allregulator.forEach((value) => {
                  regulatorObject[value.ragulatorname] = value.knownAs;
              })
              Store.updateStore('ragulatorsKnowAs', regulatorObject);
            this.setState({ ready: true });
          }
        }
    }else{
      let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
                 alertHandler: this.alertHandler, stack: {id: 0}}
      this.setState({ ready: true, alert_param: alert_param});
    }
  }

  taskindexApi() {
    let postData = { command: "taskindex" };
    let api = new APICall();
    Utils.log('sending first call')
    api.command(postData, this.processtaskIndex)
    this.setState({task_loading : false})
  }

  processtaskIndex = (result) => {
    if(result.error_code === 0) {
      if(Store.getStoreData('is_admin')) {
        this.setState({task_loading: false});
      } else {
          console.log('tasks', result);
          let windowUrl = window.location.href;
          console.log("isreferdModule==>", windowUrl.indexOf('register') != -1)
          if(windowUrl.indexOf('register') != -1 && result.result['role'] === "team"){
            let alert_param = {title: 'No Access', message: "You are not authorised to access this module. If you think this is an error, please contact the administrator.", ok_text: 'Ok', confirm: false,
                               alertHandler: this.alertHandler, stack: {id: 0}};
            this.setState({ ready: true, alert_param: alert_param, task_loading: false});
            return
          }
          Store.updateStore('role', result.result['role']);
          //Store.updateStore('contact', result.result['contact']);
          Store.updateStore('loggedincontact', result.result['contactid']);
          Store.updateStore('companyid', result.result['companyid']);

          Store.updateStore('module_config', result.result['module_config']);
          Store.updateStore('ragulatorsname', result.result['regulator_type']);
          Store.updateStore('rutypename', result.result['ru_types']);
          Store.updateStore('rutypenameAll', result.result['ru_types_all']);
          Store.updateStore('gc_company', result.result['gc_company']);
          //Store.updateStore('tags', result.result.getTagSelect.tags);
          // Store.updateStore('autoForwordFeedsRegister', result.result['jwg_feeds_autoforword']);
          // Store.updateStore('autoForwordDismissedFeeds', result.result['jwg_feeds_autoforword_dismissdata']);

          //tags section start
          let mod_cong = result.result['module_config'];
          let tagsArray = [];
          if('general' in mod_cong && 'tagValue' in mod_cong.general){
            let tagValue = mod_cong.general.tagValue;
            let addedby = mod_cong.general.addedBy;
            let addeddate = mod_cong.general.addedDate;
            for(let k in tagValue){
              let tagObject = {};
              tagObject.ID = k;
              tagObject.TagName = tagValue[k];
              tagObject.AddedBy =  addedby;
              tagObject.AddedDate = addeddate;
              tagsArray.push(tagObject);
            }
          }
          //console.log("tagsArray===>", tagsArray)
          Store.updateStore('tags', tagsArray);
          //tags section end

          let has_gc = result.result.gc_company.length > 1 ? true : false;
          Store.updateStore('has_gc',has_gc);

          let allregulator = result.result['regulator_type'];
            let  regulatorObject = {}
            allregulator.forEach((value) => {
                regulatorObject[value.ragulatorname] = value.knownAs;
            })
            Store.updateStore('ragulatorsKnowAs', regulatorObject);

            let gc_assoc = {}
            let gc_assoc_act_inact = {}
            let gc_users = {}
            let gc_filters = {};
            let gcCompany_users = {};
            let gcid_and_gcname = {};
            for(let gc of result.result.gc_company) {
                let gc_id = gc.id.toString();
                gc_assoc[gc_id] = gc.company_name;
                if(gc.is_active === 1){
                  gc_assoc_act_inact[gc_id] = gc.company_name;
                }else if(gc.is_active === 0){
                  gc_assoc_act_inact[gc_id] = gc.company_name+' (Inactive)';
                }
                if(gc.is_gc) {
                  gc_filters[gc.id.toString()] = 1;
                  gcCompany_users[gc.id.toString()] = gc.users;
                }
                if(!gc.is_gc) {
                  continue
                }
                for(let uid in gc.users) {
                  if(!(uid in gc_users))gc_users[uid] = {}
                  gc_users[uid][gc_id] = 1
                }
            }
            console.log("gc_filters==>", gc_filters)
            Store.updateStore('gc_users', gc_users);
            Store.updateStore('gc_filters', gc_filters);
            Store.updateStore('gc_assoc', gc_assoc);
            Store.updateStore('gc_assoc_active_inactive', gc_assoc_act_inact);
            Store.updateStore('gcCompany_users', gcCompany_users);
            let r = {};
            r['result'] = {};
            let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
            console.log('cts==>', cts)
            let user_roles = {};
            let update_contacts = [];
            let user_name = {};
            let contactNameValue = '';
            let contactid = result.result['contactid'];
            if('contactlist' in cts.result && 'user_roles' in cts.result) {
              user_roles = cts.result.user_roles['rcm'];
              contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
              for(let k in cts.result.contactlist){
                let clist = cts.result.contactlist;
                if(clist[k.toString()].IsActive === true && clist[k.toString()].IsDeleted === false && user_roles[k.toString()] !== undefined && user_roles[k.toString()] !== 'no_access' ){
                    update_contacts.push(cts.result.contactlist[k])
                    user_name[k.toString()] = cts.result.contactlist[k].ContactName;
                }
              }
              IndexLoader.divideAllTasksIndex(cts.result.contactlist, user_roles)

              Store.updateStore('contact', contactNameValue)
              Store.updateStore('userroles', user_roles);
              console.log("update_contacts", user_roles)
              Store.updateStore('contacts', update_contacts);
              Store.updateStore('user_name', user_name);
            }
            this.setState({ ready: true, role: result.result['role'] });
            let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
            console.log("task_id===",task_id)
            if(task_id > 0){
              let cur_view = 'register_index'
              let is_register = true;
              Store.updateStore('cur_view', cur_view);
              Store.updateStore('is_register', is_register);
            }
          }
        }else{
          // let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
          //           alertHandler: this.alertHandler, stack: {id: 0}}
          let alert_param = {title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
                    alertHandler: this.alertHandler, stack: {id: 0}}
          this.setState({ ready: true, alert_param: alert_param});
    }
  }

  moduleIndexapi(){
    // this.setState({ ready: true, task_loading: false});
    let postData = { command: "moduleindex" };
    let api = new APICall();
    api.command(postData, this.processModuleIndex);
  }

  processModuleIndex = (result) => {
    console.log("result processModuleIndex", result)
    if(result.error_code === 0) {
      Store.updateStore('role', result.result['role']);
      Store.updateStore('contact', result.result['contact']);
      Store.updateStore('ragulatorsname', result.result['regulator_type']);
      Store.updateStore('gc_company', result.result['gc_company']);
      Store.updateStore('rutypename', result.result['ru_types']);
      Store.updateStore('rutypenameAll', result.result['ru_types_all']);
      //Store.updateStore('subscribe_to_enhanced_data_feed', result.result['subscribe_to_enhanced_data_feed']);

      let has_gc = result.result.gc_company.length > 1 ? true : false;
      Store.updateStore('has_gc',has_gc);

      let r = {};
      r['result'] = {};
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      console.log('cts==>', cts)
      let user_roles = {};
      let update_contacts = [];
      let contactNameValue = '';
      let contactid = result.result['contactid'];
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles['rcm'];
        contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
        for(let k in cts.result.contactlist){
          let clist = cts.result.contactlist;
          if(clist[k.toString()].IsActive === true && clist[k.toString()].IsDeleted === false && user_roles[k.toString()] !== undefined && user_roles[k.toString()] !== 'no_access' ){
              update_contacts.push(cts.result.contactlist[k])
          }
        }
        Store.updateStore('userroles', user_roles);
        Store.updateStore('contact', contactNameValue)
        Store.updateStore('contacts', update_contacts);
      }
      this.setState({ ready: true });
    }else{
      // let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
      //            alertHandler: this.alertHandler, stack: {id: 0}}
      let alert_param = {title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
                 alertHandler: this.alertHandler, stack: {id: 0}}
      this.setState({ ready: true, alert_param: alert_param});
    }
  }

  async callApi(){
    let postData = { command: "index" };
    let api = new APICall();
    Utils.log('sending first call')

    api.command(postData, this.processIndexData)
    let task_loading = Store.getStoreData('is_admin') ? false : true;
    this.setState({task_loading : task_loading})
  }

  processIndexData = (result) => {
    console.log(result)
    if(result.error_code === 0) {
      console.log("result from server", result)
      this.state.rcmIndexSelect = result;
      let is_admin = Store.getStoreData('is_admin');

        if (is_admin) {
          // Store.updateStore('feeds', result.feeds);
          // Store.updateStore('feedscount', result.feedscount);
          Store.updateStore('documenttype', result.documenttype);
          Store.updateStore('ragulatorsname', result.ragulatorsname);
          Store.updateStore('rutypename', result.ru_types);
          Store.updateStore('rutypenameAll', result.ru_types_all);
          Store.updateStore('module_config_csl', result.module_config);
          Store.updateStore('csl_filter_log', result.filetr_log);
          let allregulator = result.ragulatorsname;
          let  regulatorObject = {}
          allregulator.forEach((value) => {
              regulatorObject[value.ragulatorname] = value.knownAs;
          })
          Store.updateStore('ragulatorsKnowAsCSL', regulatorObject);
          IndexLoader.divideCSLAllTasks(result.feedsAll)
          // Store.updateStore('rn_feeds', result.rn_feeds);
          let mode = this.props.match ? this.props.match.params.mode : 'ru';
            this.setState({
                ready: true
            });
        } else {
          if(result.result['role'] === "no_access") {
            // let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
            //                    alertHandler: this.alertHandler, stack: {id: 0}};
            let alert_param = {title: 'No Access', message: result.error_msg, ok_text: 'Ok', confirm: false,
                               alertHandler: this.alertHandler, stack: {id: 0}};
            this.setState({ ready: true, alert_param: alert_param, task_loading: false});
            return
          }
          Store.updateStore('role', result.result['role']);
          //Store.updateStore('contact', result.result['contact']);
          Store.updateStore('loggedincontact', result.result['contactid']);
          Store.updateStore('companyid', result.result['companyid']);
          Store.updateStore('module_config', result.result['module_config']);
          // Store.updateStore('documenttype', result.result['document_type']);
          Store.updateStore('documenttype', result.result['ru_types_all']);
          Store.updateStore('ragulatorsname', result.result['regulator_type']);
          // Store.updateStore('rutypename', result.result['ru_types']);
          Store.updateStore('rutypenameAll', result.result['ru_types_all']);
          Store.updateStore('gc_company', result.result['gc_company']);
          Store.updateStore('gc_companies', result.result['gc_companies']);
          Store.updateStore('newRCtasks', result.result['tasks'].newTasks);
          //Store.updateStore('autoForwordFeedsAndRU', result.result['tasks'].autoforwordfeedTasks);
          Store.updateStore('pendingRCtasks', result.result['tasks'].pendingTasks);
          Store.updateStore('saveRCtasks', result.result['tasks'].saveTasks);
          Store.updateStore('shareWithMeRCTasks', result.result['tasks'].shareWithMeTasks);
          Store.updateStore('followupRCtasks', result.result['tasks'].followupTasks);
          Store.updateStore('dismissFeedsAndRUtasks', result.result['tasks'].dismissTasks);
          Store.updateStore('myRCtasks', result.result['tasks'].mytasks);

          Store.updateStore('defaultTimePeriod', result.result['defaultTimePeriod']);
          //Store.updateStore('tags', result.result.getTagSelect.tags);
          let ru_types_all = result.result['ru_types_all']
          let ru_types = []
          for(let d of ru_types_all){
            if(d.IsShowed){
              ru_types.push(d)
            }
          }
          Store.updateStore('rutypename', ru_types);

          //tags section start
          let mod_cong = result.result['module_config'];
          let tagsArray = [];
          if('general' in mod_cong && 'tagValue' in mod_cong.general){
            let tagValue = mod_cong.general.tagValue;
            let addedby = mod_cong.general.addedBy;
            let addeddate = mod_cong.general.addedDate;
            for(let k in tagValue){
              let tagObject = {};
              tagObject.ID = k;
              tagObject.TagName = tagValue[k];
              tagObject.AddedBy =  addedby;
              tagObject.AddedDate = addeddate;
              tagsArray.push(tagObject);
            }
          }
          //console.log("tagsArray===>", tagsArray)
          Store.updateStore('tags', tagsArray);
          //tags section end


          let has_gc = result.result.gc_company.length > 1 ? true : false;
          Store.updateStore('has_gc',has_gc);

          let allregulator = result.result['regulator_type'];
          let regulatorObject = {}
          let regulatorKnownasObject = {};
          allregulator.forEach((value) => {
              regulatorObject[value.ragulatorname] = value.knownAs;
              regulatorKnownasObject[value.knownAs] = 0
          })
          Store.updateStore('ragulatorsKnowAs', regulatorObject);
          Store.updateStore('regulatorKnownasObject', regulatorKnownasObject);

          IndexLoader.divideAutoForwordFeedsAndRUIndex(result.result['tasks'].autoforwordfeedTasks)
          let select_timespan = '';
          if(result.result['defaultTimePeriod'] === ''){
              select_timespan = 'general' in result.result['module_config'] ? result.result['module_config'].general.select_timespan : '';
          }else{
            select_timespan = result.result['defaultTimePeriod'];
          }
          if(result.result['role'] !== "team"){
            CardLoader.divideDashboardCardCoutAll(result.result['tasks'], result.result['module_config'], regulatorObject, select_timespan)
          }else{
            CardLoader.divideDashboardCardCoutAllTeam(result.result['tasks'], result.result['module_config'], regulatorObject, select_timespan)
          }

          let gc_assoc = {}
          let gc_assoc_act_inact = {}
          let gc_users = {}
          let gc_filters = {};
          let gcCompany_users = {};
          let gcid_and_gcname = {};
          for(let gc of result.result.gc_company) {
              let gc_id = gc.id.toString();
              gc_assoc[gc_id] = gc.company_name;
              if(gc.is_active === 1){
                gc_assoc_act_inact[gc_id] = gc.company_name;
              }else if(gc.is_active === 0){
                gc_assoc_act_inact[gc_id] = gc.company_name+' (Inactive)';
              }
              if(!gc.is_gc){
                gcid_and_gcname[result.result['companyid'].toString()] = gc.company_name;
              }else{
                const calculated_gc_id = (parseInt(result.result['companyid']) * 100000) + parseInt(gc.id);
                gcid_and_gcname[calculated_gc_id.toString()] = gc.company_name;
              }
              if(gc.is_gc) {
                gc_filters[gc.id.toString()] = 1;
                gcCompany_users[gc.id.toString()] = gc.users;
              }
              if(!gc.is_gc) {
                continue
              }
              for(let uid in gc.users) {
                if(!(uid in gc_users))gc_users[uid] = {}
                gc_users[uid][gc_id] = 1
              }
          }
          console.log("gc_filters==>", gc_filters,"gc_users==>", gc_users, "gc_assoc===>", gc_assoc,'gcCompany_users===>', gcCompany_users, 'gcid_and_gcname===>', gcid_and_gcname)
          Store.updateStore('gc_users', gc_users);
          Store.updateStore('gc_filters', gc_filters);
          Store.updateStore('gc_assoc', gc_assoc);
          Store.updateStore('gc_assoc_active_inactive', gc_assoc_act_inact);
          Store.updateStore('gcCompany_users', gcCompany_users);
          Store.updateStore('gcid_and_gcname', gcid_and_gcname);
          const documentType = result.result.ru_types_all;
            let doctypenew = {};
            for(let i=0; i < documentType.length; i++) {
                doctypenew[documentType[i].id.toString()] = documentType[i].documentname;
            }
          Store.updateStore('doctypeObject', doctypenew);
          console.log('gc_users', gc_assoc, gc_users)

          let r = {};
          r['result'] = {};
          let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
          console.log('cts==>', cts)
          let user_roles = {};
          let update_contacts = [];
          let contactNameValue = '';
          let contactid = result.result['contactid'];
          if('contactlist' in cts.result && 'user_roles' in cts.result) {
            user_roles = cts.result.user_roles['rcm'];
            contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
            for(let k in cts.result.contactlist){
              let clist = cts.result.contactlist;
              if(clist[k.toString()].IsActive === true && clist[k.toString()].IsDeleted === false && user_roles[k.toString()] !== undefined && user_roles[k.toString()] !== 'no_access' ){
                  update_contacts.push(cts.result.contactlist[k])
              }
            }
            //CardLoader.dataCardCoutAll(result.result['tasks'], result.result['module_config']);
            console.log("user_roles===>", user_roles)
            IndexLoader.divideAllTasksIndex(cts.result.contactlist, user_roles)
            Store.updateStore('contact', contactNameValue)
            Store.updateStore('userroles', user_roles);
            Store.updateStore('contacts', update_contacts);
            Store.updateStore('contactlist_all', cts.result.contactlist);
          }
          this.setState({ ready: true, role: result.result['role'] });

          let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
          console.log("task_id===",task_id)
          if(task_id > 0){
            let cur_view = 'register_index'
            let is_register = true;
            Store.updateStore('cur_view', cur_view);
            Store.updateStore('is_register', is_register);
          }
        }
      }else{
        // let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
        //            alertHandler: this.alertHandler, stack: {id: 0}}
        let alert_param = {title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
                   alertHandler: this.alertHandler, stack: {id: 0}}
        this.setState({ ready: true, alert_param: alert_param});
      }
    }

    // processContactData = (result) => {
    //   if(localStorage.getItem("contactlist") === null){
    //     localStorage.setItem("contactlist", JSON.stringify(result));
    //   }
    //   else{
    //     let rs = JSON.parse(localStorage.getItem("contactlist"));
    //     console.log("rs ===>",rs)
    //     if(result.last_action_time > rs.last_action_time) {
    //       let contactlist = rs.contactlist;
    //       for(let key in contactlist) {
    //         rs.contactlist[key] = contactlist[key];
    //       }
    //       localStorage.setItem("contactlist", JSON.stringify(rs))
    //     }
    //   }
    // }

    processContactData = (result) => {
        //localStorage.setItem("contactlist", JSON.stringify(result));
        let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
        result.result['contactlist'] = cl
        localStorage.setItem("contactlist", JSON.stringify(result));
    }

    alertHandler = (result, stack) => {
      // this.setState({alert_param: null})
      // window.location = '/select/ServiceBasedLanding';
      Reload.ReloadPage();
    }

    refreshState = (cur_view) => {
      Utils.log("cur_view", cur_view)
      this.setState({refreshCount : this.state.refreshCount + 1, cur_view: cur_view})
    }

  render()
  {
    let task_loading = Store.getStoreData('is_admin') ? false : this.state.task_loading;
    if(task_loading){
      if(this.state.rcmIndexSelect === null || localStorage.getItem('contactlist') === null) {
        return <div><CSLPageloader /></div>
      }
    }
    if(this.state.from_register) {
      if(Store.getStoreData('rcm-taskindex-select') === null || localStorage.getItem('contactlist') === null) {
        return <div><CSLLoader /></div>
      }
    }
    if(!this.state.ready) {
      return <div><CSLLoader /></div>
    }
    if(this.state.alert_param !== null) {
      return <AlertBox alertParam = {this.state.alert_param} />
    }
    return(
      <Router>
        <ApiContext.Provider value={ this.state }>
        <Switch>
          <Route path="/moduleconfig" exact>
            <ModuleConfigHOC />
          </Route>
          <Route path="/register" exact>
              <RegisterIndex />
          </Route>
          {/* <Route path="/filterlog" exact>
            <FilterLogIndex />
          </Route> */}
          <Route path="/moduleconfig/:requester" exact component={ModuleConfigHOC} />
          <Route path="/" exact>
            <Index refreshCallback={this.refreshState} noParam={true} refresh={this.state.refreshCount}/>
          </Route>
          <Route path="/:mode" component={Index} exact />
          <Route path="/:taskId" component={Index} exact />
          <Route path="/feeds/:feedId" exact>
              <FeedIndex />
          </Route>
        </Switch>
        </ApiContext.Provider>
      </Router>
    );
    // return(
    //   <Router>
    //     <Switch>
    //       <Route path="/select/microservice/mstop/rcm/moduleconfig" exact>
    //         <ModuleConfigHOC />
    //       </Route>
    //       <Route path="/select/microservice/mstop/rcm/register" exact>
    //           <RegisterIndex />
    //       </Route>
    //       <Route path="/select/microservice/mstop/rcm/moduleconfig/:requester" exact component={ModuleConfigHOC} />
    //       <Route path="/select/microservice/mstop/rcm/" exact>
    //         <Index />
    //       </Route>
    //       <Route path="/ms/mstop/rcm/" exact>
    //         <Index />
    //       </Route>
    //       <Route path="/ms/mstop/rcm/:mode" component={Index} exact />
    //       <Route path="/select/microservice/mstop/rcm/:taskId" component={Index}  exact/>
    //       <Route path="/select/microservice/mstop/rcm/feeds/:feedId" exact>
    //             <FeedIndex />
    //       </Route>
    //
    //       <Route path="/select/microservice/msmctop/rcm/moduleconfig" exact>
    //         <ModuleConfigHOC />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/register" exact>
    //           <RegisterIndex />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/moduleconfig/:requester" exact component={ModuleConfigHOC} />
    //       <Route path="/select/microservice/msmctop/rcm/" exact>
    //         <Index />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/:taskId" component={Index}  exact/>
    //       {/* <Route path="/select/microservice/mstop/mccgeneral/auditlogs/rcm/filterlog" exact>
    //         <FilterLogIndex />
    //       </Route> */}
    //       <Route path="/select/microservice/msmctop/rcm/feeds/:feedId" exact>
    //             <FeedIndex />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/moduleconfig" exact>
    //         <ModuleConfigHOC />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/register" exact>
    //         <RegisterIndex />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/moduleconfig/:requester" exact component={ModuleConfigHOC} />
    //       <Route path="/select/microservice/msmctop/rcm/" exact>
    //         <Index />
    //       </Route>
    //       <Route path="/select/microservice/msmctop/rcm/:taskId" component={Index}  exact/>
    //       {/* <Route path="/select/microservice/mstop/mccgeneral/auditlogs/rcm/filterlog" exact>
    //               <FilterLogIndex />
    //             </Route> */}
    //       <Route path="/select/microservice/msmctop/rcm/feeds/:feedId" exact>
    //         <FeedIndex />
    //       </Route>
    //     </Switch>
    //   </Router>
	// 	);
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
    console.log('this.props', this.props)
    let is_debug = false
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', is_debug)
    Store.updateStore('api_url', api_url)

    authService.refresh().then(() => {
      this.setState({ready: true})
    })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

    return <AppContent />
  }
}

export default App;
