import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import moment from 'moment';


const RUPButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
class CSLFilterlogs extends React.Component
{
    
    state = {
        isLoaded: false,
        dismissedrnfeeds: null,
        modal_view: null,
        doctypeObject: null
    };

    constructor(props) {
        super(props);        
    }    

    componentDidMount() {      
        this.setState({ isLoaded: true});        
    }    

    processMyFeedsData = () => {        
        let ret = {data: [], columns: []};   
        let taskData = Store.getStoreData('csl_filter_log') === null ? [] : Store.getStoreData('csl_filter_log');             
        let i = 0;
        ret.columns =[  {Header: 'RU/RN Title', accessor: 'title', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Type', accessor: 'doctype', width: 130, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Sector', accessor: 'sector', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Regulator', accessor: 'regulator', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Sent Date', accessor: 'send_date', width: 165, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                              return 0;
                            }
                            let aReverse = 0;
                            let bReverse = 0;
                            let adue = a.split("/");
                            aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                          }},
                        {Header: 'Source', accessor: 'source', width: 150, headerStyle: {textAlign: 'left'}},
                        {Header: 'Reason', accessor: 'reason', minWidth: 80, headerStyle: {textAlign: 'left'}}];

        for(let d of taskData) {            
            i++;      
            let filterjson = JSON.parse(d.filter_json);  
            let dNames = "";
            for(let a=0;a<Object.keys(filterjson.filterlog_doctypes).length;a++){
                dNames += Object.keys(filterjson.filterlog_doctypes)[a];
                if(a < Object.keys(filterjson.filterlog_doctypes).length - 1) {
                    dNames += ', '
                }
            }

            let sectname = '';
            let sourceValue = 'CSL'
            if(d.source === 'JWG'){
                sectname = '---';
                sourceValue = 'Automated Feed'
            }else{
                for(let j=0;j<Object.keys(filterjson.filterlog_sector).length;j++){
                    sectname += Object.keys(filterjson.filterlog_sector)[j];
                    if(j < Object.keys(filterjson.filterlog_sector).length - 1) {
                        sectname += ', '
                    }
                }
            }            
            let send_date = moment(d.created_at).format('DD/MM/YYYY') 

            let reasonValue = "";
            if(filterjson.filterlog_doctype_reason !== ""){
                reasonValue += "Mismatching " +filterjson.filterlog_doctype_reason;
            }
            if(filterjson.filterlog_regName_reason !== ""){
                if(reasonValue !== ""){
                    reasonValue += ","
                }
                reasonValue += "Mismatching " +filterjson.filterlog_regName_reason;
            }
            if(filterjson.filterlog_sector_reason !== ""){
                if(reasonValue !== ""){
                    reasonValue += ","
                }
                reasonValue += "Mismatching " +filterjson.filterlog_sector_reason;
            }
            
            let elem = {
                    'index' : i, 
                    'feed_id': d.feed_id,                    
                    'title' : filterjson.title,
                    'doctype' : dNames,
                    'sector' : sectname,
                    'regulator' : filterjson.filterlog_regName,
                    'send_date' : send_date.toString(),
                    'source' : sourceValue,
                    'reason' : reasonValue                   
            };
            ret.data.push(elem);        
        }        
        return ret;
    } 

    render()
    {        
        if(this.state.isLoaded === false) {
            return <div>Loading...</div>
        }
        return (
            <IndexContainer>                
                <CSLTable add={false} processData={this.processMyFeedsData} tableRows={10} headerText={'Regulatory Change Management Filter Log'} />                          
            </IndexContainer>
        );
    }
}
export default CSLFilterlogs;