import React from 'react';
//import styled from 'styled-components';
//import RCMIndex from '../RCM/RCMIndex';
//import RUPending from '../RegularotyChanges/Pending/RUPending';
//import RUPendingViewOnly from '../RegularotyChanges/Pending/RUPendingViewOnly';
import Store from '../../Common/Store';
// import MGRIndex from './MGR/MGRIndex';
import MGRIndex from './MGR_updated/MGRIndex';
//import TeamIndex from './Team/TeamIndex';
import TeamIndex from './Team_updated/TeamIndex';


class SelectIndex extends React.Component
{
    state = {        
     }

    constructor(props) {
		super(props);
    }
    render() {  
        let role = Store.getStoreData('role')   
        return(<div>
            {
                (() => {
                    switch(role) {
                        case 'manager' : 
                        case 'rcm_gatekeeper' : return <MGRIndex showCardDetails={this.props.showCardDetails}/>; break; 
                        case 'team': return <TeamIndex showCardDetails={this.props.showCardDetails}/>; break; 
                        //case 'team': return <RUPending refreshCallback={this.props.refreshCallback}/>; break;                                                                    
                    }
                })()
            }
            
        </div>)  
    }
}

export default SelectIndex;