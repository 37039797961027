import React from 'react';
import styled from 'styled-components';

class RCMTagsView extends React.Component
{
    constructor(props) {
        super(props);        
    }
    
    selectOrUnselectTopic = (tagid) => (event) => {        
        this.props.selectUnselect(tagid);
    }

    render()
    {            
        return (
            <div>
                {
                this.props.tags.map((tag, index) => {
                    return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                })
                }
            </div>
        );
    }
}
export default RCMTagsView;