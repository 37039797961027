import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FaTimes,FaEdit,FaSearch, FaRegTrashAlt,FaPlusCircle, FaPencilAlt} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store.js';
import HtmlEditor from '../../HtmlEditor';
import ChildTasksRUTable from '../Partials/ChildTasksRUTable';
import DatePicker from 'react-date-picker';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import AddExternalLinks from './ExternalLinks/AddExternalLinks';
import AddKeyDates from './KeyDates/AddKeyDates';
import Utils from '../../Common/Utils';
import ShareTasksRUTable from './ShareTasksRUTable';
import Dropzone from '../Common/Dropzone';
import AddEditKeyDates from './KeyDates/AddEditKeyDates';
import AddEditExternalLinks from './ExternalLinks/AddEditExternalLinks';
import SearchablePills from './../Common/SearchablePills';
import CSLLoader from './../Common/CSLLoader';
import RCMTagsView from './RCMTagsView';
import FollowupTaskModal from './../Select/FollowupTaskModal';
import AlertBox from './../Common/AlertBox';
import '../../App.css';
import moment from 'moment';
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 0%;
    z-index: 1000;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
    padding: 6px;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #252525;
`;
const RegModalNameLabelDiv = styled.div`
    color: #252525;
    font-weight: 600;
    margin-top: 10px;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegModalNameInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 5px;
    cursor: pointer;
`;
const RegMarkForActionButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #d5d5d5;
    color: #757575;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    margin-left: 5px;
    font-size: 10px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegShareButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062;
    color: #ffffff;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-size: 11px;
    margin-left: 5px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 5px;
    /* float: right; */
    cursor: pointer;
`;
const StatusBadge = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 200px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:default;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 96%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;  
    font-family: 'Montserrat', sans-serif; 
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 37px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #343434;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #7a8da1;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 300%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
class RegulatoryClientModal extends React.Component
{
    state = {
        resonforaction: false,
        reasonforcomplete: false,
        complete_note: "",
        followuptask:false,
        textareaValue:"",
        date: new Date(),
        assign_note: "",
        dismiss_note: "",
        due_by: '1970-01-01',
        assigned_to: 0,
        lane: "COMP_RCM_ASSIGNMENT",
        externalLink: [],
        keyDatesValue: [],
        shareModal: false,
        sharedata: null,
        shareAcknowledgeMentdata: null,
        shareobject: {},
        shareUserAll: {},
        shareUserInfo: {},
        shareUserAcknowledge: {},
        send_mail_config: false,
        task_json:null,
        acknowledgeModal: false,
        editsection: false,
        documentname: '',
        documentype:null,
        documentTypes: null, 
        uploaddate: new Date(),   
        sections: [], 
        ready: false,    
        bottom_Buttons_panel: true,
        rowchecked: false,
        submitedShareUserList: [],
        searchUserShareList:[],
        searchUserShareAcknowList:[],
        groupcompanyName: "",
        reasonCode: "",
        newReasonCode: [],
        distributionCode: 0,
        distributionLists: [],
        showDistributionShareUser: false,
        binFiles: [], 
        cur_files_json: null, 
        isdesable: false,
        button_type : '',
        gcompanyUsers: {},
        isGroup: false,
        
        hiddenAddButton: true,
        showAddKeyDateModal: false,
        curKeyid: 0,
        content: null,

        hiddenAddButtonLink: true,
        showAddLinkModal: false,
        curLinkid: 0,
        contentLink: null,
        document_types: [],
        regulatorType: null,
        ragulatortypeName: "",
        tags: [],
        searchFilterTags: [],
        isLoaderIcon: false,
        selected_gc: '0',
        isGroupDistributioncheck: false,
        modal_view: null,
        alert_param: null,

        isReasonForDismissalShow: false,              
        reason_for_dismissal: [],
        selected_reason_for_dismissal: '',
        isShowDismissalDropdown: false
    };
    constructor(props) {
        super(props);
        Store.updateStore('sections', []);
      }

      addSection = (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        let section_id = 1;
        if (sections.length !== 0) {
            section_id = sections[sections.length - 1].id + 1;
        }
        sections.push({
            id: section_id,
            display: "input",
            name: `Section ${section_id.toString()}`,
            content: ""
        });
        Store.updateStore('sections', sections);
        this.setState({sections});
    }
    deleteSection = (section_id) => (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure to delete this section?")) {
            let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
            console.log("sections",sections)
            let sectionArray = []
            for(let i=0;i<sections.length;i++){
                if(sections[i].id !== section_id){
                    if(sections.length === 1){
                        sectionArray = [];
                    }else{
                        sectionArray.push({
                            content: sections[i].content,
                            display: sections[i].display,
                            id: sections[i].id,
                            name: sections[i].name
                        })
                    }
                    
                }                        
            }
            console.log("sectionArray",sectionArray)
            Store.updateStore('sections', sectionArray);
            this.setState({sections: sectionArray});
        }
    }
    changeInputValue = (status) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
                section.display = "input";
                if (!status) section.display = "label";
            }
        })
        let store_sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        store_sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
            }
        })
        Store.updateStore('sections', store_sections);
        this.setState({sections});
    }
    changeToInput = (data_id) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(data_id)) {
                section.display = "input";
            }
        })
        Store.updateStore('sections', sections);
        this.setState({sections});
    }
    componentDidMount() {        
        let objectdata = Store.getStoreData('task');        
        let {shareobject,shareUserAll, submitedShareUserList,shareUserInfo, shareUserAcknowledge,sharedata, shareAcknowledgeMentdata} = this.state;
        if('shareobject' in objectdata){
            shareobject = objectdata.shareobject;
        }
        if('shareUserAll' in objectdata){
            shareUserAll = objectdata.shareUserAll;
        }        
        if("shareUserInfo" in objectdata) {
            shareUserInfo = objectdata.shareUserInfo;
        }
        if("shareUserAcknowledge" in objectdata) {
            shareUserAcknowledge = objectdata.shareUserAcknowledge;
        }
        if("submitedShareUserList" in objectdata) {
            submitedShareUserList = objectdata.submitedShareUserList;
        }

        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')

        //tag section start
        let selectedtags = {};        
        if("tags" in Store.getStoreData('task')) {
            let tg = Store.getStoreData('task').tags;
            for(let d of tg){
                selectedtags[d.toString()] = 1
            }
            //  selectedtags = Store.getStoreData('task').tags.toString();
        }     
        // console.log("Store.getStoreData('task')==>", Store.getStoreData('task'))   
        // console.log("selectedtags==>", selectedtags)   
        let Storetags = Store.getStoreData('tags');   
        // console.log("Storetags==>", Storetags)      
        let otags = [];
        // Storetags.forEach((tag) => {
        //      if (selectedtags.includes(tag.ID.toString())) {
        //          tag.selected = true;
        //      } else {
        //          tag.selected = false;
        //      }
        //      otags.push({
        //          name: tag.TagName,
        //          id: tag.ID,
        //          styledcomponent: tag.selected ? SelectedPill : UnselectedPill,
        //          selected: tag.selected
        //      }); 
        //  })
        for(let d of Storetags){
            let selected = false            
            if(d.ID.toString() in selectedtags){
                selected = true
            }
            otags.push({
                name: d.TagName,
                id: d.ID,
                styledcomponent: selected ? SelectedPill : UnselectedPill,
                selected: selected
            });
        }
         
         console.log("otags==>", otags) 
         let stagnames = [];
         otags.forEach((item) => {
             stagnames.push(item.name);
         })
        stagnames.sort();        
        let tags = [];
        for (let i=0; i<stagnames.length; i++) {
             for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    tags.push(otags[j]);
                }
            }
        }
        //tag section end
        
        
        let send_mail_config = false;
        let module_config = Store.getStoreData('module_config');
        console.log("module_config",module_config)
        if(Object.keys(module_config).length > 0) {            
            if(module_config.email.length > 0) {
                send_mail_config = true;
            }
        }
        let topics = [];
        let sections = [];
        if ("sections" in Store.getStoreData('task')) {
            sections = JSON.parse(JSON.stringify(Store.getStoreData('task').sections));
            for( let i=0; i<sections.length; i++)
            {
                sections[i].display = "label";
                sections[i].content = window.decodeURIComponent(atob(sections[i].content));
            }
            Store.updateStore('sections', sections);
        }        
        let keyDates = [];
        if("keyDates" in Store.getStoreData('task')) {    
            let keyDatesAll = Store.getStoreData('task').keyDates;
            keyDatesAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                keyDates.push(item);
            }) 
        }
        let externalLinks = [];        
        if("externalLinks" in Store.getStoreData('task')) {         
            let externalLinksAll = Store.getStoreData('task').externalLinks;
            externalLinksAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                externalLinks.push(item);
            })               
        } 

        var documentype = objectdata.documentTypes;
        let doctypesObject = {}
        for(let s of documentype){
            doctypesObject[s.toString()] = 1
        }
        let doctype = Store.getStoreData('rutypename');  
        doctype.sort(function(a,b) {return (a.documentname > b.documentname) ? 1 : ((b.documentname > a.documentname) ? -1 : 0);} );
        console.log("uploaddate===>", objectdata.uploadDate);
        let uploaddate = new Date()
        if(objectdata.uploadDate === null){
            uploaddate = new Date(objectdata.issueDate);
        }else{
            uploaddate = new Date(objectdata.issueDate);
        }        

        let date = new Date();
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        //console.log("dateStr",dateStr)         
      
        console.log("send_mail_config--->",send_mail_config)
        let task = Store.getStoreData('this_task_detail');
        console.log("task.task.parenttask.id", task.task.parenttask.id)
        let task_json = JSON.parse(task.task.parenttask.task_json);
        //let contacts = Store.getStoreData('contacts');
        let all_contacts = Store.getStoreData('contacts');
        let contacts = [];
        
        //start-group-company
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gcid = (parseInt(task_json.task_data.group_company_id)%100000)
        console.log("gcid",gcid);
        console.log("gc_assoc",gc_assoc);
        let gcname = "";
        let gcCheck_distribution = false;
        if(gcid in gc_assoc){
            gcname = gc_assoc[gcid];
            gcCheck_distribution = true;
        }
        console.log("gcname",gcname) 
        let sarray = [];
        let newsarray = [];
        let sAcknarray = [];
        let newsAcknarray = [];
        let companyUsers = {};
        let isGroup = false
        let com_roles_userid = {}
        all_contacts.forEach((user) => {     
            com_roles_userid[user.ID] =  1;     
        })
        
        if('group_company_id' in task_json.task_data &&  task_json.task_data.group_company_id !== 0){
            let gc_company = Store.getStoreData('gc_company');        
            //console.log("gc_company", gc_company);            
            for(let gc of gc_company) {            
                if(parseInt(gc.id) === parseInt(gcid)){
                    companyUsers = gc.users; 
                }
            }
            console.log("gc_company", companyUsers);
            com_roles_userid = companyUsers;
            //info
            let sharedataAll = Store.getStoreData('shareuserlist'); 
            for(let i=0;i<sharedataAll.length;i++){            
                if(!(sharedataAll[i].id.toString() in shareUserAll)){ 
                    newsarray.push(sharedataAll[i]);                
                }
            }
            newsarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );    
            let updateSarray = [];         
            newsarray.forEach((item) => {
                if (item.id in companyUsers) {
                    updateSarray.push(item);
                }			
            })
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updateSarray.forEach((item) => {
                    if(item.id in gc_users){
                        sarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sarray = updateSarray;
            }
            //new end
            //ack
            let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');            
            for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){            
                if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){ 
                    newsAcknarray.push(shareAcknowledgeMentdataAll[i]);                
                }            
            }
            newsAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            let updatesAcknarray = [];             
            newsAcknarray.forEach((item) => {
                if(item.id in companyUsers){
                    updatesAcknarray.push(item); 
                }
            })
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updatesAcknarray.forEach((item) => {
                    if(item.id in gc_users){
                        sAcknarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sAcknarray = updatesAcknarray;
            }
            //new end

            let newContacts = [];
            all_contacts.forEach((item) => {
                if(item.ID in companyUsers){
                    newContacts.push(item); 
                }
            })
            console.log("all_contacts", all_contacts);
            console.log("newContacts", newContacts);
            contacts = newContacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} ); 
            isGroup = true;
            console.log("gcid", gcid, Store.getStoreData('companyid'))
            if(parseInt(gcid) === parseInt(Store.getStoreData('companyid'))){
                gcCheck_distribution = false;
            }
        }else{
            //info
            let sharedataAll = Store.getStoreData('shareuserlist');  
            let updateSarray = [];
            for(let i=0;i<sharedataAll.length;i++){                
                if(!(sharedataAll[i].id.toString() in shareUserAll)){
                    updateSarray.push(sharedataAll[i]);
                }
            }
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updateSarray.forEach((item) => {
                    if(item.id in gc_users){
                        sarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sarray = updateSarray;
            }
            //new end
            sarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            console.log("sarray", sarray);
            //ack 
            let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');     
            let updatesAcknarray = [];      
            for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){                
                if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){
                    updatesAcknarray.push(shareAcknowledgeMentdataAll[i]);                    
                }                
            }
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updatesAcknarray.forEach((item) => {
                    if(item.id in gc_users){
                        sAcknarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sAcknarray = updatesAcknarray;
            }
            //new end
            sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            console.log("sAcknarray", sAcknarray);

            contacts = all_contacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );  
        }
        console.log("companyUsers", companyUsers)

        //end
        let reasonCode = "";
        let newreason = [];
        if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
            if('reason_code' in module_config && module_config.reason_code.length !== 0){
                module_config.reason_code.forEach((item) => {
                    if (item.isDefaultcode === 1) {
                        reasonCode = item.reasonName;
                    }
                    if(item.isActive === 1){
                        newreason.push(item);
                    }
                })
            }
        }
        
                            
        let distributionLists = []
        if('general' in module_config && module_config.general.enable_distribution_lists === true){
            if('distribution_list' in module_config && module_config.distribution_list.length !== 0){
                let allUser = {distributionID: 12, distributionName: "All Users", distributionItem: "allusers", assingnedUserID: com_roles_userid, curgcid: 0};
                if('general' in module_config && module_config.general.enable_add_new_ru === true){
                    //console.log("gcCheck_distribution", gcCheck_distribution)
                    module_config.distribution_list.forEach((item) => {
                        if(gcCheck_distribution){
                            if('isAvailableAllGCs' in item && item.isAvailableAllGCs === true)
                            distributionLists.push(item);
                        }else{
                            distributionLists.push(item);
                        }
                    })
                }else{
                    module_config.distribution_list.forEach((item) => {                        
                        distributionLists.push(item);
                    }) 
                }
                distributionLists.push(allUser);
            }
        }
        let dname = objectdata.documentName.replace(/(--quote--)/g, "'").replace(/(--and--)/g, "&")
        Store.updateStore('shareUserAcknowledgeUpdate', shareUserAcknowledge)
        console.log("contacts==>", contacts)


        let document_types = [];        
        Store.getStoreData('rutypename').forEach((doc) => {
            // if (documentype.includes(doc.id.toString())) {
            //     doc.selected = true;
            // } else {
            //     doc.selected = false;
            // }
            if (doc.id in doctypesObject) {
                doc.selected = true;
            } else {
                doc.selected = false;
            }
            document_types.push({
                id: doc.id,
                name: doc.documentname,
                selected: doc.selected,
                show: true
            }); 
        })
        document_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("document_types", document_types);

        var regulatortypeName = objectdata.ragulatorName === null ? "" : objectdata.ragulatorName;
        let regtype = Store.getStoreData('ragulatorsname'); 
        regtype.sort(function(a,b) {return (a.ragulatorname > b.ragulatorname) ? 1 : ((b.ragulatorname > a.ragulatorname) ? -1 : 0);} );

        let selected_gc = '0'
        let isGroupDistributioncheck = false;
        let gc_filters = Store.getStoreData('gc_filters');
        if(isGroup){
            if(gcid.toString() in gc_filters){
                isGroupDistributioncheck = true;
                selected_gc = gcid;
            }            
        }

        //new concept
                     
        let updateContact = [];
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            contacts.forEach((item) => {
                if(item.ID in gc_users){
                    updateContact.push(item); 
                }
            })                                                                     
        }else{                                    
            updateContact = contacts;
        }
        console.log("contacts===>", updateContact)
        //new concept

        //rcm v5
        let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        let reason_for_dismissal = [{'isActive': 1, 'isDefaultcode': 0, 'isDelete': 0, 'reasonID': 1111, 'reasonName': "Other"}];   
        let selected_reason_for_dismissal = "";     
        for(let d of reasondismissal){
            if(d.isActive === 1 && d.isDelete === 0){
                reason_for_dismissal.push(d);
            }
            if(d.isDelete === 0 && d.isDefaultcode === 1){
                selected_reason_for_dismissal = d.reasonName;
            }
        }
        reason_for_dismissal.sort(function(a,b) {return (a.reasonName > b.reasonName) ? 1 : ((b.reasonName > a.reasonName) ? -1 : 0);} );

        console.log("reason_for_dismissal===>", reason_for_dismissal)        
        //rcm v5

        Store.updateStore("shareUserAllnew", shareUserAll)
        this.setState({ tags: tags,searchFilterTags: tags,sharedata: sarray,searchUserShareList: sarray, shareAcknowledgeMentdata: sAcknarray,searchUserShareAcknowList: sAcknarray, shareobject,shareUserAll,shareUserInfo, shareUserAcknowledge,submitedShareUserList, send_mail_config,task_json,
            documentname: dname,documentype: documentype,documentTypes: doctype,
            uploaddate: uploaddate, keyDatesValue: keyDates, externalLink: externalLinks,due_by: dateStr,
            // assigned_to: updateContact.length !== 0 ? updateContact[0].ID : 0,
            groupcompanyName: gcname,
            reasonCode: reasonCode,newReasonCode: newreason,distributionLists:distributionLists, gcompanyUsers: companyUsers, isGroup: isGroup, topics, sections, ready: true, document_types,
            ragulatortypeName: regulatortypeName, regulatorType: regtype, selected_gc: selected_gc, isGroupDistributioncheck: isGroupDistributioncheck,
            reason_for_dismissal,
            selected_reason_for_dismissal});
    }    
    
    fetchexternalLinkValue = (linkUrl,linkDescription) => {
        console.log(linkUrl,linkDescription)
        if(linkUrl != '') {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let links = {};
            links.id = randNo; 
            links.url = linkUrl;
            links.description = linkDescription;
            this.state.externalLink.push(links);
        }        
        this.setState({externalLink: this.state.externalLink, showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    fetchEditexternalLinkValue = (content) => {
        console.log("content",content)              
        if(content.url != '')
        {
            let newExLinks = [];
            this.state.externalLink.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newExLinks.push(item);
            })            
		    this.setState({externalLink: newExLinks, showAddLinkModal: false, hiddenAddButtonLink: true})
        }  
    }  
    // fetchKeyValue = (keyDate,keyDescription) => {
    //     console.log(keyDate,keyDescription)        
    //     if(keyDescription != '')
    //     {
    //         let keys = {}
    //         keys.date = keyDate;
    //         keys.description = keyDescription;
    //         this.state.keyDatesValue.push(keys)
    //     }
    //     console.log(this.state.keyDatesValue)
    //     //keyDatesValue
    //     this.setState({keyDatesValue: this.state.keyDatesValue})
    // } 
    fetchKeyValue = (keyDate,keyDescription) => {
        console.log(keyDate,keyDescription) 
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let keys = {};
            keys.id = randNo; 
            keys.date = keyDate;
            keys.description = keyDescription;            
            this.state.keyDatesValue.push(keys)
        console.log(this.state.keyDatesValue)
        this.setState({keyDatesValue: this.state.keyDatesValue, showAddKeyDateModal: false, hiddenAddButton: true})
    }

    refreshEditKeyValue = (content) => {
        console.log("content",content)  
            let newkeydate = [];
            this.state.keyDatesValue.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newkeydate.push(item);
            })            
		    this.setState({keyDatesValue: newkeydate, showAddKeyDateModal: false, hiddenAddButton: true})        
    }

    closeRegulatoryModal = (event) => {
        this.props.closeModal();
    }
    closeShareModal = (event) => {

        let objectdata = Store.getStoreData('task');        
        let shareobject = {};
        let shareUserAll = {};
        let shareUserInfo = {};
        let shareUserAcknowledge = {};        
        if('shareobject' in objectdata){
            shareobject = objectdata.shareobject;
        }
        if('shareUserAll' in objectdata){
            shareUserAll = objectdata.shareUserAll;
        }        
        if("shareUserInfo" in objectdata) {
            shareUserInfo = objectdata.shareUserInfo;
        }
        if("shareUserAcknowledge" in objectdata) {
            shareUserAcknowledge = objectdata.shareUserAcknowledge;
        }  
        
        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')
        
        let task = Store.getStoreData('this_task_detail');
        let task_json = JSON.parse(task.task.parenttask.task_json);
        let gcid = (parseInt(task_json.task_data.group_company_id)%100000)
        let sarray = [];
        let newsarray = [];
        let sAcknarray = [];
        let newsAcknarray = [];
        if('group_company_id' in task_json.task_data &&  task_json.task_data.group_company_id !== 0){
            let gc_company = Store.getStoreData('gc_company');        
            //console.log("gc_company", gc_company);
            let companyUsers = {};
            for(let gc of gc_company) {            
                if(parseInt(gc.id) === parseInt(gcid)){
                    companyUsers = gc.users; 
                }
            }
            console.log("gc_company", companyUsers);
            //info
            let sharedataAll = Store.getStoreData('shareuserlist'); 
            for(let i=0;i<sharedataAll.length;i++){ 
                if(!(sharedataAll[i].id.toString() in shareUserAll)){                                
                //}else{
                    newsarray.push(sharedataAll[i]);                
                }
            }
            newsarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            console.log("newsarray", newsarray);
            let updateSarray = [];
            newsarray.forEach((item) => {
                if (item.id in companyUsers) {
                    updateSarray.push(item);
                }			
            })
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updateSarray.forEach((item) => {
                    if(item.id in gc_users){
                        sarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sarray = updateSarray;
            }
            //new end
            //ack
            let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');            
            for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){            
                if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){                                
                //}else{
                    newsAcknarray.push(shareAcknowledgeMentdataAll[i]);                
                }            
            }
            newsAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            console.log("newsAcknarray", newsAcknarray);
            let updatesAcknarray = [];     
            newsAcknarray.forEach((item) => {
                if(item.id in companyUsers){
                    updatesAcknarray.push(item); 
                }
            }) 
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updatesAcknarray.forEach((item) => {
                    if(item.id in gc_users){
                        sAcknarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sAcknarray = updatesAcknarray;
            }
            //new end
        }else{
            let sharedataAll = Store.getStoreData('shareuserlist');  
            let updateSarray = [];
            for(let i=0;i<sharedataAll.length;i++){
                //console.log("sourav123",sharedata[i].id);
                if(!(sharedataAll[i].id.toString() in shareUserAll)){
                //}else{
                    updateSarray.push(sharedataAll[i]);
                }                
            }
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updateSarray.forEach((item) => {
                    if(item.id in gc_users){
                        sarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sarray = updateSarray;
            }
            //new end
            sarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            console.log("sarray", sarray); 
            let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');
            let updatesAcknarray = [];            
            for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){                
                if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){
                //}else{
                    updatesAcknarray.push(shareAcknowledgeMentdataAll[i]);                    
                }                
            }
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                updatesAcknarray.forEach((item) => {
                    if(item.id in gc_users){
                        sAcknarray.push(item); 
                    }
                })                                                                     
            }else{                                    
                sAcknarray = updatesAcknarray;
            }
            //new end
            sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            console.log("sAcknarray", sAcknarray);
        }
        sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        this.setState({shareModal: false, bottom_Buttons_panel: true, sharedata: sarray,searchUserShareList: sarray,
            shareAcknowledgeMentdata: sAcknarray,searchUserShareAcknowList: sAcknarray,
            shareobject,shareUserAll,shareUserInfo, shareUserAcknowledge,showDistributionShareUser: false,distributionCode: 0})
    }    
    addResonforDismiss = (event) => {
        event.preventDefault();
        // if(Store.getStoreData('parent_task_id') > 0) {            
        //     this.setState({resonforaction: true,bottom_Buttons_panel: false,lane: "COMP_RCM_FOLLOWUP_DISMISS"})
        // }else{
        //     this.setState({resonforaction: true,bottom_Buttons_panel:false,followuptask: false, assign_note: "", due_by: "", assigned_to: 0, lane: "COMP_RCM_DISMISS"});            
        // }
        if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal && Store.getStoreData('module_config').general.require_reason_code_for_dismissal){
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 1}}
            this.setState({alert_param: alert_param, assign_note: "", due_by: "", assigned_to: 0, lane: "COMP_RCM_DISMISS"})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === true && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 2}}
            this.setState({alert_param: alert_param, assign_note: "", due_by: "", assigned_to: 0, lane: "COMP_RCM_DISMISS"})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === true){
            this.setState({isShowDismissalDropdown: true, isReasonForDismissalShow: false, assign_note: "", due_by: "", assigned_to: 0, lane: "COMP_RCM_DISMISS"})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
            this.setState({isShowDismissalDropdown: false, isReasonForDismissalShow: true, assign_note: "", due_by: "", assigned_to: 0, lane: "COMP_RCM_DISMISS"})
        }
    }

    submitResonforDismissForDropdown = (event) => {
        event.preventDefault();   
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 3}}
        this.setState({alert_param: alert_param})
    }

    submitResonforDismissNormal = (event) => {
        event.preventDefault();   
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
            alertHandler: this.dismissRUTask, stack: {isChecking: 4}}
        this.setState({alert_param: alert_param})
    }

    dismissRUTask = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})  

        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;        
    
        let task_json = JSON.parse(task.task.parenttask.task_json);
        
        let rufeed = Store.getStoreData('task');
        let summarydata = window.decodeURIComponent(atob(rufeed.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');

        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }  
        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        const upload_date_obj = new Date(this.state.uploaddate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        const upload_datenew = `${upload_year}/${upload_month}/${upload_day}`;

        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })

        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].tags = tags;   
        task_json['object_data'].documentName = this.state.documentname;
        task_json['object_data'].documentTypes = doctypeArray;
        //task_json['object_data'].uploadDate = this.state.uploaddate;
        task_json['object_data'].issueDate = upload_datenew;
        task_json['object_data'].summary = btoa(window.encodeURIComponent(summary));
        task_json['object_data'].sections = sections;
        task_json['object_data'].externalLinks = this.state.externalLink;
        task_json['object_data'].keyDates = this.state.keyDatesValue; 
        task_json['object_data'].ragulatorName = this.state.ragulatortypeName;
        task_json['object_data'].issuingBodies = [this.state.ragulatortypeName];    

        //rcm v5
        let dissmiss_reason_text = "";
        let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        if(stack.isChecking === 1){
            for(let d of reasondismissal){
                if(d.isActive === 1 && d.isDelete === 0 && d.isDefaultcode === 1){
                    dissmiss_reason_text = d.reasonName;
                }
            }
        }else if(stack.isChecking === 2){
            dissmiss_reason_text = "N/A"
        }else if(stack.isChecking === 3){
            dissmiss_reason_text = this.state.selected_reason_for_dismissal;
            if(this.state.selected_reason_for_dismissal === 'Other'){
                dissmiss_reason_text = this.state.dismiss_note;
            }
            
        }else if(stack.isChecking === 4){
            dissmiss_reason_text = this.state.dismiss_note;
        }
        //rcm v5

        task_json['action_data'] = {task_id: Store.getStoreData('current_task_id'),actiondate: Date.now(),cur_lane: this.state.lane,assigned_to: this.state.assigned_to,action: "COMP_RCM_DISMISS",note: dissmiss_reason_text, performed_by: Store.getStoreData('loggedincontact')};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processsSaveClientRegulatorychange);
    }

    dismissResonformultiple = (resonName) => (event) => {
        event.preventDefault();        
        this.setState({resonforaction: false, followuptask: false,reasonforcomplete: false,bottom_Buttons_panel:true})
    }
    addFollowupActionsection = (event) => {
        event.preventDefault();
        if(Store.getStoreData('parent_task_id') > 0) {
            this.setState({resonforaction: false, bottom_Buttons_panel: false, reasonforcomplete: true, dismiss_note: "", lane: "COMP_RCM_FOLLOWUP_COMPLETE"});
        }else{

            let all_contacts = Store.getStoreData('contacts');
            let newContacts = [];
            if(this.state.isGroup){
                all_contacts.forEach((item) => {
                    if(item.ID in this.state.gcompanyUsers){
                        newContacts.push(item); 
                    }
                }) 
            }else{
                newContacts = all_contacts;
            }
            let ismsg = false;
            let messageVal = "";
            if(newContacts.length === 0){
                //alert("Sorry, no user found to assign mark for action.");
                ismsg = true;
                messageVal = "Sorry, no user found to assign mark for action.";                
            }
            if(ismsg){
                let alert_param = {title: 'Alert', message: messageVal, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationDismissClientRegulatory, stack: {}}
                this.setState({alert_param: alert_param})
            }else{
                this.processAddFollowupActionsection(false, {})
            }
            
        }
        
    }

    processAddFollowupActionsection = (result, stack) => {
        console.log(result,stack)        
        this.setState({alert_param: null, resonforaction: false, bottom_Buttons_panel: false, followuptask: true, dismiss_note: "", lane: "COMP_RCM_ACTION"});
    }
    
    handleOnChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    handleOnChangeDistribution = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
        let isshowdisModal = true;
        if(event.target.value.toString() === "0"){
            isshowdisModal = false;
        }

        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')

        let distributionObject = {};
		this.state.distributionLists.forEach((item) => {
            console.log("shareobjec",item.distributionID,"event.target.value",event.target.value)
			if (item.distributionID === parseInt(event.target.value)) {
				distributionObject = item.assingnedUserID
			}
        })
        console.log("distributionObject", distributionObject); 
        //start share data
        let objectdata = Store.getStoreData('task');
        let shareUserAll = Store.getStoreData('shareUserAllnew');
        //let {shareUserAll, submitedShareUserList,shareUserInfo, shareUserAcknowledge,sharedata, shareAcknowledgeMentdata} = this.state;        
        if('shareUserAll' in objectdata){
            shareUserAll = objectdata.shareUserAll;
        }
        let sharedataAll = Store.getStoreData('shareuserlist');        
        let sarray = [];
        for(let i=0;i<sharedataAll.length;i++){            
            if(sharedataAll[i].id.toString() in shareUserAll){                                
            }else{
                sarray.push(sharedataAll[i]);                
            }
        }
        sarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        console.log("sarray", sarray); 

        let newsharedata = [];
        if(this.state.isGroup){
            let update_newsharedata = [];
            sarray.forEach((item) => {
                if (item.id in distributionObject && item.id in this.state.gcompanyUsers) {                    
                    update_newsharedata.push(item);
                }			
            })
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                update_newsharedata.forEach((item) => {
                    if(item.id in gc_users){
                        newsharedata.push(item); 
                    }
                })                                                                     
            }else{                                    
                newsharedata = update_newsharedata;
            }
            //new end            
        }else{
            let update_newsharedata = [];
            sarray.forEach((item) => {
                if (item.id in distributionObject) {
                    update_newsharedata.push(item);
                }			
            })
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                update_newsharedata.forEach((item) => {
                    if(item.id in gc_users){
                        newsharedata.push(item); 
                    }
                })                                                                     
            }else{                                    
                newsharedata = update_newsharedata;
            }
            //new end
        }
        //end
        //start acknow data
        let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');
        let sAcknarray = [];
        for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){            
            if(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll){                                
            }else{
                sAcknarray.push(shareAcknowledgeMentdataAll[i]);                
            }            
        }
        sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("sAcknarray", sAcknarray);

        let newshareAcknowledgeMentdata = [];
        let shareobject = {}
        let subUserList = this.state.submitedShareUserList        
        for(let i=0;i<subUserList.length;i++){
            shareobject[subUserList[i].id] = 1;
        }
        let shareUserAcknowledgeUpdate = Store.getStoreData('shareUserAcknowledgeUpdate') === null ? {} : Store.getStoreData('shareUserAcknowledgeUpdate');
        if(this.state.isGroup){
            let update_newshareAcknowledgeMentdata = [];
            sAcknarray.forEach((item) => {
                if(item.id in distributionObject && item.id in this.state.gcompanyUsers){
                    update_newshareAcknowledgeMentdata.push(item);
                    // if(this.state.button_type === 'acknow'){
                    //     shareUserAll[item.id] = 1;
                    //     shareobject[item.id] = 1;
                    //     let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                    //     shareUserAcknowledgeUpdate[item.id] = val2;
                    // }
                }
            }) 
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                update_newshareAcknowledgeMentdata.forEach((item) => {
                    if(item.id in gc_users){
                        newshareAcknowledgeMentdata.push(item); 
                        if(this.state.button_type === 'acknow'){
                            shareUserAll[item.id] = 1;
                            shareobject[item.id] = 1;
                            let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                            shareUserAcknowledgeUpdate[item.id] = val2;
                        }
                    }
                })                                                                     
            }else{                                    
                update_newshareAcknowledgeMentdata.forEach((item) => {                    
                    newshareAcknowledgeMentdata.push(item); 
                    if(this.state.button_type === 'acknow'){
                        shareUserAll[item.id] = 1;
                        shareobject[item.id] = 1;
                        let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                        shareUserAcknowledgeUpdate[item.id] = val2;
                    }                    
                }) 
            }
        }else{
            let update_newshareAcknowledgeMentdata = [];
            sAcknarray.forEach((item) => {
                if(item.id in distributionObject){
                    update_newshareAcknowledgeMentdata.push(item);
                    // if(this.state.button_type === 'acknow'){
                    //     shareUserAll[item.id] = 1;
                    //     shareobject[item.id] = 1;
                    //     let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                    //     shareUserAcknowledgeUpdate[item.id] = val2;
                    // }
                }
            }) 
            //new
            if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                update_newshareAcknowledgeMentdata.forEach((item) => {
                    if(item.id in gc_users){
                        newshareAcknowledgeMentdata.push(item); 
                        if(this.state.button_type === 'acknow'){
                            shareUserAll[item.id] = 1;
                            shareobject[item.id] = 1;
                            let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                            shareUserAcknowledgeUpdate[item.id] = val2;
                        }
                    }
                })                                                                     
            }else{                                    
                update_newshareAcknowledgeMentdata.forEach((item) => {                    
                    newshareAcknowledgeMentdata.push(item); 
                    if(this.state.button_type === 'acknow'){
                        shareUserAll[item.id] = 1;
                        shareobject[item.id] = 1;
                        let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                        shareUserAcknowledgeUpdate[item.id] = val2;
                    }                    
                }) 
            }

        }        
        //end       
        console.log("shareobject",shareUserAcknowledgeUpdate)
        console.log("newsharedata",newsharedata)
        this.setState({sharedata: newsharedata,shareAcknowledgeMentdata: newshareAcknowledgeMentdata,searchUserShareList: newsharedata,searchUserShareAcknowList: newshareAcknowledgeMentdata, showDistributionShareUser: isshowdisModal, shareUserAll: shareUserAll, shareobject: shareobject, shareUserAcknowledge: shareUserAcknowledgeUpdate});
    } 

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})               
    }
    
    saveFollowupTaskchange = (event) => {
        event.preventDefault(); 

        if(this.state.assigned_to === 0){
            let alert_param = {title: 'ERROR', message: 'Please select a user to assign the task to.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        } 

        let moduleconfig = Store.getStoreData('module_config');   
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let problems = false
        let message = ''
        if('tagValue' in moduleconfig.general && Object.keys(moduleconfig.general.tagValue).length === 0){
            //alert("There are no tags available. Please add tags within Manage Module to continue.");
            problems = true
            message += "There are no tags available. Please add tags within Manage Module to continue.<br>"
        }        
        if (tags.length === 0) {            
            //alert("Please select atleast one tag.");
            problems = true
            message += "Please select atleast one tag.<br>"
        }
        if(problems) {
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                           alertHandler: this.aftercheckvalidationFollowupTask, stack: {}}

            this.setState({alert_param: alert_param})            
        } else {
            this.aftercheckvalidationFollowupTask(false, {})
        }
    }

    aftercheckvalidationFollowupTask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        if(!result){  
            let tags = [];
            this.state.tags.forEach((tag) => {
                if (tag.selected) tags.push(tag.id);
            })
            let parenttaskdetails = Store.getStoreData('this_task_detail');
            let task_json_parent = JSON.parse(parenttaskdetails.task.parenttask.task_json);
            let rufeed = Store.getStoreData('task');
            let regulatorName = "";
            if('ragulatorName' in rufeed){            
                regulatorName = this.state.ragulatortypeName.replace(/(&rsquo;)/g, "\'");
                regulatorName = regulatorName.replace(/'+/g,"'");
            }
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];        
            
            let doctypeArray = [];
            for(let i=0;i<this.state.document_types.length;i++){
                if(this.state.document_types[i].selected){
                    doctypeArray.push(this.state.document_types[i].id.toString()) 
                }
            }

            let ref_id = Utils.genKey(12);
            
            let parrent_task_created_date = moment(parenttaskdetails.task.parenttask.created_at).unix();
            if('AutoFordardJwGSelectdata' in rufeed && rufeed.AutoFordardJwGSelectdata === 1){
                parrent_task_created_date = moment(rufeed.createdDate).unix();
            }

            let task = {
                "task_data": { "module_id": "rcm", "last_action": "COMP_RCM_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assigned_to, 'task_type' : 'COMP_RCM_FOLLOWUP', 'parent_task_id' : Store.getStoreData('current_task_id'), 'cur_lane': 'COMP_RCM_FOLLOWUP_ASSIGNMENT', due_date: this.state.due_by},
                "object_data": {documentName: this.state.documentname,documentTypes: doctypeArray,ragulatorName: regulatorName, uploadDate: rufeed.uploadDate, summary :rufeed.summary, note: this.state.assign_note, due_by: this.state.due_by, cur_view: 'Unopened','ptAddedDate': parrent_task_created_date, issueDate: task_json_parent.object_data.issueDate},
                "action_data": [{ "action": "COMP_RCM_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_RCM_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.assign_note }]
            };
            let postData = { "command": "createfollowuptask", "task": task, bin_files: bin_files, parentTaskId: Store.getStoreData('current_task_id') };
            console.log("task follow up", postData);
            let api = new APICall();
            this.setState({isLoaderIcon: true})
            api.command(postData, this.processAddFollowupTask);
        }        
    }
    
    processAddFollowupTask = (result) => {
        //alert("This Regulatory Change has been successfully marked for action.");
        let alert_param = {title: 'Success', message: "The regulatory change has been marked for action.", ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateProcessAddFollowupTask, stack: {}}
        this.setState({alert_param: alert_param}) 
    }

    updateProcessAddFollowupTask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let action_data = {
            task_id: Store.getStoreData('current_task_id'),
            cur_lane: "COMP_RCM_ACTION",
            assigned_to: 0,
            action: "COMP_RCM_ASSIGN",
            note: this.state.assign_note,
            due_date: this.state.due_by,
            followup_assign_to: this.state.assigned_to
        };        
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        }) 
        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }
        const upload_date_obj = new Date(this.state.uploaddate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        const upload_datenew = `${upload_year}/${upload_month}/${upload_day}`;

        let rufeed = Store.getStoreData('task');
        let summarydata = window.decodeURIComponent(atob(rufeed.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');        
        //console.log("summary", summary)  
        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }
        
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let task_json = JSON.parse(task.task.parenttask.task_json); 
        task_json['object_data'].tags = tags;
        task_json['object_data'].documentName = this.state.documentname;
        task_json['object_data'].ragulatorName = this.state.ragulatortypeName;
        task_json['object_data'].issuingBodies = [this.state.ragulatortypeName];
        task_json['object_data'].documentTypes = doctypeArray;
        task_json['object_data'].issueDate = upload_datenew;
        task_json['object_data'].summary = btoa(window.encodeURIComponent(summary));
        task_json['object_data'].sections = sections;
        task_json['object_data'].externalLinks = this.state.externalLink;
        task_json['object_data'].keyDates = this.state.keyDatesValue;
        task_json['action_data'] = action_data;
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id, send_mail: false};
        let api = new APICall();        
        api.command(postData, this.processsAddFollowupParentTask);  
    }

    processsAddFollowupParentTask = (result) => {
        console.log("update data", result);        
        window.location.reload(true);        
    }

    dismissClientRegulatorychange = (event) => {
        event.preventDefault();
        if (this.state.lane === "COMP_RCM_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for dismissal");
            let alert_param = {title: 'ERROR', message: "Please provide a reason for dismissal", ok_text: 'OK', confirm: false, 
                           alertHandler: this.aftercheckvalidationDismissClientRegulatory, stack: {}}
            this.setState({alert_param: alert_param})   
        }else{  
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                    alertHandler: this.updateDismissClientRegulatorychange, stack: {}}
            this.setState({alert_param: alert_param})
        }
    }

    aftercheckvalidationDismissClientRegulatory = (result, stack) => {  
        this.setState({alert_param: null})        
    }

    updateDismissClientRegulatorychange = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;        
      
        let task_json = JSON.parse(task.task.parenttask.task_json);
        
        let rufeed = Store.getStoreData('task');
        let summarydata = window.decodeURIComponent(atob(rufeed.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');

        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }  
        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        const upload_date_obj = new Date(this.state.uploaddate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        const upload_datenew = `${upload_year}/${upload_month}/${upload_day}`;

        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })

        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].tags = tags;   
        task_json['object_data'].documentName = this.state.documentname;
        task_json['object_data'].documentTypes = doctypeArray;
        //task_json['object_data'].uploadDate = this.state.uploaddate;
        task_json['object_data'].issueDate = upload_datenew;
        task_json['object_data'].summary = btoa(window.encodeURIComponent(summary));
        task_json['object_data'].sections = sections;
        task_json['object_data'].externalLinks = this.state.externalLink;
        task_json['object_data'].keyDates = this.state.keyDatesValue; 
        task_json['object_data'].ragulatorName = this.state.ragulatortypeName;
        task_json['object_data'].issuingBodies = [this.state.ragulatortypeName];    
        task_json['action_data'] = {task_id: Store.getStoreData('current_task_id'),actiondate: Date.now(),cur_lane: this.state.lane,assigned_to: this.state.assigned_to,action: "COMP_RCM_DISMISS",note: this.state.dismiss_note, performed_by: Store.getStoreData('loggedincontact')};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processsSaveClientRegulatorychange);
    }    

    saveClientRegulatorychange = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        if (this.state.lane === "COMP_RCM_ASSIGNMENT") {
            //alert("Either mark for action or dismiss before saving");
            problems = true;
            message += 'Either mark for action or dismiss before saving<br>';
        }
        if (this.state.lane === "COMP_RCM_FOLLOWUP_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for followup task dismissal");
            problems = true;
            message += 'Please provide a reason for followup task dismissal<br>';
        }
        if (this.state.lane === "COMP_RCM_DISMISS" && this.state.dismiss_note === "") {
            //alert("Please provide a reason for dismissal");
            problems = true;
            message += 'Please provide a reason for dismissal<br>';
        }
        
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationDismissClientRegulatory, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{            
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                    alertHandler: this.updatesaveClientRegulatorychange, stack: {}}
            this.setState({alert_param: alert_param})
        }        
    }

    updatesaveClientRegulatorychange = (result, stack) => {   
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let action_data = {
            task_id: Store.getStoreData('current_task_id'),
            cur_lane: this.state.lane,
            assigned_to: this.state.assigned_to,
            action: this.state.lane === "COMP_RCM_ACTION" ? "COMP_RCM_ASSIGN" : "COMP_RCM_DISMISS",
            note: this.state.lane === "COMP_RCM_ACTION" ? this.state.assign_note : this.state.dismiss_note,
            performed_by: Store.getStoreData('loggedincontact'),
            //due_date: this.state.due_by
        };
        if(this.state.due_by !== ""){
            action_data.due_date = this.state.due_by
        }
        if(Store.getStoreData('parent_task_id') > 0){
            action_data = {
                task_id: Store.getStoreData('current_task_id'),
                cur_lane: this.state.lane,
                assigned_to: 0,
                action: this.state.lane === "COMP_RCM_FOLLOWUP_COMPLETE" ? "COMP_RCM_FOLLOWUP_COMPLETE" : "COMP_RCM_FOLLOWUP_DISMISS",
                note: this.state.lane === "COMP_RCM_FOLLOWUP_DISMISS" ? this.state.dismiss_note : this.state.complete_note,
            };
        }
        
        let postData = { "command": "addactiontotask", "SystemID": 1001, action_data: action_data, task_id: Store.getStoreData('current_task_id')};        
        let api = new APICall();    
        this.setState({isLoaderIcon: true})    
        api.command(postData, this.processsSaveClientRegulatorychange);
    }    

    processsSaveClientRegulatorychange = (result) => {
        console.log("update data", result); 
        if(this.state.lane === "COMP_RCM_DISMISS"){
            //alert("This Regulatory Update has been dismissed");
            let alert_param = {title: 'Success', message: "The regulatory change has been dismissed.", ok_text: 'OK', confirm: false, 
                           alertHandler: this.processSaveClientRegulatorychange, stack: {}}
            this.setState({alert_param: alert_param})
        }else{
            //alert(result.error_msg);
            let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false, 
                           alertHandler: this.processSaveClientRegulatorychange, stack: {}}
            this.setState({alert_param: alert_param})
        }       
        // window.location.reload(true);
        // this.setState({isLoaderIcon: false})
    }

    processSaveClientRegulatorychange = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }

    onDateChange = (date) => { 
        if (!moment.isDate(date)) {
			return;
		}       
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        this.setState({due_by: dateStr, date: date});
    }
    processMyExternalLink = () => {
        var linksdata = this.state.externalLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        // {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {'Header' : 'URL', accessor: 'url_value', Cell: row => (
            <div>
                <a target="_blank" href={row.original.url_value}>{row.original.url_value}</a>
            </div>
          ), minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (            
            <div>
                {
                (()=> {
                    if(this.state.editsection){
                        return(<div>
                            <FaPencilAlt onClick={this.openEditExLinkModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                            <FaTimes onClick={this.deleteExternalLink(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/>
                        </div>)
                    }
                })() 
            }                
            </div>            
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of linksdata) {           
            i++;
            //console.log("window.location.protocol===>",window.location.protocol)
            let url = <a style={{display: "table-cell"}} href={d.url} target="_blank" >{d.url}</a>                      
            let elem = {'index' : i,
                    'id': d.id, 
                    'url_value' : url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    } 

    deleteExternalLink = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newexLink = [];
			for(let i=0;i<this.state.externalLink.length;i++)
			{
				if(this.state.externalLink[i].id !== id){
					newexLink.push(this.state.externalLink[i])
				}
			}			 
			console.log("delete===>",newexLink);   
            this.setState({externalLink: newexLink})      
		//}         		  
    }

    openEditExLinkModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const contentLink = this.state.externalLink.find((item) => {
			return item.id === id;
		})        
		this.setState({contentLink,curLinkid: id,showAddLinkModal: true, hiddenAddButtonLink: false});        
    }

    processMykeyData = () => {
        var keysdata = this.state.keyDatesValue;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                {
                (()=> {
                    if(this.state.editsection){
                        return(<div>
                            <FaPencilAlt onClick={this.openEditKeyDateModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                            <FaTimes onClick={this.deleteKeyDate(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
                            </div>)
                    }
                })() 
            }
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteKeyDate = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newkeydate = [];
			for(let i=0;i<this.state.keyDatesValue.length;i++)
			{
				if(this.state.keyDatesValue[i].id !== id){
					newkeydate.push(this.state.keyDatesValue[i])
				}
			}			 
			console.log("delete===>",newkeydate);   
            this.setState({keyDatesValue: newkeydate})      
		//}         		  
    }

    openEditKeyDateModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.keyDatesValue.find((item) => {
			return item.id === id;
		})
		this.setState({content,curKeyid: id,showAddKeyDateModal: true, hiddenAddButton: false});        
	}  
    selectUser = (event) => {        
        let index = event.target.value;
        let userdata = this.state.sharedata;
        var userdataacknowledgement = this.state.shareAcknowledgeMentdata;
        if(!this.state.acknowledgeModal) {
            if(userdata[index].select){            
                userdata[index].foracknowledgement = true;
                userdata[index].forinformation = true;
                userdata[index].select = false;
                userdata[index].forinformationCheck = false;
                userdata[index].foracknowledgementCheck = false;
                delete this.state.shareobject[userdata[index].id.toString()];
                delete this.state.shareUserAll[userdata[index].id.toString()];
                delete this.state.shareUserInfo[userdata[index].id.toString()];
                delete this.state.shareUserAcknowledge[userdata[index].id.toString()]
            }else{
                let userid = userdata[index].id.toString();
                let val = 1;            
                this.state.shareobject[userid] = val;
                this.state.shareUserAll[userid] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};
                this.state.shareUserInfo[userid] = val2;

                userdata[index].foracknowledgement = false;
                userdata[index].forinformation = false;
                userdata[index].select = true;
                userdata[index].forinformationCheck = true;
                userdata[index].foracknowledgementCheck = false;
            } 
            this.setState({sharedata: userdata, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo,rowchecked: false})
            let allselectcheck = true
            for(let d of userdata) {                
                if(!d.select){
                    allselectcheck = false
                }
            }
            if(allselectcheck){
                this.setState({rowchecked: true})
            }
        } else {
            //console.log("userdata[index].select",userdataacknowledgement[index])
            if(userdataacknowledgement[index].select){
                userdataacknowledgement[index].select = false;
                userdataacknowledgement[index].foracknowledgement = true;
                userdataacknowledgement[index].foracknowledgementCheck = false;
                delete this.state.shareobject[userdataacknowledgement[index].id.toString()];
                delete this.state.shareUserAll[userdataacknowledgement[index].id.toString()];
                delete this.state.shareUserAcknowledge[userdataacknowledgement[index].id.toString()];                
            } else {
                userdataacknowledgement[index].select = true;
                userdataacknowledgement[index].foracknowledgement = false;
                userdataacknowledgement[index].foracknowledgementCheck = true;
                let userid = userdataacknowledgement[index].id.toString();
                let val = 1;            
                this.state.shareobject[userid] = val;
                this.state.shareUserAll[userid] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};
                this.state.shareUserAcknowledge[userid] = val2;                
            }
            this.setState({shareAcknowledgeMentdata: userdataacknowledgement, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserAcknowledge: this.state.shareUserAcknowledge,rowchecked: false})
            let allselectcheck = true
            for(let d of userdataacknowledgement) {                
                if(!d.select){
                    allselectcheck = false
                }
            }
            if(allselectcheck){
                this.setState({rowchecked: true})
            }
        }
        console.log("userid:-",this.state.shareobject);
        console.log("useridAll:-",this.state.shareUserAll);
        console.log("shareuserinfo:-",this.state.shareUserInfo);
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
    }
    selectinformation = (event) => {        
        let index = event.target.value;
        let userdata = this.state.sharedata;
        if(userdata[index].forinformationCheck){            
            userdata[index].forinformationCheck = false;
            userdata[index].foracknowledgementCheck = true;
            let userid = userdata[index].id.toString();
            let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};            
            this.state.shareUserAcknowledge[userid] = val;
            delete this.state.shareUserInfo[userdata[index].id.toString()];
            //shareUserAcknowledge            
        }else{  
            let userid = userdata[index].id.toString();
            let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};            
            this.state.shareUserInfo[userid] = val; 
            delete this.state.shareUserAcknowledge[userdata[index].id.toString()];          
            userdata[index].forinformationCheck = true;  
            userdata[index].foracknowledgementCheck = false;          
        }        
        this.setState({sharedata: userdata, shareUserInfo:this.state.shareUserInfo,shareUserAcknowledge:this.state.shareUserAcknowledge})
        console.log("shareuserinfo:-",this.state.shareUserInfo);
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
    }
    selectacknowledgement = (event) => {
        if(!this.state.acknowledgeModal) {        
            let index = event.target.value;
            let userdata = this.state.sharedata;
            if(userdata[index].foracknowledgementCheck){            
                userdata[index].foracknowledgementCheck = false;
                userdata[index].forinformationCheck = true;
                let userid = userdata[index].id.toString();
                let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};            
                this.state.shareUserInfo[userid] = val; 
                delete this.state.shareUserAcknowledge[userdata[index].id.toString()];            
            }else{  
                let userid = userdata[index].id.toString();
                let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                this.state.shareUserAcknowledge[userid] = val;
                delete this.state.shareUserInfo[userdata[index].id.toString()];           
                userdata[index].foracknowledgementCheck = true;
                userdata[index].forinformationCheck = false;            
            }
            this.setState({sharedata: userdata, shareUserAcknowledge:this.state.shareUserAcknowledge,shareUserInfo:this.state.shareUserInfo})
        }
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
        console.log("shareuserinfo:-",this.state.shareUserInfo);
    }

    selectAll = (event) => {
         let rowchecked = this.state.rowchecked;
         rowchecked = rowchecked ? false : true;
         let userdata = this.state.sharedata;
         let userdataacknowledgement = this.state.shareAcknowledgeMentdata;         
         if(!this.state.acknowledgeModal) { 
         if(rowchecked) {   
             console.log("Sourav")
            let i = 0;
            console.log(userdata);
            let selectArray = [];
            for(let d of userdata) {
                let selectObject = {}           
                i++;
                let val = 1;            
                this.state.shareobject[d.id] = val;
                this.state.shareUserAll[d.id] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                this.state.shareUserInfo[d.id] = val2;
                selectObject.id = d.id;
                selectObject.name = d.name;
                selectObject.email = d.email;
                selectObject.role = d.role;
                selectObject.select = true;
                selectObject.foracknowledgement = false;
                selectObject.forinformation = false;
                selectObject.foracknowledgementCheck = false;
                selectObject.forinformationCheck = true;                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,sharedata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})  
            console.log(selectArray);
        }else{
            console.log("Sourav12")
            let i = 0;
            console.log(userdata);
            let selectArray = [];
            for(let d of userdata) {
                let selectObject = {}           
                i++;
                delete this.state.shareobject[d.id.toString()];
                delete this.state.shareUserAll[d.id.toString()];
                delete this.state.shareUserInfo[d.id.toString()];                
                selectObject.id = d.id;
                selectObject.name = d.name;
                selectObject.email = d.email;
                selectObject.role = d.role;
                selectObject.select = false;
                selectObject.foracknowledgement = true;
                selectObject.forinformation = true;
                selectObject.foracknowledgementCheck = false;
                selectObject.forinformationCheck = false;                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,sharedata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})  
            console.log(selectArray);
        }
    }else{
        if(rowchecked) {   
            let i = 0;           
            let selectArray = [];
            for(let d of userdata) {
                let selectObject = {}           
                i++;
                let val = 1;            
                this.state.shareobject[d.id] = val;
                this.state.shareUserAll[d.id] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                this.state.shareUserAcknowledge[d.id] = val2;
                selectObject.id = d.id;
                selectObject.name = d.name;
                selectObject.email = d.email;
                selectObject.role = d.role;
                selectObject.select = true;
                selectObject.foracknowledgement = false;
                selectObject.forinformation = true;
                selectObject.foracknowledgementCheck = true;
                selectObject.forinformationCheck = false;                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,shareAcknowledgeMentdata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})  
            console.log(selectArray);
        }else{
            let i = 0;            
            let selectArray = [];
            for(let d of userdata) {
                let selectObject = {}           
                i++;
                delete this.state.shareobject[d.id.toString()];
                delete this.state.shareUserAll[d.id.toString()];
                delete this.state.shareUserAcknowledge[d.id.toString()];                
                selectObject.id = d.id;
                selectObject.name = d.name;
                selectObject.email = d.email;
                selectObject.role = d.role;
                selectObject.select = false;
                selectObject.foracknowledgement = true;
                selectObject.forinformation = true;
                selectObject.foracknowledgementCheck = false;
                selectObject.forinformationCheck = false;                
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,shareAcknowledgeMentdata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})  
            console.log(selectArray);
        }
       
    }
    }
    
    processMyshareData = () => {
        var sharedata = this.state.sharedata; 
        console.log("sharedata",sharedata);    
        var shareAcknowledgeMentdata = this.state.shareAcknowledgeMentdata;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectAll} /></div>, Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectUser}/>
            </div>
         ), width: 60, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},         
        {Header: 'Name', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Role', accessor: 'role_value', minWidth: 80, headerStyle: {textAlign: 'left'}},
        {Header: 'Email', accessor: 'email_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {'Header' : 'For Information', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.for_information_check} value={row.index} disabled={row.original.for_information} onClick={this.selectinformation}/>
            </div>
         ), width: 142, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},
         {'Header' : 'For Acknowledgement', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.for_acknowledgement_check} value={row.index} disabled={row.original.for_acknowledgement} onClick={this.selectacknowledgement}/>
            </div>
         ), width: 142, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}}];
         if(this.state.acknowledgeModal) { 
             console.log("Sourav1")            
            for(let d of shareAcknowledgeMentdata) {           
                i++;    
                let role = "";
                if(d.role === 'rcm_gatekeeper'){
                    role = "RCM Gatekeeper"
                }else if(d.role === 'manager'){
                    role = "Admin Manager"
                }else if(d.role === 'team'){
                    role = "Team"
                }                   
                let elem = {'index' : i,
                        'id': d.id, 
                        'name_value' : d.name,
                        'role_value' : role,
                        'email_value' : d.email,
                        'for_information' : d.forinformation,
                        'for_acknowledgement' : d.foracknowledgement,
                        'for_acknowledgement_check' : d.foracknowledgementCheck,
                        'for_information_check' : d.forinformationCheck,
                        'select' : d.select                    
                       };
                ret.data.push(elem);
            }
         }else {            
            for(let d of sharedata) {                
                console.log("Sourav2")          
                i++;    
                let role = "";
                if(d.role === 'rcm_gatekeeper'){
                    role = "RCM Gatekeeper"
                }else if(d.role === 'manager'){
                    role = "Admin Manager"
                }else if(d.role === 'team'){
                    role = "Team"
                }                   
                let elem = {'index' : i,
                        'id': d.id, 
                        'name_value' : d.name,
                        'role_value' : role,
                        'email_value' : d.email,
                        'for_information' : d.forinformation,
                        'for_acknowledgement' : d.foracknowledgement,
                        'for_acknowledgement_check' : d.foracknowledgementCheck,
                        'for_information_check' : d.forinformationCheck,
                        'select' : d.select                    
                       };
                ret.data.push(elem);
            }
         }
        
        return ret;
    }
    showShareModal = (event) => {
        event.preventDefault();
        let objectdata = Store.getStoreData('task');
        let {shareobject,shareUserAll, shareUserInfo, shareUserAcknowledge} = this.state;
        if('shareobject' in objectdata){
            shareobject = objectdata.shareobject;
        }
        if('shareUserAll' in objectdata){
            shareUserAll = objectdata.shareUserAll;
        }
        if("shareUserInfo" in objectdata) {
            shareUserInfo = objectdata.shareUserInfo;
        }
        if("shareUserAcknowledge" in objectdata) {
            shareUserAcknowledge = objectdata.shareUserAcknowledge;
        }
        this.setState({ button_type: 'info',shareModal: true, rowchecked:false, bottom_Buttons_panel: false, acknowledgeModal: false,shareobject,shareUserAll,shareUserInfo, shareUserAcknowledge })
    }
    showAcknowledgeMentModal = (event) => {
        event.preventDefault();
        var shareAcknowledgeMentdata = this.state.shareAcknowledgeMentdata; 
        console.log('shareUserAcknowledge', shareAcknowledgeMentdata)
        let i = 0;
        for(let d of shareAcknowledgeMentdata) {           
            i++;
            let val = 1;            
            this.state.shareobject[d.id] = val;
            this.state.shareUserAll[d.id] = val;
            let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
            this.state.shareUserAcknowledge[d.id] = val2;
        }
        //this.state.shareUserInfo = {}
        
        this.setState({ button_type: 'acknow',acknowledgeModal: true,rowchecked:true, bottom_Buttons_panel: false, shareobject: this.state.shareobject,shareUserAll: this.state.shareUserAll, shareModal: true, shareUserAcknowledge : this.state.shareUserAcknowledge})
    }
    searchUsers = (event) => {        
        let search_string = event.target.value;
        console.log("searchString===>",this.state.searchUserShareList)
        if(this.state.acknowledgeModal) {             
            if (search_string === "") {
                var shareAcknowledgeMentdata = this.state.searchUserShareAcknowList;
                this.setState({ shareAcknowledgeMentdata })
            }else {           
                let all_company_users = this.state.searchUserShareAcknowList;
                let shareAcknowledgeMentdata = [];
                all_company_users.forEach((user) => {
                    if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                        shareAcknowledgeMentdata.push(user);
                    }
                })
                this.setState({ shareAcknowledgeMentdata })
            }
        }else{        
            if (search_string === "") {
                var sharedata = this.state.searchUserShareList;
                this.setState({ sharedata })
            }else {           
                let all_company_users = this.state.searchUserShareList;
                let sharedata = [];
                all_company_users.forEach((user) => {
                    if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                        sharedata.push(user);
                    }
                })
                this.setState({ sharedata })
            }
        }
    }    
    changeAllRoles = (event) => {
        console.log(event.target.value);
        let search_role = event.target.value;
        if(this.state.acknowledgeModal) { 
            if(search_role === '0') {            
                let shareAcknowledgeMentdata = this.state.searchUserShareAcknowList
                this.setState({ shareAcknowledgeMentdata })
            }else {
                let all_company_users = this.state.searchUserShareAcknowList
                let shareAcknowledgeMentdata = [];
                all_company_users.forEach((user) => {
                    if (user.role.includes(search_role.toLowerCase())) {
                        shareAcknowledgeMentdata.push(user);
                    }
                })
                this.setState({ shareAcknowledgeMentdata })
            }
        }else{
            if(search_role === '0') {            
                let sharedata = this.state.searchUserShareList
                this.setState({ sharedata })
            }else {
                let all_company_users = this.state.searchUserShareList
                let sharedata = [];
                all_company_users.forEach((user) => {
                    if (user.role.includes(search_role.toLowerCase())) {
                        sharedata.push(user);
                    }
                })
                this.setState({ sharedata })
            }
        }        
    }
    shareUser = (event) => { 
        let problems = false
        let message = '';
        let moduleconfig = Store.getStoreData('module_config');  
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        }) 
        if('tagValue' in moduleconfig.general && Object.keys(moduleconfig.general.tagValue).length === 0){
            //alert("There are no tags available. Please add tags within Manage Module to continue.");
            problems = true;
            message += 'There are no tags available. Please add tags within Manage Module to continue.<br>';
        }
        if (tags.length === 0) {
            problems = true;
            message += 'Please select atleast one tag.<br>';
        }
        if(Object.keys(this.state.shareobject).length === 0){
            let rufeed = Store.getStoreData('task');        
            if('is_rn' in rufeed && rufeed.is_rn === 1){
                //alert("Please select at least one user to share the RN with.");
                problems = true;
                message += 'Please select at least one user to share the RN with.<br>';
            }else{
                //alert("Please select at least one user to share the RU with.");
                problems = true;
                message += 'Please select at least one user to share the RU with.<br>';
            }
        }
        
        
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationDismissClientRegulatory, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{ 
            this.updateshareUser(false, {})
        }   
    }

    updateshareUser = (result, stack) => {
        this.setState({alert_param: null})  

        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })

        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;
        let comnd_task = {}
        let module_config = Store.getStoreData('module_config');
        let mail_config = {};        
        let task_json = JSON.parse(task.task.parenttask.task_json);
        let rufeed = Store.getStoreData('task');
        let summarydata = window.decodeURIComponent(atob(rufeed.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');   
        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }
        
        let sharedataAll = Store.getStoreData('shareuserlist'); 
        let submitedShareUserarray = [];
        for(let i=0;i<sharedataAll.length;i++){            
            if(sharedataAll[i].id.toString() in this.state.shareUserAll){
                let shareUserobject = {};
                shareUserobject.id = sharedataAll[i].id
                shareUserobject.name = sharedataAll[i].name
                shareUserobject.email = sharedataAll[i].email
                shareUserobject.role = sharedataAll[i].role
                shareUserobject.acknowledgment_required = "";
                shareUserobject.acknowledgment = "";
                shareUserobject.share_resoncode = ""; 
                submitedShareUserarray.push(shareUserobject);              
            }              
        }
        
        let oldShareid = {}
        let subUserList = this.state.submitedShareUserList
        console.log("subUserList===>",this.state.shareUserAll)
        console.log("subUserList===>",subUserList)
        console.log("subUserList===>",submitedShareUserarray)
        for(let i=0;i<subUserList.length;i++){
            oldShareid[subUserList[i].id]={share_resoncode: subUserList[i].share_resoncode}
        }
        let curShareid = {}
        for(let i=0;i<submitedShareUserarray.length;i++){
            if(submitedShareUserarray[i].id in oldShareid){   
                
            }else{
                curShareid[submitedShareUserarray[i].id]=1
            }
        }

        let newsubmitedShareUserarray = [];
		submitedShareUserarray.forEach((item) => {
			if (item.id in oldShareid) {
                item.share_resoncode = 	oldShareid[item.id].share_resoncode;						
			}else{
				item.share_resoncode = this.state.reasonCode;	
			}
			newsubmitedShareUserarray.push(item);
		})

        console.log("newsubmitedShareUserarray",newsubmitedShareUserarray)
        //console.log("task_json", task_json)
        // console.log("documentname====>",this.state.documentname,"documentype==>",this.state.documentype,
        // "uploaddate===>",this.state.uploaddate,"summary====>",summary,"sections===>",sections,
        // "externalLink===>",this.state.externalLink,"keyDatesValue===>",this.state.keyDatesValue)  

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        const upload_date_obj = new Date(this.state.uploaddate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        const upload_datenew = `${upload_year}/${upload_month}/${upload_day}`;
        
        
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].tags = tags;
        task_json['object_data'].documentName = this.state.documentname;
        task_json['object_data'].documentTypes = doctypeArray;
        //task_json['object_data'].uploadDate = this.state.uploaddate;
        task_json['object_data'].issueDate = upload_datenew;
        task_json['object_data'].summary = btoa(window.encodeURIComponent(summary));
        task_json['object_data'].sections = sections;
        task_json['object_data'].externalLinks = this.state.externalLink;
        task_json['object_data'].keyDates = this.state.keyDatesValue;

        task_json['object_data'].shareobject = this.state.shareobject;
        task_json['object_data'].shareUserAll = this.state.shareUserAll; 
        task_json['object_data'].submitedShareUserList = newsubmitedShareUserarray;        
        task_json['object_data'].shareUserInfo = this.state.shareUserInfo;
        task_json['object_data'].shareUserAcknowledge = this.state.shareUserAcknowledge;
        task_json['object_data'].ragulatorName = this.state.ragulatortypeName;
        task_json['object_data'].issuingBodies = [this.state.ragulatortypeName];
        task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, assigned_to: 0 , mail_url: window.location.href, mail_config: mail_config, send_mail: this.state.send_mail_config, performed_by: Store.getStoreData('loggedincontact')};

        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id, send_mail: true, cur_shareid: curShareid };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.afterCompleted);
    }

    afterCompleted = (result) => {
        // console.log("Completed:", result);
        let rufeed = Store.getStoreData('task');      
        let message = "";  
        if('is_rn' in rufeed && rufeed.is_rn === 1){
            //alert('The RN has been shared with the selected user(s)');
            message = "The regulatory change has been shared with the selected user(s).";  
        }else{
            //alert('The RU has been shared with the selected user(s).' );
            message = "The regulatory change has been shared with the selected user(s).";
        }
        let alert_param = {title: 'Success', message: message, ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterCompleted, stack: {}}

        this.setState({alert_param: alert_param})        
        //window.location.reload(true);
    }

    updateafterCompleted = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }

    getMailStrucData = (data) => {
        let task_json = this.state.task_json;
        console.log("task_json",task_json)
        let contact_name = Store.getStoreData('contact');
        // console.log("payload", JSON.stringify(payload));
        let mail_body = data.body;       
        mail_body = mail_body.replace("{{ru_name}}", task_json.object_data.documentName);
        mail_body = mail_body.replace("{{regulator}}", contact_name);        
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace("{{ru_name}}", task_json.object_data.documentName);
        mail_subject = mail_subject.replace("{{regulator}}", contact_name);        
        data.subject = mail_subject;
        return data;
    }    
    updateTaskCompleted = (result) => {        
        //alert('Your changes to the Regulatory Update have been saved successfully.');
        let alert_param = {title: 'Success', message: 'Your changes to the Regulatory Update have been saved successfully.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterCompleted, stack: {}}

        this.setState({alert_param: alert_param}) 
        //window.location.reload(true);
    }
    viewEditSection1 = () => {        
        this.setState({editsection: true})
    }
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    ondocumentType = (event) => {
        event.preventDefault();       
        let dtype = event.target.value;
        let documenttypearr = dtype.split(",");        
        this.setState({[event.target.name]: documenttypearr});
    }
    onUploadDateChange = (date) => {
        if (!moment.isDate(date)) {
			return;
		}
        this.setState({uploaddate: date});
    }

    updateRCMTask = (event) => {       
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let task = Store.getStoreData('this_task_detail');
        let task_id = task.task.parenttask.id;        
          
        let task_json = JSON.parse(task.task.parenttask.task_json);
         
        let rufeed = Store.getStoreData('task');
        let summarydata = window.decodeURIComponent(atob(rufeed.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');

        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }      
        console.log("task_json", task_json)
        

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        console.log("documentname====>",this.state.documentname,"documentype==>",doctypeArray,
        "uploaddate===>",this.state.uploaddate,"summary====>",summary,"sections===>",sections,
        "externalLink===>",this.state.externalLink,"keyDatesValue===>",this.state.keyDatesValue)

        const upload_date_obj = new Date(this.state.uploaddate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        const upload_datenew = `${upload_year}/${upload_month}/${upload_day}`;

        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].tags = tags;   
        task_json['object_data'].documentName = this.state.documentname;
        task_json['object_data'].documentTypes = doctypeArray;
        //task_json['object_data'].uploadDate = this.state.uploaddate;
        task_json['object_data'].issueDate = upload_datenew;
        task_json['object_data'].summary = btoa(window.encodeURIComponent(summary));
        task_json['object_data'].sections = sections;
        task_json['object_data'].externalLinks = this.state.externalLink;
        task_json['object_data'].keyDates = this.state.keyDatesValue; 
        task_json['object_data'].ragulatorName = this.state.ragulatortypeName;
        task_json['object_data'].issuingBodies = [this.state.ragulatortypeName];      
        task_json['action_data'] = {action: "COMP_RCM_UPDATE", actiondate: Date.now(), cur_lane: "COMP_RCM_ASSIGNMENT", assigned_to: 0, performed_by: Store.getStoreData('loggedincontact')};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.updateTaskCompleted);
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    showAddKeyDate = () => {    
        //event.preventDefault();
        this.setState({ showAddKeyDateModal: true, hiddenAddButton: false,curKeyid: 0 });
    }
    closeKeyDateModal = () => {
        this.setState({ showAddKeyDateModal: false, hiddenAddButton: true});
    }

    showAddexternalLink = () => {    
        //event.preventDefault();

        this.setState({ showAddLinkModal: true, hiddenAddButtonLink: false,curLinkid: 0 });
    }
    closeExLinkModal = () => {
        this.setState({ showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    modifyEntity = (obj) => {
        console.log("obj:", obj);
        this.setState({[obj.entity]: obj.list});
    }

    selectOrUnselectTopic = (tagid) => {
        //event.preventDefault();
        console.log("Sourav22", tagid)
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    searchUsersTags = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
        console.log("fddsf",this.state.searchFilterTags)
        let search_string = event.target.value;
        if (search_string === "") {
            let tags = this.state.searchFilterTags;            
            this.setState({tags: tags});
        }else {
            let all_tags = this.state.searchFilterTags;
            let filtered_tags = [];
            all_tags.forEach((tag) => {
                if (tag.name.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_tags.push(tag);
                }                
            })            
            let tags = filtered_tags;

            this.setState({tags: tags})
        }
    }

    viewFollowupTask = (followup_id) => {
        console.log("followupid==>", followup_id)
        let thisTaskDetail = Store.getStoreData('this_task_detail');        
        let thisTaskChildren = thisTaskDetail.task.childtask.result;
        let currentfollowupData = {};
        console.log("thisTaskChildren===>", thisTaskChildren)
        for(let follow of thisTaskChildren){
            if(follow.id === followup_id){
                currentfollowupData = follow;
            }
        }        
        if(Object.keys(currentfollowupData).length !== 0){
            console.log("currentfollowupData==>", currentfollowupData)            
            let followupid = currentfollowupData.id;
            let task_json = JSON.parse(currentfollowupData.task_json);        
            if('cur_view' in task_json.object_data && task_json.object_data.cur_view === "Unopened"){
                task_json['object_data'].cur_view = "Opened";
                task_json['object_data'].OpenedDate = Date.now();
                let postData = {command: "update_rcm_followup_task", task_json: task_json, task_id: followupid, bin_files: [] };
                console.log(postData);
                const api = new APICall();
                api.command(postData, this.processupdatecurviewFoll(followupid));
            }else{            
                Store.updateStore('cur_followup_task', currentfollowupData)
                Store.updateStore('cur_parentTask', thisTaskDetail.task.parenttask)
                this.setState({ modal_view: 'view_followup_task', isLoadedIcon: false})
            }
        }
    }

    processupdatecurviewFoll = (followupid) => (result) => {  
        let postData = { command: "get_subtask", task_id: followupid};
        console.log("PostData====>", postData )
        let api = new APICall();
        api.command(postData, this.openFollowupModalCheck);         
    } 
    openFollowupModalCheck = (result) => {
        console.log("result===>", result)
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ modal_view: 'view_followup_task',isLoadedIcon: false}) 
    }

    closeModal = () => {
        this.setState({ modal_view: null });
    }

    cancelDismissReson = (event) => {
        event.preventDefault();    
        this.setState({isReasonForDismissalShow: false, isShowDismissalDropdown: false})    
    }

    render()
    {
        
        let parent_task_id = Store.getStoreData('parent_task_id');
        let tableType = Store.getStoreData('table_type');
        let currentTask = Store.getStoreData('current_task');
        let lastAction = currentTask.action_data[currentTask.action_data.length-1];
        let contacts_list = Store.getStoreData('contacts');
        let thisTaskDetail = Store.getStoreData('this_task_detail');
        let thisTaskChildren = thisTaskDetail.task.childtask.result;
        let thisTask = thisTaskDetail.task.parenttask;
        let contacts = {};
        for(var i=0; i < contacts_list.length; i++) {
            contacts[contacts_list[i].ID.toString()] = contacts_list[i].ContactName;
        }
        let assignedTo = contacts[lastAction.assigned_to];
        let rufeed = Store.getStoreData('task');
		const issue_date_obj = new Date(rufeed.issueDate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_day}/${issue_month}/${issue_year}`; 
        let mod_date = "N/A"; 
        if (rufeed.modificationDate !== null) {     
            let modificationDate = rufeed.modificationDate;
            if (modificationDate.includes(" ") === true){
                modificationDate = modificationDate.replace(" ", "+");
            }
            const mod_date_obj = new Date(modificationDate);
            const mod_day = mod_date_obj.getDate() < 10 ? "0"+mod_date_obj.getDate().toString() : mod_date_obj.getDate();
            const mod_month = mod_date_obj.getMonth()+1 < 10 ? `0${(mod_date_obj.getMonth() + 1).toString()}` : mod_date_obj.getMonth()+1;
            const mod_year = mod_date_obj.getFullYear();
            mod_date = `${mod_day}/${mod_month}/${mod_year}`;
        }

        
        let prdate = "N/A";
        if (rufeed.publicResponseDate !== null) {
            let publicResponseDate = rufeed.publicResponseDate;
            if (publicResponseDate.includes(" ") === true) {
                publicResponseDate = publicResponseDate.replace(" ", "+");
            }
            const pr_date_obj = new Date(publicResponseDate);
            const pr_day = pr_date_obj.getDate() < 10 ? "0"+pr_date_obj.getDate().toString() : pr_date_obj.getDate();
            const pr_month = pr_date_obj.getMonth()+1 < 10 ? `0${(pr_date_obj.getMonth() + 1).toString()}` : pr_date_obj.getMonth()+1;
            const pr_year = pr_date_obj.getFullYear();
            prdate = `${pr_day}/${pr_month}/${pr_year}`;
        }
        let today = new Date();
        let dueDate = new Date(thisTask.due_date);

        let taskStatus = today <= dueDate ? 'Pending' : 'Overdue';
        taskStatus = thisTaskChildren.length > 0 ? taskStatus + ', In progress' : taskStatus;
        let taskStatusColor = '#FF0000';
        switch(taskStatus) {
            case 'Pending' : taskStatusColor = '#b3b300'; break;
            case 'Overdue' : taskStatusColor = '#FF0000'; break;
            case 'Pending, In progress' : taskStatusColor = '#23FF78'; break;
            case 'Overdue, In progress' : taskStatusColor = '#FF4545'; break;
        }
        //console.log("rufeed===>",rufeed);
        let shareType = "RU";
        if('is_rn' in rufeed && rufeed.is_rn === 1){
            shareType = "RN";
        }
        let upload_date = "N/A";
        if (rufeed.uploadDate !== null) {
            let uploadDate = rufeed.uploadDate;
            if (uploadDate.includes(" ") === true) {
                uploadDate = uploadDate.replace(" ", "+");
            }
            const upload_date_obj = new Date(uploadDate);
            const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
            const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
            const upload_year = upload_date_obj.getFullYear();
            upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        }else{
            upload_date = issue_date;
        }
        let docType = "";
        let dNames = "";            
        if(rufeed.documentTypes !== null) {            
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            } 
            let i=0;
            for (let doc of rufeed.documentTypes){
                i++;
                if(doc in doctype){
                    dNames += doctype[doc]
                    if(i <= rufeed.documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        } 
        let module_config = Store.getStoreData('module_config');

        let role = Store.getStoreData('role')
        let contact_id = Store.getStoreData('loggedincontact');   
        let show_all = (role === 'admin_manager' || role === 'manager') ? true : false;
        let gc_users = Store.getStoreData('gc_users')

        if(!this.state.ready){
            return <div><CSLLoader style={{position: 'absolute'}}/></div>
        }        
        return (<div>   
            {
                (() => {
                    if(this.state.modal_view !== null){                                
                        switch(this.state.modal_view) {                            
                            case 'view_followup_task' : return <div><InactiveOverlay style={{height: "262%"}} /><FollowupTaskModal topValue={"0%"} closeModal={this.closeModal}/></div>; break;
                        }
                        
                    }
                })()
            }       
        <RegModalContainer>
            {/* <AlwaysScrollToView /> */}
            {
                (() => {
                    if(this.state.isLoaderIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <RegModalHeaderText>Assign Pending Task</RegModalHeaderText>
            <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
            <div style={{clear: "both"}}></div>
            <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
            {
                (() => {
                    if(this.state.groupcompanyName !== ""){
                        return(<div style={{float: "left"}}><MRModalHeaderText style={{marginLeft: "31px",fontSize: "14px",color: "#282828"}}>Company Name - {this.state.groupcompanyName}</MRModalHeaderText></div>)
                    }
                })()
            }            
            {
                (() => {
                    if(tableType !== 'pending') {
                        if(!this.state.editsection){
                        return(<div>                            
                            <div style={{float: "right",marginRight: "25px"}} onClick={this.viewEditSection1}><span style={{color: "#57C3C1",cursor: "pointer",fontSize: "16px"}}><FaEdit/></span></div>
                            </div>);
                        }
                    }
                })()
            }
            <div style={{clear: "both"}}></div>
            {
                (() => {
                    if(this.state.editsection) {
                        return(<div>
                            <RegDismisOuterContainer style={{padding: "6px 11px 20px 11px"}}>
                            <RegModalContainertop>
                                <RegModalNameLabelDiv>Name</RegModalNameLabelDiv>
                                <RegModalNameInputDiv style={{width: "99%"}}>
                                    <RegModalNameInput type="text" name="documentname" value={this.state.documentname} onChange={this.handleChange}/>
                                </RegModalNameInputDiv>
                                {/* <RegModalNameLabelDiv>Type</RegModalNameLabelDiv>
                                <MRModalSelect style={{width: "50%"}} name="documentype" value={this.state.documentype} onChange={this.ondocumentType}>                        
                                    <option value="">Select Type</option>
                                    {
                                        this.state.documentTypes.map((doc, index) => {
                                            return (<option key={index} value={doc.id}>{doc.documentname}</option>);
                                        })
                                    }                            
                                </MRModalSelect>*/}
                                <RegModalNameLabelDiv>Regulator Name</RegModalNameLabelDiv>
                                <RegModalNameInputDiv>
                                    <MRModalSelect style={{width: "99.2%", display: "inline-block"}} name="ragulatortypeName" value={this.state.ragulatortypeName} onChange={this.handleChange}>                        
                                        <option value="">Select Regulator</option>
                                        {
                                            this.state.regulatorType.map((reg, index) => {
                                                return (<option key={index} value={reg.ragulatorname}>{reg.knownAs}</option>);
                                            })
                                        }                            
                                    </MRModalSelect>
                                </RegModalNameInputDiv>
                                <RegModalNameLabelDiv>Document Types</RegModalNameLabelDiv>
                                <RegModalNameInputDiv style={{width: "99%"}}>
                                    <SearchablePills entity="document_types" list={this.state.document_types} modifyEntity={this.modifyEntity} />
                                </RegModalNameInputDiv>
                                <RegModalNameLabelDiv>Date</RegModalNameLabelDiv>
                               <CSLDateCover style={{width: "20%"}}>
                                    <DatePicker onChange={this.onUploadDateChange}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    locale={"en-GB"}
                                    value={this.state.uploaddate}
                                    />
                                </CSLDateCover>
                            </RegModalContainertop>
                            </RegDismisOuterContainer>
                            <RegModalHr />
                            {
                                (() => {
                                    //if(shareType === 'RU'){
                                        return(
                                            <div>
                                                <RegDismisOuterContainer style={{padding: "6px 11px 20px 11px"}}>
                                                <RegModalContainertop>
                                                <RegModalNameLabelDiv>Summary</RegModalNameLabelDiv>
                                                <RegModalNameInputDiv>
                                                    {
                                                        (() => {
                                                            let summary = window.decodeURIComponent(atob(rufeed.summary));
                                                            // let summary = Store.getStoreData('summary');
                                                            console.log("summary", summary);
                                                            return (<HtmlEditor content={summary} section_id="0"/>);
                                                        })()
                                                    }
                                                    {/* <HtmlEditor content={rufeed.summary} section_id="0"/> */}
                                                </RegModalNameInputDiv>
                                            </RegModalContainertop>
                                            </RegDismisOuterContainer>
                                            {
                                                Store.getStoreData('sections').map((section) => {
                                                    if (section.display === "label") {
                                                        return (
                                                            <RegModalContainertop key={section.id} style={{padding: "30px"}}>
                                                                <RegModalNameLabelDiv onClick={this.changeToInput(section.id)}>{section.name}</RegModalNameLabelDiv>
                                                                <RegModalNameInputDiv>                                                    
                                                                    <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>                                                    
                                                                    <div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>                                                            
                                                                </RegModalNameInputDiv>
                                                            </RegModalContainertop>
                                                        );
                                                    } else {
                                                        return (
                                                            <RegModalContainertop key={section.id} style={{padding: "30px"}}>
                                                                <RegModalNameLabelDiv >
                                                                    <RegModalNameInput type="text" name={section.id} value={section.name} onChange={this.changeInputValue(true)} onBlur={this.changeInputValue(false)} />
                                                                </RegModalNameLabelDiv>
                                                                <RegModalNameInputDiv>                                                    
                                                                    <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>                                                    
                                                                    <div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>                                                            
                                                                </RegModalNameInputDiv>
                                                            </RegModalContainertop>
                                                        );
                                                    }
                                                })
                                            }
                                            <SingleRowColumnContainer style={{padding: "30px"}}>
                                                <AddSectionButton onClick={this.addSection}>Add Section</AddSectionButton>
                                            </SingleRowColumnContainer>
                                            </div>
                                        )
                                    //}
                                })()
                            }
                            {
                                (() => {
                                    if('general' in module_config && module_config.general.enable_tag_management === true){
                                        return(
                                            <RegModalContainertop>
                                                <RegModalNameLabelDiv style={{marginLeft: "10px"}}>Available Tags</RegModalNameLabelDiv>
                                                <RegModalTagsDiv>
                                                <RegModalNameInput style={{marginTop: "13px",marginLeft: "6px",marginBottom: "11px",height: "27px",width: "96%"}} placeholder="Start typing to search..." onChange={this.searchUsersTags} type="text" />
                                                    {
                                                        (() => {
                                                            if (this.state.tags.length !== 0) {                                                                
                                                                return(<RCMTagsView tags={this.state.tags} selectUnselect={this.selectOrUnselectTopic}/>)                                                                                                         
                                                            } 
                                                        })() 
                                                    }
                                                </RegModalTagsDiv>
                                            </RegModalContainertop>
                                        )
                                    }
                                })()
                            }
                            
                            {
                                (() => {
                                    if(this.state.externalLink.length === 0)
                                    {                                    
                                    return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                            <OverviewInfoHeader style={{width: "unset"}}>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                                    {
                                                        (() => {
                                                            if(this.state.hiddenAddButtonLink){
                                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                                            }
                                                        })()
                                                    }                
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader>
                                                {
                                                    (() => {
                                                        if (this.state.showAddLinkModal) {
                                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} closeExLinkModal={this.closeExLinkModal} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                                        }
                                                    })()                                                }
                                            
                                            <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>
                                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no External Links Available</div>
                                            </OverviewInfoBody>                                            
                                            </SingleRowColumnContainer>)
                                        }else {
                                            return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                                <OverviewInfoHeader style={{width: "unset"}}>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                                    {
                                                        (() => {
                                                            if(this.state.hiddenAddButtonLink){
                                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                                            }
                                                        })()
                                                    }                
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader>
                                                {
                                                    (() => {
                                                        if (this.state.showAddLinkModal) {
                                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} content={JSON.parse(JSON.stringify(this.state.contentLink))} closeExLinkModal={this.closeExLinkModal} fetchEditexternalLinkValue={this.fetchEditexternalLinkValue} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                                        }
                                                    })()
                                                }
                                                {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                                
                                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                                </OverviewInfoBody>                                                
                                            </SingleRowColumnContainer>)
                                    }
                                })()
                            }
                            {
                                (() => {                                    
                                        if(this.state.keyDatesValue.length === 0)
                                        {                                        
                                        return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                            <OverviewInfoHeader style={{width: "unset"}}>
                                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                                {
                                                    (() =>{
                                                        if(this.state.hiddenAddButton){
                                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                                        }
                                                    })()
                                                }                
                                                <div style={{clear: "both"}}></div>
                                            </OverviewInfoHeader>
                                            {
                                                (() => {
                                                    if (this.state.showAddKeyDateModal) {
                                                        return (<AddEditKeyDates  curid={this.state.curKeyid} closeKeyDateModal={this.closeKeyDateModal} refreshKeyValue={this.fetchKeyValue}/>);
                                                    }
                                                })()
                                            }                                            
                                            <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>
                                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no Key Dates Available</div>
                                            </OverviewInfoBody>                                            
                                            </SingleRowColumnContainer>)
                                        }else {
                                            return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                                <OverviewInfoHeader style={{width: "unset"}}>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                                    {
                                                        (() =>{
                                                            if(this.state.hiddenAddButton){
                                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                                            }
                                                        })()
                                                    }                
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader>
                                                {
                                                    (() => {
                                                        if (this.state.showAddKeyDateModal) {
                                                            return (<AddEditKeyDates  curid={this.state.curKeyid} content={JSON.parse(JSON.stringify(this.state.content))} closeKeyDateModal={this.closeKeyDateModal} refreshEditKeyValue={this.refreshEditKeyValue} refreshKeyValue={this.fetchKeyValue}/>);
                                                        }
                                                    })()
                                                }
                                                {/* <AddKeyDates refreshKeyValue={this.fetchKeyValue}/> */}
                                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                              
                                                    <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                                </OverviewInfoBody>                                                
                                            </SingleRowColumnContainer>)
                                        }
                                    //}
                                })()
                            }
                        </div>);
                    }else{
                        return(<div>
                            <RegDismisOuterContainer>  
                            <OverviewInfoHeader>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.state.documentname}</div>                                                                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>
                            <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>                            
                                <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(rufeed.summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>                                       
                                <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {dNames}</div>
                                <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {issue_date}</div> 
                            </OverviewInfoBody>
                        </RegDismisOuterContainer>
                        {
                            rufeed.sections.map((section) => {
                                return (
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>                                                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                            <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>                                 
                                            
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>                                    
                                )
                            })
                        }
                        {
                            (() => {                            
                                if('externalLinks' in rufeed) {
                                    if(this.state.externalLink.length !== 0) {
                                        return(<div>
                                            <RegDismisOuterContainer>
                                                <OverviewInfoHeader>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader> 
                                                <OverviewInfoBody>          
                                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                                </OverviewInfoBody>
                                            </RegDismisOuterContainer>
                                            </div>)
                                    }
                                }
                            })()                
                        }
                        {
                            (() => {                            
                                if('keyDates' in rufeed) {    
                                    if(this.state.keyDatesValue.length !== 0) {
                                        return(<div>
                                            <RegDismisOuterContainer>
                                                <OverviewInfoHeader>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader> 
                                                <OverviewInfoBody>          
                                                    <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                                </OverviewInfoBody>
                                            </RegDismisOuterContainer>
                                            </div>)
                                    }
                                }
                            })()                
                        }    
                        </div>);
                    }
                })()
            }
            {
                (() => {
                    if(tableType === 'pending') {
                        return (
                                <div>                                    
                                    <RegDismisOuterContainer>
                                    <RegDismisInnerContainer style={{backgroundColor: "#f7f7f7"}}>
                                        <ChildTasksRUTable add={false} headertext = "Associated RC Tasks" followup_tasks={thisTaskChildren} isShowAction={true} viewFollowupTask={this.viewFollowupTask}/>
                                    </RegDismisInnerContainer>
                                </RegDismisOuterContainer>
                                </div>
                            )
                    }                    
                })()
            }
            {
                (()=>{
                    if(this.state.submitedShareUserList.length != 0){
                        return(<div>
                            <RegDismisOuterContainer>
                                <RegDismisInnerContainer style={{backgroundColor: "#f7f7f7"}}>
                                    <ShareTasksRUTable add={false} submitedShareUserList={this.state.submitedShareUserList} shareUserAcknowledge={this.state.shareUserAcknowledge} shareUserInfo={this.state.shareUserInfo}/>
                                </RegDismisInnerContainer>
                            </RegDismisOuterContainer>
                        </div>)
                    }
                })()
            }
            <RegDismisOuterContainer>
                <RegDismisInnerContainer>                    
                    {
                        (() => {
                            if(this.state.resonforaction){
                                return (<div><RegModalContainertop>
                                            <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                                            <RegModalNameInputDiv>
                                                <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                            </RegModalNameInputDiv>
                                        </RegModalContainertop>                                        
                                        {
                                            (() => {
                                                if(tableType !== 'pending') {
                                                    return <RegSubmitButton style={{float: "right", marginRight: "20px"}} onClick={this.dismissClientRegulatorychange}>Save</RegSubmitButton>
                                                }
                                            })()
                                        }
                                        <RegCancelButton onClick={this.dismissResonformultiple('action')} style={{float: "right"}}>Cancel</RegCancelButton>
                                        <div style={{clear: "both"}}></div>
                                        </div>)
                            } else if(this.state.reasonforcomplete) {
                                return (<div><RegModalContainertop>
                                            <RegModalNameLabelDiv>Complete Note</RegModalNameLabelDiv>
                                            <RegModalNameInputDiv>
                                                <RegDismissalReason name="complete_note" onChange={this.handleOnChange} value={this.state.complete_note}></RegDismissalReason>
                                            </RegModalNameInputDiv>
                                        </RegModalContainertop>
                                        {
                                            (() => {
                                                if(tableType !== 'pending') {
                                                    return <RegSubmitButton style={{float: "right"}} onClick={this.saveClientRegulatorychange}>Save</RegSubmitButton>
                                                }
                                            })()
                                        }
                                        <RegCancelButton onClick={this.dismissResonformultiple('complete')} style={{float: "right"}}>Cancel</RegCancelButton>
                                        <div style={{clear: "both"}}></div>
                                        </div>)
                            }
                            else if(this.state.followuptask){
                                let all_contacts = Store.getStoreData('contacts');
                                let newContacts = [];
                                if(this.state.isGroup){
                                    all_contacts.forEach((item) => {
                                        if(item.ID in this.state.gcompanyUsers){
                                            newContacts.push(item); 
                                        }
                                    }) 
                                }else{
                                    newContacts = all_contacts;
                                }  
                                console.log("newContacts===>", newContacts, gc_users)      
                                let updateContact = [];
                                if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
                                    newContacts.forEach((item) => {
                                        if(item.ID in gc_users){
                                            updateContact.push(item); 
                                        }
                                    })                                                                     
                                }else{                                    
                                    updateContact = newContacts;
                                }
                                console.log("contacts===>", updateContact)
                                let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
                                return(<div><RegModalContainertop>
                                    <RegModalNameLabelDiv>Remedial/Followup Tasks</RegModalNameLabelDiv>                                    
                                    <RegModalTagsDiv style={{marginLeft: "50px",width: "90%"}}>
                                        <RegModalIssueDateDiv>
                                            <div style={{float: "left", width: "50%"}}>
                                                <RegModalIssueDateLabel>Assign To</RegModalIssueDateLabel>
                                                <TermInputDiv>                                                                                             
                                                    <MRModalSelect name="assigned_to" onChange={this.handleOnChange} value={this.state.assigned_to}>
                                                        <option key={0} value={0}>Select</option>
                                                        {
                                                            contacts.map((contact, index) => {
                                                                return (<option key={index} value={contact.ID}>{contact.ContactName}</option>);
                                                            })
                                                        }
                                                    </MRModalSelect>                                                
                                                </TermInputDiv>
                                            </div>
                                            <div style={{float: "left", width: "calc(50% - 15px)", marginLeft: "15px"}}>
                                                <RegModalIssueDateLabel>Due By</RegModalIssueDateLabel>
                                                <TermInputDiv>
                                                    <CSLDateCover>
                                                        <DatePicker
                                                                onChange={this.onDateChange}
                                                                clearIcon={null}
                                                                calendarIcon={null}
                                                                locale={"en-GB"}
                                                                value={this.state.date}
                                                                />
                                                    </CSLDateCover>
                                                </TermInputDiv>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                            <RegModalIssueDateLabel>Note</RegModalIssueDateLabel>
                                            <RegModalNameInputDiv>
                                                <RegDismissalReason style={{marginTop: "10px"}} name="assign_note" onChange={this.handleOnChange} value={this.state.assign_note}></RegDismissalReason>
                                            </RegModalNameInputDiv>
                                        </RegModalIssueDateDiv>
                                    </RegModalTagsDiv>
                                    <ModalNameLabelDiv style={{marginTop: "30px"}}>Attachments</ModalNameLabelDiv>                                                                     
                                    <ModalNameInputDiv style={{marginLeft: "48px"}}>
                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={!this.state.isdesable}/>
                                    </ModalNameInputDiv>                                        
                                    
                                </RegModalContainertop>
                                <RegSubmitButton style={{float: "right"}} onClick={this.saveFollowupTaskchange}>Save</RegSubmitButton>                                
                                <RegCancelButton onClick={this.dismissResonformultiple('followup')} style={{float: "right"}}>Cancel</RegCancelButton>
                                <div style={{clear: "both"}}></div>
                                </div>)
                            }
                        })()
                    }                    
                </RegDismisInnerContainer>
            </RegDismisOuterContainer>            
            {
                (() => {
                    if('general' in module_config && module_config.general.enable_distribution_lists === true){
                        if('distribution_list' in module_config && module_config.distribution_list.length !== 0){    
                            console.log("module_config.distribution_list==>", module_config.distribution_list)
                            if(this.state.shareModal) {                                
                            return(<div>
                                <RegDismisOuterContainer>
                                    <RegModalNameLabelDiv style={{marginLeft: "9px"}}>Distribution Lists</RegModalNameLabelDiv>
                                    <MRModalSelect style={{width: "30%",marginTop: "10px",marginLeft: "9px",marginBottom: "11px"}} name="distributionCode" value={this.state.distributionCode} onChange={this.handleOnChangeDistribution}> 
                                        <option key={0} value={0}>Select</option>
                                        {
                                            (() =>{
                                                console.log("this.state.isGroup==>", this.state.isGroup)
                                                if(!this.state.isGroupDistributioncheck){
                                                    return(                             
                                                        this.state.distributionLists.map((doc, index) => {
                                                            return (<option key={index} value={doc.distributionID}>{doc.distributionName}</option>);
                                                        })
                                                    )
                                                }else{
                                                    return(                             
                                                        this.state.distributionLists.map((gc) => {
                                                            console.log('distribution_group_list gc', gc)
                                                            if(this.state.selected_gc === '0' || (gc.distributionItem === 'company' && gc.curgcid.toString() === this.state.selected_gc.toString()) || (gc.distributionItem === 'allusers' || gc.distributionItem === 'role' || gc.distributionItem === 'allcontacts')) {
                                                                if(show_all) {
                                                                    return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                                } else if(role === 'rcm_gatekeeper') {
                                                                    if(gc.curgcid in gc_users[contact_id] || (gc.distributionItem === 'allusers' || gc.distributionItem === 'role' || gc.distributionItem === 'allcontacts')) {
                                                                        return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                                    }
                                                                } 
                                                            }
                                                        })
                                                    )
                                                }
                                            })()
                                        }                            
                                    </MRModalSelect>
                                    {
                                        (() => {
                                            if(this.state.showDistributionShareUser){
                                                return(<div>
                                                    <OverviewInfoHeader>
                                                            {/* <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select Users you want to share this {shareType} with</div>  */}
                                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select users you want to share this regulatory change with</div> 
                                                            <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                                            <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                        
                                                    <OverviewInfoBody>
                                                    <div style={{width: "71%",display: "inline-block",marginRight: "25px",marginBottom: "6px"}}>
                                                        <SearchInput placeholder="Search..." onChange={this.searchUsers}/>                             
                                                    </div>
                                                    <div style={{width: "25%", display: "inline-block"}}>
                                                        <PermissionDropdown onChange={this.changeAllRoles}>
                                                            <option value="0">Select Role</option>
                                                            <option value="team">Team</option>
                                                            <option value="rcm_gatekeeper">RCM Gatekeeper</option>
                                                            <option value="manager">Admin Manager</option>
                                                        </PermissionDropdown>
                                                    </div>
                                                    
                                                        <CSLTable add={false} processData={this.processMyshareData} headerText={''} tableRows={10}/>
                        
                                                        {
                                                            (() => {
                                                                if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
                                                                    if('reason_code' in module_config && module_config.reason_code.length !== 0){
                                                                        return(<div>
                                                                            <RegModalNameLabelDiv>Shared Reason Codes</RegModalNameLabelDiv>
                                                                            <MRModalSelect style={{width: "30%",marginTop: "10px"}} name="reasonCode" value={this.state.reasonCode} onChange={this.handleOnChange}> 
                                                                                {
                                                                                    this.state.newReasonCode.map((doc, index) => {
                                                                                        return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                                                                    })
                                                                                }                            
                                                                            </MRModalSelect>  
                                                                        </div>)
                                                                    }
                                                                }
                                                            })()
                                                        }
                        
                                                    </OverviewInfoBody>
                                                    <RegCancelSubmitButtonOuterContainer>
                                                        <RegCancelButton onClick={this.closeShareModal}>Cancel</RegCancelButton>
                                                        <RegSubmitButton onClick={this.shareUser}>Share</RegSubmitButton>
                                                    </RegCancelSubmitButtonOuterContainer>
                                                </div>)
                                            }
                                        })()
                                    }
                                </RegDismisOuterContainer>
                            </div>)
                            }
                        }else{
                            if(this.state.shareModal) {
                                console.log("Sourav3+++")
                                return(<RegDismisOuterContainer>
                                    
        
                                    <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select users you want to share this regulatory change with</div> 
                                            <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                            <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
        
                                    <OverviewInfoBody>
                                    <div style={{width: "71%",display: "inline-block",marginRight: "25px",marginBottom: "6px"}}>
                                        <SearchInput placeholder="Search..." onChange={this.searchUsers}/>                             
                                    </div>
                                    <div style={{width: "25%", display: "inline-block"}}>
                                        <PermissionDropdown onChange={this.changeAllRoles}>
                                            <option value="0">Select Role</option>
                                            <option value="team">Team</option>
                                            <option value="rcm_gatekeeper">RCM Gatekeeper</option>
                                            <option value="manager">Admin Manager</option>
                                        </PermissionDropdown>
                                    </div>
                                    
                                        <CSLTable add={false} processData={this.processMyshareData} headerText={''} tableRows={10}/>
        
                                        {
                                            (() => {
                                                if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
                                                    if('reason_code' in module_config && module_config.reason_code.length !== 0){
                                                        return(<div>
                                                            <RegModalNameLabelDiv>Shared Reason Codes</RegModalNameLabelDiv>
                                                            <MRModalSelect style={{width: "30%",marginTop: "10px"}} name="reasonCode" value={this.state.reasonCode} onChange={this.handleOnChange}> 
                                                                {
                                                                    this.state.newReasonCode.map((doc, index) => {
                                                                        return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                                                    })
                                                                }                            
                                                            </MRModalSelect>  
                                                        </div>)
                                                    }
                                                }
                                            })()
                                        }
        
                                    </OverviewInfoBody>
                                    <RegCancelSubmitButtonOuterContainer>
                                        <RegCancelButton onClick={this.closeShareModal}>Cancel</RegCancelButton>
                                        <RegSubmitButton onClick={this.shareUser}>Share</RegSubmitButton>
                                    </RegCancelSubmitButtonOuterContainer>
                                    </RegDismisOuterContainer>
                                );
                            }
                        }
                    }else{
                        if(this.state.shareModal) {
                            console.log("Sourav2+++")
                            return(<RegDismisOuterContainer>
                                
    
                                <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select users you want to share this regulatory change with</div> 
                                        <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                        <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
    
                                <OverviewInfoBody>
                                <div style={{width: "71%",display: "inline-block",marginRight: "25px",marginBottom: "6px"}}>
                                    <SearchInput placeholder="Search..." onChange={this.searchUsers}/>                             
                                </div>
                                <div style={{width: "25%", display: "inline-block"}}>
                                    <PermissionDropdown onChange={this.changeAllRoles}>
                                        <option value="0">Select Role</option>
                                        <option value="team">Team</option>
                                        <option value="rcm_gatekeeper">RCM Gatekeeper</option>
                                        <option value="manager">Admin Manager</option>
                                    </PermissionDropdown>
                                </div>
                                
                                    <CSLTable add={false} processData={this.processMyshareData} headerText={''} tableRows={10}/>
    
                                    {
                                        (() => {
                                            if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
                                                if('reason_code' in module_config && module_config.reason_code.length !== 0){
                                                    return(<div>
                                                        <RegModalNameLabelDiv>Shared Reason Codes</RegModalNameLabelDiv>
                                                        <MRModalSelect style={{width: "30%",marginTop: "10px"}} name="reasonCode" value={this.state.reasonCode} onChange={this.handleOnChange}> 
                                                            {
                                                                this.state.newReasonCode.map((doc, index) => {
                                                                    return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                                                })
                                                            }                            
                                                        </MRModalSelect>  
                                                    </div>)
                                                }
                                            }
                                        })()
                                    }
    
                                </OverviewInfoBody>
                                <RegCancelSubmitButtonOuterContainer>
                                    <RegCancelButton onClick={this.closeShareModal}>Cancel</RegCancelButton>
                                    <RegSubmitButton onClick={this.shareUser}>Share</RegSubmitButton>
                                </RegCancelSubmitButtonOuterContainer>
                                </RegDismisOuterContainer>
                            );
                        }
                    }                     
                })()
            }
            {
                (() => {
                    let role = Store.getStoreData('role');
                    //console.log("role===",role)
                    if(role !== 'rcm_gatekeeper' && role !== 'manager') {
                        return(
                            <RegCancelSubmitButtonOuterContainer>
                                <RegCancelButton onClick={this.closeRegulatoryModal}>Cancel</RegCancelButton>
                                {
                                    (() => {
                                        if(tableType !== 'pending') {
                                            return <RegSubmitButton onClick={this.saveClientRegulatorychange}>Save</RegSubmitButton>
                                        }
                                    })()
                                }                                
                            </RegCancelSubmitButtonOuterContainer>
                        )

                    }else {
                                                   
                            if(this.state.bottom_Buttons_panel){
                                return(
                                    <RegCancelSubmitButtonOuterContainer>                                
                                        <RegCancelButton onClick={this.closeRegulatoryModal}>Close</RegCancelButton>
                                        {
                                            (() => {
                                                if(this.state.editsection) {
                                                    return(<RegShareButton onClick={this.showShareModal}>Save & Share</RegShareButton>)
                                                }else{
                                                    return(<RegShareButton onClick={this.showShareModal}>Share</RegShareButton>)
                                                }
                                            })()
                                        }                                
                                        {
                                            (() => {
                                                if(tableType !== 'pending') {
                                                    return <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton>
                                                }
                                            })()
                                        }
                                        <RegSubmitButton onClick={this.showAcknowledgeMentModal}>Send for Acknowledgement</RegSubmitButton>
                                        <RegSubmitButton onClick={this.addFollowupActionsection}>Mark For Action</RegSubmitButton>
                                        {
                                            (() => {
                                                if(parent_task_id === 0) {                                                    
                                                }else {
                                                    return(<RegMarkForActionButton onClick={this.addFollowupActionsection}>Complete</RegMarkForActionButton>);
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if(this.state.editsection) {
                                                    return(<RegSubmitButton onClick={this.updateRCMTask}>Save and Close</RegSubmitButton>);
                                                }
                                            })()
                                        } 
                                    </RegCancelSubmitButtonOuterContainer>
                                )
                            }
                                        
                        
                        
                    }
                })()
            }    
            {
                    (() => {
                        if(this.state.isReasonForDismissalShow){
                            return(<div><RegModalContainertop>
                                <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                                <RegModalNameInputDiv>
                                    <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                </RegModalNameInputDiv>
                            </RegModalContainertop>
                            <RegCancelSubmitButtonOuterContainer>    
                                <RegCancelButton onClick={this.cancelDismissReson}>Cancel</RegCancelButton>                                
                                <RegSubmitButton onClick={this.submitResonforDismissNormal}>Save</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer> 
                            <div style={{clear: "both"}}></div>
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.isShowDismissalDropdown){
                            return(<div><RegModalContainertop>
                                <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>                                
                                <MRModalSelect style={{width: "50%"}} name="selected_reason_for_dismissal" value={this.state.selected_reason_for_dismissal} onChange={this.handleOnChange}> 
                                    {
                                        this.state.reason_for_dismissal.map((doc, index) => {
                                            return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                        })
                                    }                            
                                </MRModalSelect>  
                                <RegModalNameLabelDiv></RegModalNameLabelDiv>  
                                {
                                    (() => {
                                        if(this.state.selected_reason_for_dismissal === 'Other'){
                                            return(
                                                <RegModalNameInputDiv>
                                                    <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                                </RegModalNameInputDiv>
                                            )
                                        }
                                    })()
                                }                                
                            </RegModalContainertop>
                            <RegCancelSubmitButtonOuterContainer>    
                                <RegCancelButton onClick={this.cancelDismissReson}>Cancel</RegCancelButton>                                
                                <RegSubmitButton onClick={this.submitResonforDismissForDropdown}>Save</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer> 
                            <div style={{clear: "both"}}></div>
                            </div>)
                        }
                    })()
                }                   
        </RegModalContainer>
        <AlertBox alertParam = {this.state.alert_param}/>
        </div> );
    }
}

export default RegulatoryClientModal;