import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from './CSLTable';
import CSLLoader from './CSLLoader';
import CSLMultiSelect from './CSLMultiSelect';
import CSLbucketloader from './CSLbucketloader';
import CSLReportModal from './CSLReportModal';
import PdfGenerator from './PdfGenerator';
import Utils from '../../Common/Utils';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosTrash} from 'react-icons/io';

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;    
    margin-top: 89px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  
`;
const RegisterButton = styled.button`
    box-sizing: border-box;
    background-color: #EBEBEB;
    color: black;
    border: 1px solid #CECECE;
    width: 100px;
    font-size: 13px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 0 4px #CECECE;
`;
const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: #000000;
	padding: 10px 20px;
`;
const FilterText = styled.div`
	float: left;
`;
const FilterChevron = styled.div`
	float: right;
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const FilterCell1 = styled.div`
	width: calc(25% - 5px);
	float: left;
	box-sizing: border-box;
`;
const FilterCell2 = styled.div`
	width: calc(49% - 5px);
	float: left;
	box-sizing: border-box;
	margin-right: 16px;
`;
const FilterCell3 = styled.div`
	width: calc(75% - 5px);
	float: left;
	box-sizing: border-box;
`;
const FilterCell4 = styled.div`
	width: calc(100% - 5px);
	float: left;
	box-sizing: border-box;
`;
const Label = styled.div`
	padding-bottom: 7px;
	font-weight: bolder;
`;
const FilterButtonsDiv = styled.div`
	background-color: #ffffff;
	padding: 5px 35px;
`;
const ClearButton = styled.div`
	background-color: #ffffff;
	color: #000000;
	padding: 10px 20px;
	border: 1px solid #000000;
	float: right;
	border-radius: 4px;
	cursor: pointer;
	margin-right: 5px;
`;
const ApplyButton = styled.div`
	background-color: #04ADA8;
	color: #ffffff;
	padding: 10px 20px;
	border: 1px solid #04ADA8;
	float: right;
	border-radius: 4px;
	cursor: pointer;
	margin-right:-25px;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const ModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;



class TextInput extends React.Component
{
	state = {text: ''}

	setComponentState = () => {
		this.setState({text: this.props.selectedItems.text})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prev_props) => {
		if(prev_props === this.props) return
		this.setComponentState()
	}

	onInputChange = (event) => {
		event.preventDefault();
		this.props.selectCallback(event.target.value, this.props.stack)
        this.setState({[event.target.name]: event.target.value});
	}

	render() {
		return (<div>
					<ModalInput name="text" value={this.state.text} onChange={this.onInputChange} />
				</div>)
	}
}

class CSLRegister extends React.Component
{
	state = {show_filter: false, filters: {}, show_report: false, generate: false, groupby_selections: [], sortby_selected: {accessor: 'none', order: 'Descending'}, selections_gathered: [], toggle_state: true}

	data = {}

	noOps = () => {

	}

	setComponentState = () => {
		if ("toggle_state" in this.props) {
			this.setState({toggle_state: this.props.toggle_state, filters: this.props.filters});
		}else{
			this.setState({filters: this.props.filters})	
		}			
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prev_props) => {
		if(this.props === prev_props)return
		this.setComponentState()
	}

	toggleFilter = () => {
		// event.preventDefault();
		let show_filter = this.state.show_filter ? false : true;
		this.setState({show_filter});		
	}

	selectCallback = (selected, stack) => {
		let type = stack.type
		let filter_id = stack.filter_id
		let filters = this.state.filters
		console.log('type==>', type)
		switch(type) {
			case 'multi': filters[filter_id].selections[selected] = filters[filter_id].options[selected]; break;
			case 'text' : filters[filter_id].selections.text = selected; break;
			default: filters[filter_id].selections.selected = filters[filter_id].options[selected];
		}
		
		this.setState({filters})
	}
	deselectCallback = (deselected, stack) => {
		let type = stack.type
		let filter_id = stack.filter_id
		let filters = this.state.filters
		delete filters[filter_id].selections[deselected]
		this.setState({filters})
	}

	clearFilter = () => {
		let filters = this.state.filters
		for(let id in filters) {
			if(filters[id].type === 'text') {
				filters[id].selections.text = ''
			} else {
				filters[id].selections = {}
			}
		}
		this.setState({filters})
		let selections_gathered = [];
		Store.updateStore('selections_gathered', selections_gathered);
		this.props.applyFilter(filters)
	}

	applyFilter = () => {
		console.log("this.state.filters", this.state.filters)
		let selections_gathered = [];
		Object.keys(this.state.filters).map((id, i) => {
			let selections_gathered_object = {};
			let filter = this.state.filters[id]
			if(filter.name === "regulator"){
				if(Object.keys(filter.selections).length !== 0){
					selections_gathered_object.accessor = filter.name;
					let newObj = [];
					for(let id in filter.selections) {
						//console.log("sssss=>", id)
						newObj.push(id);
					}
					selections_gathered_object.selection = newObj;
					selections_gathered_object.type = "multiple_select";
					selections_gathered.push(selections_gathered_object);
				}
			}else if(filter.name === "type"){
				if(Object.keys(filter.selections).length !== 0){
					selections_gathered_object.accessor = filter.name;
					let newObj = [];
					for(let id in filter.selections) {
						//console.log("sssssmmmmm=>", id)
						newObj.push(id);
					}
					selections_gathered_object.selection = newObj;
					selections_gathered_object.type = "multiple_select";
					selections_gathered.push(selections_gathered_object);
				}
			}
		})		
		//console.log("selections_gathered", selections_gathered)
		Store.updateStore('selections_gathered', selections_gathered);
		this.props.applyFilter(this.state.filters)
	}

	changeSort = (sortby) => {
		//console.log("this.state.data", this.props.processData().data)
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.sortby = sortby;
        this.setState({data: this.data, sortby_selected: sortby.selected});
	}

	changeGroup = (groupby) => {
        let data = JSON.parse(JSON.stringify(this.data));
        this.data.groupby = groupby;
        this.setState({data: this.data, groupby_selections: groupby.selections});
	}

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});		
	}

	hideReport = () => {
		this.setState({show_report: false})
	}

	reportCallback = () => {
		this.setState({show_report: true})
	}

	render() {
		//Utils.log('CSLRegister props', this.props)
		return (

		<div style={{height: '100%', marginLeft: 10, marginRight: 10}}>
			<OverviewInfoHeader>
                      <div style={{float: "left", color: "#282828", fontWeight: "600"}}>{this.props.headerText}</div>
                      {
                      	this.props.headerButtons.map((item, key) => {
                      		return (<RegisterButton key={key} 
                      			onClick = {'processClick' in item ? item.processClick : this.noOps}
                      			style={{
                      				float: 'right',
                      				color: 'color' in item ? item.color : 'black',
                      				backgroundColor: 'bgcolor' in item ? item.bgcolor : '#EBEBEB',
                      				cursor: 'processClick' in item ? 'pointer' : 'default'
                      			}}
                      			disabled={'disabled' in item && item.disabled}>{item.display}</RegisterButton>)
                      	})
                      }
                      <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
			{
				(() => {
					const has_gc = Store.getStoreData('has_gc')
					let cur_role = Store.getStoreData('role');
					if (cur_role === 'manager' && has_gc === true && "activeView" in this.props && this.props.activeView === "manager" && "toggle_text" in this.props === true) {
						return (
							<div style={{padding: "10px 25px 28px 24px", backgroundColor: "#ffffff"}}>
							<div style={{float: "right"}}>
								<div style={{float: "left", marginTop: "4px"}}>{this.props.toggle_text}</div>
								<div style={{float: "right", marginLeft: "5px"}}>
								{
									(() => {
										if (this.state.toggle_state === true) {
											return (
												<div>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.props.toggleCallback}>No</UnselectedRadio>
												</div>
											);
										} else {
											return (
												<div>
													<UnselectedRadio onClick={this.props.toggleCallback}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</div>
											);
										}
									})()
								}
								</div>
								<div style={{clear: "both"}}></div>
							</div>
							</div>
						);
					}
				})()
			}
			<div style={{clear: "both"}}></div>
			<FilterTopBar>
				<FilterText>Advanced Filters</FilterText>
				<FilterChevron onClick={() => this.toggleFilter()}>
				{
					(() => {
						if (this.state.show_filter) {
							return <IoIosArrowUp />
						} else {
							return <IoIosArrowDown />
						}
					})()
				}
				</FilterChevron>
				<div style={{clear: "both"}}></div>
			</FilterTopBar>
			{
				(() => {
					if(this.state.show_filter) {
						return (<FilterBody>
								{
									Object.keys(this.state.filters).map((id, i) => {
										let filter = this.state.filters[id]
										const filterCells = {1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4}
										const FilterCell = filterCells[filter.size.toString()]
										const filterInputs = {multi : CSLMultiSelect, text: TextInput}
										const FilterInput = filterInputs[filter.type]
										return (<FilterCell key={i}>
												<Label>{filter.nickname}</Label>
												{
													(() => {
														return (<FilterInput 
																	items={filter.options} 
																	selectedItems={filter.selections} 
																	stack={{type : filter.type, filter_id: id}}
																	selectCallback={this.selectCallback}
																	deselectCallback={this.deselectCallback}
																/>)
													})()
												}
											</FilterCell>)
									})
								}
								<div style={{clear: "both"}}></div>
								<FilterButtonsDiv>
									<ApplyButton onClick={() => this.applyFilter()}>Apply</ApplyButton>
									<ClearButton onClick={() => this.clearFilter()}>Clear</ClearButton>
								</FilterButtonsDiv>
								<div style={{clear: "both"}}></div>
								</FilterBody>)
					}
				})()
			}
			<div  style={{position: 'relative'}}>
			{
				(() => {
						if(Store.getStoreData('rutaskdata') === null) {							
						return <CSLbucketloader style={{position: 'absolute'}} />
						}
				})()
			}
			<div style={{clear: "both", height: '3px'}}></div>
			<CSLTable 
				add={false} 
				export={this.props.export} 
				export_file_name={this.props.exportFilename} 
				prefered_columns={this.props.preferedColumns} 
				processData={this.props.processData} 
				tableRows={7} 
				refreshCallback={this.props.refreshCallback} 
				qcbutton={true}
				reportButton={true}
				seeMoreButton={true}
				reportCallback={this.reportCallback}
				seemoreCallback={this.props.seemoreCallback}
				seeMoreCheck={this.props.seeMoreCheck}
			/>
			</div>
			{
				(() => {
					if(this.state.show_report) {
						let fields = this.props.fields
						let data = this.props.processData().data
						this.data = {
											data: data, 
											groupby: {fields: fields, selections: this.state.groupby_selections}, 
											sortby: {fields: fields, selected: this.state.sortby_selected},
											table_headers: fields,
											module: this.props.module
										}
						Utils.log('widths new_data', this.data)
						return (
								<div style={{position:'absolute', top:'0px', width:'100%'}}>
									<InactiveOverlay />
									<CSLReportModal
										data={this.data}
										changeSort={this.changeSort}
										changeGroup={this.changeGroup}
										hideReport={this.hideReport}
										toggleGenerate={this.toggleGenerate}
									/>
								</div>
							);
					}
				})()
			}
			{
				(() => {
					if (this.state.generate) {
						return (
							<div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
								<InactiveOverlay style={{zIndex: "1010"}} />
								<PdfGenerator toggleGenerate={this.toggleGenerate} contactName={Store.getStoreData('contact')} />
							</div>
						);
					}
				})()
			}
		</div>
		)
	}
}

export default CSLRegister