import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Store from '../../../Common/Store.js';
import DatePicker from 'react-date-picker';
import {FaCalendarAlt} from 'react-icons/fa'


const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 40%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #282828;
  background-color: #ffffff;
  height: 25px;
  padding-top: 2px;
  padding-left: 2px;
  float: left;
  position: relative;
`;

const start_day = moment().subtract(30, 'days').format('YYYY/MM/DD')


class DateSelect extends React.Component
{
	state = {
		from_date: new Date(), 
		to_date: new Date(), 
		ready: false
	};

	setComponentState = () => {		
		console.log('items this.props', this.props)
		if(Object.keys(this.props.selectedItems).length === 0) {
			this.setState({from_date: new Date(), to_date: new Date(), ready: true});
		} else if('from_date' in this.props.selectedItems && this.props.selectedItems.from_date !== '' && 'to_date' in this.props.selectedItems && this.props.selectedItems.to_date !== '') {
			let fromdate = moment(this.props.selectedItems.from_date, 'DD/MM/YYYY')
			let todate = moment(this.props.selectedItems.to_date, 'DD/MM/YYYY')
			this.setState({ready: true, from_date: new Date(fromdate), to_date: new Date(todate)});
		}else{
			this.setState({ready: true});
		}
		
	}
	componentDidMount()
	{
		this.setComponentState()
	}

	componentDidUpdate(prevprops) {
		if(this.props !== prevprops) {
			this.setComponentState()
		}
	}

	handleFromDateChange = (date) => {
		if (!moment.isDate(date)) {
			return;
		}
		let date_range = {};		
		let date_str = moment(date).format('DD/MM/YYYY')
		// console.log('applyAdvancedFilterDate, date_str', date_str)
		date_range['from_date'] = date_str;
		date_range['to_date'] = moment(this.state.to_date).format('DD/MM/YYYY');
		let stack = 'stack' in this.props ? this.props.stack : null
		this.props.selectCallback(date_range, stack)
		this.setState({from_date: date});
	}

	handleToDateChange = (date) => {
		if (!moment.isDate(date)) {
			return;
		}
		let date_range = {};
		date_range['from_date'] = moment(this.state.from_date).format('DD/MM/YYYY');
		let date_str = moment(date).format('DD/MM/YYYY')
		date_range['to_date'] = date_str;
		let stack = 'stack' in this.props ? this.props.stack : null
		this.props.selectCallback(date_range, stack)	
		this.setState({to_date: date});
	}

	render()
	{
		if (!this.state.ready) {
			return (<div></div>);
		}
		// console.log("State===>", this.state)
		return (
			<div style={{marginTop: "8px"}}>
                        <CSLDateCover>
                            <DatePicker
                                      onChange={this.handleFromDateChange}
                                      clearIcon={null}
									  locale={"en-GB"}
                                      calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}                                      
                                      value={this.state.from_date}
                                    />
                        </CSLDateCover> 
                        <div style={{float: 'left', paddingTop: 17, marginLeft: 10, marginRight: 10}}>To</div>
                        <CSLDateCover>
                            <DatePicker
                                      onChange={this.handleToDateChange}
                                      clearIcon={null}
                                      calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                      locale={"en-GB"}
                                      value={this.state.to_date}
                                    />
                        </CSLDateCover> 
                        <div style={{clear: 'both'}} />
			</div>
		);
	}
}

export default DateSelect;

