import React from 'react';
import Store from '../../Common/Store';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import CSLLoader from './../Common/CSLLoader';
import AlertBox from './../Common/AlertBox';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 0%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;    
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #484848;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
    margin-left: 5px;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #282828;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 96%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;  
    font-family: 'Montserrat', sans-serif; 
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

class AutoForWardDismissTaskModal extends React.Component
{
    state = {
        isLoaderIcon: false,
        alert_param: null
    }

    componentDidMount = () => {
        console.log(Store.getStoreData('cur_auto_forward_dismiss_task'))        
    }

    closeDismissModal = (event) => {
        this.props.closeModal();
    }

    processMyExternalLink = () => {
        let curAutoForwardData = Store.getStoreData('cur_auto_forward_dismiss_task');        
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json);
        var linksdata = update_json_feed.externalLinks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {           
            i++;
            //console.log("window.location.protocol===>",window.location.protocol)
            let url = <a style={{display: "table-cell"}} href={d.url} target="_blank" >{d.url}</a>                      
            let elem = {'index' : i, 
                    'url_value' : url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    } 
    processMykeyData = () => {
        let curAutoForwardData = Store.getStoreData('cur_auto_forward_dismiss_task');        
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json);
        var keysdata = update_json_feed.keyDates;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    } 
    
    processAutoForWardDismissTask = () =>{    
        let curAutoForwardData = Store.getStoreData('cur_auto_forward_dismiss_task');        
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 

        let tags = update_json_feed.tags;        
        let companyID = Store.getStoreData('companyid');        
        let summary = update_json_feed.summary;
        let id = curAutoForwardData.feed_id;

        const issue_date_obj = new Date(update_json_feed.issueDate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_year}/${issue_month}/${issue_day}`; 

        let createdAt = curAutoForwardData.created_at !== null ? curAutoForwardData.created_at : update_json_feed.uploadDate;
         
        let rufeed = {};
        rufeed.tags = tags;   
        rufeed.summary = summary;       
        rufeed.sections = []; 
        rufeed.id = id;
        rufeed.companyid = companyID;
        rufeed.documentName = update_json_feed.documentName;
        rufeed.issueDate = issue_date;
        rufeed.createdDate = createdAt;
        rufeed.uploadDate = update_json_feed.uploadDate;
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();

        rufeed.keyDates = update_json_feed.keyDates;
        rufeed.externalLinks = update_json_feed.externalLinks;
        rufeed.documentTypes = update_json_feed.documentTypes;
        rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
        rufeed.ragulatorName = update_json_feed.ragulatorName        
        rufeed.issuingBodies = [update_json_feed.ragulatorName];
        rufeed.legislativeInitiatives = [];
        rufeed.is_jwg = 1;
        rufeed.AutoFordardJwGSelectdata = 1;
        
        let postData = {"command": "reprosses_autoforward_JwGData", "id": id, "data": rufeed, "selected_topics": tags};   
        console.log("postData===>",postData);
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processdismissTaskValue);
    }
    processdismissTaskValue = (result) => {        
        //alert("This Regulatory Change is now ready for processing");
        let alert_param = {title: 'Success', message: 'The regulatory change is now ready for processing.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})
        // window.location.reload(true);
        // this.setState({isLoaderIcon: false})
    }

    updateafterAlertWithLoading = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }
    
    render() {        
        let curAutoForwardData = Store.getStoreData('cur_auto_forward_dismiss_task');        
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 
        let documentName = update_json_feed.documentName;
        let summary = update_json_feed.summary;

        const documentType = Store.getStoreData('rutypenameAll');
        let doctypenew = {};
        for(let i=0; i < documentType.length; i++) {
            doctypenew[documentType[i].id] = documentType[i].documentname;
        } 

        let documentTypes = update_json_feed.documentTypes;         
        let dNames = "";
        if (documentTypes !== null) {
            let i=0;
            for (let doc of documentTypes){
                i++;
                if(doc in doctypenew){
                    dNames += doctypenew[doc]
                    if(i <= documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }

        const upload_date_obj = new Date(update_json_feed.uploadDate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        let upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        let dismissalNote = "Dismissed as regulator is filtered"
        return(<div>
        <RegModalContainer>
                {
                    (() => {
                        if(this.state.isLoaderIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <RegModalHeaderText>Regulatory Changes - Dismissed Task</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeDismissModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
                <RegDismisOuterContainer>
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{documentName}</div>                                                                
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>                            
                        <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>                                       
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {dNames}</div>
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {upload_date}</div> 
                    </OverviewInfoBody>
                </RegDismisOuterContainer>                
                {
                    (() => {                            
                        if('externalLinks' in update_json_feed) {
                            if(update_json_feed.externalLinks.length !== 0) {
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader> 
                                        <OverviewInfoBody>          
                                            <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} />
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    </div>)
                            }else{
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>           
                                        <OverviewInfoBody>
                                            <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Useful Links Available</div>
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                </div>)
                            }
                        }
                    })()                
                }
                {
                    (() => {                            
                        if('keyDates' in update_json_feed) {    
                            if(update_json_feed.keyDates.length !== 0) {
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader> 
                                        <OverviewInfoBody>          
                                            <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} />
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    </div>)
                            }else{
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>           
                                        <OverviewInfoBody>
                                            <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Key Dates Available</div>
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                </div>)
                            }
                        }
                    })()                
                }      
                {
                    (() => {
                        if('filterlog' in this.props && this.props.filterlog === true){
                            return(<RegModalContainertop>
                                <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                                <RegModalNameInputDiv>
                                <RegDismissalReason style={{marginTop: "10px"}} defaultValue={dismissalNote} readOnly></RegDismissalReason>
                                </RegModalNameInputDiv>
                            </RegModalContainertop> )
                        }
                    })()
                }   
                      
                <RegCancelSubmitButtonOuterContainer>
                    {
                        (() => {
                            if(Store.getStoreData('role') === 'manager'){
                                if(!('filterlog' in this.props)){
                                    return(<RegSubmitButton onClick={this.processAutoForWardDismissTask}>Process</RegSubmitButton>)
                                }
                            }
                        })()
                    }                    
                    <RegCancelButton onClick={this.closeDismissModal}>Cancel</RegCancelButton>                     
                </RegCancelSubmitButtonOuterContainer>      
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>)
    }
}
export default AutoForWardDismissTaskModal;