import React from 'react';
import RcmHeader from '../Components/RcmHeader';
import WelcomeBanner from '../Components/WelcomeBanner';
import Store from '../Common/Store';
import CSLIndex from '../Components/CSL/CSLIndex';
import SelectIndex from '../Components/Select/SelectIndex';
//import RUPending from '../Components/RegularotyChanges/Pending/RUPending';
import RegisterIndex from '../Components/Register/RegisterIndex';
import TaskHeader from './TaskHeader';
import AlertBox from './Common/AlertBox';
import AllRus from './../Components/Select/MGR_updated/table/AllRus';
import TeamAllRus from './../Components/Select/Team_updated/table/TeamAllRus';

class Index extends React.Component
{
    state = { view_count: 0, cur_view: null,
        regName: '' }

    constructor(props) {
		super(props);
    }

    refreshState = (cur_view) => {
        //console.log("cur_view ===>", cur_view)
       this.props.refreshCallback(cur_view);
      }

    componentDidMount = () => {
        let is_admin = Store.getStoreData('is_admin');
        if (is_admin) {
            let cur_view = 'csl_index';
            this.setState({cur_view})
        }else{
            let cur_view = 'select_index';
            let role = Store.getStoreData('role')
            if(role === 'no_access'){
                cur_view = 'no_access';
            }
            //console.log("componentDidMount",cur_view)
            this.setState({cur_view})
        }
        let referenceUrl = false;
        console.log("document.referrer", document.referrer);
        let task_id = this.props.match ? this.props.match.params.taskId : 0;
        if(task_id !== 0){
            referenceUrl = true;
        }
        let isReferenceEmail = false;
        if(referenceUrl && document.referrer === ""){
            isReferenceEmail = true;
        }
        Store.updateStore('isReferenceEmail',isReferenceEmail)
        Store.updateStore('isreferenceUrl',referenceUrl)
    }

    refreshThisState = (view) => {
		this.setState({cur_view: view});
    }

    alertHandler = (result, stack) => {
        window.location = '/';
    }

    showCardDetails = (id, regName) => {
        console.log("Sourav", id)
        this.setState({cur_view: id, regName: regName})
    }
    backToDashboard = () => {
        this.setState({cur_view: 'select_index'})
    }

    render() {
        let task_id = this.props.match ? this.props.match.params.taskId : 0;
        let cur_tab = this.props.match ? this.props.match.params.mode : 'ru';
        let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
               alertHandler: this.alertHandler, stack: {id: 0}}

        console.log("task_id", task_id);
        let contact = "";
        if(Store.getStoreData('contact') !== null){
            contact = 'Welcome back , '+Store.getStoreData('contact');
        }
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                {
                    (() => {
                        if(task_id > 0) {
                            return(<TaskHeader />)
                        }else {
                            return(<RcmHeader refreshParentState={this.refreshThisState} current_tab={cur_tab}/>)
                        }
                    })()
                }
                <WelcomeBanner welcome={contact} />
                {
                    (() => {
                        if(task_id > 0) {
                            return <RegisterIndex refreshParentState={this.refreshState} taskId={task_id}/>;
                        }else{
                            if(this.state.cur_view) {
                                switch(this.state.cur_view) {
                                    case 'csl_index' : return <CSLIndex current_tab={cur_tab}/>; break;
                                    case 'select_index' : return <SelectIndex refreshCallback={this.refreshState} showCardDetails={this.showCardDetails}/>; break;
                                    case 'register_index': return <RegisterIndex />; break;
                                    case 'new_rus': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"New RUs"} regName={this.state.regName}/>; break;
                                    case 'inprogress_rus': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"IN PROGRESS RUs"} regName={this.state.regName}/>; break;
                                    case 'dismissed_rus': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"DISMISSED RUs"} regName={this.state.regName}/>; break;
                                    case 'custom_rus': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"CUSTOM RUs"} regName={this.state.regName}/>; break;
                                    case 'saved_rus': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"SAVED RUs"} regName={this.state.regName}/>; break;
                                    case 'my_rus': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"MY RUs"} regName={this.state.regName}/>; break;
                                    case 'my_tasks': return <AllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"MY TASKS"} regName={this.state.regName}/>; break;
                                    case 'my_rus_team': return <TeamAllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"MY RUs"} regName={this.state.regName}/>; break;
                                    case 'my_tasks_team': return <TeamAllRus backToDashboard={this.backToDashboard} cur_view={this.state.cur_view} headerText={"MY TASKS"} regName={this.state.regName}/>; break;
                                    case 'no_access' : return <AlertBox alertParam = {alert_param}/>; break;
                                }
                            }
                        }
                    })()
                }
                {/* <CSLLoader /> */}
            </div>
        );
    }
}

export default Index;
