import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import AddRUNotification from './AddRUNotification';
import ProcessRUNotification from './ProcessRUNotification';
const RUPTable = styled.table`
    table-layout: fixed;
    width: 100%;
    margin-top: 15px;
`;
const RUPButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
class RUNotification extends React.Component
{

    state = {
        isLoaded: false,
        rnfeeds: null,
        modal_view: null,
        doctypeObject: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const feedsdata = Store.getStoreData('rn_feeds');
        //const documentType = Store.getStoreData('rutypename');
        const documentType = Store.getStoreData('rutypenameAll');
        let doctype = {};
        for(let i=0; i < documentType.length; i++) {
            doctype[documentType[i].id] = documentType[i].documentname;
        }
        //console.log(doctype);

        this.setState({ rnfeeds: feedsdata,doctypeObject: doctype,isLoaded: true});
        //console.log('state', this.state);
    }

    processMyFeedsData = () => {
        console.log("feedsdata", "feedsdata");
        let ret = {data: [], columns: []};
        let feedsdata = this.state.rnfeeds;
        //console.log("feedsdata=====>",feedsdata);
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Name', accessor: 'document_name', minWidth: 180, headerStyle: {textAlign: 'left'}},
                        {Header: 'Regulator Name', accessor: 'regulator_name', width: 160, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Types', accessor: 'document_types', width: 150, headerStyle: {textAlign: 'left'}},
                        {Header: 'Date of Issue', accessor: 'issuing_date', width: 165, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                              return 0;
                            }
                            let aReverse = 0;
                            let bReverse = 0;
                            let adue = a.split("/");
                            aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                          }},
                        {Header: 'Action', accessor: 'action', width: 110, Cell: row => (
                            <div>
                                {
                                    (()=> {
                                        if (row.original.change_act === 0) {
                                            return(<RUPButton value={row.original.id} onClick={this.showRUNotificationModal(row.original.change_act)}>Process</RUPButton>);
                                        } else {
                                            return(<RUPButton value={row.original.id} onClick={this.showRUNotificationModal(row.original.change_act)}>View</RUPButton>);
                                        }
                                    })()
                                }
                            </div>
                        ),headerStyle: {textAlign: 'left'}}];

        for(let d of feedsdata) {
            //let json_feed = JSON.parse(d.feed_json);
            //let update_json_feed = JSON.parse(d.feed_update_json);
            i++;
            let documentTypes = d.documentTypes;
            //console.log("documentTypes======>",documentTypes)
            let dtype = "";
            let dNames = "";
            if (documentTypes !== null) {
                let i=0;
                for (let doc of documentTypes){
                    i++;
                    if(doc in this.state.doctypeObject){
                        dNames += this.state.doctypeObject[doc]
                        if(i <= documentTypes.length - 1) {
                            dNames += ', '
                        }
                    }
                }
            }
            //let doctypename = this.state.doctypeObject[dtype];
            let issueDate = d.issueDate;
            const issue_obj = new Date(issueDate);
            const issue_day = issue_obj.getDate() < 10 ? "0"+issue_obj.getDate().toString() : issue_obj.getDate();
            const issue_month = issue_obj.getMonth()+1 < 10 ? `0${(issue_obj.getMonth() + 1).toString()}` : issue_obj.getMonth()+1;
            const issue_year = issue_obj.getFullYear();
            issueDate = `${issue_day}/${issue_month}/${issue_year}`;

            let  regulatorObject = Store.getStoreData('ragulatorsKnowAsCSL');
            let regulatorName = "";
            let updatedRegulatrName = d.ragulatorName.replace(/(&rsquo;)/g, "");
            //console.log("check==>", d.documentName,"===",updatedRegulatrName)
            if(updatedRegulatrName === "European Insurance and Occupational Pensions Autho"){
                updatedRegulatrName = "European Insurance and Occupational Pensions Authority"
            }
            if(updatedRegulatrName === "Information Commissioner"){
                updatedRegulatrName = "Information Commissioner"
            }
            if(updatedRegulatrName === "HM Treasury (HMT)"){
                updatedRegulatrName = "Her Majesty's Treasury"
            }
            if(updatedRegulatrName === "Information Commissioners Office"){
                updatedRegulatrName = "Information Commissioner"
            }
            if(updatedRegulatrName === "Information Commissioner's Office"){
                updatedRegulatrName = "Information Commissioner"
            }

            if('ragulatorName' in d && updatedRegulatrName in regulatorObject){
                regulatorName = regulatorObject[updatedRegulatrName];
            }

            let action = <RUPButton value={d.id} onClick={this.showRUNotificationModal(d.change_action)}>{d.change_action === 0 ? 'Process' : 'View'}</RUPButton>;
            let elem = {
                    'index' : i,
                    'id': d.id,
                    'document_name' : d.documentName.replace(/’/g, "'"),
                    'regulator_name' : regulatorName,
                    'document_types' : dNames,
                    'issuing_date' : issueDate,
                    'change_act' : d.change_action,
                    //'action' : action
                   };
           ret.data.push(elem);
        }
        let sarr = [];
        ret.data.forEach((item) => {
            if (item.change_act === 0) {
                sarr.push(item);
            }
        })
        let sarrsort = sarr.sort((a,b) => {
          if (a.issuing_date === b.issuing_date) {
            return 0;
          }
          let adue = a.issuing_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let  bbdue = b.issuing_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
        });
        let sarrnew = [];
        ret.data.forEach((item) => {
            if (item.change_act === 1) {
                sarrnew.push(item);
            }
        })
        let sarrsortnew = sarrnew.sort((a,b) => {
          if (a.issuing_date === b.issuing_date) {
            return 0;
          }
          let adue = a.issuing_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let  bbdue = b.issuing_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
        });
        let newarray = [];
        let incount = 0;
        sarrsort.forEach((item, index) => {
            item.index = index + 1
            incount = incount + 1;
            newarray.push(item);
        })
        sarrsortnew.forEach((item, index) => {
            item.index = incount + (index + 1)
            newarray.push(item);
        })
        //newarray=sarrsortnew
        ret.data = newarray
        return ret;
    }

    showRUNotificationModal = (changeActionValue) => (event) => {
        event.preventDefault();
        let url = Store.getStoreData('api_url');
        console.log("url",url)
        let is_admin = Store.getStoreData('is_admin');
        let is_debug = Store.getStoreData('is_debug');
        let postData = { "command" : "getfeed" , "feed_id": event.target.value, 'env' : is_admin ? 'csl' : 'select', "is_rn": 1};
        console.log("postData", postData);

		let httpVerb = { method: "POST", mode: "cors", headers: {"Content-Type": "application/x-www-form-urlencoded"}, body: "jsondata=" + JSON.stringify(postData) };
        fetch(url, httpVerb)
        .then((res) => res.json())
        .then(result => {
            console.log("result+++",result)
            Store.updateStore('rn_cur_feed', JSON.parse(result.feed.feed_update_json));
            Store.updateStore('current_activityLog', result.feed.activity_log);
            Store.updateStore('istask_action', result.feed.change_action);
            Store.updateStore('is_custom', result.feed.is_custom);
            Store.updateStore('istask_dismiss', result.feed.isdismiss);
            Store.updateStore('tags', result.tags);
            Store.updateStore('changeActionValue', changeActionValue);
            this.setState({modal_view: 'process_regulatory_modal'})
        })
    }

    addregulatory = (event) => {
        event.preventDefault();
        this.setState({modal_view: 'add_ru_notification_modal'})
    }

    closeModal = () => {
        this.setState({ modal_view: null });
    }

    render()
    {
        // console.log("STATE===>",this.state.modal_view);
        if(this.state.isLoaded === false) {
            return <div>Loading...</div>
        }
        return (
            <IndexContainer>
                <AddRbutton onClick = {this.addregulatory}>Add New</AddRbutton>
                <CSLTable add={false} processData={this.processMyFeedsData} tableRows={10} headerText={'New Regulatory Notifications'} />
                {
                  (() => {
                        if(this.state.modal_view !== null){
                            switch(this.state.modal_view){
                                case 'add_ru_notification_modal': return <AddRUNotification closeModal={this.closeModal}/>; break;
                                case 'process_regulatory_modal': return <ProcessRUNotification closeModal={this.closeModal}/>; break;
                            }
                        }
                        })()
                }
            </IndexContainer>
        );
    }
}
export default RUNotification;
