import React from 'react';
import styled from 'styled-components';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosTrash} from 'react-icons/io';
import CSLMultiSelect from './CSLMultiSelect';
import SingleSelect from './SingleSelect';
import DateSelect from './DateSelect';

const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: #000000;
	padding: 10px 20px;
`;
const FilterText = styled.div`
	float: left;
`;
const InfoText = styled.div`
	font-size: 11px;
	font-weight: 400;
`;
const FilterChevron = styled.div`
	float: right;
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const FilterCell1 = styled.div`
	padding: 5px;
	width: calc(17% - 5px);
	float: left;
	box-sizing: border-box;
	height: 75px;
`;
const FilterCell2 = styled.div`
	padding: 5px;
	width: calc(33% - 5px);
	float: left;
	box-sizing: border-box;
	height: 75px;
`;
const FilterCell3 = styled.div`
	padding: 5px;
	width: calc(50% - 5px);
	float: left;
	box-sizing: border-box;
	height: 75px;
`;
const FilterCell4 = styled.div`
	padding: 5px;
	width: calc(67% - 5px);
	float: left;
	box-sizing: border-box;
	height: 75px;
`;
const FilterCell5 = styled.div`
	padding: 5px;
	width: calc(84% - 5px);
	float: left;
	box-sizing: border-box;
	height: 75px;
`;
const FilterCell6 = styled.div`
	padding: 5px;
	width: calc(100% - 5px);
	float: left;
	box-sizing: border-box;
	height: 75px;
`;
const Label = styled.div`
	padding-bottom: 7px;
	font-weight: bolder;
`;
const FilterButtonsDiv = styled.div`
	background-color: #ffffff;
	padding: 5px 35px;
`;
const ClearButton = styled.div`
	background-color: #ffffff;
	color: #000000;
	padding: 10px 20px;
	border: 1px solid #000000;
	float: right;
	border-radius: 4px;
	cursor: pointer;
	margin-right: 5px;
`;
const ApplyButton = styled.div`
	background-color: #04ADA8;
	color: #ffffff;
	padding: 10px 20px;
	border: 1px solid #04ADA8;
	float: right;
	border-radius: 4px;
	cursor: pointer;
	margin-right:-25px;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const ModalInput = styled.input`
    height: 35px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
`;
const Dropdown = styled.select`
	display: block;
    box-sizing: border-box;
    height: 38px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
`;

class TextInput extends React.Component
{
	state = {text: ''}

	setComponentState = () => {
		this.setState({text: this.props.selectedItems.text})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prev_props) => {
		if(prev_props === this.props) return
		this.setComponentState()
	}

	onInputChange = (event) => {
		event.preventDefault();
		this.props.selectCallback(event.target.value, this.props.stack)
        this.setState({[event.target.name]: event.target.value});
	}

	render() {
		return (<div style={{padding: "5px"}}>
					<ModalInput name="text" value={this.state.text} onChange={this.onInputChange} />
				</div>)
	}
}


class AdvanceFilter extends React.Component
{
	state = {show_filter: true, filters: {}, view_count: 0}

	init_filters = null

	setComponentState = () => {
		//// console.log('AdvanceFilter this.props', this.props)
		this.setState({filters: this.props.filters})
	}

	componentDidMount = () => {
		if(this.init_filters === null){
			this.init_filters = JSON.stringify(this.props.filters)
			// console.log('clearAdvancedFilter this.init_filters, this.props.filters', this.init_filters, this.props.filters)
		}
		this.setComponentState()
	}

	componentDidUpdate = (prev_props) => {
		if(this.props === prev_props)return
		this.setComponentState()
	}

	toggleFilter = () => {
		// event.preventDefault();
		let show_filter = this.state.show_filter ? false : true;
		this.setState({show_filter});		
	}

	selectCallback = (selected, stack) => {
		let type = stack.type
		let filter_id = stack.filter_id
		let filters = this.state.filters
		switch(type) {
			case 'multi': filters[filter_id].selections[selected] = filters[filter_id].options[selected]; break;
			case 'text' : filters[filter_id].selections = selected === '' ? {} : {text: selected}; break;
			case 'single' : filters[filter_id].selections = selected === 'Choose' ? {} : {[selected] : 1}; break;
			case 'date_range' : filters[filter_id].selections = selected; break;
			default: filters[filter_id].selections.selected = filters[filter_id].options[selected];
		}
		
		this.setState({filters})
	}
	deselectCallback = (deselected, stack) => {
		let type = stack.type
		let filter_id = stack.filter_id
		let filters = this.state.filters
		delete filters[filter_id].selections[deselected]
		this.setState({filters})
	}

	clearFilter = () => {
		let filters = this.state.filters
		// for(let id in filters) {
		// 	switch(filters[id].type) {
		// 		case 'text': filters[id].selections.text = ''; break;
		// 		case 'multi': filters[id].selections = {}; break;
		// 		case 'single': filters[id].selections['Choose'] = {name: 'Choose'}; break;
		// 		case 'date_range': filters[id].selections.from_date = '';filters[id].selections.to_date = ''; break;
		// 	}
		// 	// if(filters[id].type === 'text') {
		// 	// 	filters[id].selections.text = ''
		// 	// } else {
		// 	// 	filters[id].selections = {}
		// 	// }
		// }
		// this.props.clearAdvancedFilter()
		// this.setState({filters})
	}

	clearAdvancedFilter = () => {
		window.location.reload(true)
		// // console.log('clearAdvancedFilter clear called',  this.init_filters)
		// this.setState({view_count: this.state.view_count + 1, filters: JSON.parse(this.init_filters)})
	}

	applyFilter = () => {
		this.props.applyFilter(this.state.filters)
	}

	render()
	{
		if (this.state.filters === null) {
			return (<div>Loading</div>);
		}
		let show_all = false;
		if ("show_all" in this.props) show_all = this.props.show_all;
		// console.log('clearAdvancedFilter this.state', this.state);
		let filters = this.state.filters['filters'];
		return (
			<div style={{height: '100%'}}>
			<FilterTopBar>
				<FilterText>Define Query & Apply Filters</FilterText>
				<div style={{clear: 'both'}} />
				{
					(() => {
						if (show_all === false) {
							return (<InfoText>* Fields with a single value throughout the table are excluded.</InfoText>);
						}
					})()
				}
				<FilterChevron onClick={() => this.toggleFilter()}>
				{
					(() => {
						if (this.state.show_filter) {
							return <IoIosArrowUp />
						} else {
							return <IoIosArrowDown />
						}
					})()
				}
				</FilterChevron>
				<div style={{clear: "both"}}></div>
			</FilterTopBar>
			{
				(() => {
					if(this.state.show_filter) {
						return (<FilterBody>
								{
									Object.keys(this.state.filters).map((id, i) => {
										let filter = this.state.filters[id]
										const filterCells = {1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4, 5: FilterCell5, 6: FilterCell6}
										const FilterCell = filterCells[filter.size.toString()]
										const filterInputs = {multi : CSLMultiSelect, text: TextInput, single: SingleSelect, date_range: DateSelect}
										const FilterInput = filterInputs[filter.type]
										//// console.log('AdvanceFilter id', id)
										//// console.log('AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)
										if (show_all === false) {
											if(Object.keys(this.state.filters[id].options).length <= 1) return <div key={i}></div>
										}
										if(!('is_grouping' in this.state.filters[id])) {
											return (<FilterCell key={i}>
													<Label>{filter.nickname}</Label>
													{
														(() => {
															return (<FilterInput 
																		items={filter.options} 
																		selectedItems={filter.selections} 
																		stack={{type : filter.type, filter_id: id}}
																		selectCallback={this.selectCallback}
																		deselectCallback={this.deselectCallback}
																	/>)
														})()
													}
												</FilterCell>)
										}
									})
								}
								{
									Object.keys(this.state.filters).map((id, i) => {
										let filter = this.state.filters[id]
										const filterCells = {1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4, 5: FilterCell5, 6: FilterCell6}
										const FilterCell = filterCells[filter.size.toString()]
										const filterInputs = {multi : CSLMultiSelect, text: TextInput, single: SingleSelect, date_range: DateSelect}
										const FilterInput = filterInputs[filter.type]
										//// console.log('AdvanceFilter id', id)
										//// console.log('AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)
										if(Object.keys(this.state.filters[id].options).length <= 1) return <div key={i}></div>
										if('is_grouping' in this.state.filters[id]) {
											return (<FilterCell key={i}>
													<Label>{filter.nickname}</Label>
													{
														(() => {
															return (<FilterInput 
																		items={filter.options} 
																		selectedItems={filter.selections} 
																		stack={{type : filter.type, filter_id: id}}
																		selectCallback={this.selectCallback}
																		deselectCallback={this.deselectCallback}
																	/>)
														})()
													}
												</FilterCell>)
										}
									})
								}
								<div style={{clear: "both"}}></div>
								<FilterButtonsDiv>
									<ApplyButton onClick={() => this.applyFilter()}>Query</ApplyButton>
									<ClearButton onClick={() => this.props.clearAdvancedFilter()}>Clear</ClearButton>
								</FilterButtonsDiv>
								<div style={{clear: "both"}}></div>
								</FilterBody>)
					}
				})()
			}
			</div>
			);
	}
}

export default AdvanceFilter;