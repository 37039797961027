import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {chevronDown} from 'react-icons-kit/ionicons/chevronDown';
import {chevronUp} from 'react-icons-kit/ionicons/chevronUp';

const DropContainer = styled.div`
	min-height: 40px;
	width: 100%;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 8px 10px;
`;
const Section70 = styled.div`
	float: left;
	width: 70%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section15 = styled.div`
	float: left;
	width: 30%;
	box-sizing: border-box;
`;
const ColorBox = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 4px;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`;
const SingleOption = styled.div`
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #DBDBDB;
	cursor: pointer;
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;

class CslDropDown extends React.Component
{
	state = {
		data: [],
		selected: {},
		show_options: false
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		console.log("this.props.data",this.props.allData);
		console.log("this.props.data",this.props.selectedAllData);
		let data = this.props.allData
		let selected = this.props.selectedAllData
		this.setState({data,selected})
	}

	componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if(this.props.isclear){
				console.log("Sourav2")
				const options = this.state.data;
				const selected = this.props.selectedAllData    
				this.props.changeClear()
                this.setState({selected: selected, data: options})
            }
        }
    }

	componentWillUnmount() {
	    document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = (node) => {
	    this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		if (this.state.show_options && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			// alert('You clicked outside of me!');
			this.setState({show_options: false});
		}
	}

	toggleOptions = (event) => {
		event.preventDefault();
		const show_options = this.state.show_options ? false : true;
		this.setState({show_options});
	}

	makeSelected = (data) => (event) => {
		event.preventDefault();
		this.setState({selected: data, show_options: false});
		this.props.statusValue(data);
	}

	render()
	{
		return (
			<div style={{position: "relative"}} ref={this.setWrapperRef}>
				<DropContainer>					
					<Section70 style={{paddingTop: "3px"}}>{this.state.selected.label}</Section70>
					<Section15 style={{textAlign: "right", paddingTop: "3px"}}>
						{
							(() => {
								if (this.state.show_options) {
									return <Icon icon={chevronUp} onClick={this.toggleOptions} style={{cursor: "pointer"}} />
								} else {
									return <Icon icon={chevronDown} onClick={this.toggleOptions} style={{cursor: "pointer"}} />
								}
							})()
						}
					</Section15>
					<div style={{clear: "both"}}></div>
				</DropContainer>
				{
					(() => {
						if (this.state.show_options) {
							return (
								<OptionsContainer>
									{
										this.state.data.map((item) => {
											return (
												<SingleOption key={item.id} onClick={this.makeSelected(item)}>													
													<Section70 style={{paddingTop: "3px"}}>{item.label}</Section70>
													<div style={{clear: "both"}}></div>
												</SingleOption>
											);
										})
									}
								</OptionsContainer>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default CslDropDown;