import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FaTimes,FaEdit,FaSearch, FaRegTrashAlt,FaPlusCircle, FaPencilAlt} from 'react-icons/fa';
import {IoIosArrowUp} from 'react-icons/io';
import Store from '../../Common/Store';
import RCMTagsView from '../Modals/RCMTagsView';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import Dropzone from '../Common/Dropzone';
import Utils from '../../Common/Utils';
import SearchablePills from './../Common/SearchablePills';
import HtmlEditor from '../../HtmlEditor';
import AddEditKeyDates from './../Modals/KeyDates/AddEditKeyDates';
import AddEditExternalLinks from './../Modals/ExternalLinks/AddEditExternalLinks';
import CSLLoader from './../Common/CSLLoader';
import moment from 'moment';
import AlertBox from './../Common/AlertBox';
import ClipLoader from "react-spinners/ClipLoader";

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 0%;
    z-index: 1000;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #282828;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
    padding: 6px;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #252525;
`;
const RegModalNameLabelDiv = styled.div`
    color: #252525;
    font-weight: 600;
    margin-top: 10px;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegModalNameInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 5px;
    cursor: pointer;
`;
const RegMarkForActionButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #d5d5d5;
    color: #757575;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    margin-left: 5px;
    font-size: 10px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegShareButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #234062;
    color: #ffffff;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font-size: 11px;
    margin-left: 5px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 5px;
    /* float: right; */
    cursor: pointer;
`;
const StatusBadge = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 200px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor:default;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 96%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;  
    font-family: 'Montserrat', sans-serif; 
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 95%;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 37px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #343434;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #7a8da1;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const ProgressContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 400px;
    position: fixed;
    top: 80px;
    text-align: center;
    left: calc(50vw - 200px);
    z-index: 1008;
    color:  #143151;  
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
`;

const ProgressBarTotal = styled.div`
    width: 380px;
    height: 14px;
    background-color: white;
    border: 1px solid #04ADA8; 
    margin-top: 8px;
    margin-bottom: 8px;
`;

const ProgressBar = styled.div`
    height: 100%;
    background-color: blue;
    float: left;
`;
const AlertBoxContainer = styled.div`
	position: fixed;
	top: 40vh;
	left: 40vw;
    width: 450px;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F7F7F7;
    color: #2D2D2D;
    border-radius: 10px;
    border-style: solid;
    border-color: #F7F7F7;
    z-index: 2000;
`
const AlertBoxBody = styled.div`
    width: calc(100% - 30px);
    background-color: #F7F7F7;
    color: #646A7E;
    font-size: 18px;
    min-height: 80px;
    text-align: center;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px
`
const AlertBoxFooter = styled.div`
    width: calc(100% - 15px);
    background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
`
const OkButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #04ACA7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
class AutoForwordFeedModalForGC extends React.Component
{
    state = {
        ready: false,
        documentname: '',
        summary: '',
        docType: '',
        upload_date: '',
        tags: [],
        sharedata: null,
        shareAcknowledgeMentdata: null,
        shareobject: {},
        shareUserAll: {},
        shareUserInfo: {},
        shareUserAcknowledge: {},
        show_Buttons_panel: true,
        button_type : '',
        shareModal: false,
        rowchecked: false,
        acknowledgeModal: false,
        searchUserShareList:[],
        reasonCode: "",
        newReasonCode: [],
        distributionCode: 0,
        distributionLists: [],
        showDistributionShareUser: false,
        followuptask: false,
        date: new Date(),
        assign_note: "",
        due_by: '1970-01-01',
        assigned_to: '',
        binFiles: [], 
        cur_files_json: null, 
        isdesable: false,
        feedID: 0,

        editsection: false,
        document_types: [],
        uploaddate: new Date(),
        sections: [],
        externalLink: [],
        keyDatesValue: [], 

        hiddenAddButton: true,
        showAddKeyDateModal: false,
        curKeyid: 0,
        content: null,

        hiddenAddButtonLink: true, 
        showAddLinkModal: false, 
        curLinkid: 0,
        contentLink: null,
        regulatorType: null,
        ragulatortypeName: "",
        isLoaderIcon: false,
        groupCompanysList: [],
        rowcheckedgc: false,
        sendClientRN: {},
        show_progress: false,
        share_count: 0,
        stop_polling: false,
        alert_param: null,

        isReasonForDismissalShow: false,
        dismiss_note: '',        
        reason_for_dismissal: [],
        selected_reason_for_dismissal: '',
        isShowDismissalDropdown: false
    }

    stop_polling = false

    constructor(props) {
        super(props);
        Store.updateStore('sections', []);
    }    

    componentDidMount() {
        let curAutoForwardData = Store.getStoreData('cur_autoForwordFeeds');
        console.log("curAutoForwardData", curAutoForwardData)
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 
        let save_json_by_companyid = JSON.parse(curAutoForwardData.save_json_by_companyid); 

        let save_json_by_companyid_object = save_json_by_companyid !== null ? save_json_by_companyid : {};
        let companyID = Store.getStoreData('companyid');
        

        let selectedtags = [];
        if("tags" in update_json_feed) {
            let all_tags = update_json_feed.tags;
            if(companyID in save_json_by_companyid_object){            
                all_tags = save_json_by_companyid_object[companyID].tags;   
            }
            for (let tag of all_tags){                
                selectedtags.push(tag.toString())
            }            
        }
        console.log("rufeed ====> selectedtags", selectedtags);
        let otags = [];
        let fetchtags = Store.getStoreData('tags')
        //console.log("fetchtags===>", fetchtags);
        fetchtags.forEach((top) => {
            if (selectedtags.includes(top.ID)) {
                top.selected = true;
            } else {
                top.selected = false;
            }
            otags.push({
                name: top.TagName,
                id: top.ID,
                styledcomponent: top.selected ? SelectedPill : UnselectedPill,
                selected: top.selected
            });
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let tags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    tags.push(otags[j]);
                }
            }
        }

        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')
        
        let documentname = update_json_feed.documentName;
        let summary = update_json_feed.summary;
        let feedID = curAutoForwardData.feed_id

        //let documentTypes = update_json_feed.documentTypes;  
        // let dtype = "";
        // if (documentTypes !== null) {
        //     for (let doc of documentTypes){
        //         dtype = dtype + doc +",";
        //         dtype = dtype.substring(0, dtype.length - 1)
        //     }
        // }

        const documentType = Store.getStoreData('rutypenameAll');
        let doctypenew = {};
        for(let i=0; i < documentType.length; i++) {
            doctypenew[documentType[i].id] = documentType[i].documentname;
        } 

        let documentTypes = update_json_feed.documentTypes;     
        if(companyID in save_json_by_companyid_object){            
            documentTypes = save_json_by_companyid_object[companyID].documentTypes;   
        }   
        
        let doctypesObject = {}
        for(let s of documentTypes){
            doctypesObject[s.toString()] = 1
        }

        let dNames = "";
        if (documentTypes !== null) {
            let i=0;
            for (let doc of documentTypes){
                i++;
                if(doc in doctypenew){
                    dNames += doctypenew[doc]
                    if(i <= documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }

        let uploaddate = new Date()
        if(update_json_feed.issueDate !== null){
            uploaddate = new Date(update_json_feed.issueDate);
        }
        
        if(companyID in save_json_by_companyid_object){        
            let issdate = save_json_by_companyid_object[companyID].issueDate;  
            let newuploaddate = moment(issdate, 'YYYY/MM/DD');          
            uploaddate = new Date(newuploaddate);            
        }

        //let docType = doctypenew[dtype];

        const upload_date_obj = new Date(update_json_feed.uploadDate);
        const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
        const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
        const upload_year = upload_date_obj.getFullYear();
        let upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        let shareUserAll = {};
        let sarray = [];
        let sAcknarray = [];
        let sharedataAll = Store.getStoreData('shareuserlist');  
        let updateSarray = [];
        for(let i=0;i<sharedataAll.length;i++){            
            if(!(sharedataAll[i].id.toString() in shareUserAll)){   
                updateSarray.push(sharedataAll[i]);               
            }            
        }
        //new
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            updateSarray.forEach((item) => {
                if(item.id in gc_users){
                    sarray.push(item); 
                }
            })                                                                     
        }else{                                    
            sarray = updateSarray;
        }
        //new end
        sarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        console.log("sarray", sarray); 

        let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');   
        let updatesAcknarray = [];               
        for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){            
            if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){   
                updatesAcknarray.push(shareAcknowledgeMentdataAll[i]);                
            }            
        }
        //new
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            updatesAcknarray.forEach((item) => {
                if(item.id in gc_users){
                    sAcknarray.push(item); 
                }
            })                                                                     
        }else{                                    
            sAcknarray = updatesAcknarray;
        }
        //new end
        sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("sAcknarray", sAcknarray);

        let reasonCode = "";
        let newreason = [];
        let module_config = Store.getStoreData('module_config');
        if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
            if('reason_code' in module_config && module_config.reason_code.length !== 0){
                module_config.reason_code.forEach((item) => {
                    if (item.isDefaultcode === 1) {
                        reasonCode = item.reasonName;
                    }
                    if(item.isActive === 1){
                        newreason.push(item);
                    }
                })
            }
        }
        let all_contacts = Store.getStoreData('contacts');
        let com_roles_userid = {}
        all_contacts.forEach((user) => {     
            com_roles_userid[user.ID] =  1;     
        })
        let gcCheck_distribution = false;
        let distributionLists = []
        if('general' in module_config && module_config.general.enable_distribution_lists === true){
            if('distribution_list' in module_config && module_config.distribution_list.length !== 0){
                let allUser = {distributionID: 12, distributionName: "All Users", assingnedUserID: com_roles_userid};
                if('general' in module_config && module_config.general.enable_add_new_ru === true){
                    //console.log("gcCheck_distribution", gcCheck_distribution)
                    module_config.distribution_list.forEach((item) => {
                        if(gcCheck_distribution){
                            if('isAvailableAllGCs' in item && item.isAvailableAllGCs === true)
                            distributionLists.push(item);
                        }else{
                            distributionLists.push(item);
                        }
                    })
                }else{
                    module_config.distribution_list.forEach((item) => {                        
                        distributionLists.push(item);
                    }) 
                }
                distributionLists.push(allUser);
            }
        }
        let shareUserAcknowledge = {}
        Store.updateStore('shareUserAcknowledgeUpdate', shareUserAcknowledge)
        Store.updateStore("shareUserAllnew", shareUserAll)

        let date = new Date();
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();

        
        let contacts = [];
        contacts = all_contacts.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} ); 

        //console.log("documentTypes==>", documentTypes)
        let document_types = [];        
        Store.getStoreData('rutypename').forEach((doc) => {            
            // if (documentTypes.includes(doc.id.toString())) {
            //     doc.selected = true;
            // } else {
            //     doc.selected = false;
            // }
            if (doc.id in doctypesObject) {
                doc.selected = true;
            } else {
                doc.selected = false;
            }
            document_types.push({
                id: doc.id,
                name: doc.documentname,
                selected: doc.selected,
                show: true
            }); 
        })
        document_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("document_types", document_types);

        //section
        let sections = [];
        if ("sections" in update_json_feed) {
            sections = JSON.parse(JSON.stringify(update_json_feed.sections));
        }
        if(companyID in save_json_by_companyid_object && 'sections' in save_json_by_companyid_object[companyID]){            
            sections = save_json_by_companyid_object[companyID].sections;   
        }
        console.log("sections===>", sections)
        for( let i=0; i<sections.length; i++)
        {
            sections[i].display = "label";
            sections[i].content = window.decodeURIComponent(atob(sections[i].content));
        }
        Store.updateStore('sections', sections);
        //section end

        let keyDates = [];
        if("keyDates" in update_json_feed) {    
            let keyDatesAll = update_json_feed.keyDates;
            keyDatesAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                keyDates.push(item);
            }) 
        }       

        let externalLinks = [];        
        if("externalLinks" in update_json_feed) {         
            let externalLinksAll = update_json_feed.externalLinks;
            externalLinksAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                externalLinks.push(item);
            })               
        }        

        //new concept        
        let gc_usersAll = {}              
        let updateContact = [];   
        contacts.forEach((item) => {
            if(item.ID in gc_usersAll){
                updateContact.push(item); 
            }
        })                                        
        //updateContact = contacts;        
        console.log("contacts===>", updateContact)
        //new concept

        var regulatortypeName = update_json_feed.ragulatorName === null ? "" : update_json_feed.ragulatorName;
        let regtype = Store.getStoreData('ragulatorsname'); 
        regtype.sort(function(a,b) {return (a.ragulatorname > b.ragulatorname) ? 1 : ((b.ragulatorname > a.ragulatorname) ? -1 : 0);} );

        let groupCompanysList = [];
        //let gc_companies = Store.getStoreData('gc_companies');
        let gc_companies = Store.getStoreData('gc_company');
        let sendClientRN = {};
        if(companyID in save_json_by_companyid_object && 'selectedCompanyIDs' in save_json_by_companyid_object[companyID] && Object.keys(save_json_by_companyid_object[companyID].selectedCompanyIDs).length !== 0){
            console.log("save_json_by_companyid_object[companyID].selectedCompanyIDs==>", save_json_by_companyid_object[companyID].selectedCompanyIDs)
            sendClientRN = save_json_by_companyid_object[companyID].selectedCompanyIDs;
            for(let p of gc_companies){
                if(p.is_active === 1){
                    if(p.id in save_json_by_companyid_object[companyID].selectedCompanyIDs){
                        let gcobject = {};
                        gcobject.gcid = p.id;
                        gcobject.gcname = p.company_name;
                        gcobject.select = true;
                        groupCompanysList.push(gcobject);
                    }else{
                        let gcobject = {};
                        gcobject.gcid = p.id;
                        gcobject.gcname = p.company_name;
                        gcobject.select = false;
                        groupCompanysList.push(gcobject);
                    }
                }
            }            
        }else{
            gc_companies.forEach((value) => {
                if(value.is_active === 1){
                    let gcobject = {};
                    gcobject.gcid = value.id;
                    gcobject.gcname = value.company_name;
                    gcobject.select = false;
                    groupCompanysList.push(gcobject);
                }
            }) 
        }  
        
        Store.updateStore('sharedataAll_sarray', sarray)
        Store.updateStore('shareAcknowledgeMentdata_sAcknarray', sAcknarray)     
        
        if(companyID in save_json_by_companyid_object){
            documentname = save_json_by_companyid_object[companyID].documentName;            
            summary = save_json_by_companyid_object[companyID].summary;  
            regulatortypeName = save_json_by_companyid_object[companyID].ragulatorName;   
            externalLinks = save_json_by_companyid_object[companyID].externalLinks;        
            keyDates = save_json_by_companyid_object[companyID].keyDates;  
        }
        console.log("tags====>", tags)
        //rcm v5
        let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        let reason_for_dismissal = [{'isActive': 1, 'isDefaultcode': 0, 'isDelete': 0, 'reasonID': 1111, 'reasonName': "Other"}];   
        let selected_reason_for_dismissal = "";     
        for(let d of reasondismissal){
            if(d.isActive === 1 && d.isDelete === 0){
                reason_for_dismissal.push(d);
            }
            if(d.isDelete === 0 && d.isDefaultcode === 1){
                selected_reason_for_dismissal = d.reasonName;
            }
        }
        reason_for_dismissal.sort(function(a,b) {return (a.reasonName > b.reasonName) ? 1 : ((b.reasonName > a.reasonName) ? -1 : 0);} );

        console.log("reason_for_dismissal===>", reason_for_dismissal)
        let isShowDismissal = 'isShowDismissalDropdown' in this.props ? this.props.isShowDismissalDropdown : false
        let isShowDismissalDropdown = false;
        let isReasonForDismissalShow = false;
        if('isDismissClickOnTable' in this.props && this.props.isDismissClickOnTable && isShowDismissal){
            isShowDismissalDropdown = true;
        }else if('isDismissClickOnTable' in this.props && this.props.isDismissClickOnTable && !isShowDismissal){
            isReasonForDismissalShow = true;
        }
        //rcm v5
        this.setState({due_by: dateStr, 
            // assigned_to: updateContact.length !== 0 ? updateContact[0].ID : 0,
            distributionLists:distributionLists,
            shareAcknowledgeMentdata: sAcknarray,searchUserShareAcknowList: sAcknarray,
            reasonCode: reasonCode,newReasonCode: newreason,
            sharedata: sarray,searchUserShareList: sarray,
            ready: true, documentname, summary, docType: dNames, upload_date, tags: tags, searchFilterTags: tags, feedID: feedID, 
            document_types, uploaddate: uploaddate, sections, keyDatesValue: keyDates, externalLink: externalLinks,
            ragulatortypeName: regulatortypeName, regulatorType: regtype, groupCompanysList,
            sendClientRN,
            reason_for_dismissal,
            selected_reason_for_dismissal,
            isShowDismissalDropdown,
            isReasonForDismissalShow})
    }

    closeRegulatoryModal = (event) => {
        this.stop_polling = true;
        this.props.closeModal();
    }

    selectOrUnselectTopic = (tagid) => {
        //event.preventDefault();
        console.log("Sourav22", tagid)
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    searchUsers = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
        console.log("fddsf",this.state.searchFilterTags)
        let search_string = event.target.value;
        if (search_string === "") {
            let tags = this.state.searchFilterTags;            
            this.setState({tags: tags});
        }else {
            let all_tags = this.state.searchFilterTags;
            let filtered_tags = [];
            all_tags.forEach((tag) => {
                if (tag.name.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_tags.push(tag);
                }                
            })            
            let tags = filtered_tags;

            this.setState({tags: tags})
        }
    }

    showShareModal = (event) => {
        event.preventDefault();  
        let problems = false
        let message = '';
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        if (tags.length === 0) {
            //alert("Please select tag(s) to take action.");
            message += 'Please select tag(s) to take action.<br>';
            problems = true;            
        }    
        if(Object.keys(this.state.sendClientRN).length === 0){
            //alert("Please select atleast one group company you want to share this RN.");
            message += 'Please select at least one group company in order to process this regulatory change.<br>';
            problems = true; 
            //return;
        }      
        
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{            
            //new start
            let gc_company = Store.getStoreData('gc_company');
            let gc_usersAll = {}
            for(let gc of gc_company) {
                if(gc.id in this.state.sendClientRN){
                    for(let uid in gc.users) {                    
                        gc_usersAll[uid] = 1
                    }
                }
            }
            let sharedataAll = Store.getStoreData('sharedataAll_sarray')     
            let update_sharedataAll = [];
            sharedataAll.forEach((value) => {                       
                if(value.id in gc_usersAll){
                    update_sharedataAll.push(value)
                }
            })
            console.log("update_sharedataAll==>",update_sharedataAll)
            //new end
            this.setState({ button_type: 'info',shareModal: true, rowchecked:false, show_Buttons_panel: false, acknowledgeModal: false, sharedata: update_sharedataAll, searchUserShareList: update_sharedataAll})
        }   
    }   

    aftercheckvalidationAll = (result, stack) => {  
        this.setState({alert_param: null})        
    }

    showAcknowledgeMentModal = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        let tags = [];
        //console.log("tags===>", this.state.tags)
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        if (tags.length === 0) {
            //alert("Please select tag(s) to take action.");
            message += 'Please select tag(s) to take action.<br>';
            problems = true; 
        }
        if(Object.keys(this.state.sendClientRN).length === 0){
            //alert("Please select atleast one group company you want to share this RN.");
            message += 'Please select at least one group company in order to process this regulatory change.<br>';
            problems = true;
        }

        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{ 
            var shareAcknowledgeMentdata = this.state.shareAcknowledgeMentdata; 
            let gc_company = Store.getStoreData('gc_company');
            let gc_usersAll = {}
            for(let gc of gc_company) {
                if(gc.id in this.state.sendClientRN){
                    for(let uid in gc.users) {                    
                        gc_usersAll[uid] = 1
                    }
                }
            }        
            let sharedataAcknowAll = Store.getStoreData('shareAcknowledgeMentdata_sAcknarray')    
            console.log("gc_usersAll==>", sharedataAcknowAll) 
            let update_sharedataAcknowAll = [];
            sharedataAcknowAll.forEach((value) => {                       
                if(value.id in gc_usersAll){
                    let val = 1;            
                    this.state.shareobject[value.id] = val;
                    this.state.shareUserAll[value.id] = val;
                    let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                    this.state.shareUserAcknowledge[value.id] = val2;
                    update_sharedataAcknowAll.push(value)
                }
            })
            console.log("update_sharedataAll==>",update_sharedataAcknowAll)
            //new end            
            this.setState({ button_type: 'acknow',acknowledgeModal: true,rowchecked:true, show_Buttons_panel: false, shareobject: this.state.shareobject,
            shareUserAll: this.state.shareUserAll, shareModal: true, shareUserAcknowledge : this.state.shareUserAcknowledge,
            shareAcknowledgeMentdata: update_sharedataAcknowAll,searchUserShareAcknowList: update_sharedataAcknowAll}) 
        }        
    }

    searchUsersShareTable = (event) => {        
        let search_string = event.target.value;        
        if(this.state.acknowledgeModal) {             
            if (search_string === "") {
                var shareAcknowledgeMentdata = this.state.searchUserShareAcknowList;
                this.setState({ shareAcknowledgeMentdata })
            }else {           
                let all_company_users = this.state.searchUserShareAcknowList;
                let shareAcknowledgeMentdata = [];
                all_company_users.forEach((user) => {
                    if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                        shareAcknowledgeMentdata.push(user);
                    }
                })
                this.setState({ shareAcknowledgeMentdata })
            }
        }else{        
            if (search_string === "") {
                var sharedata = this.state.searchUserShareList;
                this.setState({ sharedata })
            }else {           
                let all_company_users = this.state.searchUserShareList;
                let sharedata = [];
                all_company_users.forEach((user) => {
                    if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                        sharedata.push(user);
                    }
                })
                this.setState({ sharedata })
            }
        }
    }  
    
    changeAllRoles = (event) => {
        console.log(event.target.value);
        let search_role = event.target.value;
        if(this.state.acknowledgeModal) { 
            if(search_role === '0') {            
                let shareAcknowledgeMentdata = this.state.searchUserShareAcknowList
                this.setState({ shareAcknowledgeMentdata })
            }else {
                let all_company_users = this.state.searchUserShareAcknowList
                let shareAcknowledgeMentdata = [];
                all_company_users.forEach((user) => {
                    if (user.role.includes(search_role.toLowerCase())) {
                        shareAcknowledgeMentdata.push(user);
                    }
                })
                this.setState({ shareAcknowledgeMentdata })
            }
        }else{
            if(search_role === '0') {            
                let sharedata = this.state.searchUserShareList
                this.setState({ sharedata })
            }else {
                let all_company_users = this.state.searchUserShareList
                let sharedata = [];
                all_company_users.forEach((user) => {
                    if (user.role.includes(search_role.toLowerCase())) {
                        sharedata.push(user);
                    }
                })
                this.setState({ sharedata })
            }
        }        
    }

    selectAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;
        let userdata = this.state.sharedata;
        let userdataacknowledgement = this.state.shareAcknowledgeMentdata; 
        if(!this.state.acknowledgeModal) { 
            if(rowchecked) {                   
                let i = 0;                
                let selectArray = [];
                for(let d of userdata) {
                    let selectObject = {}           
                    i++;
                    let val = 1;            
                    this.state.shareobject[d.id] = val;
                    this.state.shareUserAll[d.id] = val;
                    let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                    this.state.shareUserInfo[d.id] = val2;
                    selectObject.id = d.id;
                    selectObject.name = d.name;
                    selectObject.email = d.email;
                    selectObject.role = d.role;
                    selectObject.select = true;
                    selectObject.foracknowledgement = false;
                    selectObject.forinformation = false;
                    selectObject.foracknowledgementCheck = false;
                    selectObject.forinformationCheck = true;                
                    selectArray.push(selectObject)
                }
                this.setState({rowchecked: rowchecked,sharedata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo}) 
            }else{                
                let i = 0;                
                let selectArray = [];
                for(let d of userdata) {
                    let selectObject = {}           
                    i++;
                    delete this.state.shareobject[d.id.toString()];
                    delete this.state.shareUserAll[d.id.toString()];
                    delete this.state.shareUserInfo[d.id.toString()];                
                    selectObject.id = d.id;
                    selectObject.name = d.name;
                    selectObject.email = d.email;
                    selectObject.role = d.role;
                    selectObject.select = false;
                    selectObject.foracknowledgement = true;
                    selectObject.forinformation = true;
                    selectObject.foracknowledgementCheck = false;
                    selectObject.forinformationCheck = false;                
                    selectArray.push(selectObject)
                }
                this.setState({rowchecked: rowchecked,sharedata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})
            }
        }else{
            console.log("rowchecked", rowchecked)
            if(rowchecked) {   
                let i = 0;           
                let selectArray = [];
                for(let d of userdataacknowledgement) {
                    let selectObject = {}           
                    i++;
                    let val = 1;            
                    this.state.shareobject[d.id] = val;
                    this.state.shareUserAll[d.id] = val;
                    let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                    this.state.shareUserAcknowledge[d.id] = val2;
                    selectObject.id = d.id;
                    selectObject.name = d.name;
                    selectObject.email = d.email;
                    selectObject.role = d.role;
                    selectObject.select = true;
                    selectObject.foracknowledgement = false;
                    selectObject.forinformation = true;
                    selectObject.foracknowledgementCheck = true;
                    selectObject.forinformationCheck = false;                
                    selectArray.push(selectObject)
                }
                this.setState({rowchecked: rowchecked,shareAcknowledgeMentdata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})
            }else{
                let i = 0;            
                let selectArray = [];
                for(let d of userdataacknowledgement) {
                    let selectObject = {}           
                    i++;
                    delete this.state.shareobject[d.id.toString()];
                    delete this.state.shareUserAll[d.id.toString()];
                    delete this.state.shareUserAcknowledge[d.id.toString()];                
                    selectObject.id = d.id;
                    selectObject.name = d.name;
                    selectObject.email = d.email;
                    selectObject.role = d.role;
                    selectObject.select = false;
                    selectObject.foracknowledgement = true;
                    selectObject.forinformation = true;
                    selectObject.foracknowledgementCheck = false;
                    selectObject.forinformationCheck = false;                
                    selectArray.push(selectObject)
                }
                this.setState({rowchecked: rowchecked,shareAcknowledgeMentdata: selectArray, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo})
            }
      
        }
   }

   selectUser = (event) => {        
        let index = event.target.value;
        let userdata = this.state.sharedata;
        var userdataacknowledgement = this.state.shareAcknowledgeMentdata;
        if(!this.state.acknowledgeModal) {
            console.log("Sourav 1")
            if(userdata[index].select){   
                console.log("Sourav 1.1")         
                userdata[index].foracknowledgement = true;
                userdata[index].forinformation = true;
                userdata[index].select = false;
                userdata[index].forinformationCheck = false;
                userdata[index].foracknowledgementCheck = false;
                delete this.state.shareobject[userdata[index].id.toString()];
                delete this.state.shareUserAll[userdata[index].id.toString()];
                delete this.state.shareUserInfo[userdata[index].id.toString()];
                delete this.state.shareUserAcknowledge[userdata[index].id.toString()]
            }else{
                console.log("Sourav 1.2")    
                let userid = userdata[index].id.toString();
                let val = 1;            
                this.state.shareobject[userid] = val;
                this.state.shareUserAll[userid] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};
                this.state.shareUserInfo[userid] = val2;

                userdata[index].foracknowledgement = false;
                userdata[index].forinformation = false;
                userdata[index].select = true;
                userdata[index].forinformationCheck = true;
                userdata[index].foracknowledgementCheck = false;
            } 
            this.setState({sharedata: userdata, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserInfo: this.state.shareUserInfo,rowchecked: false})
            let allselectcheck = true
            for(let d of userdata) {                
                if(!d.select){
                    allselectcheck = false
                }
            }
            if(allselectcheck){
                this.setState({rowchecked: true})
            }
        } else {            
            if(userdataacknowledgement[index].select){
                userdataacknowledgement[index].select = false;
                userdataacknowledgement[index].foracknowledgement = true;
                userdataacknowledgement[index].foracknowledgementCheck = false;
                delete this.state.shareobject[userdataacknowledgement[index].id.toString()];
                delete this.state.shareUserAll[userdataacknowledgement[index].id.toString()];
                delete this.state.shareUserAcknowledge[userdataacknowledgement[index].id.toString()];                
            } else {
                userdataacknowledgement[index].select = true;
                userdataacknowledgement[index].foracknowledgement = false;
                userdataacknowledgement[index].foracknowledgementCheck = true;
                let userid = userdataacknowledgement[index].id.toString();
                let val = 1;            
                this.state.shareobject[userid] = val;
                this.state.shareUserAll[userid] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};
                this.state.shareUserAcknowledge[userid] = val2;                
            }
            this.setState({shareAcknowledgeMentdata: userdataacknowledgement, shareobject:this.state.shareobject,shareUserAll: this.state.shareUserAll, shareUserAcknowledge: this.state.shareUserAcknowledge,rowchecked: false})
            let allselectcheck = true
            for(let d of userdataacknowledgement) {                
                if(!d.select){
                    allselectcheck = false
                }
            }
            if(allselectcheck){
                this.setState({rowchecked: true})
            }
        }
        console.log("userid:-",this.state.shareobject);
        console.log("useridAll:-",this.state.shareUserAll);
        console.log("shareuserinfo:-",this.state.shareUserInfo);
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
    }

    selectinformation = (event) => {        
        let index = event.target.value;
        let userdata = this.state.sharedata;
        if(userdata[index].forinformationCheck){            
            userdata[index].forinformationCheck = false;
            userdata[index].foracknowledgementCheck = true;
            let userid = userdata[index].id.toString();
            let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};            
            this.state.shareUserAcknowledge[userid] = val;
            delete this.state.shareUserInfo[userdata[index].id.toString()];
            //shareUserAcknowledge            
        }else{  
            let userid = userdata[index].id.toString();
            let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};            
            this.state.shareUserInfo[userid] = val; 
            delete this.state.shareUserAcknowledge[userdata[index].id.toString()];          
            userdata[index].forinformationCheck = true;  
            userdata[index].foracknowledgementCheck = false;          
        }        
        this.setState({sharedata: userdata, shareUserInfo:this.state.shareUserInfo,shareUserAcknowledge:this.state.shareUserAcknowledge})
        console.log("shareuserinfo:-",this.state.shareUserInfo);
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
    }
    selectacknowledgement = (event) => {
        if(!this.state.acknowledgeModal) {        
            let index = event.target.value;
            let userdata = this.state.sharedata;
            if(userdata[index].foracknowledgementCheck){            
                userdata[index].foracknowledgementCheck = false;
                userdata[index].forinformationCheck = true;
                let userid = userdata[index].id.toString();
                let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};            
                this.state.shareUserInfo[userid] = val; 
                delete this.state.shareUserAcknowledge[userdata[index].id.toString()];            
            }else{  
                let userid = userdata[index].id.toString();
                let val = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                this.state.shareUserAcknowledge[userid] = val;
                delete this.state.shareUserInfo[userdata[index].id.toString()];           
                userdata[index].foracknowledgementCheck = true;
                userdata[index].forinformationCheck = false;            
            }
            this.setState({sharedata: userdata, shareUserAcknowledge:this.state.shareUserAcknowledge,shareUserInfo:this.state.shareUserInfo})
        }
        console.log("shareUserAcknowledge:-",this.state.shareUserAcknowledge);
        console.log("shareuserinfo:-",this.state.shareUserInfo);
    }

    processMyshareData = () => {
        var sharedata = this.state.sharedata; 
        //console.log("sharedata",sharedata);    
        var shareAcknowledgeMentdata = this.state.shareAcknowledgeMentdata;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectAll} /></div>, Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectUser}/>
            </div>
         ), width: 60, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},         
        {Header: 'Name', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Role', accessor: 'role_value', minWidth: 80, headerStyle: {textAlign: 'left'}},
        {Header: 'Email', accessor: 'email_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {'Header' : 'For Information', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.for_information_check} value={row.index} disabled={row.original.for_information} onClick={this.selectinformation}/>
            </div>
         ), width: 142, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},
         {'Header' : 'For Acknowledgement', Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.for_acknowledgement_check} value={row.index} disabled={row.original.for_acknowledgement} onClick={this.selectacknowledgement}/>
            </div>
         ), width: 142, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}}];
         if(this.state.acknowledgeModal) { 
             console.log("Sourav1")            
            for(let d of shareAcknowledgeMentdata) {           
                i++;    
                let role = "";
                if(d.role === 'rcm_gatekeeper'){
                    role = "RCM Gatekeeper"
                }else if(d.role === 'manager'){
                    role = "Admin Manager"
                }else if(d.role === 'team'){
                    role = "Team"
                }                   
                let elem = {'index' : i,
                        'id': d.id, 
                        'name_value' : d.name,
                        'role_value' : role,
                        'email_value' : d.email,
                        'for_information' : d.forinformation,
                        'for_acknowledgement' : d.foracknowledgement,
                        'for_acknowledgement_check' : d.foracknowledgementCheck,
                        'for_information_check' : d.forinformationCheck,
                        'select' : d.select                    
                       };
                ret.data.push(elem);
            }
         }else {            
            for(let d of sharedata) {                
                //console.log("Sourav2")          
                i++;    
                let role = "";
                if(d.role === 'rcm_gatekeeper'){
                    role = "RCM Gatekeeper"
                }else if(d.role === 'manager'){
                    role = "Admin Manager"
                }else if(d.role === 'team'){
                    role = "Team"
                }                   
                let elem = {'index' : i,
                        'id': d.id, 
                        'name_value' : d.name,
                        'role_value' : role,
                        'email_value' : d.email,
                        'for_information' : d.forinformation,
                        'for_acknowledgement' : d.foracknowledgement,
                        'for_acknowledgement_check' : d.foracknowledgementCheck,
                        'for_information_check' : d.forinformationCheck,
                        'select' : d.select                    
                       };
                ret.data.push(elem);
            }
         }
        
        return ret;
    }

    handleOnChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    closeShareModal = (event) => {  
        let shareobject = {};
        let shareUserAll = {};
        let shareUserInfo = {};
        let shareUserAcknowledge = {};
        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')
        let sarray = [];
        let sAcknarray = [];        
        let sharedataAll = Store.getStoreData('shareuserlist');  
        let updateSarray = [];
        for(let i=0;i<sharedataAll.length;i++){            
            if(!(sharedataAll[i].id.toString() in shareUserAll)){ 
                updateSarray.push(sharedataAll[i]);                
            }
        }
        //new
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            updateSarray.forEach((item) => {
                if(item.id in gc_users){
                    sarray.push(item); 
                }
            })                                                                     
        }else{                                    
            sarray = updateSarray;
        }
        //new end
        sarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        
        let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');  
        let updatesAcknarray = [];                 
        for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){            
            if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){
                updatesAcknarray.push(shareAcknowledgeMentdataAll[i]);
            }
        }
        //new
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            updatesAcknarray.forEach((item) => {
                if(item.id in gc_users){
                    sAcknarray.push(item); 
                }
            })                                                                     
        }else{                                    
            sAcknarray = updatesAcknarray;
        }
        //new end
        sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 

        this.setState({shareModal: false, show_Buttons_panel: true, sharedata: sarray,searchUserShareList: sarray,
            shareAcknowledgeMentdata: sAcknarray,searchUserShareAcknowList: sAcknarray,
            shareobject,shareUserAll,shareUserInfo, shareUserAcknowledge,showDistributionShareUser: false,distributionCode: 0})
    }  

    handleOnChangeDistribution = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});

        let distributionObject = {};
		this.state.distributionLists.forEach((item) => {
            console.log("shareobjec",item.distributionID,"event.target.value",event.target.value)
			if (item.distributionID === parseInt(event.target.value)) {
				distributionObject = item.assingnedUserID
			}
        })
        console.log("distributionObject", distributionObject); 
        let gc_company = Store.getStoreData('gc_company');
        let gc_usersAll = {}
        for(let gc of gc_company) {
            if(gc.id in this.state.sendClientRN){
                for(let uid in gc.users) {                    
                    gc_usersAll[uid] = 1
                }
            }
        }
        //start share data
        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')

        let sharedataAll = Store.getStoreData('shareuserlist');        
        let sarray = [];
        let shareUserAll = {};
        for(let i=0;i<sharedataAll.length;i++){            
            if(!(sharedataAll[i].id.toString() in shareUserAll)){  
                sarray.push(sharedataAll[i]);                
            }
        }
        sarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        console.log("sarray", sarray); 

        let newsharedata = [];    
        let update_newsharedata = [];    
        sarray.forEach((item) => {
            if (item.id in distributionObject) {
                update_newsharedata.push(item);
            }			
        })  
        //new
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            update_newsharedata.forEach((item) => {
                if(item.id in gc_users){
                    newsharedata.push(item); 
                }
            })                                                                     
        }else{ 
            update_newsharedata.forEach((item) => {
                if(item.id in gc_usersAll){
                    newsharedata.push(item); 
                }
            })                                     
            //newsharedata = update_newsharedata;
        }
        //new end      
        //end
        //start acknow data
        let shareAcknowledgeMentdataAll = Store.getStoreData('shareAcknowledgeMentList');
        let sAcknarray = [];
        for(let i=0;i<shareAcknowledgeMentdataAll.length;i++){            
            if(!(shareAcknowledgeMentdataAll[i].id.toString() in shareUserAll)){ 
                sAcknarray.push(shareAcknowledgeMentdataAll[i]);                
            }            
        }
        sAcknarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("sAcknarray", sAcknarray);

        let newshareAcknowledgeMentdata = [];
        let update_newshareAcknowledgeMentdata = [];
        let shareobject = {}        
        let shareUserAcknowledgeUpdate = Store.getStoreData('shareUserAcknowledgeUpdate') === null ? {} : Store.getStoreData('shareUserAcknowledgeUpdate');
        let rowchecked = false;
        sAcknarray.forEach((item) => {
            if(item.id in distributionObject){
                update_newshareAcknowledgeMentdata.push(item);                
            }
        }) 

        //new
        if(role === 'rcm_gatekeeper' && Store.getStoreData('gc_companies') !== null && Store.getStoreData('gc_companies').length !== 0){                                    
            update_newshareAcknowledgeMentdata.forEach((item) => {
                if(item.id in gc_users){
                    newshareAcknowledgeMentdata.push(item); 
                    if(this.state.button_type === 'acknow'){
                        shareUserAll[item.id] = 1;
                        shareobject[item.id] = 1;
                        let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                        shareUserAcknowledgeUpdate[item.id] = val2;
                        rowchecked = true;
                    }
                }
            })                                                                     
        }else{                                    
            update_newshareAcknowledgeMentdata.forEach((item) => {      
                if(item.id in gc_usersAll){              
                    newshareAcknowledgeMentdata.push(item); 
                    if(this.state.button_type === 'acknow'){
                        shareUserAll[item.id] = 1;
                        shareobject[item.id] = 1;
                        let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                        shareUserAcknowledgeUpdate[item.id] = val2;
                        rowchecked = true;
                    }      
                }              
            }) 
        }
                
        //end       
        console.log("shareobject",shareUserAcknowledgeUpdate)
        console.log("newsharedata",newsharedata)
        this.setState({rowchecked: rowchecked,sharedata: newsharedata,shareAcknowledgeMentdata: newshareAcknowledgeMentdata,searchUserShareList: newsharedata,searchUserShareAcknowList: newshareAcknowledgeMentdata, showDistributionShareUser: true, shareUserAll: shareUserAll, shareobject: shareobject, shareUserAcknowledge: shareUserAcknowledgeUpdate});
    } 

    addFollowupActionsection = (event) => {
        event.preventDefault();     
        let problems = false
        let message = '';   
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        if (tags.length === 0) {
            //alert("Please select tag(s) to take action.");
            message += 'Please select tag(s) to take action.<br>';
            problems = true;            
        }
        let all_contacts = Store.getStoreData('contacts');
        let newContacts = [];            
        newContacts = all_contacts;            
        if(newContacts.length === 0){
            //alert("Sorry, no user found to assign mark for action.");
            message += 'Sorry, no user found to assign mark for action.<br>';
            problems = true;
        }
        if(Object.keys(this.state.sendClientRN).length === 0){
            //alert("Please select atleast one group company you want to share this RN.");
            message += 'Please select at least one group company in order to process this regulatory change.<br>';
            problems = true;
        }

        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{ 
            let gc_company = Store.getStoreData('gc_company');
            let gc_usersAll = {}
            for(let gc of gc_company) {
                if(gc.id in this.state.sendClientRN){
                    for(let uid in gc.users) {                    
                        gc_usersAll[uid] = 1
                    }
                }
            }                            
            let updateContact = [];                                                            
            all_contacts.forEach((item) => {
                if(item.ID in gc_usersAll){
                    updateContact.push(item); 
                }
            })                       
            console.log("contacts===>", updateContact)                                                        
            let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
            if(contacts.length !== 0){
                // this.setState({assigned_to: contacts[0].ID})
            }    
            this.setState({show_Buttons_panel: false, followuptask: true}); 
        } 
    }

    onDateChange = (date) => {  
        if (!moment.isDate(date)) {
			return;
		}      
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        this.setState({due_by: dateStr, date: date});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    dismissResonformultiple = (resonName) => (event) => {
        event.preventDefault();        
        this.setState({followuptask: false,show_Buttons_panel:true})
    }

    addResonforDismiss = (event) => {
        event.preventDefault();
        // if(Object.keys(this.state.sendClientRN).length !== 0){
        //     let alert_param = {title: 'Confirm', message: 'This RU will be dismissed and not sent to selected Group Companies. Are you sure you want to dismiss?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
        //             alertHandler: this.updateDismissClientRegulatorychange, stack: {}}
        //     this.setState({alert_param: alert_param})            
        // }else{
        //     let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory notification?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
        //             alertHandler: this.updateDismissClientRegulatorychange, stack: {}}
        //     this.setState({alert_param: alert_param})             
        // }  
        
        if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal && Store.getStoreData('module_config').general.require_reason_code_for_dismissal){  
            if(Object.keys(this.state.sendClientRN).length !== 0){
                let alert_param = {title: 'Confirm', message: 'This RU will be dismissed and not sent to selected Group Companies. Are you sure you want to dismiss?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                        alertHandler: this.updateDismissClientRegulatorychange, stack: {isChecking: 1}}
                this.setState({alert_param: alert_param})            
            }else{
                let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                        alertHandler: this.updateDismissClientRegulatorychange, stack: {isChecking: 1}}
                this.setState({alert_param: alert_param})             
            } 
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === true && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){            
            if(Object.keys(this.state.sendClientRN).length !== 0){
                let alert_param = {title: 'Confirm', message: 'This RU will be dismissed and not sent to selected Group Companies. Are you sure you want to dismiss?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                        alertHandler: this.updateDismissClientRegulatorychange, stack: {isChecking: 2}}
                this.setState({alert_param: alert_param})            
            }else{
                let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                        alertHandler: this.updateDismissClientRegulatorychange, stack: {isChecking: 2}}
                this.setState({alert_param: alert_param})             
            }
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === true){
            this.setState({isShowDismissalDropdown: true, isReasonForDismissalShow: false})
        }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
            this.setState({isShowDismissalDropdown: false, isReasonForDismissalShow: true})
        }
    }

    updateDismissClientRegulatorychange = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoaderIcon: true})
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let companyID = Store.getStoreData('companyid');
        let summarydata = window.decodeURIComponent(atob(this.state.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');
        let id = this.state.feedID;

        let curAutoForwardData = Store.getStoreData('cur_autoForwordFeeds');
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 

        const issue_date_obj = new Date(this.state.uploaddate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_year}/${issue_month}/${issue_day}`; 
        
        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        let createdAt = curAutoForwardData.created_at !== null ? curAutoForwardData.created_at : update_json_feed.uploadDate;
        

        let rufeed = {};
        rufeed.tags = tags;   
        rufeed.summary = btoa(window.encodeURIComponent(summary));        
        rufeed.sections = sections; 
        rufeed.id = id;
        rufeed.companyid = companyID;
        rufeed.documentName = this.state.documentname;
        rufeed.issueDate = issue_date;
        rufeed.createdDate = createdAt;
        rufeed.uploadDate = update_json_feed.uploadDate;
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();

        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        rufeed.documentTypes = doctypeArray;
        rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
        rufeed.ragulatorName = this.state.ragulatortypeName        
        rufeed.issuingBodies = [this.state.ragulatortypeName];
        rufeed.legislativeInitiatives = [];
        rufeed.is_jwg = 1;
        rufeed.AutoFordardJwGSelectdata = 1;

        let dissmiss_reason_text = "";
        let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        if(stack.isChecking === 1){
            for(let d of reasondismissal){
                if(d.isActive === 1 && d.isDelete === 0 && d.isDefaultcode === 1){
                    dissmiss_reason_text = d.reasonName;
                }
            }
        }else if(stack.isChecking === 2){
            dissmiss_reason_text = "N/A"
        }

        //console.log("rufeed", rufeed)
        let postData = {"command": "save_JwGData_Select_delete", "id": id, "data": rufeed, dissmiss_reason_text: dissmiss_reason_text};
        console.log("postData===>",postData);
        let api = new APICall();        
        api.command(postData, this.processDeleteRUTask);
    }

    processDeleteRUTask = (result) => {        
        //alert("This Regulatory Update has been dismissed");  
        let alert_param = {title: 'Success', message: 'The regulatory change has been dismissed.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})       
        //window.location.reload(true);        
    }

    updateafterAlertWithLoading = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }

    saveandcloseRCTask = (event) => {  
        if(Object.keys(this.state.sendClientRN).length === 0){
            this.saveWithOUTSelectGCCompany();
        }else{
            this.saveWithINSelectGCCompany();
        }        
    }

    saveWithOUTSelectGCCompany() {
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })              
        let summarydata = window.decodeURIComponent(atob(this.state.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');
        let id = this.state.feedID;   

        const issue_date_obj = new Date(this.state.uploaddate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_year}/${issue_month}/${issue_day}`;        
        

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }
        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }  

        let rufeed = {};
        rufeed.id = id;
        rufeed.documentName = this.state.documentname;
        rufeed.ragulatorName = this.state.ragulatortypeName;        
        rufeed.documentTypes = doctypeArray;
        rufeed.issueDate = issue_date;
        rufeed.summary = btoa(window.encodeURIComponent(summary));
        rufeed.sections = sections; 
        rufeed.tags = tags; 
        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;  

        rufeed.selectedCompanyIDs = this.state.sendClientRN; 
        rufeed.modificationDate = new Date();
        
        console.log("rufeed", rufeed)        
        let postData = {"command": "saveandclose_JwGData", "id": id, "data": rufeed, gc_company_ids: this.state.sendClientRN, isGCcreate: true, saveWithoutCompany: true};      
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.SaveandCloseJWGFeedFetchData);
    }

    saveWithINSelectGCCompany() {
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let companyID = Store.getStoreData('companyid');
        //let summary = this.state.summary;
        let summarydata = window.decodeURIComponent(atob(this.state.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');
        let id = this.state.feedID;

        let curAutoForwardData = Store.getStoreData('cur_autoForwordFeeds');
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 

        const issue_date_obj = new Date(this.state.uploaddate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_year}/${issue_month}/${issue_day}`;        
        

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }
        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }

        let createdAt = curAutoForwardData.created_at !== null ? curAutoForwardData.created_at : update_json_feed.uploadDate;
        

        let rufeed = {};
        rufeed.tags = tags;   
        rufeed.summary = btoa(window.encodeURIComponent(summary));        
        rufeed.sections = sections; 
        rufeed.id = id;
        rufeed.companyid = companyID;
        rufeed.documentName = this.state.documentname;
        rufeed.issueDate = issue_date;
        rufeed.createdDate = createdAt;
        rufeed.uploadDate = update_json_feed.uploadDate;
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();

        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        rufeed.documentTypes = doctypeArray;
        rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
        rufeed.ragulatorName = this.state.ragulatortypeName        
        rufeed.issuingBodies = [this.state.ragulatortypeName];
        rufeed.legislativeInitiatives = [];
        rufeed.is_jwg = 1;
        rufeed.AutoFordardJwGSelectdata = 1;

        rufeed.selectedCompanyIDs = this.state.sendClientRN;    
        
        console.log("rufeed", rufeed)        
        let postData = {"command": "saveandclose_JwGData", "id": id, "data": rufeed, gc_company_ids: this.state.sendClientRN, isGCcreate: true, saveWithoutCompany: false};      
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.SaveandCloseJWGFeedFetchData);
    }   

    SaveandCloseJWGFeedFetchData = (result) => {        
        //alert("This Regulatory Update has been saved.");    
        let alert_param = {title: 'Success', message: 'This Regulatory Update has been saved.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})           
        //window.location.reload(true);        
    }


    shareUser = (event) => {   
        let problems = false
        let message = ''; 
        if(Object.keys(this.state.shareobject).length === 0){            
            //alert("Please select at least one user to share the RN with.");
            message = 'Please select at least one user to share the RN with.'; 
            problems = true;                
        }
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{ 
            let tags = [];
            this.state.tags.forEach((tag) => {
                if (tag.selected) tags.push(tag.id);
            })
            let companyID = Store.getStoreData('companyid');
            //let summary = this.state.summary;
            let summarydata = window.decodeURIComponent(atob(this.state.summary));
            let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');
            let id = this.state.feedID;

            let curAutoForwardData = Store.getStoreData('cur_autoForwordFeeds');
            let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 

            const issue_date_obj = new Date(this.state.uploaddate);
            const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
            const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
            const issue_year = issue_date_obj.getFullYear();
            const issue_date = `${issue_year}/${issue_month}/${issue_day}`;      
            
            //share section
            let sharedataAll = Store.getStoreData('shareuserlist'); 
            let submitedShareUserarray = [];
            for(let i=0;i<sharedataAll.length;i++){            
                if(sharedataAll[i].id.toString() in this.state.shareUserAll){
                    let shareUserobject = {};
                    shareUserobject.id = sharedataAll[i].id
                    shareUserobject.name = sharedataAll[i].name
                    shareUserobject.email = sharedataAll[i].email
                    shareUserobject.role = sharedataAll[i].role
                    shareUserobject.acknowledgment_required = "";
                    shareUserobject.acknowledgment = "";
                    shareUserobject.share_resoncode = ""; 
                    submitedShareUserarray.push(shareUserobject);              
                }              
            }
            let oldShareid = {}
            let subUserList = [];
            // console.log("subUserList===>",this.state.shareUserAll)
            // console.log("subUserList===>",subUserList)
            // console.log("subUserList===>",submitedShareUserarray)
            for(let i=0;i<subUserList.length;i++){
                oldShareid[subUserList[i].id]={share_resoncode: subUserList[i].share_resoncode}
            }
            let curShareid = {}
            for(let i=0;i<submitedShareUserarray.length;i++){
                if(!(submitedShareUserarray[i].id in oldShareid)){ 
                    curShareid[submitedShareUserarray[i].id]=1
                }
            }

            let newsubmitedShareUserarray = [];
            submitedShareUserarray.forEach((item) => {
                if (item.id in oldShareid) {
                    item.share_resoncode = 	oldShareid[item.id].share_resoncode;						
                }else{
                    item.share_resoncode = this.state.reasonCode;	
                }
                newsubmitedShareUserarray.push(item);
            })

            let doctypeArray = [];
            for(let i=0;i<this.state.document_types.length;i++){
                if(this.state.document_types[i].selected){
                    doctypeArray.push(this.state.document_types[i].id.toString()) 
                }
            }
            let sections = Store.getStoreData('sections'); 
            for( let i=0; i<sections.length; i++)
            {
                sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
            }
            //console.log("newsubmitedShareUserarray",newsubmitedShareUserarray)        
            //share section end

            let createdAt = curAutoForwardData.created_at !== null ? curAutoForwardData.created_at : update_json_feed.uploadDate;
            

            let rufeed = {};
            rufeed.tags = tags;   
            rufeed.summary = btoa(window.encodeURIComponent(summary));       
            rufeed.sections = sections; 
            rufeed.id = id;
            rufeed.companyid = companyID;
            rufeed.documentName = this.state.documentname;
            rufeed.issueDate = issue_date;
            rufeed.createdDate = createdAt;
            rufeed.uploadDate = update_json_feed.uploadDate;
            rufeed.modificationDate = new Date();
            rufeed.publicResponseDate = new Date();

            rufeed.keyDates = this.state.keyDatesValue;
            rufeed.externalLinks = this.state.externalLink;
            rufeed.documentTypes = doctypeArray;
            rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
            rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
            rufeed.ragulatorName = this.state.ragulatortypeName        
            rufeed.issuingBodies = [this.state.ragulatortypeName];
            rufeed.legislativeInitiatives = [];
            rufeed.is_jwg = 1;

            rufeed.shareobject = this.state.shareobject;
            rufeed.shareUserAll = this.state.shareUserAll; 
            rufeed.submitedShareUserList = newsubmitedShareUserarray;        
            rufeed.shareUserInfo = this.state.shareUserInfo;
            rufeed.shareUserAcknowledge = this.state.shareUserAcknowledge;
            rufeed.AutoFordardJwGSelectdata = 1;          
            
            let gcCompanyWiseShareUser = {};
            let gcCompany_users = Store.getStoreData('gcCompany_users');
            if(Object.keys(this.state.sendClientRN).length !== 0){
                for(let k in this.state.sendClientRN){
                    if(k.toString() !== companyID){
                        if(k.toString() in gcCompany_users){
                            let gcusers = gcCompany_users[k.toString()]; 
                            let shareobjectOthers = {};
                            for(let p in this.state.shareobject){
                                if(p.toString() in gcusers){
                                    shareobjectOthers[p.toString()] = 1;                          
                                }
                            }
                            let shareUserAllOthers = {};
                            for(let p in this.state.shareUserAll){
                                if(p.toString() in gcusers){
                                    shareUserAllOthers[p.toString()] = 1;                          
                                }
                            }
                            let submitedShareUserListOthers = [];
                            for(let p of newsubmitedShareUserarray){
                                if(p.id.toString() in gcusers){
                                    submitedShareUserListOthers.push(p);                          
                                }
                            }
                            let shareUserInfoOthers = {};
                            for(let p in this.state.shareUserInfo){
                                if(p.toString() in gcusers){
                                    shareUserInfoOthers[p.toString()] = this.state.shareUserInfo[p.toString()];                          
                                }
                            }
                            let shareUserAcknowledgeOthers = {};
                            for(let p in this.state.shareUserAcknowledge){
                                if(p.toString() in gcusers){
                                    shareUserAcknowledgeOthers[p.toString()] = this.state.shareUserAcknowledge[p.toString()];                          
                                }
                            }
                            gcCompanyWiseShareUser[k.toString()] = {shareobject: shareobjectOthers, shareUserAll: shareUserAllOthers, submitedShareUserList: submitedShareUserListOthers, shareUserInfo: shareUserInfoOthers,
                                shareUserAcknowledge: shareUserAcknowledgeOthers} 
                        }
                    }
                }
            }
            console.log("gcCompanyWiseShareUser===>", gcCompanyWiseShareUser)
            
            Store.updateStore('feedidprogress', id)
            let postData = {"command": "start_save_JwGData_Select", "id": id, "data": rufeed, "selected_topics": tags, 
            shareobject:this.state.shareobject,
            shareUserAll: this.state.shareUserAll, 
            submitedShareUserList: newsubmitedShareUserarray,        
            shareUserInfo: this.state.shareUserInfo,
            shareUserAcknowledge: this.state.shareUserAcknowledge,
            cur_shareid: curShareid, gc_company_ids: this.state.sendClientRN, isGCcreate: true,
            gcCompanyWiseShareUser: gcCompanyWiseShareUser}; 
            console.log("postData", postData)       
            let api = new APICall();
            this.setState({isLoaderIcon: true})
            api.command(postData, this.SaveJWGFeedFetchData);
        }        
    }

    SaveJWGFeedFetchData = (result) => {
        // console.log("update data", result);     
        // alert('The RN has been shared with the selected user(s)');
        // window.location.reload(true); 
        this.setState({isLoaderIcon: false}) 
        setTimeout(() => {
            this.trackShareProgress()
        }, 2000);
        this.setState({show_progress: true, share_count: 0})
    }

    processTrackProgress = (result) => {
        console.log('result trackprogress', result)
        let count = result.result.count
        Store.updateStore('hide_progress', false)
        if(count < Object.keys(this.state.sendClientRN).length - 1 && !this.stop_polling) {
            setTimeout(() => {
                this.trackShareProgress()
            }, 2000);
            this.setState({share_count:count})
        } else {
            //alert('The RN has been shared with the selected user(s)');
            let alert_param = {title: 'Success', message: 'The regulatory update has been shared with the selected user(s)', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
            this.setState({alert_param: alert_param})  
            //window.location.reload();
        }
    }

    trackShareProgress = () => {
        let feedid = Store.getStoreData('feedidprogress');
        let postData = {command: "get_share_count", feed_id: feedid};
        const api = new APICall();
        Store.updateStore('hide_progress', true)
        api.command(postData, this.processTrackProgress);
    }

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})               
    }

    shareUserFollowup = (event) => {
        event.preventDefault();       
        
        if(this.state.assigned_to === ''){
            let alert_param = {title: 'ERROR', message: 'Please select a user to assign the task to.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }

        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let companyID = Store.getStoreData('companyid');
        let summarydata = window.decodeURIComponent(atob(this.state.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');
        let id = this.state.feedID;

        let curAutoForwardData = Store.getStoreData('cur_autoForwordFeeds');
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 

        const issue_date_obj = new Date(this.state.uploaddate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_year}/${issue_month}/${issue_day}`;  

        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }
        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        let createdAt = curAutoForwardData.created_at !== null ? curAutoForwardData.created_at : update_json_feed.uploadDate;


        let rufeed = {};
        rufeed.tags = tags;   
        rufeed.summary = btoa(window.encodeURIComponent(summary));       
        rufeed.sections = sections; 
        rufeed.id = id;
        rufeed.companyid = companyID;
        rufeed.documentName = this.state.documentname;
        rufeed.issueDate = issue_date;
        rufeed.createdDate = createdAt;
        rufeed.uploadDate = update_json_feed.uploadDate;
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();

        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        rufeed.documentTypes = doctypeArray;
        rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
        rufeed.ragulatorName = this.state.ragulatortypeName        
        rufeed.issuingBodies = [this.state.ragulatortypeName];
        rufeed.legislativeInitiatives = [];
        rufeed.is_jwg = 1;
        rufeed.AutoFordardJwGSelectdata = 1;

        //console.log("rufeed", rufeed)


        // followupin start        
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];        
        
        let ref_id = Utils.genKey(12);

        let gc_users = Store.getStoreData('gc_users');
        let CreatedFollowupCompanyID = {}
        if(this.state.assigned_to in gc_users){
            console.log("company_id==>", gc_users[this.state.assigned_to])
            CreatedFollowupCompanyID = gc_users[this.state.assigned_to];
        }
        
        let parrent_task_created_date = moment(createdAt).unix()
        let task = {
            "task_data": { "module_id": "rcm", "last_action": "COMP_RCM_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assigned_to, 'task_type' : 'COMP_RCM_FOLLOWUP','cur_lane': 'COMP_RCM_FOLLOWUP_ASSIGNMENT', due_date: this.state.due_by},
            "object_data": {documentName: update_json_feed.documentName,documentTypes: update_json_feed.documentTypes,ragulatorName: this.state.ragulatortypeName, uploadDate: new Date(), summary :rufeed.summary, note: this.state.assign_note, due_by: this.state.due_by, cur_view: 'Unopened','ptAddedDate': parrent_task_created_date, issueDate: issue_date},
            "action_data": [{ "action": "COMP_RCM_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_RCM_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.assign_note }]
        };        
        //followup action end
        let postData = {"command": "save_JwGData_Select_markforaction", 
            "id": id, 
            "data": rufeed, 
            "followupTaskData": task, 
            bin_files: bin_files, 
            gc_company_ids: this.state.sendClientRN, 
            created_followup_companyid: CreatedFollowupCompanyID, 
            cur_assigned_to: this.state.assigned_to,
            isGCcreate: true
        };
        console.log("postData===>",postData);
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.SaveJWGFeedFetchDataNew);        
    }

    SaveJWGFeedFetchDataNew = (result) => {
        console.log("update data", result);     
        //alert('This Regulatory Change has been successfully marked for action.');
        let alert_param = {title: 'Success', message: 'The regulatory change has been marked for action.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})  
        //window.location.reload(true); 
    }

    viewEditSection = () => {        
        this.setState({editsection: true})
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    modifyEntity = (obj) => {
        console.log("obj:", obj);
        this.setState({[obj.entity]: obj.list});
    }
    onUploadDateChange = (date) => {
        if (!moment.isDate(date)) {
			return;
		}
        this.setState({uploaddate: date});
    }

    changeToInput = (data_id) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(data_id)) {
                section.display = "input";
            }
        })
        Store.updateStore('sections', sections);
        this.setState({sections});
    }
    deleteSection = (section_id) => (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure to delete this section?")) {
            let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
            console.log("sections",sections)
            let sectionArray = []
            for(let i=0;i<sections.length;i++){
                if(sections[i].id !== section_id){
                    if(sections.length === 1){
                        sectionArray = [];
                    }else{
                        sectionArray.push({
                            content: sections[i].content,
                            display: sections[i].display,
                            id: sections[i].id,
                            name: sections[i].name
                        })
                    }
                    
                }                        
            }
            console.log("sectionArray",sectionArray)
            Store.updateStore('sections', sectionArray);
            this.setState({sections: sectionArray});
        }
    }

    changeInputValue = (status) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
                section.display = "input";
                if (!status) section.display = "label";
            }
        })
        let store_sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        store_sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
            }
        })
        Store.updateStore('sections', store_sections);
        this.setState({sections});
    }

    addSection = (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        let section_id = 1;
        if (sections.length !== 0) {
            section_id = sections[sections.length - 1].id + 1;
        }
        sections.push({
            id: section_id,
            display: "input",
            name: `Section ${section_id.toString()}`,
            content: ""
        });
        Store.updateStore('sections', sections);
        this.setState({sections});
    }

    showAddKeyDate = () => {    
        //event.preventDefault();
        this.setState({ showAddKeyDateModal: true, hiddenAddButton: false,curKeyid: 0 });
    }
    closeKeyDateModal = () => {
        this.setState({ showAddKeyDateModal: false, hiddenAddButton: true});
    }

    showAddexternalLink = () => {    
        //event.preventDefault();

        this.setState({ showAddLinkModal: true, hiddenAddButtonLink: false,curLinkid: 0 });
    }
    closeExLinkModal = () => {
        this.setState({ showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    fetchexternalLinkValue = (linkUrl,linkDescription) => {
        console.log(linkUrl,linkDescription)
        if(linkUrl != '') {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let links = {};
            links.id = randNo; 
            links.url = linkUrl;
            links.description = linkDescription;
            this.state.externalLink.push(links);
        }        
        this.setState({externalLink: this.state.externalLink, showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    fetchEditexternalLinkValue = (content) => {
        console.log("content",content)              
        if(content.url != '')
        {
            let newExLinks = [];
            this.state.externalLink.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newExLinks.push(item);
            })            
		    this.setState({externalLink: newExLinks, showAddLinkModal: false, hiddenAddButtonLink: true})
        }  
    }  
    
    fetchKeyValue = (keyDate,keyDescription) => {
        console.log(keyDate,keyDescription) 
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let keys = {};
            keys.id = randNo; 
            keys.date = keyDate;
            keys.description = keyDescription;            
            this.state.keyDatesValue.push(keys)
        console.log(this.state.keyDatesValue)
        this.setState({keyDatesValue: this.state.keyDatesValue, showAddKeyDateModal: false, hiddenAddButton: true})
    }

    refreshEditKeyValue = (content) => {
        console.log("content",content)  
            let newkeydate = [];
            this.state.keyDatesValue.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newkeydate.push(item);
            })            
		    this.setState({keyDatesValue: newkeydate, showAddKeyDateModal: false, hiddenAddButton: true})        
    }

    processMyExternalLink = () => {
        var linksdata = this.state.externalLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        // {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {'Header' : 'URL', accessor: 'url_value', Cell: row => (
            <div>
                <a target="_blank" href={row.original.url_value}>{row.original.url_value}</a>
            </div>
          ), minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (            
            <div>
                {
                (()=> {
                    if(this.state.editsection){
                        return(<div>
                            <FaPencilAlt onClick={this.openEditExLinkModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                            <FaTimes onClick={this.deleteExternalLink(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/>
                        </div>)
                    }
                })() 
            }                
            </div>            
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of linksdata) {           
            i++;
            //console.log("window.location.protocol===>",window.location.protocol)
            let url = <a style={{display: "table-cell"}} href={d.url} target="_blank" >{d.url}</a>                      
            let elem = {'index' : i,
                    'id': d.id, 
                    'url_value' : url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    } 

    deleteExternalLink = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newexLink = [];
			for(let i=0;i<this.state.externalLink.length;i++)
			{
				if(this.state.externalLink[i].id !== id){
					newexLink.push(this.state.externalLink[i])
				}
			}			 
			console.log("delete===>",newexLink);   
            this.setState({externalLink: newexLink})      
		//}         		  
    }

    openEditExLinkModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const contentLink = this.state.externalLink.find((item) => {
			return item.id === id;
		})        
		this.setState({contentLink,curLinkid: id,showAddLinkModal: true, hiddenAddButtonLink: false});        
    }

    processMykeyData = () => {
        var keysdata = this.state.keyDatesValue;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                {
                (()=> {
                    if(this.state.editsection){
                        return(<div>
                            <FaPencilAlt onClick={this.openEditKeyDateModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                            <FaTimes onClick={this.deleteKeyDate(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
                            </div>)
                    }
                })() 
            }
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteKeyDate = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newkeydate = [];
			for(let i=0;i<this.state.keyDatesValue.length;i++)
			{
				if(this.state.keyDatesValue[i].id !== id){
					newkeydate.push(this.state.keyDatesValue[i])
				}
			}			 
			console.log("delete===>",newkeydate);   
            this.setState({keyDatesValue: newkeydate})      
		//}         		  
    }

    openEditKeyDateModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.keyDatesValue.find((item) => {
			return item.id === id;
		})
		this.setState({content,curKeyid: id,showAddKeyDateModal: true, hiddenAddButton: false});        
	}
    
    selectClientAllgc = (event) => {
        let rowcheckedgc = this.state.rowcheckedgc;
        rowcheckedgc = rowcheckedgc ? false : true;        
        let clientListAll = this.state.groupCompanysList;
       if(rowcheckedgc) {   
            let i = 0;            
            let selectArray = [];
            for(let d of clientListAll) {                
                let selectObject = {}           
                i++;   
                let val = 1;            
                this.state.sendClientRN[d.gcid] = val;                          
                selectObject.gcid = d.gcid;
                selectObject.gcname = d.gcname;                
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }

            //new start
            // let gc_company = Store.getStoreData('gc_company');
            // let gc_usersAll = {}
            // for(let gc of gc_company) {
            //     if(gc.id in this.state.sendClientRN){
            //         for(let uid in gc.users) {                    
            //             gc_usersAll[uid] = 1
            //         }
            //     }
            // }
            // let sharedataAll = Store.getStoreData('sharedataAll_sarray')     
            // let update_sharedataAll = [];
            // sharedataAll.forEach((value) => {                       
            //     if(value.id in gc_usersAll){
            //         update_sharedataAll.push(value)
            //     }
            // })
            // console.log("update_sharedataAll==>",update_sharedataAll)
            //new end

            if(this.state.followuptask){
                let all_contacts = Store.getStoreData('contacts');
                let gc_company = Store.getStoreData('gc_company');
                let gc_usersAll = {}
                for(let gc of gc_company) {
                    if(gc.id in this.state.sendClientRN){
                        for(let uid in gc.users) {                    
                            gc_usersAll[uid] = 1
                        }
                    }
                }                            
                let updateContact = [];                                                            
                all_contacts.forEach((item) => {
                    if(item.ID in gc_usersAll){
                        updateContact.push(item); 
                    }
                })                               
                //updateContact = newContacts;                            
                console.log("contacts===>", updateContact)                                                        
                let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
                if(contacts.length !== 0){
                    // this.setState({assigned_to: contacts[0].ID})
                }
            }
            this.setState({rowcheckedgc: rowcheckedgc,groupCompanysList: selectArray, sendClientRN: this.state.sendClientRN 
                //sharedata: update_sharedataAll, searchUserShareList: update_sharedataAll
            })
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of clientListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.sendClientRN[d.gcid.toString()];                            
                selectObject.gcid = d.gcid;
                selectObject.gcname = d.gcname;                
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            //new start
            // let gc_company = Store.getStoreData('gc_company');
            // let gc_usersAll = {}
            // for(let gc of gc_company) {
            //     if(gc.id in this.state.sendClientRN){
            //         for(let uid in gc.users) {                    
            //             gc_usersAll[uid] = 1
            //         }
            //     }
            // }
            // let sharedataAll = Store.getStoreData('sharedataAll_sarray')     
            // let update_sharedataAll = [];
            // sharedataAll.forEach((value) => {                       
            //     if(value.id in gc_usersAll){
            //         update_sharedataAll.push(value)
            //     }
            // })
            // console.log("update_sharedataAll==>",update_sharedataAll)
            //new end

            if(this.state.followuptask){
                let all_contacts = Store.getStoreData('contacts');
                let gc_company = Store.getStoreData('gc_company');
                let gc_usersAll = {}
                for(let gc of gc_company) {
                    if(gc.id in this.state.sendClientRN){
                        for(let uid in gc.users) {                    
                            gc_usersAll[uid] = 1
                        }
                    }
                }                            
                let updateContact = [];                                                            
                all_contacts.forEach((item) => {
                    if(item.ID in gc_usersAll){
                        updateContact.push(item); 
                    }
                })                               
                //updateContact = newContacts;                            
                console.log("contacts===>", updateContact)                                                        
                let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
                if(contacts.length !== 0){
                    // this.setState({assigned_to: contacts[0].ID})
                }
            }
            this.setState({rowcheckedgc: rowcheckedgc,groupCompanysList: selectArray, sendClientRN: this.state.sendClientRN
                //sharedata: update_sharedataAll, searchUserShareList: update_sharedataAll
            })
       }
   }

   selectClientgc = (event) => {        
    let index = event.target.value;        
    var groupCompanysList = this.state.groupCompanysList;        
        
    if(groupCompanysList[index].select){
        groupCompanysList[index].select = false;            
        delete this.state.sendClientRN[groupCompanysList[index].gcid.toString()];                    
    } else {
        groupCompanysList[index].select = true;            
        let userid = groupCompanysList[index].gcid.toString();
        let val = 1;            
        this.state.sendClientRN[userid] = val;                            
    }

    //new start
    // let gc_company = Store.getStoreData('gc_company');
    // let gc_usersAll = {}
    // for(let gc of gc_company) {
    //     if(gc.id in this.state.sendClientRN){
    //         for(let uid in gc.users) {                    
    //             gc_usersAll[uid] = 1
    //         }
    //     }
    // }
    // let sharedataAll = Store.getStoreData('sharedataAll_sarray')     
    // let update_sharedataAll = [];
    // sharedataAll.forEach((value) => {                       
    //     if(value.id in gc_usersAll){
    //         update_sharedataAll.push(value)
    //     }
    // })
    // console.log("update_sharedataAll==>",update_sharedataAll)
    //new end
    if(this.state.followuptask){
        let all_contacts = Store.getStoreData('contacts');
        let gc_company = Store.getStoreData('gc_company');
        let gc_usersAll = {}
        for(let gc of gc_company) {
            if(gc.id in this.state.sendClientRN){
                for(let uid in gc.users) {                    
                    gc_usersAll[uid] = 1
                }
            }
        }                            
        let updateContact = [];                                                            
        all_contacts.forEach((item) => {
            if(item.ID in gc_usersAll){
                updateContact.push(item); 
            }
        })                               
        //updateContact = newContacts;                            
        console.log("contacts===>", updateContact)                                                        
        let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
        if(contacts.length !== 0){
            // this.setState({assigned_to: contacts[0].ID})
        }
    }

    //new start 30-12-2021
    console.log("this.state.button_type====>", this.state.button_type)
    if(this.state.button_type === 'acknow'){
        let shareobject = {};
        let shareUserAll = {};        
        let shareUserAcknowledge = {};

        let gc_company = Store.getStoreData('gc_company');
        let gc_usersAll = {}
        for(let gc of gc_company) {
            if(gc.id in this.state.sendClientRN){
                for(let uid in gc.users) {                    
                    gc_usersAll[uid] = 1
                }
            }
        }        
        let sharedataAcknowAll = Store.getStoreData('shareAcknowledgeMentdata_sAcknarray')    
        console.log("gc_usersAll==>", sharedataAcknowAll) 
        let update_sharedataAcknowAll = [];
        sharedataAcknowAll.forEach((value) => {                       
            if(value.id in gc_usersAll){
                let val = 1;            
                shareobject[value.id] = val;
                shareUserAll[value.id] = val;
                let val2 = {date: Date.now(),status: "Open",cur_view: "Unopened",responsedate: ""};           
                shareUserAcknowledge[value.id] = val2;
                update_sharedataAcknowAll.push(value)
            }
        })
        this.setState({ shareobject: shareobject, shareUserAll: shareUserAll, shareUserAcknowledge : shareUserAcknowledge,
        shareAcknowledgeMentdata: update_sharedataAcknowAll,searchUserShareAcknowList: update_sharedataAcknowAll}) 
    }else if(this.state.button_type === 'info'){        
        let gc_company = Store.getStoreData('gc_company');
        let gc_usersAll = {}
        for(let gc of gc_company) {
            if(gc.id in this.state.sendClientRN){
                for(let uid in gc.users) {                    
                    gc_usersAll[uid] = 1
                }
            }
        }
        let sharedataAll = Store.getStoreData('sharedataAll_sarray')     
        let update_sharedataAll = [];
        sharedataAll.forEach((value) => {                       
            if(value.id in gc_usersAll){
                update_sharedataAll.push(value)
            }
        })

        this.setState({ sharedata: update_sharedataAll, searchUserShareList: update_sharedataAll})
    }   
    
    //new end
    
    this.setState({groupCompanysList: groupCompanysList, sendClientRN: this.state.sendClientRN,rowcheckedgc: false})
    let allselectcheck = true
    for(let d of groupCompanysList) {        
        if(!d.select){
            allselectcheck = false
        }
    }
    if(allselectcheck){
        this.setState({rowcheckedgc: true})
    }
}

    processMyGroupCompaniesData = () => {            
        var clientList = this.state.groupCompanysList;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowcheckedgc} onClick={this.selectClientAllgc}/></div>, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectClientgc}/>
                        </div>
                    ), width: 142, sortable: false,headerStyle: {textAlign: 'left'}},         
                    {Header: 'Group Company', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
        clientList.sort(function(a,b) {return (a.gcname > b.gcname) ? 1 : ((b.gcname > a.gcname) ? -1 : 0);} );               
        for(let d of clientList) {           
            i++;
            let elem = {'index' : i,
                    'id': d.gcid, 
                    'name_value' : d.gcname,
                    'select' : d.select                    
                    };
            ret.data.push(elem);
        }        
        return ret;
    }

    processOk = (event) => {  
        this.setState({show_progress: false})        
    }

    submitResonforDismissForDropdown = (event) => {
        event.preventDefault();  
        if(Object.keys(this.state.sendClientRN).length !== 0){
            let alert_param = {title: 'Confirm', message: 'This RU will be dismissed and not sent to selected Group Companies. Are you sure you want to dismiss?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                    alertHandler: this.dismissAutoForwardDatainRegister, stack: {isChecking: 3}}
            this.setState({alert_param: alert_param})            
        }else{
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                    alertHandler: this.dismissAutoForwardDatainRegister, stack: {isChecking: 3}}
            this.setState({alert_param: alert_param})             
        }
    }

    submitResonforDismissNormal = (event) => {
        event.preventDefault();           
        if(Object.keys(this.state.sendClientRN).length !== 0){
            let alert_param = {title: 'Confirm', message: 'This RU will be dismissed and not sent to selected Group Companies. Are you sure you want to dismiss?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                    alertHandler: this.dismissAutoForwardDatainRegister, stack: {isChecking: 4}}
            this.setState({alert_param: alert_param})            
        }else{
            let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                    alertHandler: this.dismissAutoForwardDatainRegister, stack: {isChecking: 4}}
            this.setState({alert_param: alert_param})             
        }
    }

    dismissAutoForwardDatainRegister = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})  

        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let companyID = Store.getStoreData('companyid');
        let summarydata = window.decodeURIComponent(atob(this.state.summary));
        let summary = Store.getStoreData('summary') === null ? summarydata : Store.getStoreData('summary');
        let id = this.state.feedID;

        let curAutoForwardData = Store.getStoreData('cur_autoForwordFeeds');
        let update_json_feed = JSON.parse(curAutoForwardData.feed_update_json); 

        const issue_date_obj = new Date(this.state.uploaddate);
		const issue_day = issue_date_obj.getDate() < 10 ? "0"+issue_date_obj.getDate().toString() : issue_date_obj.getDate();
		const issue_month = issue_date_obj.getMonth()+1 < 10 ? `0${(issue_date_obj.getMonth() + 1).toString()}` : issue_date_obj.getMonth()+1;
		const issue_year = issue_date_obj.getFullYear();
        const issue_date = `${issue_year}/${issue_month}/${issue_day}`; 
        
        let sections = Store.getStoreData('sections'); 
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        let createdAt = curAutoForwardData.created_at !== null ? curAutoForwardData.created_at : update_json_feed.uploadDate;
        

        let rufeed = {};
        rufeed.tags = tags;   
        rufeed.summary = btoa(window.encodeURIComponent(summary));        
        rufeed.sections = sections; 
        rufeed.id = id;
        rufeed.companyid = companyID;
        rufeed.documentName = this.state.documentname;
        rufeed.issueDate = issue_date;
        rufeed.createdDate = createdAt;
        rufeed.uploadDate = update_json_feed.uploadDate;
        rufeed.modificationDate = new Date();
        rufeed.publicResponseDate = new Date();

        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        rufeed.documentTypes = doctypeArray;
        rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
        rufeed.ragulatorName = this.state.ragulatortypeName        
        rufeed.issuingBodies = [this.state.ragulatortypeName];
        rufeed.legislativeInitiatives = [];
        rufeed.is_jwg = 1;
        rufeed.AutoFordardJwGSelectdata = 1;

        let dissmiss_reason_text = "";        
        if(stack.isChecking === 3){
            dissmiss_reason_text = this.state.selected_reason_for_dismissal;
            if(this.state.selected_reason_for_dismissal === 'Other'){
                dissmiss_reason_text = this.state.dismiss_note;
            }
            
        }else if(stack.isChecking === 4){
            dissmiss_reason_text = this.state.dismiss_note;
        }

        //console.log("rufeed", rufeed)
        let postData = {"command": "save_JwGData_Select_delete", "id": id, "data": rufeed, dissmiss_reason_text: dissmiss_reason_text};
        console.log("postData===>",postData);
        let api = new APICall();        
        api.command(postData, this.processDeleteRUTask);
    }

    cancelDismissReson = (event) => {
        event.preventDefault();  
        let isDismissClickOnTable = 'isDismissClickOnTable' in this.props ? this.props.isDismissClickOnTable : false  
        if(!isDismissClickOnTable){
            this.setState({isReasonForDismissalShow: false, isShowDismissalDropdown: false})  
        }else{
            this.props.closeModal();
        }          
    }

    render()
    {
        let module_config = Store.getStoreData('module_config');
        let role = Store.getStoreData('role')
        let gc_users = Store.getStoreData('gc_users')
        let isDismissClickOnTable = 'isDismissClickOnTable' in this.props ? this.props.isDismissClickOnTable : false
        if(!this.state.ready){
            return(<div></div>)
        }      
        console.log("isLoaderIcon", this.state.isLoaderIcon)  
        return(<div>
            <RegModalContainer>
                {/* <AlwaysScrollToView /> */}
                {
                    (() => {
                        if(this.state.isLoaderIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <RegModalHeaderText>Auto Forwarded Feeds</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
                {
                    (() => {
                        if(!this.state.editsection && !isDismissClickOnTable){
                            return(<div style={{float: "right",marginRight: "25px"}} onClick={this.viewEditSection}><span style={{color: "#57C3C1",cursor: "pointer",fontSize: "16px"}}><FaEdit/></span></div>)
                        }
                    })()
                }
                
                <div style={{clear: "both"}}></div>
                {
                    (() => {
                        if(!this.state.editsection) {
                            return(<div>
                                    <RegDismisOuterContainer>  
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.state.documentname}</div>                                                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>                            
                                            <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(this.state.summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>                                       
                                            <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {this.state.docType}</div>
                                            <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {this.state.upload_date}</div> 
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    {
                                    (() => {
                                        if('general' in module_config && module_config.general.enable_tag_management === true){
                                            return(
                                            <RegModalContainertop>
                                                <RegModalNameLabelDiv style={{marginLeft: "10px"}}>Available Tags</RegModalNameLabelDiv>
                                                <RegModalTagsDiv>
                                                <RegModalNameInput style={{marginTop: "13px",marginLeft: "6px",marginBottom: "11px",height: "27px",width: "96%"}} placeholder="Start typing to search..." onChange={this.searchUsers} type="text" />
                                                    {
                                                        (() => {
                                                            if (this.state.tags.length !== 0) {                                                        
                                                                return(<RCMTagsView tags={this.state.tags} selectUnselect={this.selectOrUnselectTopic}/>)                                                                                                         
                                                            } 
                                                        })() 
                                                    }
                                                </RegModalTagsDiv>
                                            </RegModalContainertop>
                                            )
                                        }
                                    })()
                                    }
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select Group Companies you want to share this regulatory update with</div> 
                                            <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
                                        <OverviewInfoBody>
                                        {
                                            (() => {
                                                if(this.state.groupCompanysList.length !== 0){
                                                    return(                                                  
                                                        <CSLTable add={false} processData={this.processMyGroupCompaniesData} headerText={''} tableRows={5}/>
                                                    )
                                                }else{
                                                    return(<div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Group Company Available.</div>)
                                                }
                                            })()
                                        } 
                                        </OverviewInfoBody> 
                                    </RegDismisOuterContainer>
                                </div>)
                        }else{
                            return(<div>
                                <RegDismisOuterContainer style={{padding: "6px 11px 20px 11px"}}>
                                    <RegModalContainertop>
                                        <RegModalNameLabelDiv>Name</RegModalNameLabelDiv>
                                        <RegModalNameInputDiv style={{width: "99%"}}>
                                            <RegModalNameInput type="text" name="documentname" value={this.state.documentname} onChange={this.handleChange}/>
                                        </RegModalNameInputDiv>
                                        <RegModalNameLabelDiv>Regulator Name</RegModalNameLabelDiv>
                                        <RegModalNameInputDiv>
                                            <MRModalSelect style={{width: "99.2%", display: "inline-block"}} name="ragulatortypeName" value={this.state.ragulatortypeName} onChange={this.handleChange}>                        
                                                <option value="">Select Regulator</option>
                                                {
                                                    this.state.regulatorType.map((reg, index) => {
                                                        return (<option key={index} value={reg.ragulatorname}>{reg.knownAs}</option>);
                                                    })
                                                }                            
                                            </MRModalSelect>
                                        </RegModalNameInputDiv>
                                        <RegModalNameLabelDiv>Document Types</RegModalNameLabelDiv>
                                        <RegModalNameInputDiv style={{width: "99%"}}>
                                            <SearchablePills entity="document_types" list={this.state.document_types} modifyEntity={this.modifyEntity} />
                                        </RegModalNameInputDiv>
                                        <RegModalNameLabelDiv>Date</RegModalNameLabelDiv>
                                        <CSLDateCover style={{width: "20%"}}>
                                            <DatePicker onChange={this.onUploadDateChange}
                                            clearIcon={null}
                                            calendarIcon={null}
                                            locale={"en-GB"}
                                            value={this.state.uploaddate}
                                            />
                                        </CSLDateCover>
                                    </RegModalContainertop>
                                </RegDismisOuterContainer>
                                <RegModalHr />
                                {
                                    (() => {                                        
                                            return(
                                                <div>
                                                    <RegDismisOuterContainer style={{padding: "6px 11px 20px 11px"}}>
                                                    <RegModalContainertop>
                                                    <RegModalNameLabelDiv>Summary</RegModalNameLabelDiv>
                                                    <RegModalNameInputDiv>
                                                        {
                                                            (() => {
                                                                let summary = window.decodeURIComponent(atob(this.state.summary));  
                                                                return (<HtmlEditor content={summary} section_id="0"/>);
                                                            })()
                                                        }                                                        
                                                    </RegModalNameInputDiv>
                                                </RegModalContainertop>
                                                </RegDismisOuterContainer>
                                                {
                                                    Store.getStoreData('sections').map((section) => {
                                                        if (section.display === "label") {
                                                            return (
                                                                <RegModalContainertop key={section.id} style={{padding: "30px"}}>
                                                                    <RegModalNameLabelDiv onClick={this.changeToInput(section.id)}>{section.name}</RegModalNameLabelDiv>
                                                                    <RegModalNameInputDiv>                                                    
                                                                        <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>                                                    
                                                                        <div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>                                                            
                                                                    </RegModalNameInputDiv>
                                                                </RegModalContainertop>
                                                            );
                                                        } else {
                                                            return (
                                                                <RegModalContainertop key={section.id} style={{padding: "30px"}}>
                                                                    <RegModalNameLabelDiv >
                                                                        <RegModalNameInput type="text" name={section.id} value={section.name} onChange={this.changeInputValue(true)} onBlur={this.changeInputValue(false)} />
                                                                    </RegModalNameLabelDiv>
                                                                    <RegModalNameInputDiv>                                                    
                                                                        <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>                                                    
                                                                        <div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>                                                            
                                                                    </RegModalNameInputDiv>
                                                                </RegModalContainertop>
                                                            );
                                                        }
                                                    })
                                                }
                                                <SingleRowColumnContainer style={{padding: "30px"}}>
                                                    <AddSectionButton onClick={this.addSection}>Add Section</AddSectionButton>
                                                </SingleRowColumnContainer>
                                                </div>
                                            )                                        
                                    })()
                                }
                                {
                                    (() => {
                                        if('general' in module_config && module_config.general.enable_tag_management === true){
                                            return(
                                            <RegModalContainertop>
                                                <RegModalNameLabelDiv style={{marginLeft: "10px"}}>Available Tags</RegModalNameLabelDiv>
                                                <RegModalTagsDiv>
                                                <RegModalNameInput style={{marginTop: "13px",marginLeft: "6px",marginBottom: "11px",height: "27px",width: "96%"}} placeholder="Start typing to search..." onChange={this.searchUsers} type="text" />
                                                    {
                                                        (() => {
                                                            if (this.state.tags.length !== 0) {
                                                                //console.log(this.state.tags); 
                                                                return(<RCMTagsView tags={this.state.tags} selectUnselect={this.selectOrUnselectTopic}/>)                                                                                                         
                                                            } 
                                                        })() 
                                                    }
                                                </RegModalTagsDiv>
                                            </RegModalContainertop>
                                            )
                                        }
                                    })()
                                }           
                                {
                                    (() => {
                                        if(this.state.externalLink.length === 0){                                    
                                            return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                                <OverviewInfoHeader style={{width: "unset"}}>
                                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                                            {
                                                                (() => {
                                                                    if(this.state.hiddenAddButtonLink){
                                                                        return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                                                    }
                                                                })()
                                                            }                
                                                            <div style={{clear: "both"}}></div>
                                                        </OverviewInfoHeader>
                                                        {
                                                            (() => {
                                                                if (this.state.showAddLinkModal) {
                                                                    return (<AddEditExternalLinks  curid={this.state.curLinkid} closeExLinkModal={this.closeExLinkModal} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                                                }
                                                            })()                                                }
                                                    
                                                    <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>
                                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no External Links Available</div>
                                                    </OverviewInfoBody>                                                    
                                                </SingleRowColumnContainer>)
                                        }else {
                                            return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                                <OverviewInfoHeader style={{width: "unset"}}>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                                    {
                                                        (() => {
                                                            if(this.state.hiddenAddButtonLink){
                                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                                            }
                                                        })()
                                                    }                
                                                    <div style={{clear: "both"}}></div>
                                                </OverviewInfoHeader>
                                                {
                                                    (() => {
                                                        if (this.state.showAddLinkModal) {
                                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} content={JSON.parse(JSON.stringify(this.state.contentLink))} closeExLinkModal={this.closeExLinkModal} fetchEditexternalLinkValue={this.fetchEditexternalLinkValue} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                                        }
                                                    })()
                                                }
                                                {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                              
                                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                                </OverviewInfoBody>                                                
                                            </SingleRowColumnContainer>)
                                        }
                                    })()
                                }
                                {
                                    (() => {                                        
                                            if(this.state.keyDatesValue.length === 0){                                            
                                                return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                                    <OverviewInfoHeader style={{width: "unset"}}>
                                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                                        {
                                                            (() =>{
                                                                if(this.state.hiddenAddButton){
                                                                    return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                                                }
                                                            })()
                                                        }                
                                                        <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                                                    {
                                                        (() => {
                                                            if (this.state.showAddKeyDateModal) {
                                                                return (<AddEditKeyDates  curid={this.state.curKeyid} closeKeyDateModal={this.closeKeyDateModal} refreshKeyValue={this.fetchKeyValue}/>);
                                                            }
                                                        })()
                                                    }                                            
                                                    <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>   
                                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no Key Dates Available</div>
                                                    </OverviewInfoBody>                                                    
                                                </SingleRowColumnContainer>)
                                            }else {
                                                return(<SingleRowColumnContainer style={{padding: "30px"}}>
                                                    <OverviewInfoHeader style={{width: "unset"}}>
                                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                                        {
                                                            (() =>{
                                                                if(this.state.hiddenAddButton){
                                                                    return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                                                }
                                                            })()
                                                        }                
                                                        <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                                                    {
                                                        (() => {
                                                            if (this.state.showAddKeyDateModal) {
                                                                return (<AddEditKeyDates  curid={this.state.curKeyid} content={JSON.parse(JSON.stringify(this.state.content))} closeKeyDateModal={this.closeKeyDateModal} refreshEditKeyValue={this.refreshEditKeyValue} refreshKeyValue={this.fetchKeyValue}/>);
                                                            }
                                                        })()
                                                    }                                                    
                                                    <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                                   
                                                        <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                                    </OverviewInfoBody>                                                    
                                                </SingleRowColumnContainer>)
                                            }
                                        
                                    })()
                                }
                                <RegDismisOuterContainer>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select Group Companies you want to share this regulatory update with</div> 
                                        <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody>
                                    {
                                        (() => {
                                            if(this.state.groupCompanysList.length !== 0){
                                                return(                                                  
                                                    <CSLTable add={false} processData={this.processMyGroupCompaniesData} headerText={''} tableRows={5}/>
                                                )
                                            }else{
                                                return(<div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Group Company Available.</div>)
                                            }
                                        })()
                                    } 
                                    </OverviewInfoBody> 
                                </RegDismisOuterContainer>
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if('general' in module_config && module_config.general.enable_distribution_lists === true){
                            if('distribution_list' in module_config && module_config.distribution_list.length !== 0){
                                if(this.state.shareModal) {                                
                                    return(<div>
                                        <RegDismisOuterContainer>
                                            <RegModalNameLabelDiv style={{marginLeft: "9px"}}>Distribution Lists</RegModalNameLabelDiv>
                                            <MRModalSelect style={{width: "30%",marginTop: "10px",marginLeft: "9px",marginBottom: "11px"}} name="distributionCode" value={this.state.distributionCode} onChange={this.handleOnChangeDistribution}> 
                                                <option key={0} value={0}>Select</option>
                                                {
                                                    this.state.distributionLists.map((doc, index) => {
                                                        return (<option key={index} value={doc.distributionID}>{doc.distributionName}</option>);
                                                    })
                                                }                            
                                            </MRModalSelect>
                                            {
                                                (() => {
                                                    if(this.state.showDistributionShareUser){
                                                        return(<div>
                                                            <OverviewInfoHeader>
                                                                    {/* <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select Users you want to share this RN with</div>  */}
                                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select users you want to share this regulatory change with</div> 
                                                                    <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                                                    <div style={{clear: "both"}}></div>
                                                            </OverviewInfoHeader>
                                
                                                            <OverviewInfoBody>
                                                            <div style={{width: "71%",display: "inline-block",marginRight: "25px",marginBottom: "6px"}}>
                                                                <SearchInput placeholder="Search..." onChange={this.searchUsers}/>                             
                                                            </div>
                                                            <div style={{width: "25%", display: "inline-block"}}>
                                                                <PermissionDropdown onChange={this.changeAllRoles}>
                                                                    <option value="0">Select Role</option>
                                                                    <option value="team">Team</option>
                                                                    <option value="rcm_gatekeeper">RCM Gatekeeper</option>
                                                                    <option value="manager">Admin Manager</option>
                                                                </PermissionDropdown>
                                                            </div>
                                                            
                                                                <CSLTable add={false} processData={this.processMyshareData} headerText={''} tableRows={10}/>
                                
                                                                {
                                                                    (() => {
                                                                        if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
                                                                            if('reason_code' in module_config && module_config.reason_code.length !== 0){
                                                                                return(<div>
                                                                                    <RegModalNameLabelDiv>Shared Reason Codes</RegModalNameLabelDiv>
                                                                                    <MRModalSelect style={{width: "30%",marginTop: "10px"}} name="reasonCode" value={this.state.reasonCode} onChange={this.handleOnChange}> 
                                                                                        {
                                                                                            this.state.newReasonCode.map((doc, index) => {
                                                                                                return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                                                                            })
                                                                                        }                            
                                                                                    </MRModalSelect>  
                                                                                </div>)
                                                                            }
                                                                        }
                                                                    })()
                                                                }
                                
                                                            </OverviewInfoBody>
                                                            <RegCancelSubmitButtonOuterContainer>
                                                                <RegCancelButton onClick={this.closeShareModal}>Cancel</RegCancelButton>
                                                                <RegSubmitButton onClick={this.shareUser}>Share</RegSubmitButton>
                                                            </RegCancelSubmitButtonOuterContainer>
                                                        </div>)
                                                    }
                                                })()
                                            }
                                        </RegDismisOuterContainer>
                                    </div>)
                                }
                            }else{
                                if(this.state.shareModal) {
                                    return(<RegDismisOuterContainer>
                                            <OverviewInfoHeader>
                                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select users you want to share this regulatory change with</div> 
                                                    <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                                    <div style={{clear: "both"}}></div>
                                            </OverviewInfoHeader>
                
                                            <OverviewInfoBody>
                                                <div style={{width: "71%",display: "inline-block",marginRight: "25px",marginBottom: "6px"}}>
                                                    <SearchInput placeholder="Search..." onChange={this.searchUsersShareTable}/>                             
                                                </div>
                                                <div style={{width: "25%", display: "inline-block"}}>
                                                    <PermissionDropdown onChange={this.changeAllRoles}>
                                                        <option value="0">Select Role</option>
                                                        <option value="team">Team</option>
                                                        <option value="rcm_gatekeeper">RCM Gatekeeper</option>
                                                        <option value="manager">Admin Manager</option>
                                                    </PermissionDropdown>
                                                </div>
                                                <CSLTable add={false} processData={this.processMyshareData} headerText={''} tableRows={10}/>
                                                {
                                                    (() => {
                                                        if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
                                                            if('reason_code' in module_config && module_config.reason_code.length !== 0){
                                                                return(<div>
                                                                    <RegModalNameLabelDiv>Shared Reason Codes</RegModalNameLabelDiv>
                                                                    <MRModalSelect style={{width: "30%",marginTop: "10px"}} name="reasonCode" value={this.state.reasonCode} onChange={this.handleOnChange}> 
                                                                        {
                                                                            this.state.newReasonCode.map((doc, index) => {
                                                                                return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                                                            })
                                                                        }                            
                                                                    </MRModalSelect>  
                                                                </div>)
                                                            }
                                                        }
                                                    })()
                                                }
                                            </OverviewInfoBody>
                                            <RegCancelSubmitButtonOuterContainer>
                                                <RegCancelButton onClick={this.closeShareModal}>Cancel</RegCancelButton>
                                                <RegSubmitButton onClick={this.shareUser}>Share</RegSubmitButton>
                                            </RegCancelSubmitButtonOuterContainer>
                                        </RegDismisOuterContainer>
                                        )
                                } 
                            }
                        }else{
                            if(this.state.shareModal) {
                                return(<RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select users you want to share this regulatory change with</div> 
                                                <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><IoIosArrowUp /></div>
                                                <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>
            
                                        <OverviewInfoBody>
                                            <div style={{width: "71%",display: "inline-block",marginRight: "25px",marginBottom: "6px"}}>
                                                <SearchInput placeholder="Search..." onChange={this.searchUsersShareTable}/>                             
                                            </div>
                                            <div style={{width: "25%", display: "inline-block"}}>
                                                <PermissionDropdown onChange={this.changeAllRoles}>
                                                    <option value="0">Select Role</option>
                                                    <option value="team">Team</option>
                                                    <option value="rcm_gatekeeper">RCM Gatekeeper</option>
                                                    <option value="manager">Admin Manager</option>
                                                </PermissionDropdown>
                                            </div>
                                            <CSLTable add={false} processData={this.processMyshareData} headerText={''} tableRows={10}/>
                                            {
                                                (() => {
                                                    if('general' in module_config && module_config.general.require_reason_code_for_sharing === true){
                                                        if('reason_code' in module_config && module_config.reason_code.length !== 0){
                                                            return(<div>
                                                                <RegModalNameLabelDiv>Shared Reason Codes</RegModalNameLabelDiv>
                                                                <MRModalSelect style={{width: "30%",marginTop: "10px"}} name="reasonCode" value={this.state.reasonCode} onChange={this.handleOnChange}> 
                                                                    {
                                                                        this.state.newReasonCode.map((doc, index) => {
                                                                            return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                                                        })
                                                                    }                            
                                                                </MRModalSelect>  
                                                            </div>)
                                                        }
                                                    }
                                                })()
                                            }
                                        </OverviewInfoBody>
                                        <RegCancelSubmitButtonOuterContainer>
                                            <RegCancelButton onClick={this.closeShareModal}>Cancel</RegCancelButton>
                                            <RegSubmitButton onClick={this.shareUser}>Share</RegSubmitButton>
                                        </RegCancelSubmitButtonOuterContainer>
                                    </RegDismisOuterContainer>
                                    )
                            }
                        }
                    })()
                }
                <RegDismisOuterContainer>
                <RegDismisInnerContainer>
                {
                    (() => {
                        if(this.state.followuptask){
                            let all_contacts = Store.getStoreData('contacts');
                            let gc_company = Store.getStoreData('gc_company');
                            let gc_usersAll = {}
                            for(let gc of gc_company) {
                                if(gc.id in this.state.sendClientRN){
                                    for(let uid in gc.users) {                    
                                        gc_usersAll[uid] = 1
                                    }
                                }
                            }                            
                            let updateContact = [];                                                            
                            all_contacts.forEach((item) => {
                                if(item.ID in gc_usersAll){
                                    updateContact.push(item); 
                                }
                            })                               
                            //updateContact = newContacts;                            
                            //console.log("contacts===>", updateContact)                                                        
                            let contacts = updateContact.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
                            if(contacts.length !== 0){
                            return(<div><RegModalContainertop>
                                <RegModalNameLabelDiv>Remedial/Followup Tasks</RegModalNameLabelDiv>                                    
                                <RegModalTagsDiv style={{marginLeft: "50px",width: "90%"}}>
                                    <RegModalIssueDateDiv>
                                        <div style={{float: "left", width: "50%"}}>
                                            <RegModalIssueDateLabel>Assign To</RegModalIssueDateLabel>
                                            <TermInputDiv>                                                                                             
                                                <MRModalSelect name="assigned_to" onChange={this.handleOnChange} value={this.state.assigned_to}>
                                                    <option key={0} value="">Select</option>
                                                    {
                                                        contacts.map((contact, index) => {
                                                            return (<option key={index} value={contact.ID}>{contact.ContactName}</option>);
                                                        })
                                                    }
                                                </MRModalSelect>                                                
                                            </TermInputDiv>
                                        </div>
                                        <div style={{float: "left", width: "calc(50% - 15px)", marginLeft: "15px"}}>
                                            <RegModalIssueDateLabel>Due By</RegModalIssueDateLabel>
                                            <TermInputDiv>
                                                <CSLDateCover>
                                                    <DatePicker
                                                            onChange={this.onDateChange}
                                                            clearIcon={null}
                                                            calendarIcon={null}
                                                            locale={"en-GB"}
                                                            value={this.state.date}
                                                            />
                                                </CSLDateCover>
                                            </TermInputDiv>
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                        <RegModalIssueDateLabel>Note</RegModalIssueDateLabel>
                                        <RegModalNameInputDiv>
                                            <RegDismissalReason style={{marginTop: "10px"}} name="assign_note" onChange={this.handleOnChange} value={this.state.assign_note}></RegDismissalReason>
                                        </RegModalNameInputDiv>
                                    </RegModalIssueDateDiv>
                                </RegModalTagsDiv>
                                <ModalNameLabelDiv style={{marginTop: "30px"}}>Attachments</ModalNameLabelDiv>                                                                     
                                <ModalNameInputDiv style={{marginLeft: "48px"}}>
                                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={!this.state.isdesable}/>
                                </ModalNameInputDiv>                                        
                                
                            </RegModalContainertop>
                            <RegSubmitButton style={{float: "right"}} onClick={this.shareUserFollowup}>Save</RegSubmitButton>                                
                            <RegCancelButton onClick={this.dismissResonformultiple('followup')} style={{float: "right"}}>Cancel</RegCancelButton>
                            <div style={{clear: "both"}}></div>
                            </div>)
                            }
                        }
                    })()
                }
                </RegDismisInnerContainer>
                </RegDismisOuterContainer>
                {
                    (() => {
                        if(this.state.show_Buttons_panel){
                            if(!this.state.editsection) {
                                if(!isDismissClickOnTable){
                                    return(
                                        <RegCancelSubmitButtonOuterContainer>                                
                                            <RegCancelButton onClick={this.closeRegulatoryModal}>Close</RegCancelButton>
                                            <RegShareButton onClick={this.showShareModal}>Share</RegShareButton>
                                            <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton>
                                            <RegSubmitButton onClick={this.showAcknowledgeMentModal}>Send for Acknowledgement</RegSubmitButton>
                                            <RegSubmitButton onClick={this.addFollowupActionsection}>Mark For Action</RegSubmitButton>
                                        </RegCancelSubmitButtonOuterContainer>
                                    )
                                }
                            }else{
                                return(
                                    <RegCancelSubmitButtonOuterContainer>                                
                                        <RegCancelButton onClick={this.closeRegulatoryModal}>Close</RegCancelButton>
                                        <RegShareButton onClick={this.showShareModal}>Save and Share</RegShareButton>
                                        <RegDismisButton onClick={this.addResonforDismiss}>Dismiss</RegDismisButton>
                                        <RegSubmitButton onClick={this.showAcknowledgeMentModal}>Send for Acknowledgement</RegSubmitButton>
                                        <RegSubmitButton onClick={this.addFollowupActionsection}>Mark For Action</RegSubmitButton>
                                        <RegSubmitButton onClick={this.saveandcloseRCTask}>Save and Close</RegSubmitButton>
                                    </RegCancelSubmitButtonOuterContainer>
                                )
                            }
                        }
                    })()
                } 
                {
                    (() => {
                        if(this.state.show_progress) {  
                            return (<AlertBoxContainer id="CSLAlertBox"> 
                                    <AlertBoxBody>The regulatory change is being shared with the selected user(s). This may take a few seconds to update.</AlertBoxBody>
                                    <span style={{marginLeft: "50%"}}><ClipLoader  size={25} color={"#04ada8"} loading={true} /></span>
                                    <AlertBoxFooter>
                                        {/* <OkButton onClick={this.processOk}>OK</OkButton> */}
                                    </AlertBoxFooter>
                                </AlertBoxContainer>)
                        }
                    })()
                }     

                {
                    (() => {
                        if(this.state.isReasonForDismissalShow){
                            return(<div><RegModalContainertop>
                                <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                                <RegModalNameInputDiv>
                                    <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                </RegModalNameInputDiv>
                            </RegModalContainertop>
                            <RegCancelSubmitButtonOuterContainer>    
                                <RegCancelButton onClick={this.cancelDismissReson}>Cancel</RegCancelButton>                                
                                <RegSubmitButton onClick={this.submitResonforDismissNormal}>Save</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer> 
                            <div style={{clear: "both"}}></div>
                            </div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.isShowDismissalDropdown){
                            return(<div><RegModalContainertop >
                                <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>                                
                                <MRModalSelect style={{width: "50%"}} name="selected_reason_for_dismissal" value={this.state.selected_reason_for_dismissal} onChange={this.handleOnChange}> 
                                    {
                                        this.state.reason_for_dismissal.map((doc, index) => {
                                            return (<option key={index} value={doc.reasonName}>{doc.reasonName}</option>);
                                        })
                                    }                            
                                </MRModalSelect>  
                                <RegModalNameLabelDiv></RegModalNameLabelDiv>  
                                {
                                    (() => {
                                        if(this.state.selected_reason_for_dismissal === 'Other'){
                                            return(
                                                <RegModalNameInputDiv>
                                                    <RegDismissalReason name="dismiss_note" onChange={this.handleOnChange} value={this.state.dismiss_note}></RegDismissalReason>
                                                </RegModalNameInputDiv>
                                            )
                                        }
                                    })()
                                }                                
                            </RegModalContainertop>
                            <RegCancelSubmitButtonOuterContainer>    
                                <RegCancelButton onClick={this.cancelDismissReson}>Cancel</RegCancelButton>                                
                                <RegSubmitButton onClick={this.submitResonforDismissForDropdown}>Save</RegSubmitButton>
                            </RegCancelSubmitButtonOuterContainer> 
                            <div style={{clear: "both"}}></div>
                            </div>)
                        }
                    })()
                }   
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>)
    }
}
export default AutoForwordFeedModalForGC;