import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import { BiEdit } from "react-icons/bi";
import EditFollowupTaskModal from './../Select/MGR/EditFollowupTaskModal';
import APICall from './../../Common/APICall';
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;
class ChildTasksRUTable extends React.Component
{
    state = {
        modal_view: null
    }
    constructor(props) {
        super(props);
    }
    processChildFeedsData = () => {
        let ret = {data: [], columns: [], row_count: 0};
        let feedsdata = this.props.followup_tasks;
        let role = Store.getStoreData('role');
        let gc_users = Store.getStoreData('gc_users');
        let feedsdata_update = [];
        if(role === 'rcm_gatekeeper'){
            for(let k of feedsdata){
                if(k.cur_assigned_to in gc_users){
                    feedsdata_update.push(k)
                }
            }
        }else{
            feedsdata_update = feedsdata;
        }
        let i = 0;
        console.log("feedsdata length =>", feedsdata_update.length );
        let userrole = Store.getStoreData('role');
        const has_action = 'isShowAction' in this.props ? true : false;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Notes', accessor: 'notes', minWidth: 250, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status', width: 100, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action',headerStyle: {textAlign: 'center'}, show: has_action ? true : false, width: 150, Cell: row => (
                            <div style={{display: "inline-flex"}}>                         
                                <MRButton style={{cursor: "pointer"}} onClick={this.viewAssignTask(row.original.id)}>View</MRButton>
                                {
                                    (() => {
                                        if(userrole !== 'team'){
                                            if(row.original.status_value !== "Completed")return(<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><BiEdit /></div>)
                                        }
                                    })()
                                }                 
                            </div>                            
                        )}
                    ];

        for(let d of feedsdata_update) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let status = "Pending";
            if(d.last_action === 'COMP_RCM_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_RCM_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            let contacts = Store.getStoreData('contacts');
            let contact_name = "None";
            for(let i = 0; i < contacts.length; i++)
            {
                if(contacts[i].ID == parseInt(d.cur_assigned_to)){
                    contact_name = contacts[i].ContactName; 
                    break;
                }
            }
            let notes = tdata[0].note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            //console.log('day, month, year', dd,mm,yyyy)

            var day_str = dd >= 10 ? dd.toString() : "0"+dd.toString();
            var month_str = mm >= 10 ? mm.toString() : "0"+mm.toString();
            var str_due_date = day_str + "/"+ month_str +"/"+yyyy.toString();

            var due_date_status = new Date(yyyy, mm-1, dd);

            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }            
            let elem = {
                    'index' : i, 
                    'id': d.id,
                    'assign_to' : contact_name,
                    'notes' : notes,
                    'due_date' : str_due_date,
                    'issue_date' : d.issue_date,
                    'status' : {text: text, color: color},
                    'status_value' : text
                   };
            ret.data.push(elem);
        }
        ret.row_count = i;
        return ret;
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.editFollowupModal);
    }

    editFollowupModal = (result) => {
        console.log("followup_task",result.result.task);
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ modal_view: 'edit_followup_task', isLoadedIcon: false}) 
    }

    viewAssignTask = (followupid) => (event) => {        
        this.props.viewFollowupTask(followupid)  
    }

    closeModal = () => {
        this.setState({ modal_view: null });
    }

    render()
    {
        if(this.props.followup_tasks.length !== 0){
            return(<div>
                {
                    (() => {
                        if(this.state.modal_view !== null){                                
                            switch(this.state.modal_view) {                                
                                case 'edit_followup_task' : return <div><InactiveOverlay /><EditFollowupTaskModal ischeckRegulatory={true} closeModal={this.closeModal}/></div>; break;
                            }
                            
                        }
                    })()
                }
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.props.headertext}</div>                                
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>          
                <CSLTable add={false} processData={this.processChildFeedsData} tableRows={5} headerText={''} />
                </OverviewInfoBody>
                
                </div>);
        }else{
            return(<div>
                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.props.headertext}</div>                                
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>           
                <OverviewInfoBody>
                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Associated RC Tasks Available</div>
                </OverviewInfoBody>
                </div>);
        }        
    }
}
export default ChildTasksRUTable;