import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaPencilAlt,FaRegTrashAlt} from 'react-icons/fa';
import Store from '../../Common/Store';
import HtmlEditor from '../../HtmlEditor';
import DatePicker from 'react-date-picker';
import APICall from '../../Common/APICall';
import Utils from '../../Common/Utils';
import RCMTagsView from './RCMTagsView';
import AddKeyDates from './KeyDates/AddKeyDates';
import CSLTable from '../Common/CSLTable';
import AddExternalLinks from './ExternalLinks/AddExternalLinks';
import AddEditKeyDates from './KeyDates/AddEditKeyDates';
import AddEditExternalLinks from './ExternalLinks/AddEditExternalLinks';
import SearchablePills from './../Common/SearchablePills';

let btoa = require('btoa');

const CSLDateCover = styled.div`
    height: 40px;
    width: 19%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 8px;
    color: #292929;
    background-color: #ffffff;
`;
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1000;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #373737;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const RegModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalIssueDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #979797;
`;
const RegModalIssueDateInput = styled.input`
    height: 40px;
    width: 19%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #292929;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #252525;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
`;
const RegModalNameInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const RegDismisInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
`;
const RegDismisalButtonDiv = styled.div`
    display: block;
    box-sizing: border-box;
    text-align: center;
    padding-top: 15px;
`;
const RegDismisButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #db6170;
    color: #ffffff;
    border: 2px solid #a24651;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
`;
const RegMarkForActionButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #d5d5d5;
    color: #757575;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    margin-left: 5px;
    font-size: 10px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegDismissalReason = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
    cursor: pointer;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #7a8da1;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #292929;
`;

const SectionTitle = styled.div`
    float: left;
    color: rgb(255, 255, 255);
    font-weight: 600;
    width: 93%;
    height: 31px;    
    cursor: pointer;
    padding-top: 12px;
    font-family: 'Montserrat', sans-serif;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
class AddRegulatory extends React.Component
{
    state = {
        sections: [],
        topics: [],
        isactionTaken: 0,
        tags: [],
        searchFilterTags: [],
        issuedate: new Date(),
        uploaddate: new Date(),
        modificationdate: new Date(),
        publicresponsedate: new Date(),
        onDate: new Date(),
        documentname: "",
        ragulatortypeName: "",
        documentype:null,
        issuingbodies:null,
        legislative:null,
        summary: "",
        content: "",
        ready: false,
        keyDatesValue: [],
        externalLink: [],
        documentTypes: null,
        regulatorType: null,
        scaleOfImpact: "low",
        likelyhoodOfImplementation: "low",

        hiddenAddButton: true,
        showAddKeyDateModal: false,
        curKeyid: 0,
        content: null,

        hiddenAddButtonLink: true,
        showAddLinkModal: false,
        curLinkid: 0,
        contentLink: null,
        document_types: []
};

    constructor(props) {
        super(props);
        Store.updateStore('sections', []);
        this.component_id = Store.registerCallBack('istask_action', this.changeTaskaction);
    }

    saveRegulatorychangeForLater = (saveChangeValue) => (event) => {
        event.preventDefault();
        this.saveChange(saveChangeValue);
    }
    
    saveChange(saveForLater) {        
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        if (saveForLater === false && tags.length === 0) {
            alert("Please select atleast one tag.");
            return;
        }
        let rufeed = {};
        rufeed.tags = tags;
        let summary = Store.getStoreData('summary') === null ? "" : Store.getStoreData('summary');
        rufeed.summary = btoa(window.encodeURIComponent(summary));
        let sections = Store.getStoreData('sections');
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }

        let curmonth = parseInt(this.state.issuedate.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? this.state.issuedate.getFullYear()+'/'+currmonth+'/'+this.state.issuedate.getDate() : this.state.issuedate.getFullYear()+'/0'+currmonth+'/'+this.state.issuedate.getDate();

        rufeed.sections = sections;
        let id = Utils.genKey(35);
        let documentname = this.state.documentname;
        let issuedate = dateStr;
        let uploaddate= this.state.uploaddate;
        let modificationdate= this.state.modificationdate;
        let publicresponsedate= this.state.publicresponsedate;
        //let ondate = this.state.onDate;
        //let documentype = this.state.documentype;

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        let issuingbodies= this.state.issuingbodies;
        let legislative = this.state.legislative;
        if (documentname === "" || (saveForLater === false && doctypeArray.length === 0)) {
            let blanks = [];
            if (documentname === "") {blanks.push("Name")};
            if (saveForLater === false && doctypeArray.length) {blanks.push("Document Types")};            
            let msg = "";
            if (blanks.length === 1) {
                msg = `The ${blanks[0]} field is mandatory.\nThis field cannot be left blank`;
            } else {
                blanks.forEach((blank) => {
                    msg = msg + `${blank}, `;
                })
                msg = msg.substring(0, msg.length - 2);
                msg = msg + " fields are mandatory.\nCannot be left blank";
            }
            alert(msg);
            return false;
        }       
    
        //console.log("doctypeArray==>", doctypeArray)

        rufeed.id = id;
        rufeed.documentName = documentname;
        rufeed.issueDate = issuedate;
        rufeed.uploadDate =uploaddate;
        rufeed.modificationDate = modificationdate;
        rufeed.publicResponseDate = publicresponsedate;
        //rufeed.onDate = ondate;
        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        //rufeed.documentTypes = documentype;
        rufeed.documentTypes = doctypeArray;
        rufeed.scaleOfImpact = this.state.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = this.state.likelyhoodOfImplementation;
        rufeed.ragulatorName = this.state.ragulatortypeName        
        rufeed.issuingBodies = [this.state.ragulatortypeName];
        rufeed.legislativeInitiatives = [];       
        console.log("summary", rufeed);
        let postData = {"command": "savefeed", "id": id, "data": rufeed, "selected_topics": tags,'save_for_later' : saveForLater ? 1 : 0,'is_custom' : 1, is_add: 1, 'job_type' : 'Assign_feedtoManager'};
        console.log("postData===>",postData);
        let api = new APICall();
        api.command(postData, this.SaveFeedFetchData);
    }
    SaveFeedFetchData = (result) => {
        console.log("update data", result);
        alert(result.error_msg);
        //localStorage.clear();
        window.location.reload(true);
    }

    changeTaskaction = () => {
        const istask_action = Store.getStoreData('istask_action');
        this.setState({ isactionTaken: istask_action });
    }

    componentWillUnmount() {
        Store.deRegisterCallback('changeTaskaction', this.component_id);
    }

    AddFeedtag = (result) => {
        let otags = [];
        result.tags.forEach((top) => {
            otags.push({
                name: top.TagName,
                id: top.ID,
                styledcomponent: UnselectedPill,
                selected: false
            });
        })
        let stagnames = [];
        otags.forEach((item) => {
            stagnames.push(item.name);
        })
        stagnames.sort();
        let tags = [];
        for (let i=0; i<stagnames.length; i++) {
            for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    tags.push(otags[j]);
                }
            }
        }
        let doctype = Store.getStoreData('rutypename');
        let document_types = [];
        Store.getStoreData('rutypename').forEach((dt => document_types.push({id: dt.id, name: dt.documentname, selected: false, show: true})));
        document_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        doctype.sort(function(a,b) {return (a.documentname > b.documentname) ? 1 : ((b.documentname > a.documentname) ? -1 : 0);} ); 
        let regtype = Store.getStoreData('ragulatorsname'); 
        //console.log("regtype",regtype) 
        regtype.sort(function(a,b) {return (a.ragulatorname > b.ragulatorname) ? 1 : ((b.ragulatorname > a.ragulatorname) ? -1 : 0);} );     
        this.setState({ tags: tags, searchFilterTags: tags,documentTypes: doctype, regulatorType: regtype, ready: true, document_types});
    }
 
componentDidMount() {

    let postData = { command: "getfeed" , feed_id: 0  };
    let api = new APICall();
    api.command(postData, this.AddFeedtag);

     }

     closeRegulatoryModal = (event) => {
        this.props.closeModal();    
    }

    onIssueDateChange = (date) => {
        let issuesdate = this.state.issuedate;
        this.setState({issuedate: date});
    }

    onUploadDateChange = (date) => {
        this.setState({uploaddate: date});
    }

    onModificationDateChange = (date) => {
        this.setState({modificationdate: date});
    }

    onPublicresponseDate = (date) => {
        this.setState({publicresponsedate: date});
    }   

    addSection = (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        console.log("sections",sections)
        let section_id = 1;
        if (sections.length !== 0) {
            section_id = sections[sections.length - 1].id + 1;
            console.log("sections_id",section_id)
        }
        sections.push({
            id: section_id,
            display: "input",
            name: `Section ${section_id.toString()}`,
            content: ""
        });
        console.log("sections2",sections)
        Store.updateStore('sections', sections);
        this.setState({sections});
    }

    deleteSection = (section_id) => (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure to delete this section?")) {
            let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
            console.log("sections",sections)
            let sectionArray = []
            for(let i=0;i<sections.length;i++){
                if(sections[i].id !== section_id){
                    if(sections.length === 1){
                        sectionArray = [];
                    }else{
                        sectionArray.push({
                            content: sections[i].content,
                            display: sections[i].display,
                            id: sections[i].id,
                            name: sections[i].name
                        })
                    }
                    
                }                        
            }
            console.log("sectionArray",sectionArray)
            Store.updateStore('sections', sectionArray);
            this.setState({sections: sectionArray});
        }
    }

    selectOrUnselectTopic = (tagid) => {
        //event.preventDefault();
        console.log("Sourav22", tagid)
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    changeInputValue = (status) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
                section.display = "input";
                if (!status) section.display = "label";
            }
        })
        let store_sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        store_sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
            }
        })
        Store.updateStore('sections', store_sections);
        this.setState({sections});
    }

    changeToInput = (data_id) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(data_id)) {
                section.display = "input";
            }
        })
        this.setState({sections});
    }


    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    ondocumentType = (event) => {
        event.preventDefault();
        let dtype = event.target.value;
        let documenttypearr = dtype.split(",");
        this.setState({[event.target.name]: documenttypearr});
    }
    onissuingbodies = (event) => {
        event.preventDefault();
        let iboddies = event.target.value;
        let issuingbodiesarr = iboddies.split(",");
        this.setState({[event.target.name]: issuingbodiesarr});
    }
    onlegislative = (event) => {
        event.preventDefault();
        let legislative = event.target.value;
        let legislativearr = legislative.split(",");
        this.setState({[event.target.name]: legislativearr });
    }

    createNewFeed = (event) => {
        event.preventDefault();
        let rufeed = {};
        let summary = Store.getStoreData('summary');
        rufeed.summary = btoa(window.encodeURIComponent(summary));
        let sections = Store.getStoreData('sections');
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }
        rufeed.sections = sections;
        let id = Utils.genKey(35);
        let documentname = this.state.documentname;
        let issuedate = this.state.issuedate;
        let uploaddate= this.state.uploaddate;
        let modificationdate= this.state.modificationdate;
        let publicresponsedate= this.state.publicresponsedate;
        let documentype = this.state.documentype;
        let issuingbodies= this.state.issuingbodies;
        let legislative = this.state.legislative;
    
        rufeed.id = id;
        rufeed.documentname = documentname;
        rufeed.issuedate = issuedate;
        rufeed.uploaddate =uploaddate;
        rufeed.modificationdate = modificationdate;
        rufeed.publicresponsedate = publicresponsedate;
        rufeed.documentype = documentype;
        rufeed.issuingbodies = issuingbodies;
        rufeed.legislative = legislative;
        rufeed.tag = this.state.tags       
        console.log("summary", rufeed);
    }

    searchUsers = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
        console.log("fddsf",this.state.searchFilterTags)
        let search_string = event.target.value;
        if (search_string === "") {
            let tags = this.state.searchFilterTags;            
            this.setState({tags: tags});
        }else {
            let all_tags = this.state.searchFilterTags;
            let filtered_tags = [];
            all_tags.forEach((tag) => {
                if (tag.name.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_tags.push(tag);
                }                
            })            
            let tags = filtered_tags;

            this.setState({tags: tags})
        }
    }
    
    fetchKeyValue = (keyDate,keyDescription) => {
        console.log(keyDate,keyDescription)        
        // if(keyDescription != '')
        // {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let keys = {}
            keys.id = randNo; 
            keys.date = keyDate;
            keys.description = keyDescription;            
            this.state.keyDatesValue.push(keys)
        //}
        console.log(this.state.keyDatesValue)
        //keyDatesValue
        this.setState({keyDatesValue: this.state.keyDatesValue, showAddKeyDateModal: false, hiddenAddButton: true})
    }

    refreshEditKeyValue = (content) => {
        console.log("content",content)   
        // if(content.description != '')
        // {
            let newkeydate = [];
            this.state.keyDatesValue.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newkeydate.push(item);
            })            
		    this.setState({keyDatesValue: newkeydate, showAddKeyDateModal: false, hiddenAddButton: true})
        //}
    }

    fetchexternalLinkValue = (linkUrl,linkDescription) => {
        console.log(linkUrl,linkDescription)
        if(linkUrl != '') {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let links = {};
            links.id = randNo; 
            links.url = linkUrl;
            links.description = linkDescription;
            this.state.externalLink.push(links);
        }
        this.setState({externalLink: this.state.externalLink, showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    fetchEditexternalLinkValue = (content) => {
        console.log("content",content)              
        if(content.url != '')
        {
            let newExLinks = [];
            this.state.externalLink.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newExLinks.push(item);
            })            
		    this.setState({externalLink: newExLinks, showAddLinkModal: false, hiddenAddButtonLink: true})
        }  
    }

    processMykeyData = () => {
        var keysdata = this.state.keyDatesValue;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                <FaPencilAlt onClick={this.openEditKeyDateModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                <FaTimes onClick={this.deleteKeyDate(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteKeyDate = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newkeydate = [];
			for(let i=0;i<this.state.keyDatesValue.length;i++)
			{
				if(this.state.keyDatesValue[i].id !== id){
					newkeydate.push(this.state.keyDatesValue[i])
				}
			}			 
			console.log("delete===>",newkeydate);   
            this.setState({keyDatesValue: newkeydate})      
		//}         		  
    }

    openEditKeyDateModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.keyDatesValue.find((item) => {
			return item.id === id;
		})
		this.setState({content,curKeyid: id,showAddKeyDateModal: true, hiddenAddButton: false});        
	}

    processMyExternalLink = () => {
        var linksdata = this.state.externalLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        // {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {'Header' : 'URL', accessor: 'url_value', Cell: row => (
            <div>
                <a target="_blank" href={row.original.url_value}>{row.original.url_value}</a>
            </div>
          ), minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                <FaPencilAlt onClick={this.openEditExLinkModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                <FaTimes onClick={this.deleteExternalLink(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of linksdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    // 'url_value' : <a target="_blank" href={d.url}>{d.url}</a>,
                    'url_value' : d.url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteExternalLink = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newexLink = [];
			for(let i=0;i<this.state.externalLink.length;i++)
			{
				if(this.state.externalLink[i].id !== id){
					newexLink.push(this.state.externalLink[i])
				}
			}			 
			console.log("delete===>",newexLink);   
            this.setState({externalLink: newexLink})      
		//}         		  
    }

    openEditExLinkModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const contentLink = this.state.externalLink.find((item) => {
			return item.id === id;
		})        
		this.setState({contentLink,curLinkid: id,showAddLinkModal: true, hiddenAddButtonLink: false});        
    }

    showAddKeyDate = () => {    
        //event.preventDefault();
        this.setState({ showAddKeyDateModal: true, hiddenAddButton: false,curKeyid: 0 });
    }
    closeKeyDateModal = () => {
        this.setState({ showAddKeyDateModal: false, hiddenAddButton: true});
    }

    showAddexternalLink = () => {    
        //event.preventDefault();

        this.setState({ showAddLinkModal: true, hiddenAddButtonLink: false,curLinkid: 0 });
    }
    closeExLinkModal = () => {
        this.setState({ showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    modifyEntity = (obj) => {
        console.log("obj:", obj);
        this.setState({[obj.entity]: obj.list});
    }

  render()
    {   
        //console.log("document_types:", this.state.document_types);
        let uploadDate = this.state.uploaddate;        
        const upload_date_obj = new Date(uploadDate);
		const upload_day = upload_date_obj.getDate();
		const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
		const upload_year = upload_date_obj.getFullYear();
        const upload_date = `${upload_day}/${upload_month}/${upload_year}`;
        //console.log("this.state.tags",this.state.tags)
        if (!this.state.ready) {
			return (<div></div>);
		}             
        return (
            <RegModalContainer>
                <RegModalHeaderText>Add Regulatory Change</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <div style={{marginTop: "30px"}}>
                    <label style={{marginTop: "15px",marginLeft: "20px",marginRight: "16px",fontWeight: "600",color: "#252525"}}>Name</label>
                    <RegModalNameInput style={{width: "84%",marginLeft: "6.2%"}} type="text" name="documentname" value={this.state.documentname} onChange={this.handleChange}  />
                </div>
                <div style={{marginTop: "30px"}}>
                    <label style={{marginTop: "15px",marginLeft: "20px",marginRight: "16px",fontWeight: "600",color: "#252525"}}>Regulator Name</label>
                    <MRModalSelect style={{width: "84%", display: "inline-block"}} name="ragulatortypeName" value={this.state.ragulatortypeName} onChange={this.handleChange}>                        
                        <option value="">Select Regulator</option>
                        {
                            this.state.regulatorType.map((reg, index) => {
                                return (<option key={index} value={reg.ragulatorname}>{reg.knownAs}</option>);
                            })
                        }                            
                    </MRModalSelect>
                </div>
                <div style={{marginTop: "25px", display: "flex"}}>
                    {/* <label style={{marginTop: "15px",marginLeft: "20px",marginRight: "20px",fontWeight: "600",color: "#252525"}}>Type</label>
                    <MRModalSelect style={{width: "25%"}} name="documentype" value={this.state.documentype} onChange={this.ondocumentType}>                        
                        <option value="">Select Type</option>
                        {
                            this.state.documentTypes.map((doc, index) => {
                                return (<option key={index} value={doc.id}>{doc.documentname}</option>);
                            })
                        }                            
                    </MRModalSelect> */}
                    <label style={{marginTop: "13px",marginLeft: "20px",marginRight: "15px",fontWeight: "600",color: "#252525"}}>Regulator Published Date</label>
                    <CSLDateCover>
                    <DatePicker onChange={this.onIssueDateChange}
                        clearIcon={null}
                        calendarIcon={null}
                        locale={"en-GB"}
                        value={this.state.issuedate}/>
                    </CSLDateCover>
                    <label style={{marginTop: "13px",marginLeft: "26px",marginRight: "15px",fontWeight: "600",color: "#252525"}}>Date</label>
                    <RegModalIssueDateInput defaultValue={upload_date} readOnly/>
                    {/* <CSLDateCover>
                        <DatePicker onChange={this.onUploadDateChange}
                        clearIcon={null}
                        calendarIcon={null}
                        locale={"en-GB"}
                        value={this.state.uploaddate}
                        />
                    </CSLDateCover> */}
                </div>  
                <div style={{marginTop: "25px", display: "flex",marginBottom: "20px"}}>
                    <label style={{marginTop: "15px",marginLeft: "20px",marginRight: "20px",fontWeight: "600",color: "#252525"}}>Scale of Impact</label>
                    <MRModalSelect style={{width: "15%"}} name="scaleOfImpact" value={this.state.scaleOfImpact} onChange={this.handleChange}>                        
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>                            
                    </MRModalSelect>
                    <label style={{marginTop: "15px",marginLeft: "20px",marginRight: "20px",fontWeight: "600",color: "#252525"}}>Likelihood of implementation</label>
                    <MRModalSelect style={{width: "15%"}} name="likelyhoodOfImplementation" value={this.state.likelyhoodOfImplementation} onChange={this.handleChange}>                        
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>                             
                    </MRModalSelect>
                </div>
                <RegModalContainertop>
                    <RegModalNameLabelDiv>Document Types</RegModalNameLabelDiv>
                    <SearchablePills entity="document_types" list={this.state.document_types} modifyEntity={this.modifyEntity} />
                </RegModalContainertop>
                <RegModalHr />
                <RegModalContainertop>
                    <RegModalNameLabelDiv>Summary</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        {
                            (() => {
                                return (<HtmlEditor content={this.state.summary} section_id="0" />);
                            })()
                        }
                    </RegModalNameInputDiv>
                </RegModalContainertop>
                {
                    Store.getStoreData('sections').map((section) => {
                        if (section.display === "label") {
                            return (
                                <RegModalContainertop key={section.id}>
                                    <RegModalNameLabelDiv onClick={this.changeToInput(section.id)}>{section.name}</RegModalNameLabelDiv>
                                    <RegModalNameInputDiv>
                                        <HtmlEditor content={section.content} section_id={section.id}/>
                                    </RegModalNameInputDiv>
                                </RegModalContainertop>
                            );
                        } else {
                            return (
                                <RegModalContainertop key={section.id}>
                                    <RegModalNameLabelDiv >
                                        <RegModalNameInput type="text" name={section.id} value={section.name} onChange={this.changeInputValue(true)} onBlur={this.changeInputValue(false)} />
                                    </RegModalNameLabelDiv>
                                    <RegModalNameInputDiv>
                                        <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>
                                        <div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>
                                    </RegModalNameInputDiv>
                                </RegModalContainertop>
                            );
                        }
                    })
                }
                <SingleRowColumnContainer>
                        <AddSectionButton onClick={this.addSection}>Add Section</AddSectionButton>                        
                </SingleRowColumnContainer>
               <RegModalContainertop>
                    <RegModalNameLabelDiv>Available Tags</RegModalNameLabelDiv>
                    <RegModalTagsDiv>
                    <RegModalNameInput style={{marginTop: "13px",marginLeft: "6px",marginBottom: "11px",height: "33px",width: "98%"}} placeholder="Start typing to search..." onChange={this.searchUsers} type="text" />
                        {
                            (() => {
                                if (this.state.tags.length !== 0) {
                                    //console.log(this.state.tags); 
                                    // this.state.tags.map((tag, index) => {
                                    //     return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>{tag.name}</tag.styledcomponent>);
                                    // })
                                    return(<RCMTagsView tags={this.state.tags} selectUnselect={this.selectOrUnselectTopic}/>)                                                                                                         
                                } 
                            })()                                                
                            //if(this.state.tags.length != 0) {
                            
                            //}
                            
                        }
                    </RegModalTagsDiv>
                </RegModalContainertop>
                {
                    (() => {
                        if(this.state.keyDatesValue.length === 0)
                        {
                            return(<SingleRowColumnContainer >
                            <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                    {
                                        (() =>{
                                            if(this.state.hiddenAddButton){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddKeyDateModal) {
                                            return (<AddEditKeyDates  curid={this.state.curKeyid} closeKeyDateModal={this.closeKeyDateModal} refreshKeyValue={this.fetchKeyValue}/>);
                                        }
                                    })()
                                }
                            {/* <AddKeyDates refreshKeyValue={this.fetchKeyValue}/> */}
                            <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}> 
                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no Key Dates Available</div>
                            </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }else {
                            return(<SingleRowColumnContainer >
                                <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                    {
                                        (() =>{
                                            if(this.state.hiddenAddButton){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddKeyDateModal) {
                                            return (<AddEditKeyDates  curid={this.state.curKeyid} content={JSON.parse(JSON.stringify(this.state.content))} closeKeyDateModal={this.closeKeyDateModal} refreshEditKeyValue={this.refreshEditKeyValue} refreshKeyValue={this.fetchKeyValue}/>);
                                        }
                                    })()
                                }
                                {/* <AddKeyDates refreshKeyValue={this.fetchKeyValue}/> */}
                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                                    
                                    <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.externalLink.length === 0)
                        {
                            return(<SingleRowColumnContainer >
                                <OverviewInfoHeader style={{width: "unset"}}>
                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                {
                                    (() => {
                                        if(this.state.hiddenAddButtonLink){
                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                        }
                                    })()
                                }                
                                <div style={{clear: "both"}}></div>
                            </OverviewInfoHeader>
                            {
                                (() => {
                                    if (this.state.showAddLinkModal) {
                                        return (<AddEditExternalLinks  curid={this.state.curLinkid} closeExLinkModal={this.closeExLinkModal} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                    }
                                })()
                            }
                            {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                            <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}> 
                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no External Links Available</div>
                            </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }else {
                            return(<SingleRowColumnContainer >
                                <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                    {
                                        (() => {
                                            if(this.state.hiddenAddButtonLink){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddLinkModal) {
                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} content={JSON.parse(JSON.stringify(this.state.contentLink))} closeExLinkModal={this.closeExLinkModal} fetchEditexternalLinkValue={this.fetchEditexternalLinkValue} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                        }
                                    })()
                                }
                                {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                                    
                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }
                    })()
                }

                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeRegulatoryModal}>Cancel</RegCancelButton>
                    {
                        (() => {
                            if(this.state.isactionTaken === 0) {
                                return (<RegSubmitButton onClick={this.saveRegulatorychangeForLater(true)} >Save for later</RegSubmitButton>);
                            }
                        })()
                    }
                    {
                        (() => {
                            if (this.state.isactionTaken === 0) {
                                return (<RegSubmitButton onClick={this.saveRegulatorychangeForLater(false)}>Submit RC</RegSubmitButton>);
                            } else {
                                return (<RegSubmitButton onClick={this.saveRegulatorychangeForLater(true)}>Save</RegSubmitButton>);
                            }
                        })()
                    }

                </RegCancelSubmitButtonOuterContainer>
   
            </RegModalContainer>
        );
    }
}
export default AddRegulatory;