import React from 'react';
import styled from 'styled-components';
import CSLLoader from './../Common/CSLLoader';
import Store from './../../Common/Store';
import CSLRegisterTable from './../Common/CSLRegisterTable';
import APICall from './../../Common/APICall';
import moment from 'moment';
import RegisterManagerViewModal from './../GenericRegister/RegisterManagerViewModal';
import ViewManagerRU from './../GenericRegister/ViewManagerRU';
import reportData from './reportData';
import RegisterViewAutoForwardFeedModal from './RegisterViewAutoForwardFeedModal';
import DismissTaskModal from './../Select/DismissTaskModal';
import AutoForWardDismissTaskModal from './../Select/AutoForWardDismissTaskModal';
import RegisterPendingtaskModal from './RegisterPendingtaskModal';


const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;  
  margin-right:15px;
  opacity: 1 ; 
`;

const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  margin-right:15px;  
  opacity: 1 ; 
`;

const InProgress = styled.span`
  height: 15px;
  width:15px;
  background-color: #FFD11A; 
  margin-right:15px; 
  opacity: 1;
`;
const Saved = styled.span`
  height: 15px;
  width:15px;
  background-color: rgb(27, 52, 81); 
  margin-right:15px; 
  opacity: 1;
`;

const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;  
  margin-right:15px;
  opacity: 1 ; 
`;
const UnselectedPill = styled.div`
    background-color:#EEF2F7;
    color: #243E57;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
    width: 170px;
    border: 1px solid #243E57;
`;
const SelectedPill = styled.div`
    background-color: #243E57;
    color: #EEF2F7;
    padding: 8px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
    width: 170px;
    border: 1px solid #243E57;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 200%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;  
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
class RegisterManagerView extends React.Component {

    state = {
      show_filter: [],
      status_count:{},
      filters: {},
      header_buttons: [],
      registerIndexData: {},
      register_filters: {
        type: {name: 'type', 'nickname': 'Type', type: 'multi', options: {},selections: {}, size: 2},
        regulator: {name: 'regulator', 'nickname': 'Regulator', type: 'multi', options: {},selections: {}, size: 2},            
        company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
        reg_pub_date: {name: 'reg_pub_date', 'nickname': 'Regulator Publish Date', type: 'date_range', options: {},selections: {}, size: 2},          
        status: {name: 'status', 'nickname': 'Status', type: 'multi', options: {},selections: {}, size: 2},          
        tags: {name: 'tags', 'nickname': 'Tags', type: 'multi', options: {},selections: {}, size: 2}          
      },   
      register_tasks: [],
      doctype: {},
      show_all_Group_RUs: true,
      prefered_columns: {},
      modal_view:null,
      fields: [],
      registerbucLoader: false,
      ispillsShow: false,
      ready: false,
      isLoaderIcon: false,
      onClickType: '',
      isAutoForwordData: false,
      isfilterupdate: false
    }

    componentDidMount(){      
      let show_filter = [{name: "All",id: 'all',styledcomponent: SelectedPill,selected: true},
                  {name: "Pending",id: 'pending',styledcomponent: UnselectedPill,selected: false},
                  {name: "In Progress",id: 'inprogress',styledcomponent: UnselectedPill,selected: false},
                  {name: "Complete",id: 'complete',styledcomponent: UnselectedPill,selected: false},
                  {name: "Dismissed",id: 'dismissed',styledcomponent: UnselectedPill,selected: false},
                  {name: "Overdue",id: 'overdue',styledcomponent: UnselectedPill,selected: false},
                  {name: "Untagged",id: 'untaggedCount',styledcomponent: UnselectedPill,selected: false}];  

      let status_count = {'all': 0,'pending': 0,'inprogress': 0,'complete': 0,'dismissed': 0,'overdue': 0,'untaggedCount': 0}

      let header_buttons = []
      header_buttons.push({display: 'Team View', disabled: true, color: '#D4D5D8'})
      header_buttons.push({display: 'User View', processClick: this.userView})
      header_buttons.push({display: 'Manager View'}) 

      let filters = {
        type: {name: 'type', 'nickname': 'Type', type: 'multi', options: {},selections: {}, size: 2},
        regulator: {name: 'regulator', 'nickname': 'Regulator', type: 'multi', options: {},selections: {}, size: 2},
        groupcompanyname: {name: 'groupcompanyname', 'nickname': 'Group', type: 'multi', options: {},selections: {}, size: 2}
      }

      const documentType = Store.getStoreData('rutypenameAll');
      let doctype = {};
      for(let i=0; i < documentType.length; i++) {
          doctype[documentType[i].id] = documentType[i].documentname;
      }

      if(Store.getStoreData('registerFilter') === null || Store.getStoreData('registertask') === null){
        //new
        if(Store.getStoreData('rcm-register_list_tasks-select') !== null){
          this.setState({          
            register_filters: Store.getStoreData('rcm-register_list_tasks-select').result.register_all_tasks.filters,
            isfilterupdate: true          
          });
        }
        //new
        const today = moment().format('DD/MM/YYYY');
        const fromDate = moment().subtract(1, 'years').format('DD/MM/YYYY');
        const api1 = new APICall();
        let register_filters = JSON.parse(JSON.stringify(this.state.register_filters))      
        register_filters.reg_pub_date.selections = {from_date: fromDate, to_date: today};            
        const payload = {command: "register_list_tasks", filters: {register_filters}};
        api1.command(payload, this.listTasks);
      }else{               
        this.setState({
          register_tasks: Store.getStoreData('registertask') === [] ? null : Store.getStoreData('registertask'),
          register_filters: Store.getStoreData('registerFilter'), 
          ready: true
        });
      }      

      if(Store.getStoreData('userViewData') === null) {        
        const postData = {command: "get_rcm_registry_user"}
        console.log("postdata", postData)
        const api = new APICall();
        api.command(postData, this.processregisterVuewUserRCM)
      } 

      let module_config = Store.getStoreData('module_config');
      let prefered_columns = {}
      if('general' in module_config && module_config.general.enable_tag_management === true){
          prefered_columns = {
            'status': 'Status',
            'name': 'Name', 
            'type': 'Type', 
            'regulator' : 'Regulator', 
            'tags_name' : 'Tag(s)',
            'sharedinfopeople': 'Info', 
            'sharedinfo': 'Shared - Info', 
            'sharedactionpeople' : 'Action', 
            'sharedaction': 'Shared - Action', 
            'followuptasks' : 'Follow Up Task',
            'regulator_published_date' : 'Regulator Published Date'
          }
      }else{
          prefered_columns = {
            'status': 'Status',
            'name': 'Name', 
            'type': 'Type', 
            'regulator' : 'Regulator',                   
            'sharedinfopeople': 'Info', 
            'sharedinfo': 'Shared - Info', 
            'sharedactionpeople' : 'Action', 
            'sharedaction': 'Shared - Action', 
            'followuptasks' : 'Follow Up Task',
            'regulator_published_date' : 'Regulator Published Date'
          }
      }

      let fields = []
      fields.push({accessor: 'status', nickname: 'Status', type: 'alpha', width: 1.2})
      fields.push({accessor: 'name', nickname: 'Name', type: 'alpha', width: 2.6})
      fields.push({accessor: 'type', nickname: 'Type', type: 'alpha', width:1.2})
      fields.push({accessor: 'regulator', nickname: 'Regulator', type: 'alpha', width:1.1})
      fields.push({accessor: 'tags_name', nickname: 'Tag(s)', type: 'alpha', width:2})
      fields.push({accessor: 'sharedinfo', nickname: 'Info', type: 'numeric', width:1})
      fields.push({accessor: 'sharedaction', nickname: 'Action', type: 'numeric', width:1})
      fields.push({accessor: 'followuptasks', nickname: 'Followup', type: 'numeric', width:1})
      fields.push({accessor: 'regulator_published_date', nickname: 'Reg. Date', type: 'alpha', width:1})    

      console.log("show_filter...+", Store.getStoreData('show_filter'))
      console.log("show_filter+", show_filter)
      console.log("status_count...+", Store.getStoreData('status_count'))
      console.log("status_coun+", status_count)
      if(Store.getStoreData('status_count') === null){
        this.setState({
          show_filter, status_count          
        });
      }else{  
        let rawtags = Store.getStoreData('tags');    
        if(Store.getStoreData('status_count').saved !== 0) show_filter.push({name: "Saved",id: 'saved',styledcomponent: UnselectedPill,selected: false})
        for(let rawtag of rawtags){
          if(rawtag.ID in Store.getStoreData('status_count')){
            let obj = {name: rawtag.TagName, id: rawtag.ID,styledcomponent: UnselectedPill,selected: false}
            show_filter.push(obj);
          }
        }        
        this.setState({
          show_filter: show_filter, 
          status_count: Store.getStoreData('status_count'),
          ispillsShow: true          
        });
      }
      
      // Store.updateStore('showFilter', show_filter)
      this.setState({ header_buttons, filters, doctype, prefered_columns, fields})
    }

    listTasks = (result) => {
      console.log(result); 
      let status_count = {'all': 0,'pending': 0,'inprogress': 0,'complete': 0,'dismissed': 0,'overdue': 0,'untaggedCount': 0, 'saved': 0}
      let taskData = result.result.register_all_tasks.tasks;

      let curcompanyid = Store.getStoreData('companyid'); 
      let cur_role = Store.getStoreData('role');
      let update_register_tasks = [];
      for(let d of taskData){
        if(cur_role === 'manager'){
          if(!this.state.show_all_Group_RUs && d[1] === curcompanyid){           
            update_register_tasks.push(d) 
          }else if(this.state.show_all_Group_RUs){
            update_register_tasks.push(d) 
          }
        }else{
          update_register_tasks.push(d) 
        }
      }

      let tags = {};
      for(let d of update_register_tasks) {
        let status = d[10]; 
        let blankTags = d[16];
        for(let val of blankTags){
          tags[val.toString()] = 1;
        }
        if(status === 'Pending') {
            status_count.pending = status_count.pending + 1;
        }
        if(status === 'In Progress') {
            status_count.inprogress = status_count.inprogress + 1;
        }
        if(status === 'Complete') {
            status_count.complete = status_count.complete + 1;
        }
        if(status === 'Dismissed') {
            status_count.dismissed = status_count.dismissed + 1;
        }
        if(status === 'Overdue') {
            status_count.overdue = status_count.overdue + 1;
        }
        if(blankTags.length === 0) {
            status_count.untaggedCount = status_count.untaggedCount + 1;
        } 
        if(status === 'Saved') {
          status_count.saved = status_count.saved + 1;
        }
      }
      Object.keys(tags).forEach(function(key) {
        let j = 0;
        for(let f of update_register_tasks) {
            let addtags = f[16];
            let check_tag = {};
            addtags.forEach(function(keynew) {
                check_tag[keynew.toString()] = 1;                
            });            
            if(key in check_tag) {
                j++;
                status_count[key.toString()] = j;
            }
        }
      });      
      status_count.all = update_register_tasks.length;
      let rawtags = Store.getStoreData('tags');

      let show_filter = [{name: "All",id: 'all',styledcomponent: SelectedPill,selected: true},
                  {name: "Pending",id: 'pending',styledcomponent: UnselectedPill,selected: false},
                  {name: "In Progress",id: 'inprogress',styledcomponent: UnselectedPill,selected: false},
                  {name: "Complete",id: 'complete',styledcomponent: UnselectedPill,selected: false},
                  {name: "Dismissed",id: 'dismissed',styledcomponent: UnselectedPill,selected: false},
                  {name: "Overdue",id: 'overdue',styledcomponent: UnselectedPill,selected: false},
                  {name: "Untagged",id: 'untaggedCount',styledcomponent: UnselectedPill,selected: false}];

      console.log("status_count==>", this.state.show_filter)
      console.log("status_count==>", status_count)
      // let show_filter = this.state.show_filter;
      // let show_filter = this.state.show_filter;
      if(status_count.saved !== 0) show_filter.push({name: "Saved",id: 'saved',styledcomponent: UnselectedPill,selected: false})
      for(let rawtag of rawtags){
        if(rawtag.ID in status_count){
          let obj = {name: rawtag.TagName, id: rawtag.ID,styledcomponent: UnselectedPill,selected: false}
          show_filter.push(obj);
        }
      }    
      
      Store.updateStore('registertask', result.result.register_all_tasks.tasks);
      Store.updateStore('status_count', status_count)
      Store.updateStore('registerFilter', result.result.register_all_tasks.filters);
      Store.updateStore('registerFilterInEntry', result.result.register_all_tasks.filters);
      //new      
      if(!this.state.isfilterupdate){
        this.setState({          
          register_filters: result.result.register_all_tasks.filters         
        });
      }
      //new
      this.setState({
          register_tasks: result.result.register_all_tasks.tasks === [] ? null : result.result.register_all_tasks.tasks,
          //register_filters: result.result.register_all_tasks.filters, 
          status_count,
          show_filter,
          ispillsShow: true,
          ready: true
      });  
  }
  processregisterVuewUserRCM = (result) => {  
    console.log("userViewData", result)  
    Store.updateStore('userViewData', result.userViewlist);    
  }

    userView = () => {      
      if(Store.getStoreData('userViewData') !== null){
        this.props.modalView('userView');
      }
    }

    selectOrUnselectTopic = (tagid) => (event) => {
      event.preventDefault();
      let show_filter = this.state.show_filter;   
      let regdata = Store.getStoreData('registertask')  
      show_filter.forEach((tag) => {
          if (tag.id === tagid) {
              if (tag.selected) {
                  tag.selected = false;
                  tag.styledcomponent = UnselectedPill;
              } else {
                  tag.selected = true;
                  tag.styledcomponent = SelectedPill;
              }
          }
      })  
      console.log("show_filter===>", show_filter)
      
      let register_tasks = [];      
      let faq = {};
      for(let val of regdata){      
        show_filter.map((tag, index) => {
          if (tag.selected) {
            if(tag.id !=='all'){
                for(let tagval of val[16]){
                  if(tagval === tag.id) {
                    faq[val[2]] = 1;
                  }
                }                                
                if(tag.id === 'pending' && val[10] === 'Pending'){  
                  faq[val[2]] = 1;
                }
                if(tag.id === 'inprogress' && val[10] === 'In Progress'){                  
                  faq[val[2]] = 1;
                }
                if(tag.id === 'complete' && val[10] === 'Complete'){                  
                  faq[val[2]] = 1;
                }
                if(tag.id === 'dismissed' && val[10] === 'Dismissed'){                  
                  faq[val[2]] = 1;
                }
                if(tag.id === 'overdue' && val[10] === 'Overdue'){                  
                  faq[val[2]] = 1;
                }
                if(tag.id === 'untaggedCount' && val[16].length === 0){                  
                  faq[val[2]] = 1;
                }
                if(tag.id === 'saved' && val[10] === 'Saved'){                  
                  faq[val[2]] = 1;
                }
              }else{
                faq[val[2]] = 1;
              }              
          }
        })
      }
      for(let d of regdata){
        if(d[2] in faq){
          register_tasks.push(d);
        }
      }         
      this.setState({ show_filter: show_filter, register_tasks});
    }

    processAcceptanceQueueData = () => {  
      let ret = {data: [], columns: []};
      let i = 1;   
      const has_gc = Store.getStoreData('has_gc');
      let has_tag = false
      if('general' in Store.getStoreData('module_config') && Store.getStoreData('module_config').general.enable_tag_management === true){
        has_tag = true;
      }     
      ret.columns =[{Header: 'Status', accessor: 'status', 
                  Cell: row => (<div>
                    {
                      (() => {                          
                        switch(row.original.status) {
                          case 'Overdue': return  <div><Overdue><span style={{paddingLeft: "15px"}}></span></Overdue>{row.original.status}</div>; break;
                          case 'Dismissed': return  <div><Overdue><span style={{paddingLeft: "15px"}}></span></Overdue>{row.original.status}</div>; break;
                          case 'Complete': return  <div><Complete><span style={{paddingLeft: "15px"}}></span></Complete>{row.original.status}</div>; break;
                          case 'In Progress': return  <div><InProgress><span style={{paddingLeft: "15px"}}></span></InProgress>{row.original.status}</div>; break;
                          case 'Saved': return  <div><Saved><span style={{paddingLeft: "15px"}}></span></Saved>{row.original.status}</div>; break;
                          default: return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status}</div>
                        }
                      })()
                    }
                  </div>), minWidth: 70, headerStyle: {textAlign: 'left'}},
                  {'Header' : 'Name', accessor: 'name', Cell: row => (
                    <div>
                        {
                            (() => {
                                if(row.original.externalLink !== ''){
                                    return(<a target="_blank" href={row.original.externalLink}>{row.original.name}</a>)
                                }else{
                                    return(<div>{row.original.name}</div>)
                                }
                            })()
                            
                        }
                        
                    </div>
                  ), minWidth: 140, headerStyle: {textAlign: 'left'}},
                  {Header: 'Company', accessor: 'groupcompanyname', minWidth: 70, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                  {Header: 'Type', accessor: 'typeValue', minWidth: 70, headerStyle: {textAlign: 'left'}},
                  {Header: 'Regulator', accessor: 'regulator', minWidth: 70, headerStyle: {textAlign: 'left'}},
                  {Header: 'Tag(s)', accessor: 'tags_name', minWidth: 70, headerStyle: {textAlign: 'left'}, show: has_tag ? true : false},                    
                  {Header: 'Shared – Info', accessor: 'sharedinfo', Cell: row => (
                    (() => {
                        if(row.original.sharedinfo === '0'){
                            return(<div >{row.original.sharedinfo}</div>)
                        }else{
                            // return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} >{row.original.sharedinfo}</div>)
                            if(row.original.is_legecy === 1){
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.gotoManagerView(row.original.id, 'sharedinfo', row.original)}>{row.original.sharedinfo}</div>)
                            }else if(row.original.is_legecy === 2){
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} >{row.original.sharedinfo}</div>)
                            }else{
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.gotoManagerViewGC(row.original.taskid, 'sharedinfo')}>{row.original.sharedinfo}</div>)
                            }
                        }
                    })()            
                  ), minWidth: 40, headerStyle: {textAlign: 'left'}, style: {textAlign: 'center'}},
                  {Header: 'Shared – Action', accessor: 'sharedaction', Cell: row => (
                    (() => {
                        if(row.original.sharedaction === '0'){
                            return(<div >{row.original.sharedaction}</div>)
                        }else{
                            // return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} >{row.original.sharedaction}</div>)
                            if(row.original.is_legecy === 1){
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.gotoManagerView(row.original.id, 'sharedaction', row.original)}>{row.original.sharedaction}</div>)
                            }else if(row.original.is_legecy === 2){
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} >{row.original.sharedaction}</div>)
                            }else{
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.gotoManagerViewGC(row.original.taskid, 'sharedaction')}>{row.original.sharedaction}</div>)
                            }
                        }
                    })()            
                  ), minWidth: 40, headerStyle: {textAlign: 'left'}, style: {textAlign: 'center'}},
                  {Header: 'Follow Up Tasks', accessor: 'followuptasks', Cell: row => (
                    (() => {
                        if(row.original.followuptasks === '0'){
                            return(<div >{row.original.followuptasks}</div>)
                        }else{
                            // return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} >{row.original.followuptasks}</div>)
                            if(row.original.is_legecy === 1){
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.gotoManagerView(row.original.id, 'followuptasks', row.original)}>{row.original.followuptasks}</div>)
                            }else if(row.original.is_legecy === 2){
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} >{row.original.followuptasks}</div>)
                            }else{
                              return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.gotoManagerViewGC(row.original.taskid, 'followuptasks')}>{row.original.followuptasks}</div>)
                            }
                        }
                    })()            
                  ), minWidth: 40, headerStyle: {textAlign: 'left'}, style: {textAlign: 'center'}},
                  {Header: 'Regulator Published Date', accessor: 'regulator_published_date', minWidth: 70, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                      if (a === b) {
                          return 0;
                      }
                      let adue = a.split("/");
                      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                      let  bbdue = b.split("/");
                      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                      return aReverse > bReverse ? 1 : -1;
                  }},                    
                  {'Header' : 'View', Cell: row => (
                    <div>
                      {
                        (() => {
                          if(row.original.is_legecy === 1){
                            return(<MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerViewRCDetails(row.original.id, row.original)}>Read</MRButton>)
                          }else if(row.original.is_legecy === 2){                              
                            return(<MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerViewAutoForwordRCDetails(row.original.id, row.original)}>Read</MRButton>)
                          }else{
                            return(<MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerViewGCRCDetails(row.original.taskid)}>Read</MRButton>)
                          }
                        })()
                      }                          
                    </div>
                  ), width: 120, headerStyle: {textAlign: 'center'}},      
                  {'Header' : 'Action', Cell: row => (
                    <div>
                      {
                        (() => {
                          if(row.original.is_legecy === 1){
                            return(<MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerView(row.original.id, '', row.original)}>View</MRButton>)
                          }else if(row.original.is_legecy === 2){
                            return(<MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerViewAutoForwordRCDetails(row.original.id, row.original)}>View</MRButton>)
                          }else{
                            return(<MRButton style={{cursor: "pointer"}} onClick={this.gotoManagerViewGC(row.original.taskid, '')}>View</MRButton>)
                          }
                        })()
                      }                          
                    </div>
                  ), width: 120, headerStyle: {textAlign: 'center'}}]; 

      let gc_company = "";
      let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
      let curcompanyid = Store.getStoreData('companyid'); 
      let cur_role = Store.getStoreData('role');
      for(let d of this.state.register_tasks) { 
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[11].length !== 0 ? d[11][0].url : "";

        let dNames = "";
        let dtypenameNew = "";         
        let i=0;
        for (let doc of d[15]){
          i++;
          if(doc in this.state.doctype){
              dNames += this.state.doctype[doc]
              dtypenameNew = this.state.doctype[doc]
              if(i <= d[15].length - 1) {
                  dNames += ', '
              }
          }
        } 

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs'); 
        // console.log("regulatorObject==>", regulatorObject,"====" ,d[17])
        let regulatorNamenew = "";
        if(d[17] in regulatorObject){
            regulatorNamenew = regulatorObject[d[17]];
        }

        let tTags = d[16];          
        let updatetTags = [];
        let tTagsNames = "";          
        for(let i=0; i<tTags.length;i++){              
            let subcribedTags = Store.getStoreData('tags')
            const find_tag = subcribedTags.find((item) => {
                return item.ID.toString() === tTags[i].toString()
            })              
            if(typeof find_tag !== 'undefined'){                
              let tTagsObject = {}
              tTagsObject.id = find_tag.ID;
              tTagsObject.name = find_tag.TagName;
              updatetTags.push(tTagsObject);                  
            }               
        }          
        for(let j=0; j<updatetTags.length;j++){       
          tTagsNames += updatetTags[j].name;
          if(j < updatetTags.length - 1) {
              tTagsNames += ', '
          }               
        }

        let shareinfoPepoleName = '';
        let shareacknowPepoleName = '';
        let userName = Store.getStoreData('user_name');
        if(Object.keys(d[7]).length !== 0) {          
          let k=0;
          for(let key in d[7]){
            shareinfoPepoleName += userName[key.toString()];
            if(k < Object.keys(d[7]).length - 1) {
              shareinfoPepoleName += ','
            }
            k++;
          }
        }
        if(Object.keys(d[9]).length !== 0) {          
          let k=0;
          for(let key in d[9]){
            shareacknowPepoleName += userName[key.toString()];
            if(k < Object.keys(d[9]).length - 1) {
              shareacknowPepoleName += ','
            }
            k++;
          }
        }    
        let elem = {};
        if(cur_role === 'manager'){
          if(!this.state.show_all_Group_RUs && d[1] === curcompanyid){
            elem = {
              'status': d[10],  
              'taskid': d[0], 
              'id' : d[2],                      
              'name' : d[14].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
              'groupcompanyname' : gc_company,          
              'externalLink': exterlink,
              'type' : dtypenameNew,
              'typeValue' : dNames,
              'regulator' : regulatorNamenew,
              'tags_name' : tTagsNames,
              'sharedinfo' : d[6].toString(),
              'sharedinfopeople': shareinfoPepoleName,
              'sharedaction' : d[8].toString(),
              'sharedactionpeople': shareacknowPepoleName,
              'followuptasks': d[20].toString(),
              'regulator_published_date': moment(d[12], 'YYYY/MM/DD').format('DD/MM/YYYY'),
              'is_legecy': d[19],          
              'untaggedCount' : tTags.length,
              'is_csl': d[21],
              'externalLinkall': d[11],
              'keyDates': d[22],
              'summary': d[23],
              'dismissDate': d[24] !== '' ? moment.unix(d[24]).format('DD/MM/YYYY') : '',
              'dismissNote': d[25]
            };  
            ret.data.push(elem) 
          }else if(this.state.show_all_Group_RUs){
            elem = {
              'status': d[10], 
              'taskid': d[0], 
              'id' : d[2],                      
              'name' : d[14].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
              'groupcompanyname' : gc_company,          
              'externalLink': exterlink,
              'type' : dtypenameNew,
              'typeValue' : dNames,
              'regulator' : regulatorNamenew,
              'tags_name' : tTagsNames,
              'sharedinfo' : d[6].toString(),
              'sharedinfopeople': shareinfoPepoleName,
              'sharedaction' : d[8].toString(),
              'sharedactionpeople': shareacknowPepoleName,
              'followuptasks': d[20].toString(),
              'regulator_published_date': moment(d[12], 'YYYY/MM/DD').format('DD/MM/YYYY'),
              'is_legecy': d[19],          
              'untaggedCount' : tTags.length,
              'is_csl': d[21],
              'externalLinkall': d[11],
              'keyDates': d[22],
              'summary': d[23],
              'dismissDate': d[24] !== '' ? moment.unix(d[24]).format('DD/MM/YYYY') : '',
              'dismissNote': d[25]
            }; 
            ret.data.push(elem)
          }
        }else{
          elem = {
            'status': d[10],  
            'taskid': d[0], 
            'id' : d[2],                      
            'name' : d[14].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
            'groupcompanyname' : gc_company,          
            'externalLink': exterlink,
            'type' : dtypenameNew,
            'typeValue' : dNames,
            'regulator' : regulatorNamenew,
            'tags_name' : tTagsNames,
            'sharedinfo' : d[6].toString(),
            'sharedinfopeople': shareinfoPepoleName,
            'sharedaction' : d[8].toString(),
            'sharedactionpeople': shareacknowPepoleName,
            'followuptasks': d[20].toString(),
            'regulator_published_date': moment(d[12], 'YYYY/MM/DD').format('DD/MM/YYYY'),
            'is_legecy': d[19],          
            'untaggedCount' : tTags.length,
            'is_csl': d[21],
            'externalLinkall': d[11],
            'keyDates': d[22],
            'summary': d[23],
            'dismissDate': d[24] !== '' ? moment.unix(d[24]).format('DD/MM/YYYY') : '',
            'dismissNote': d[25]
          }; 
          ret.data.push(elem)
        }     
      } 

      ret.data = ret.data.sort((a,b) => {
          if (a.regulator_published_date === b.regulator_published_date) {
              return 0;
          }
          let adue = a.regulator_published_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let  bbdue = b.regulator_published_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
      });
      console.log("ret===>", ret.data)
      Store.updateStore('tbl_cur_rcmregister', ret.data)
      return ret;      
    }

    gotoManagerViewRCDetails = (id, allData) => (event) => {
      event.preventDefault();   
      if(allData.is_csl === 1){
        console.log(allData.taskid)   
        let postData = { "command": "getGCRUDetails", ref_id : allData.taskid  };
        console.log("result===>",postData)
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.managerGettaskRCDetails);    
      }else{     
        console.log(id)   
        let postData = { "command": "getManagerViewDetails", ref_id : id  };
        console.log("result===>",postData)
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.managerGettaskRCDetails); 
      }         
    }
    managerGettaskRCDetails = (result) => {
      console.log("result===>",result); 
      Store.updateStore('managerViewDetails', result.result);
      Store.updateStore('currentFollowupTask', result.result.shareFollowup);
      this.setState({modal_view:'RegisterManagerViewRCDetailsModal',ready: true, isLoaderIcon: false, isAutoForwordData: false})
    }

    gotoManagerViewAutoForwordRCDetails = (id, allData) => (event) => {
      event.preventDefault();        
      //console.log(id)   
      // let postData = { "command": "getautoForwardViewRUDetails", ref_id : id  };
      let postData = { "command" : "getFeedSelectSide" , "feed_id": id};   
      console.log("result===>",postData)
      let api = new APICall();
      this.setState({isLoaderIcon: true})
      if(allData.status === 'Dismissed'){        
        api.command(postData, this.processsViewAutoForwardDismissTask);
      }else{        
        api.command(postData, this.managerGettaskRCDetails_forRawFeed);    
      }      
    }   
    
    processsViewAutoForwardDismissTask = (result) => {
        console.log('result==>', result)
        Store.updateStore('cur_auto_forward_dismiss_task', result)
        this.setState({ modal_view: 'view_auto_froward_dismissed_task',isLoadedIcon: false})
    }

    managerGettaskRCDetails_forRawFeed = (result) => {
      console.log("result===>",result); 
      // Store.updateStore('managerViewDetails', result.result);
      // Store.updateStore('currentFollowupTask', result.result.shareFollowup);
      Store.updateStore('cur_autoForwordFeeds', result)
      this.setState({modal_view:'registerViewAutoForwardFeedModal',ready: true, isLoaderIcon: false, isAutoForwordData: true})
    }

    gotoManagerViewGCRCDetails = (id) => (event) => {
      event.preventDefault();        
      console.log(id)   
      let postData = { "command": "getGCRUDetails", ref_id : id  };
      console.log("result===>",postData)
      let api = new APICall();
      this.setState({isLoaderIcon: true})
      api.command(postData, this.managerGettaskRCDetails);          
    }    

    gotoManagerView = (id, onclicktype, allData) => (event) => {
      event.preventDefault();        
      console.log(id)   
      console.log("allData", allData)   
      if(allData.status === 'Dismissed'){
        let postData = { "command": "gettask", "task_id" : allData.taskid };        
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processsViewDismissTask);
      }else if(allData.status === 'Pending'){
        Store.updateStore('current_task_id', allData.taskid);
        let postData = { "command": "gettask", "task_id" : allData.taskid };        
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processshowRCMtask);        
      }else{
        if(allData.is_legecy === 1){
          let postData = { "command": "getGCRUDetails", ref_id : allData.taskid };
          let api = new APICall();
          this.setState({isLoaderIcon: true})
          api.command(postData, this.managerGettask(onclicktype)); 
        }else{
          let postData = { "command": "getManagerViewDetails", ref_id : id };
          let api = new APICall();
          this.setState({isLoaderIcon: true})
          api.command(postData, this.managerGettask(onclicktype));  
        }        
      }              
    }

    processshowRCMtask = (result) => {
        console.log('result==>', result)        
        let parent_task_id = result.result.task.parenttask.parent_task_id;
        let task = JSON.parse(result.result.task.parenttask.task_json);
        Store.updateStore('this_task_detail', result.result);
        Store.updateStore('parent_task_id', parent_task_id);
        Store.updateStore('table_type', 'new');
        Store.updateStore('childtasks', result.result.task['childtask'].result);
        Store.updateStore('task', task.object_data);
        Store.updateStore('current_task', task);   
        this.setState({isLoaderIcon: false, modal_view: 'registerPendingtaskModal'})                 
    }

    processsViewDismissTask = (result) => {
        console.log('result==>', result.result.task)
        if(result.result.task.length !== 0){
            Store.updateStore('cur_dismiss_task', result.result.task.parenttask)
            this.setState({ modal_view: 'view_dismissed_task',isLoaderIcon: false})
        }
    }
    managerGettask = (onclicktype) => (result) => {
      console.log("result===>",result); 
      console.log("onclicktype===>",onclicktype); 
      Store.updateStore('managerViewDetails', result.result);
      Store.updateStore('currentFollowupTask', result.result.shareFollowup);
      this.setState({modal_view:'RegisterManagerViewModal',ready: true, isLoaderIcon: false, onClickType: onclicktype})
    }   
    gotoManagerViewGC = (id, onclicktype) => (event) => {
      event.preventDefault();        
      console.log(id)   
      let postData = { "command": "getGCRUDetails", ref_id : id  };
      let api = new APICall();
      this.setState({isLoaderIcon: true})
      api.command(postData, this.managerGettask(onclicktype));          
    }
    
    switchRadio = (event) => {
      event.preventDefault();
      console.log(this.state.show_all_Group_RUs); 
      let show_all_Group_RUs = this.state.show_all_Group_RUs ? false : true;
      let register_tasks = this.state.register_tasks;
      let curcompanyid = Store.getStoreData('companyid'); 

      let update_register_tasks = [];
      for(let d of register_tasks){
        if(!show_all_Group_RUs && d[1] === curcompanyid){           
          update_register_tasks.push(d) 
        }else if(show_all_Group_RUs){
          update_register_tasks.push(d) 
        }
      }

      //status Count start
      let status_count = {'all': 0,'pending': 0,'inprogress': 0,'complete': 0,'dismissed': 0,'overdue': 0,'untaggedCount': 0, 'saved': 0}
      let tags = {};
      for(let d of update_register_tasks) {
        let status = d[10]; 
        let blankTags = d[16];
        for(let val of blankTags){
          tags[val.toString()] = 1;
        }
        if(status === 'Pending') {
            status_count.pending = status_count.pending + 1;
        }
        if(status === 'In Progress') {
            status_count.inprogress = status_count.inprogress + 1;
        }
        if(status === 'Complete') {
            status_count.complete = status_count.complete + 1;
        }
        if(status === 'Dismissed') {
            status_count.dismissed = status_count.dismissed + 1;
        }
        if(status === 'Overdue') {
            status_count.overdue = status_count.overdue + 1;
        }
        if(blankTags.length === 0) {
            status_count.untaggedCount = status_count.untaggedCount + 1;
        }    
        if(status === 'Saved') {
            status_count.saved = status_count.saved + 1;
        }  
      }

      Object.keys(tags).forEach(function(key) {
        let j = 0;
        for(let f of update_register_tasks) {
            let addtags = f[16];
            let check_tag = {};
            addtags.forEach(function(keynew) {
                check_tag[keynew.toString()] = 1;                
            });            
            if(key in check_tag) {
                j++;
                status_count[key.toString()] = j;
            }
        }
      });
      status_count.all = update_register_tasks.length;
      let rawtags = Store.getStoreData('tags');
      let show_filter = [{name: "All",id: 'all',styledcomponent: SelectedPill,selected: true},
                  {name: "Pending",id: 'pending',styledcomponent: UnselectedPill,selected: false},
                  {name: "In Progress",id: 'inprogress',styledcomponent: UnselectedPill,selected: false},
                  {name: "Complete",id: 'complete',styledcomponent: UnselectedPill,selected: false},
                  {name: "Dismissed",id: 'dismissed',styledcomponent: UnselectedPill,selected: false},
                  {name: "Overdue",id: 'overdue',styledcomponent: UnselectedPill,selected: false},
                  {name: "Untagged",id: 'untaggedCount',styledcomponent: UnselectedPill,selected: false}];
      //console.log("status_count==>", status_count.saved)
      if(status_count.saved !== 0) show_filter.push({name: "Saved",id: 'saved',styledcomponent: UnselectedPill,selected: false})
      for(let rawtag of rawtags){
        if(rawtag.ID in status_count){
          let obj = {name: rawtag.TagName, id: rawtag.ID,styledcomponent: UnselectedPill,selected: false}
          show_filter.push(obj);
        }
      } 
    
      Store.updateStore('status_count', status_count)
      //status Count end
      this.setState({show_all_Group_RUs, status_count, show_filter});
    }
    refreshCallback = () => {
      this.props.refreshCallback();
    }

    refreshState = (modal_view) => {
      
  }

  applyRegisterFilter = (filters) => {
    console.log("register_filters:",filters);
    Store.updateStore('registerFilter', filters);

    let selectionsComid = {};   
    let selecteddate = {};
    let selectedRegulator = {} 
    let selectedType = {} 
    let selectedStatus = {} 
    let selectedTags = {} 
    for (let key in filters) {        
      if (Object.keys(filters[key].selections).length !== 0) {                
        if (key === "company_name") {              
            selectionsComid = filters[key].selections;              
        } 
        if (key === "reg_pub_date") {
            selecteddate = filters[key].selections; 
        }
        if (key === "regulator") {
            selectedRegulator = filters[key].selections; 
        }
        if (key === "type") {
            selectedType = filters[key].selections; 
        }        
        if (key === "status") {
            selectedStatus = filters[key].selections; 
        }        
        if (key === "tags") {
            selectedTags = filters[key].selections; 
        }        
      }
    }
    
    let curcompanyid = Store.getStoreData('companyid'); 
    if(Object.keys(selectionsComid).length === 0){
      selectionsComid[curcompanyid.toString()] = 1;
    }
    console.log("selectedTags:", selectedTags);

    //others      
    if(Object.keys(filters.reg_pub_date.selections).length === 0){
      const today = moment().format('DD/MM/YYYY');  
      filters.reg_pub_date.selections = {from_date: today, to_date: today}
    }    

    const postData = {command: "get_filtered_register_tasks", filters: filters, selectionsComid: selectionsComid, selecteddate: selecteddate, selectedRegulator: selectedRegulator, selectedType: selectedType,
    selectedStatus: selectedStatus, selectedTags: selectedTags};
    let api = new APICall();
    this.setState({registerbucLoader: true})
    api.command(postData, this.processFilteredRegisterTasks);    
  }

  processFilteredRegisterTasks = (result) => {
    console.log(result)
    let status_count = {'all': 0,'pending': 0,'inprogress': 0,'complete': 0,'dismissed': 0,'overdue': 0,'untaggedCount': 0, 'saved': 0}
      let taskData = result;

      let curcompanyid = Store.getStoreData('companyid'); 
      let cur_role = Store.getStoreData('role');
      let update_register_tasks = [];
      for(let d of taskData){
        if(cur_role === 'manager'){
          if(!this.state.show_all_Group_RUs && d[1] === curcompanyid){           
            update_register_tasks.push(d) 
          }else if(this.state.show_all_Group_RUs){
            update_register_tasks.push(d) 
          }
        }else{
          update_register_tasks.push(d) 
        }
      }

      let tags = {};
      for(let d of update_register_tasks) {
        let status = d[10]; 
        let blankTags = d[16];
        for(let val of blankTags){
          tags[val.toString()] = 1;
        }
        if(status === 'Pending') {
            status_count.pending = status_count.pending + 1;
        }
        if(status === 'In Progress') {
            status_count.inprogress = status_count.inprogress + 1;
        }
        if(status === 'Complete') {
            status_count.complete = status_count.complete + 1;
        }
        if(status === 'Dismissed') {
            status_count.dismissed = status_count.dismissed + 1;
        }
        if(status === 'Overdue') {
            status_count.overdue = status_count.overdue + 1;
        }
        if(blankTags.length === 0) {
            status_count.untaggedCount = status_count.untaggedCount + 1;
        }    
        if(status === 'Saved') {
            status_count.saved = status_count.saved + 1;
        }  
      }
      Object.keys(tags).forEach(function(key) {
        let j = 0;
        for(let f of update_register_tasks) {
            let addtags = f[16];
            let check_tag = {};
            addtags.forEach(function(keynew) {
                check_tag[keynew.toString()] = 1;                
            });            
            if(key in check_tag) {
                j++;
                status_count[key.toString()] = j;
            }
        }
      });
      status_count.all = update_register_tasks.length;
      let rawtags = Store.getStoreData('tags');
      
      let show_filter = [{name: "All",id: 'all',styledcomponent: SelectedPill,selected: true},
                  {name: "Pending",id: 'pending',styledcomponent: UnselectedPill,selected: false},
                  {name: "In Progress",id: 'inprogress',styledcomponent: UnselectedPill,selected: false},
                  {name: "Complete",id: 'complete',styledcomponent: UnselectedPill,selected: false},
                  {name: "Dismissed",id: 'dismissed',styledcomponent: UnselectedPill,selected: false},
                  {name: "Overdue",id: 'overdue',styledcomponent: UnselectedPill,selected: false},
                  {name: "Untagged",id: 'untaggedCount',styledcomponent: UnselectedPill,selected: false}];
      //console.log("status_count==>", status_count.saved)
      if(status_count.saved !== 0) show_filter.push({name: "Saved",id: 'saved',styledcomponent: UnselectedPill,selected: false})
      for(let rawtag of rawtags){
        if(rawtag.ID in status_count){
          let obj = {name: rawtag.TagName, id: rawtag.ID,styledcomponent: UnselectedPill,selected: false}
          show_filter.push(obj);
        }
      }
      
    Store.updateStore('registertask', result);    
    Store.updateStore('status_count', status_count)
    this.setState({register_tasks: result, status_count, show_filter, registerbucLoader: false, ispillsShow: true});
  }

    clearPendingAdvancedFilter = () => {   

      // let register_filters = {
      //     type: {name: 'type', 'nickname': 'Type', type: 'multi', options: {},selections: {}, size: 2},
      //     regulator: {name: 'regulator', 'nickname': 'Regulator', type: 'multi', options: {},selections: {}, size: 2},            
      //     company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
      //     reg_pub_date: {name: 'reg_pub_date', 'nickname': 'Regulator Publish Date', type: 'date_range', options: {},selections: {}, size: 2},          
      //     status: {name: 'status', 'nickname': 'Status', type: 'multi', options: {},selections: {}, size: 2},          
      //     tags: {name: 'tags', 'nickname': 'Tags', type: 'multi', options: {},selections: {}, size: 2}          
      // } 
      const today = moment().format('DD/MM/YYYY');            
      // register_filters.reg_pub_date.selections = {from_date: today, to_date: today}; 
      
      console.log("registerFilterInEntry==>", Store.getStoreData('registerFilterInEntry'))
      let clearRegisterFilter = Store.getStoreData('registerFilterInEntry');
      clearRegisterFilter.reg_pub_date.selections = {};      
      console.log("registerFilterInEntry==>", clearRegisterFilter)
      Store.updateStore('registerFilter', clearRegisterFilter)
      this.setState({register_filters: clearRegisterFilter})     
      //window.location.reload();
    }
    closeModal = () => {
      this.setState({ modal_view: null });
    }

    render() {  
      
      let module_config = Store.getStoreData('module_config');  
      if(Store.getStoreData('rcm-taskindex-select') === null) {
        return <div><CSLLoader /></div>
      } 
      if(!this.state.ready){        
        return <div><CSLLoader /></div>
      }
      return(
        <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
          {
              (() => {
                  if(this.state.isLoaderIcon){
                      return <div><CSLLoader style={{position: 'absolute'}}/></div>
                  }
              })()
          }
          {
            (() => {
              if(this.state.ispillsShow){
                console.log("this.state.show_filter===>", this.state.show_filter)
                return(
                <div>
                {
                    this.state.show_filter.map((tag, index) => {
                        let t = tag.selected ? <span>&#10003;    </span> : '';
                        if(tag.id === 'all' || tag.id === 'pending' || tag.id === 'inprogress' || tag.id === 'complete' || tag.id === 'dismissed' || tag.id === 'overdue' || tag.id === 'untaggedCount'){
                          return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>
                              {t}{tag.name}<span style={{float: 'right', color: "#04ADA8"}}> {this.state.status_count[tag.id.toString()]} </span>
                              </tag.styledcomponent>);
                        }
                    })
                }
                </div>
                )
              }
            })()
          }
          
          {
            (()=>{              
              if('general' in module_config && module_config.general.enable_tag_management === true){
                  return(<div>
                    {
                        this.state.show_filter.map((tag, index) => {                
                            let t = tag.selected ? <span>&#10003;    </span> : '';
                            if(tag.id !== 'all' && tag.id !== 'pending' && tag.id !== 'inprogress' && tag.id !== 'complete' && tag.id !== 'dismissed' && tag.id !== 'overdue' && tag.id !== 'untaggedCount'){
                              return (<tag.styledcomponent key={index} onClick={this.selectOrUnselectTopic(tag.id)}>
                                  {t}{tag.name}<span style={{float: 'right', color: "#04ADA8"}}> {this.state.status_count[tag.id.toString()]} </span>
                                  </tag.styledcomponent>);
                            }
                        })
                    }
                    </div>)
              }
            })()
          } 
          <div style={{clear: "both"}}>&nbsp;</div>          
          <CSLRegisterTable 
              add={false} 
              export={true} 
              export_file_name="Register" 
              preferedColumns={this.state.prefered_columns}
              data={reportData}
              processData={this.processAcceptanceQueueData} 
              tableRows="14" 
              headerText={'Regulatory Change Register'}
              headerButtons={this.state.header_buttons}
              activeView={'manager'}
              toggle_text={'Show all Group RUs'}
              toggle_state={this.state.show_all_Group_RUs}
              toggleCallback={this.switchRadio}
              refreshCallback={this.refreshState}
              filters={this.state.register_filters} 
              applyFilter={this.applyRegisterFilter}
              clearAdvancedFilter={this.clearPendingAdvancedFilter}
              reportButton={true}
              fields={this.state.fields}
              module={{display: 'Regulatory Change', id: 'rcm'}}
              registerbucLoader={this.state.registerbucLoader}
          />
          {
            (() => {                
              if(this.state.modal_view != null){
                switch(this.state.modal_view){ 
                  case 'RegisterManagerViewModal' : return <div><InactiveOverlay /><RegisterManagerViewModal closeModal={this.closeModal} onClickType={this.state.onClickType}/></div>; break;
                  case 'RegisterManagerViewRCDetailsModal' : return <div><InactiveOverlay /><ViewManagerRU closeModal={this.closeModal} /></div>; break;
                  case 'registerViewAutoForwardFeedModal' : return <div><InactiveOverlay /><RegisterViewAutoForwardFeedModal closeModal={this.closeModal}/></div>; break;
                  case 'registerPendingtaskModal' : return <div><InactiveOverlay /><RegisterPendingtaskModal closeModal={this.closeModal}/></div>; break;
                  case 'view_dismissed_task' : return <div><InactiveOverlay style={{height: "108%"}}/><DismissTaskModal isRegister={true} closeModal={this.closeModal}/></div>; break;
                  case 'view_auto_froward_dismissed_task' : return <div><InactiveOverlay style={{height: "108%"}}/><AutoForWardDismissTaskModal isRegister={true} closeModal={this.closeModal}/></div>; break;
                }                  
              }
            })()
          }
        </div>
      )
    }

}
export default RegisterManagerView;