import React from 'react';
import Store from '../../Common/Store';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import CSLLoader from './../Common/CSLLoader';
import AlertBox from './../Common/AlertBox';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 0%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;    
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #484848;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;        
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
    margin-left: 5px;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #282828;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 96%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;  
    font-family: 'Montserrat', sans-serif; 
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

class DismissTaskModal extends React.Component
{
    state = {
        isLoaderIcon: false,
        alert_param: null
    }

    componentDidMount = () => {
        console.log(Store.getStoreData('cur_dismiss_task'))
    }

    closeDismissModal = (event) => {
        this.props.closeModal();
    }

    processMyExternalLink = () => {
        var linksdata = JSON.parse(Store.getStoreData('cur_dismiss_task').task_json).object_data.externalLinks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of linksdata) {           
            i++;
            //console.log("window.location.protocol===>",window.location.protocol)
            let url = <a style={{display: "table-cell"}} href={d.url} target="_blank" >{d.url}</a>                      
            let elem = {'index' : i, 
                    'url_value' : url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    } 
    processMykeyData = () => {
        var keysdata = JSON.parse(Store.getStoreData('cur_dismiss_task').task_json).object_data.keyDates;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    } 
    
    processDismissTask = () =>{    
        
        let action_data = {
            task_id: Store.getStoreData('cur_dismiss_task').id,
            cur_lane: "COMP_RCM_ASSIGNMENT",
            assigned_to: 0,
            action: "COMP_RCM_ASSIGNMENT",            
            performed_by: Store.getStoreData('loggedincontact')            
        };
        //console.log("task==>",action_data)
        let postData = {"command": "addactiontotask", "SystemID": 1001, action_data: action_data, task_id: Store.getStoreData('cur_dismiss_task').id};
        console.log("postData===>",postData);
        let api = new APICall();
        this.setState({isLoaderIcon: true})
        api.command(postData, this.processdismissTaskValue);
    }
    processdismissTaskValue = (result) => {        
        //alert("This Regulatory Change is now ready for processing");
        let alert_param = {title: 'Success', message: 'The regulatory change is now ready for processing.', ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})
        // window.location.reload(true);
        // this.setState({isLoaderIcon: false})
    }
    updateafterAlertWithLoading = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }
    
    render() {
        let objectData = JSON.parse(Store.getStoreData('cur_dismiss_task').task_json).object_data;
        let actionData = JSON.parse(Store.getStoreData('cur_dismiss_task').task_json).action_data;
        let dismissalNote = actionData[actionData.length - 1].note;
        let documentName = objectData.documentName;
        let summary = objectData.summary;
        let docType = "";
        // docType = objectData.documentTypes;
        // if(!isNaN(objectData.documentTypes)) {
        //     //const documentType = Store.getStoreData('rutypename');
        //     const documentType = Store.getStoreData('rutypenameAll');
        //     let doctype = {};
        //     for(let i=0; i < documentType.length; i++) {
        //         doctype[documentType[i].id] = documentType[i].documentname;
        //     } 
        //     docType = doctype[objectData.documentTypes]
        // }
        let dNames = "";            
        if(objectData.documentTypes !== null) {            
            const documentType = Store.getStoreData('rutypenameAll');
            let doctype = {};
            for(let i=0; i < documentType.length; i++) {
                doctype[documentType[i].id] = documentType[i].documentname;
            } 
            let i=0;
            for (let doc of objectData.documentTypes){
                i++;
                if(doc in doctype){
                    dNames += doctype[doc]
                    if(i <= objectData.documentTypes.length - 1) {
                        dNames += ', '
                    }
                }
            }
        }
        let upload_date = '';
        if('uploadDate' in objectData && objectData.uploadDate !== null){
            let uploadDate = objectData.uploadDate;
            if (uploadDate.includes(" ") === true) {
                uploadDate = uploadDate.replace(" ", "+");
            }
            
            const upload_date_obj = new Date(uploadDate);
            
            const upload_day = upload_date_obj.getDate() < 10 ? "0"+upload_date_obj.getDate().toString() : upload_date_obj.getDate();
            const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
            const upload_year = upload_date_obj.getFullYear();
            upload_date = `${upload_day}/${upload_month}/${upload_year}`;

            console.log("uploadDate==>", upload_date)
        }
        if('filterlog' in this.props && this.props.filterlog === true){
            if('resonfordismiss' in objectData && objectData.resonfordismiss === 'RU_Sectors'){
                dismissalNote = "Dismissed as sector is filtered";
            }else if('resonfordismiss' in objectData && objectData.resonfordismiss === 'RU_Regulator'){
                dismissalNote = "Dismissed as regulator is filtered";
            }
        }
        let sectionsVaule = objectData.sections === '' ? [] : objectData.sections;
        return(<div>
        <RegModalContainer>
                {
                    (() => {
                        if(this.state.isLoaderIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <RegModalHeaderText>Regulatory Changes - Dismissed Task</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeDismissModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <hr style={{marginTop: "20px", borderTop: "0px solid #ccc", marginBottom: "15px"}}/>
                <RegDismisOuterContainer>
                    <OverviewInfoHeader>
                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{documentName}</div>                                                                
                        <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                    <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>                            
                        <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(summary))}} style={{textAlign: "justify",marginBottom: "20px"}}></div>                                       
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Type</span>&nbsp; {dNames}</div>
                        <div style={{display: "inline-block",marginRight: "40px"}}><span style={{fontWeight: "bold"}}>Date </span>&nbsp; {upload_date}</div> 
                    </OverviewInfoBody>
                </RegDismisOuterContainer>
                {
                    sectionsVaule.map((section) => {
                        return (
                            <RegDismisOuterContainer>
                                <OverviewInfoHeader>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{section.name}</div>                                                                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                <OverviewInfoBody style={{marginBottom: "10px",backgroundColor: "#EDEDED"}}>
                                    <div dangerouslySetInnerHTML={{__html: window.decodeURIComponent(atob(section.content))}} style={{textAlign: "justify"}}></div>                                 
                                    
                                </OverviewInfoBody>
                            </RegDismisOuterContainer>                                    
                        )
                    })
                }
                {
                    (() => {                            
                        if('externalLinks' in objectData) {
                            if(objectData.externalLinks.length !== 0) {
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader> 
                                        <OverviewInfoBody>          
                                            <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    </div>)
                            }else{
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Useful Links</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>           
                                        <OverviewInfoBody>
                                            <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Useful Links Available</div>
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                </div>)
                            }
                        }
                    })()                
                }
                {
                    (() => {                            
                        if('keyDates' in objectData) {    
                            if(objectData.keyDates.length !== 0) {
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader> 
                                        <OverviewInfoBody>          
                                            <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                    </div>)
                            }else{
                                return(<div>
                                    <RegDismisOuterContainer>
                                        <OverviewInfoHeader>
                                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>                                
                                            <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>           
                                        <OverviewInfoBody>
                                            <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no Key Dates Available</div>
                                        </OverviewInfoBody>
                                    </RegDismisOuterContainer>
                                </div>)
                            }
                        }
                    })()                
                }
                <RegModalContainertop>
                    <RegModalNameLabelDiv>Reason For Dismissal</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                    <RegDismissalReason style={{marginTop: "10px"}} defaultValue={dismissalNote} readOnly></RegDismissalReason>
                    </RegModalNameInputDiv>
                </RegModalContainertop>
                <RegCancelSubmitButtonOuterContainer>
                    {
                        (() => {
                            if(Store.getStoreData('role') === 'manager'){
                                if(!('filterlog' in this.props)){
                                    return(<RegSubmitButton onClick={this.processDismissTask}>Process</RegSubmitButton>)
                                }
                            }
                        })()
                    }                    
                    <RegCancelButton onClick={this.closeDismissModal}>Cancel</RegCancelButton>                     
                </RegCancelSubmitButtonOuterContainer>      
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>)
    }
}
export default DismissTaskModal;