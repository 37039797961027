import React from 'react';
import styled from 'styled-components';
import RUThumbnails from '../RUThumbnails/RUThumbnails';
import {FaEllipsisH} from 'react-icons/fa';
import RUPendingTable from '../RegularotyChanges/Pending/RUPendingTable';
import RUNotification from './RUNotification';
import Store from '../../Common/Store';
import ModuleConfigCSL from './ModuleConfigCSL';
import DismissedRN from './DismissedRN';
import CSLAuditlogs from './CSLAuditlogs';
import CSLFilterlogs from './CSLFilterlogs';

const PendingContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
`;
const PendingInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-bottom: 10px;
`;
const PendingHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;
const Button1tablink =styled.button`
  background-color: #6D6E70;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 14px;
  width: 19%;
  margin-left: 10px; 
`;

const Button2tablink =styled.button`
  
  background-color: #F2F2F3;  
  color: #484848;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 14px;
  width: 19%;  
`;

class CSLIndex extends React.Component
{
    state = { modalView: "RU_update" }

    constructor(props) {
		super(props);
    }

    componentDidMount() {
        console.log("current_tab==>",this.props.current_tab)
        if(this.props.current_tab === "rn"){
            this.setState({modalView: "RU_notification"})
        }else{
            if(this.props.current_tab === "config"){
                this.setState({modalView: "csl_config"})
            }else if(this.props.current_tab === "dismissed_rn"){
                this.setState({modalView: "dismissed_RN"})
            }else if(this.props.current_tab === "auditlog"){
                this.setState({modalView: "auditlog"})
            }else if(this.props.current_tab === "logfilter"){
                this.setState({modalView: "logfilter"})
            }else{
                this.setState({modalView: "RU_update"})
            }
        }
    }

    userView = (modelview) => (event) => {
        event.preventDefault();
        console.log("modelview",modelview)
        this.setState({modalView: modelview})
    }

    render() {
        let filter_log_url = '/logfilter';
        let audit_log_url = '/auditlog';
        let dismiss_regulatory_notification_url = '/dismissed_rn';
        let regulatory_notification_url = '/rn';
        let regulatory_update_url = '/ru';
        return(<div>
            {
                (() => {
                    if(this.state.modalView === "RU_update"){
                        return(
                            <div>
                                <Button1tablink >Regulatory Update</Button1tablink>
                                <a href={regulatory_notification_url}><Button2tablink>Regulatory Notifications</Button2tablink></a>
                                <a href={dismiss_regulatory_notification_url}><Button2tablink>Dismissed Regulatory Notifications</Button2tablink></a>
                                <a href={audit_log_url}><Button2tablink>Audit Logs</Button2tablink></a>
                                <a href={filter_log_url}><Button2tablink>Filter Log</Button2tablink></a>
                                <div style={{clear: "both"}}></div>
                                <RUThumbnails />
                                <PendingContainer>
                                    <PendingInnerContainer>
                                        <PendingHeaderIcon><FaEllipsisH /></PendingHeaderIcon>
                                        <RUPendingTable />
                                    </PendingInnerContainer>
                                </PendingContainer>
                            </div>
                        )
                    }else if(this.state.modalView === "RU_notification"){
                        return(
                            <div>
                                <a href={regulatory_update_url}><Button2tablink style={{marginLeft: "10px"}}>Regulatory Update</Button2tablink></a>
                                <Button1tablink >Regulatory Notifications</Button1tablink>
                                <a href={dismiss_regulatory_notification_url}><Button2tablink>Dismissed Regulatory Notifications</Button2tablink></a>
                                <a href={audit_log_url}><Button2tablink>Audit Logs</Button2tablink></a>
                                <a href={filter_log_url}><Button2tablink>Filter Log</Button2tablink></a>
                                <div style={{clear: "both"}}></div>
                                <PendingContainer>
                                    <PendingInnerContainer>
                                        <RUNotification />
                                    </PendingInnerContainer>
                                </PendingContainer>
                            </div>
                        )
                    }else if(this.state.modalView === "dismissed_RN"){
                        return(
                            <div>
                                <a href={regulatory_update_url}><Button2tablink style={{marginLeft: "10px"}}>Regulatory Update</Button2tablink></a>
                                <a href={regulatory_notification_url}><Button2tablink>Regulatory Notifications</Button2tablink></a>
                                <Button1tablink>Dismissed Regulatory Notifications</Button1tablink>
                                <a href={audit_log_url}><Button2tablink>Audit Logs</Button2tablink></a>
                                <a href={filter_log_url}><Button2tablink>Filter Log</Button2tablink></a>
                                <div style={{clear: "both"}}></div>
                                <PendingContainer>
                                    <PendingInnerContainer>
                                        <DismissedRN />
                                    </PendingInnerContainer>
                                </PendingContainer>
                            </div>
                        )
                    }else if(this.state.modalView === "auditlog"){
                        return(
                            <div>
                                <a href={regulatory_update_url}><Button2tablink style={{marginLeft: "10px"}}>Regulatory Update</Button2tablink></a>
                                <a href={regulatory_notification_url}><Button2tablink>Regulatory Notifications</Button2tablink></a>
                                <a href={dismiss_regulatory_notification_url}><Button2tablink>Dismissed Regulatory Notifications</Button2tablink></a>
                                <Button1tablink>Audit Logs</Button1tablink>
                                <a href={filter_log_url}><Button2tablink>Filter Log</Button2tablink></a>
                                <div style={{clear: "both"}}></div>
                                <PendingContainer>
                                    <PendingInnerContainer>
                                        <CSLAuditlogs />
                                    </PendingInnerContainer>
                                </PendingContainer>
                            </div>
                        )
                    }else if(this.state.modalView === "logfilter"){
                        return(
                            <div>
                                <a href={regulatory_update_url}><Button2tablink style={{marginLeft: "10px"}}>Regulatory Update</Button2tablink></a>
                                <a href={regulatory_notification_url}><Button2tablink>Regulatory Notifications</Button2tablink></a>
                                <a href={dismiss_regulatory_notification_url}><Button2tablink>Dismissed Regulatory Notifications</Button2tablink></a>
                                <a href={audit_log_url}><Button2tablink>Audit Logs</Button2tablink></a>
                                <Button1tablink>Filter Log</Button1tablink>
                                <div style={{clear: "both"}}></div>
                                <PendingContainer>
                                    <PendingInnerContainer>
                                        <CSLFilterlogs />
                                    </PendingInnerContainer>
                                </PendingContainer>
                            </div>
                        )
                    }else{
                        return(
                            <PendingContainer style={{paddingBottom: "10px"}}>
                                    <ModuleConfigCSL />
                            </PendingContainer>
                        )
                    }
                })()
            }



        </div>)
    }
}

export default CSLIndex;
