import React from 'react';
import styled from 'styled-components';
import Utils from './../../../Common/Utils';
import { BiListPlus } from 'react-icons/bi';
import { IoMdRefresh } from 'react-icons/io';
import Store from './../../../Common/Store';
import UICard from "./UICard";
import AddRUModal from './../../Modals/AddRUModal';
import AlertBox from './../../Common/AlertBox'
import CardLoader from './../../../Common/CardLoader';
import APICall from './../../../Common/APICall';
import CSLLoader from './../../Common/CSLLoader';
import IndexLoader from './../../../Common/IndexLoader';
import ApiContext from "../../contexts/ApiContext";

const FilterContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 2px 2px 2px 2px;
  background-color: #e7f0f7;
  box-sizing: border-box;
`;
const FilterInnerContainer = styled.div`  
  display: flex;
  float: right;
  padding-top: 24px;
  padding-right: 2%;
  
`;
const FilterInnerContainerLeft = styled.div`  
  display: flex;
  float: left;
  padding-top: 24px;
  padding-left: 48px;
`;
const RoleSelect = styled.select`
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 40px;
  width: auto;
  float: right;
  background-color: #fff;

  margin-bottom: 20px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;  
  border: none;
  margin-right: 5px;
  padding: 8px;
  cursor: pointer;
`;
const HeaderSectionbutton = styled.button`
  width: auto;
  height: 40px;
  background-color: #ffffff;
  border:1px solid #dedede;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right:10px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  font-family: 'Roboto Medium', 'Roboto', sans-serif;
  font-weight: 0;
  color: #1a3552;
  cursor: pointer;
  display: inherit;
  padding: 2%;
`;

const Mbutton = styled.button`
  width: 150px;
  height: 40px;
  background-color: #ffffff;
  border:1px solid #dedede;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right:10px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.50) 0px 5px 15px;
  }
  font-family: 'Roboto Medium', 'Roboto', sans-serif;
  font-weight: 0;
  color: #1a3552;
  cursor: pointer;
  padding-bottom: 5px;
`;

const ClearDiv = styled.div`
  height: "50px";
  width: "100px";
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 300%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const RefreshedText = styled.div`
    float: left;
    width: auto;
    display: inline-block;
    padding-right: 1%;
    padding-top: 1%;
    font-family: "Roboto Medium","Roboto",sans-serif;
    font-weight: 0;
    color: #1a3552;
    opacity: 0.85;
    padding-left: 58px;
`;

class MGRIndex extends React.Component
{
    state = {
        ready: false,
        selectedTimePeriod: "",
        modal_view: null,
        alert_param: null,
        isLoadingIcon: false,
        refreshLoader: false
    }

    options = {
      title: "",
      // colors: ["#63dee9", "#4dd9e6", "#36d4e2", "#20cfdf", "#1dbac9", "#19a6b3", "#17939e", "#137c86", "#106870", "#0d5359", "#0a3e43", "#06292d"],
      colors: ["#0e6ee3", "#0085f3", "#009afe", "#00afff", "#00c2ff", "#00d4ff", "#00e6ff", "#00f7ff"],
      legend: { position: "left", alignment: "start" ,maxLines: 5},
      tooltip: { isHtml: true, ignoreBounds: true } ,
      chartArea:{left:2,top:2,width:'100%',height:'100%'}
    };
    data = [
      ["Task", "Hours per Day"],
      ["Work", 11],
      ["Eat", 2],
      ["Commute", 2],
      ["Watch TV", 2],
      ["Sleep", 7],
    ];

    static contextType = ApiContext;

    constructor(props) {
		  super(props);
    }

    componentDidMount(){
        let selectedTimePeriod = '';
        if(Store.getStoreData('defaultTimePeriod') === ''){
            selectedTimePeriod = 'general' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').general.select_timespan : ''
        }else{
            selectedTimePeriod = Store.getStoreData('defaultTimePeriod');
        }
        this.setState({ selectedTimePeriod, ready: true})
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
            let selectedTimePeriod = '';
            if(Store.getStoreData('defaultTimePeriod') === ''){
                selectedTimePeriod = 'general' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').general.select_timespan : ''
            }else{
                selectedTimePeriod = Store.getStoreData('defaultTimePeriod');
            }
            this.setState({ selectedTimePeriod, ready: true})
        }
    }

    showModuleConfig = () => {
        let module_config_url = '/moduleconfig';
        window.location.href = module_config_url;
    }
    showRCMRegister = () => {
        let register_url = '/register' ;
        window.location.href = register_url;
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    handleChangeDefaultTimePeriod = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value, isLoadingIcon: true});
        let postData = { command: "setDefaultTimePeriod", timePeriod: event.target.value, isChecked: true};
        let api = new APICall();
        api.command(postData, this.afterSetDefaultTimePeriod(event.target.value));
    }

    afterSetDefaultTimePeriod = (timePeriod) => (result) => {
        let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        console.log("result==>", result)
        Store.updateStore('pendingRCtasks', result['tasks'].pendingTasks);
        Store.updateStore('dismissFeedsAndRUtasks', result['tasks'].dismissTasks);
        Store.updateStore('followupRCtasks', result['tasks'].followupTasks);
        Store.updateStore('myRCtasks', result['tasks'].mytasks);
        Store.updateStore('newRCtasks', result['tasks'].newTasks);
        Store.updateStore('pendingRCtasks', result['tasks'].pendingTasks);
        Store.updateStore('saveRCtasks', result['tasks'].saveTasks);
        Store.updateStore('shareWithMeRCTasks', result['tasks'].shareWithMeTasks);

        IndexLoader.divideAutoForwordFeedsAndRUIndex(result['tasks'].autoforwordfeedTasks)
        CardLoader.divideDashboardCardCoutAll([], {}, regulatorObject, timePeriod)

        let rcm_index = this.context.rcmIndexSelect;
        if(rcm_index !== null && 'result' in rcm_index && 'defaultTimePeriod' in rcm_index.result){
            rcm_index.result.defaultTimePeriod = timePeriod;
        }
        if(rcm_index !== null && 'result' in rcm_index && 'tasks' in rcm_index.result){
            rcm_index.result.tasks = result['tasks'];
        }
        this.context.rcmIndexSelect =  rcm_index
        Store.updateStore('defaultTimePeriod', timePeriod)
        this.setState({isLoadingIcon: false});
    }

    showAddNewRUModal = (row) => (event) => {
        event.preventDefault();
        let index = row.index === null ? 0 : row.index;
        this.setState({modal_view: "AddRUModal"})
    }

    showAlertAddNewRU = (row) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Confirm', message: 'You have not configured any tags. If you wish to add this item anyway, please press OK.', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                            alertHandler: this.processShowAlertAddNewRU, stack: {row: row}}
        this.setState({alert_param: alert_param})
    }

    processShowAlertAddNewRU = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let index = stack.row.index === null ? 0 : stack.row.index;
        this.setState({alert_param: null, modal_view: "AddRUModal"})
    }
    closeModal = () => {
      this.setState({ modal_view: null });
    }

    refreshedTasks = (event) => {
        event.preventDefault();
        let postData = { command: "refreshTasks", role: Store.getStoreData('role')};
        let api = new APICall();
        this.setState({refreshLoader: true})
        api.command(postData, this.afterProcessRefreshedTasks);
    }

    afterProcessRefreshedTasks = (result) => {
        console.log("result==>", result)
        Store.updateStore('newRCtasks', result.result['tasks'].newTasks);
        Store.updateStore('pendingRCtasks', result.result['tasks'].pendingTasks);
        Store.updateStore('saveRCtasks', result.result['tasks'].saveTasks);
        Store.updateStore('shareWithMeRCTasks', result.result['tasks'].shareWithMeTasks);
        Store.updateStore('followupRCtasks', result.result['tasks'].followupTasks);
        Store.updateStore('dismissFeedsAndRUtasks', result.result['tasks'].dismissTasks);
        Store.updateStore('myRCtasks', result.result['tasks'].mytasks);

        IndexLoader.divideAutoForwordFeedsAndRUIndex(result.result['tasks'].autoforwordfeedTasks)
        let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        CardLoader.divideDashboardCardCoutAll(result.result['tasks'], {}, regulatorObject, this.state.selectedTimePeriod)
        this.setState({refreshLoader: false})
    }


    render() {
        if(!this.state.ready){
            return(<div></div>)
        }
        if(this.state.refreshLoader){
            return <div><CSLLoader style={{position: 'absolute'}}/></div>
        }
        let last_refresh = Utils.dashboardDatefornate();
        return(<div>
            {
                (() => {
                    if(this.state.isLoadingIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }
            <FilterContainer>
                <FilterInnerContainerLeft>
                    {
                    (() => {
                        if('general' in Store.getStoreData('module_config') && Store.getStoreData('module_config').general.enable_add_new_ru === true){
                            if('enable_tag_management' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.enable_tag_management === true && 'tagValue' in Store.getStoreData('module_config').general && Object.keys(Store.getStoreData('module_config').general.tagValue).length !== 0){
                                return(<div style={{float:"left",width:"auto", display: "inline-block", paddingLeft:"5%"}} onClick={this.showAddNewRUModal({index: -1})}><Mbutton><div style={{paddingLeft:"20px",paddingTop:"10px",float:"left"}}>Add New RU </div><div style={{float:"left",paddingLeft:"2px",paddingTop:"3px"}}><BiListPlus style={{fontSize:"20px", paddingTop:"5px"}}/></div></Mbutton></div>)
                            }else{
                                return(<div style={{float:"left",width:"auto", display: "inline-block", paddingLeft:"5%"}} onClick={this.showAlertAddNewRU({index: -1})}><Mbutton><div style={{paddingLeft:"20px",paddingTop:"10px",float:"left"}}>Add New RU </div><div style={{float:"left",paddingLeft:"2px",paddingTop:"3px"}}><BiListPlus style={{fontSize:"20px"}}/></div></Mbutton></div>)
                            }

                        }
                    })()
                }
                </FilterInnerContainerLeft>
                <FilterInnerContainer>

                    <HeaderSectionbutton onClick={this.showRCMRegister}><span style={{marginRight: "10px", marginTop: "3px"}}>Register</span><img src={Utils.baseImageRegister()} style={{height: "16px", width: "16px", marginTop: "3px"}}/></HeaderSectionbutton>
                    {/* <HeaderSectionbutton>Reporting</HeaderSectionbutton> */}
                    {
                        (() => {
                            if(Store.getStoreData('role') === "admin_manager" || Store.getStoreData('role') === "manager"){
                                return(<HeaderSectionbutton onClick={this.showModuleConfig}><span style={{marginRight: "10px", marginTop: "3px"}}>Config</span><img src={Utils.baseImageModuleConfig()} style={{height: "16px", width: "16px", marginTop: "3px"}}/></HeaderSectionbutton>)
                            }
                        })()
                    }
                    <RoleSelect value={this.state.selectedTimePeriod} name="selectedTimePeriod" onChange={this.handleChangeDefaultTimePeriod}>
                        <option key="0" value="this_month">This month</option>
                        <option key="1" value="last_month">Last Month</option>
                        <option key="2" value="last_thirty_days">Last 30 days</option>
                        <option key="3" value="last_twelve_month">Last 12 months</option>
                        <option key="4" value="this_year">This Year</option>
                        <option key="5" value="last_year">Last Year</option>
                        <option key="6" value="quarter_one">Quarter One</option>
                        <option key="7" value="quarter_two">Quarter Two</option>
                        <option key="8" value="quarter_three">Quarter Three</option>
                        <option key="9" value="quarter_four">Quarter Four</option>
                    </RoleSelect>
                    <HeaderSectionbutton style={{weight:"40px", marginLeft: "5px", marginRight:"28px", paddingTop:"10px"}} onClick={this.refreshedTasks}><IoMdRefresh style={{fontSize:"16px"}}/></HeaderSectionbutton>
                </FilterInnerContainer>
            </FilterContainer>
            <div style={{width:"100%" ,height:"40px", paddingTop:"5px", display: "inline-block"}}>

                { /*<div style={{float: "right",width:"auto", display: "inline-block", paddingRight: "5%"}}><Mbutton style={{weight:"40px"}} onClick={this.refreshedTasks}><IoMdRefresh/></Mbutton></div>*/}
                <RefreshedText ><span style={{paddingRight:"10px"}}>Last Refreshed: {last_refresh}</span></RefreshedText>
            </div>
            <div style={{marginTop: "5px", marginLeft:"1px",display:"flex",flexWrap:"wrap",marginLeft:"28px"}}>
            {
                (() => {
                    if(Store.getStoreData('new_rus').totalCount !== 0){
                        if(Store.getStoreData('has_gc') && 'general' in Store.getStoreData('module_config') && Store.getStoreData('module_config').general.assign_automated_news_feed_to_group_companies === true){
                            if(Store.getStoreData('role') === 'manager' && Store.getStoreData('new_rus') !== null){
                                return(<UICard data={Store.getStoreData('new_rus').data} options={this.options} cardtitle={"NEW RUs"} cardvalue={Store.getStoreData('new_rus').totalCount} id={'new_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                            }
                        }else{
                            return(<UICard data={Store.getStoreData('new_rus').data} options={this.options} cardtitle={"NEW RUs"} cardvalue={Store.getStoreData('new_rus').totalCount} id={'new_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                        }
                    }
                })()
            }
            {
                (() => {
                    if(Store.getStoreData('inprogress_rus') !== null){
                        return(<UICard data={Store.getStoreData('inprogress_rus').data} options={this.options} cardtitle={"IN PROGRESS RUs"} cardvalue={Store.getStoreData('inprogress_rus').totalCount} id={'inprogress_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                    }
                })()
            }
            {
                (() => {
                    if(Store.getStoreData('dismissed_rus') !== null){
                        return(<UICard data={Store.getStoreData('dismissed_rus').data} options={this.options} cardtitle={"DISMISSED RUs"} cardvalue={Store.getStoreData('dismissed_rus').totalCount} id={'dismissed_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                    }
                })()
            }
            {
                (() => {
                    if(Store.getStoreData('custom_rus') !== null){
                        return(<UICard data={Store.getStoreData('custom_rus').data} options={this.options} cardtitle={"CUSTOM RUs"} cardvalue={Store.getStoreData('custom_rus').totalCount} id={'custom_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                    }
                })()
            }
            {
                (() => {
                    if(Store.getStoreData('saved_rus') !== null){
                        return(<UICard data={Store.getStoreData('saved_rus').data} options={this.options} cardtitle={"SAVED RUs"} cardvalue={Store.getStoreData('saved_rus').totalCount} id={'saved_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                    }
                })()
            }
            {
                (() => {
                    if(Store.getStoreData('my_rus') !== null){
                        return(<UICard data={Store.getStoreData('my_rus').data} options={this.options} cardtitle={"MY RUs"} cardvalue={Store.getStoreData('my_rus').totalCount} id={'my_rus'} showCardDetails={this.props.showCardDetails}></UICard>)
                    }
                })()
            }
            {
                (() => {
                    if(Store.getStoreData('my_tasks') !== null){
                        return(<UICard data={Store.getStoreData('my_tasks').data} options={this.options} cardtitle={"MY TASKS"} cardvalue={Store.getStoreData('my_tasks').totalCount} id={'my_tasks'} showCardDetails={this.props.showCardDetails}></UICard>)
                    }
                })()
            }
            </div>
            <ClearDiv />
            {
                (() => {
                    if(this.state.modal_view !== null){
                        switch(this.state.modal_view) {
                            case 'AddRUModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'75%'}}><InactiveOverlay /><AddRUModal closeModal={this.closeModal}/></div>; break;
                        }

                    }
                })()
            }
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>)
    }
}

export default MGRIndex;
