import React from 'react';
import RegisterManagerView from './RegisterManagerView';
import RegisterUserView from './RegisterUserView';
import RcmRegisterHeader from './RcmRegisterHeader';
import WelcomeBanner from '../../Components/WelcomeBanner';
import Store from '../../Common/Store';

class RegisterIndex extends React.Component
{
    state = {        
        modal_view: 'adminmanager'        
    };    

    viewModal = (view) => {
      console.log(view)      
      this.setState({modal_view: view})
    }

    render()
    {
      let task_id = typeof this.props.taskId === 'undefined' ? 0 : this.props.taskId !== 0 ? this.props.taskId : 0;
      console.log("task_id===>",task_id)
      let contact = "";
        if(Store.getStoreData('contact') !== null){
            contact = 'Welcome back , '+Store.getStoreData('contact');  
      }
      return(
        <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
             {
               (()=>{
                  if(task_id === 0) {
                    return(
                      <div>
                          <RcmRegisterHeader refreshParentState={this.refreshThisState}/>              
                          <WelcomeBanner welcome={contact} />
                      </div>
                    )
                  }
               })()
             }            
            {
                (() => {
                  switch(this.state.modal_view) {
                    case 'adminmanager' : return <RegisterManagerView taskID={task_id} modalView={this.viewModal}/>; break;
                    case 'userView' : return <RegisterUserView taskID={task_id} modalView={this.viewModal}/>; break;
                  }                    
                })()
            }
            {/* <CSLLoader /> */}
        </div>
    ); 
    }   
}

export default RegisterIndex;