import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaPencilAlt,FaRegTrashAlt} from 'react-icons/fa';
import Store from '../../Common/Store.js';
import HtmlEditor from '../../HtmlEditor';
import RUPills from '../Partials/RUPills';
import RUFullPills from '../Partials/RUFullPills';
import APICall from '../../Common/APICall';
import DatePicker from 'react-date-picker';
import AddKeyDates from './KeyDates/AddKeyDates';
import CSLTable from '../Common/CSLTable';
import AddExternalLinks from './ExternalLinks/AddExternalLinks';
import RCMTagsView from './RCMTagsView';
import Utils from '../../Common/Utils';
import AddEditKeyDates from './KeyDates/AddEditKeyDates';
import AddEditExternalLinks from './ExternalLinks/AddEditExternalLinks';
import SearchablePills from './../Common/SearchablePills';
import CSLLoader from './../Common/CSLLoader';
import AlertBox from './../Common/AlertBox';
import moment from 'moment';

let btoa = require('btoa');
let atob = require('atob');
const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    margin: 80px 100px;
    top: 0%;
    z-index: 1000;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #282828;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const RegModalNameInput = styled.input`
    height: 27px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;    
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #292929;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const CSLDateCover = styled.div`
    height: 23px;
    width: 13%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 8px;
    color: #292929;
    background-color: #ffffff;
`;
const RegModalIssueDateInput = styled.input`
    height: 27px;
    width: 15%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #292929;
    font-family: 'Montserrat', sans-serif;
`;
const RegModalHr = styled.div`
    width: 96%;
    border: 1px solid #ebebeb;
    margin: 10px 20px 0px 20px;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
    margin-top: 15px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #252525;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const AddSectionButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
    cursor: pointer;
`;
const RegModalTagsDiv = styled.div`
    height: auto;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    background-color: #ffffff;
`;
const UnselectedPill = styled.div`
    background-color: #ededed;
    color: #7a8da1;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const SelectedPill = styled.div`
    background-color: #37ada7;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    display: inline-block;
    margin-left: 7px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 3px;
    cursor: pointer;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
class SaveSelectRUModal extends React.Component
{
    state = {
        sections: [],
        topics: [],
        isactionTaken: 0,
        iscustomTaken: 0,
        tags: [],
        documentname: '',
        issuedate: new Date(),
        uploaddate: new Date(),
        modificationdate: new Date(),
        publicresponsedate: new Date(),
        displayEditType: "label",
        documentype:null,
        ragulatortypeName: "",
        ready: false,
        issue_date: null,
        mod_date: null,
        upload_date: null,
        prdate: null,
        keyDatesValue: [],
        externalLink: [],
        documentTypes: null,
        regulatorType: null,
        scaleOfImpact: "low",
        likelyhoodOfImplementation: "low",
        searchFilterTags: [],
        issuing_bodies: [],
        taskID: 0,
        refID: null,
        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,        
        companyID: 0,

        hiddenAddButton: true,
        showAddKeyDateModal: false,
        curKeyid: 0,
        content: null,

        hiddenAddButtonLink: true,
        showAddLinkModal: false,
        curLinkid: 0,
        contentLink: null,
        document_types: [],
        isLoaderIcon: false,
        currentcompanyName: "",
        alert_param: null
    };

    constructor(props) {
        super(props);
        Store.updateStore('sections', []);        
    } 

    componentDidMount() {        
        let rufeed = JSON.parse(Store.getStoreData('cur_mySave_task').task_json).object_data;        
        console.log("refID==>", Store.getStoreData('cur_mySave_task').ref_id);        
        let issuing_bodies = [];        
        if (rufeed.issuingBodies !== null && rufeed.issuingBodies.length !== 0) {
            rufeed.issuingBodies.forEach((body) => {
                if (!issuing_bodies.includes(body)) {
                    issuing_bodies.push(body);
                }
            })
        }        
        console.log("issuing_bodies", issuing_bodies);
        var documentype = rufeed.documentTypes;
        let doctypesObject = {}
        for(let s of documentype){
            doctypesObject[s.toString()] = 1
        }
        var regulatortype = rufeed.issuingBodies === null ? "" : rufeed.issuingBodies[0];        
        console.log("rufeed ====> sourav", rufeed.summary);
        let summary = typeof rufeed.summary !== 'undefined' ? rufeed.summary : '';
        Store.updateStore('summary', window.decodeURIComponent(atob(summary)));        
        let topics = [];        
        let sections = [];
        if ("sections" in rufeed) {
             sections = JSON.parse(JSON.stringify(rufeed.sections));
             for( let i=0; i<sections.length; i++)
             {
                 sections[i].display = "label";
                 sections[i].content = window.decodeURIComponent(atob(sections[i].content));
             }
             Store.updateStore('sections', sections);
        }
        this.setState({topics, sections});

        // let selectedtags = [];
        let selectedtags = {};
        if("tags" in rufeed) {
            //  selectedtags = rufeed.tags;
            let tg = rufeed.tags;
            for(let d of tg){
                selectedtags[d.toString()] = 1
            }
        }

        console.log("rufeed ====> selectedtags", selectedtags);

        const Storetags = Store.getStoreData('tags');
        console.log("Storetags", Storetags);
        let otags = [];
        // Storetags.forEach((tag) => {
        //      if (selectedtags.includes(tag.ID)) {
        //          tag.selected = true;
        //      } else {
        //          tag.selected = false;
        //      }
        //      otags.push({
        //          name: tag.TagName,
        //          id: tag.ID,
        //          styledcomponent: tag.selected ? SelectedPill : UnselectedPill,
        //          selected: tag.selected
        //      }); 
        //  })
        for(let d of Storetags){
            let selected = false            
            if(d.ID.toString() in selectedtags){
                selected = true
            }
            otags.push({
                name: d.TagName,
                id: d.ID,
                styledcomponent: selected ? SelectedPill : UnselectedPill,
                selected: selected
            });
        }
         let stagnames = [];
         otags.forEach((item) => {
             stagnames.push(item.name);
         })
        stagnames.sort();
        let tags = [];
        for (let i=0; i<stagnames.length; i++) {
             for (let j=0; j<otags.length; j++) {
                if (stagnames[i] === otags[j].name) {
                    tags.push(otags[j]);
                }
            }
        }        
        let uploaddate = new Date(rufeed.uploadDate);
        let issuedate = new Date(rufeed.issueDate);
        let modificationdate = new Date(rufeed.modificationDate);
        let publicresponsedate = new Date();
        if(rufeed.publicResponseDate != null)
        {
            publicresponsedate = new Date(rufeed.publicResponseDate);
        }
        let keyDates = [];
        if("keyDates" in rufeed) {    
            let keyDatesAll = rufeed.keyDates;
            keyDatesAll.forEach((item) => {
                if(!('id' in item)){ 
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                keyDates.push(item);
            })        
            //keyDates = rufeed.keyDates;
        }
        let externalLinks = [];        
        if("externalLinks" in rufeed) {         
            let externalLinksAll = rufeed.externalLinks;
            externalLinksAll.forEach((item) => {
                if(!('id' in item)){                     
                // }else{
                    let randNo = Math.floor(Math.random()*(999-100+1)+100);
                    item.id = randNo;
                }
                externalLinks.push(item);
            })   
            //externalLinks = rufeed.externalLinks;
        }

        let document_types = [];        
        Store.getStoreData('rutypename').forEach((doc) => {
            // if (documentype.includes(doc.id.toString())) {
            //     doc.selected = true;
            // } else {
            //     doc.selected = false;
            // }
            if (doc.id in doctypesObject) {
                doc.selected = true;
            } else {
                doc.selected = false;
            }
            document_types.push({
                id: doc.id,
                name: doc.documentname,
                selected: doc.selected,
                show: true
            }); 
        })
        document_types.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("document_types", document_types);

        let doctype = Store.getStoreData('rutypename');
        doctype.sort(function(a,b) {return (a.documentname > b.documentname) ? 1 : ((b.documentname > a.documentname) ? -1 : 0);} );
        let regtype = Store.getStoreData('ragulatorsname'); 
        regtype.sort(function(a,b) {return (a.ragulatorname > b.ragulatorname) ? 1 : ((b.ragulatorname > a.ragulatorname) ? -1 : 0);} );
        //start-group-company        
        let gc_companies = Store.getStoreData('gc_companies');          
        let cur_task_data = JSON.parse(Store.getStoreData('cur_mySave_task').task_json).task_data;
        console.log("cur_task_data==>", cur_task_data)
        let curgc_id = cur_task_data.group_company_id;
        console.log("curgc_id",curgc_id)
        let sampleNumber = curgc_id;
        let lastDigitcurgc_id = sampleNumber % 100000;
        console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = false;
        let currentcompanyName = "";
        for(let gc of gc_company) {                
            if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                is_gc = gc.is_gc;
                console.log("gc.id", gc.id);
                currentcompanyName = gc.company_name
            }
        }
        let cur_gc_id = parseInt(lastDigitcurgc_id)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;



        this.setState({ tags: tags,searchFilterTags: tags,documentname: rufeed.documentName, uploaddate: uploaddate, issuedate, modificationdate, publicresponsedate, 
            documentype: documentype, ready: true, ragulatortypeName: regulatortype,
        keyDatesValue: keyDates, externalLink: externalLinks,documentTypes: doctype,regulatorType: regtype, scaleOfImpact: rufeed.scaleOfImpact,
        likelyhoodOfImplementation: rufeed.likelyhoodOfImplementation, issuing_bodies,taskID:Store.getStoreData('cur_mySave_task').id,
        refID: Store.getStoreData('cur_mySave_task').ref_id,
        gc_companies: gc_companies, gc_company: Store.getStoreData('gc_company'), cur_gc_id: lastDigitcurgc_id, is_gc: is_gc,
        companyID: companyID, document_types, currentcompanyName: currentcompanyName});
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    onIssueDateChange = (date) => {
        if (!moment.isDate(date)) {
			return;
		}
        let issuesdate = this.state.issuedate;
        this.setState({issuedate: date});
    }

    onUploadDateChange = (date) => {
        this.setState({uploaddate: date});
    }

    ondocumentType = (event) => {
        event.preventDefault();
        //console.log("doctype",event.target.value)
        let dtype = event.target.value;
        let documenttypearr = dtype.split(",");
        //console.log("doctype222",JSON.stringify(documenttypearr))
        this.setState({[event.target.name]: documenttypearr});
    }

    fetchKeyValue = (keyDate,keyDescription) => {
        console.log(keyDate,keyDescription)        
        // if(keyDescription != '')
        // {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let keys = {};
            keys.id = randNo; 
            keys.date = keyDate;
            keys.description = keyDescription;            
            this.state.keyDatesValue.push(keys)
        // }
        console.log(this.state.keyDatesValue)
        //keyDatesValue
        this.setState({keyDatesValue: this.state.keyDatesValue, showAddKeyDateModal: false, hiddenAddButton: true})
    }

    refreshEditKeyValue = (content) => {
        console.log("content",content)   
        // if(content.description != '')
        // {
            let newkeydate = [];
            this.state.keyDatesValue.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newkeydate.push(item);
            })            
		    this.setState({keyDatesValue: newkeydate, showAddKeyDateModal: false, hiddenAddButton: true})
        //}  
    }

    fetchexternalLinkValue = (linkUrl,linkDescription) => {
        console.log(linkUrl,linkDescription)
        if(linkUrl != '') {
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            let links = {};
            links.id = randNo; 
            links.url = linkUrl;
            links.description = linkDescription;
            this.state.externalLink.push(links);
        }        
        this.setState({externalLink: this.state.externalLink, showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    fetchEditexternalLinkValue = (content) => {
        console.log("content",content)              
        if(content.url != '')
        {
            let newExLinks = [];
            this.state.externalLink.forEach((item) => {
                if (item.id === content.id) {                    
                    item = content;                    
                }
                newExLinks.push(item);
            })            
		    this.setState({externalLink: newExLinks, showAddLinkModal: false, hiddenAddButtonLink: true})
        }  
    }

    processMykeyData = () => {
        var keysdata = this.state.keyDatesValue;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, {Header: 'Date', accessor: 'date_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                <FaPencilAlt onClick={this.openEditKeyDateModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                <FaTimes onClick={this.deleteKeyDate(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of keysdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    'date_value' : d.date,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteKeyDate = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newkeydate = [];
			for(let i=0;i<this.state.keyDatesValue.length;i++)
			{
				if(this.state.keyDatesValue[i].id !== id){
					newkeydate.push(this.state.keyDatesValue[i])
				}
			}			 
			console.log("delete===>",newkeydate);   
            this.setState({keyDatesValue: newkeydate})      
		//}         		  
    }

    openEditKeyDateModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.keyDatesValue.find((item) => {
			return item.id === id;
		})
		this.setState({content,curKeyid: id,showAddKeyDateModal: true, hiddenAddButton: false});        
	}

    processMyExternalLink = () => {
        var linksdata = this.state.externalLink;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
        // {Header: 'URL', accessor: 'url_value', minWidth: 200, headerStyle: {textAlign: 'left'}},
        {'Header' : 'URL', accessor: 'url_value', Cell: row => (
            <div>
                <a target="_blank" href={row.original.url_value}>{row.original.url_value}</a>
            </div>
          ), minWidth: 200, headerStyle: {textAlign: 'left'}},
        {Header: 'Description', accessor: 'description_value', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
            <div>
                <FaPencilAlt onClick={this.openEditExLinkModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#7a8da1',display :'inline-block',marginRight: "5px"}} />
                <FaTimes onClick={this.deleteExternalLink(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#7a8da1',display :'inline-block'}}/> 
            </div>
          ), width: 70, headerStyle: {textAlign: 'center'}}];
        for(let d of linksdata) {           
            i++;                      
            let elem = {'index' : i, 
                    'id': d.id,
                    // 'url_value' : <a target="_blank" href={d.url}>{d.url}</a>,
                    'url_value' : d.url,
                    'description_value' : d.description                    
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    deleteExternalLink = (id) => (event) => {
        // if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)           	
			let newexLink = [];
			for(let i=0;i<this.state.externalLink.length;i++)
			{
				if(this.state.externalLink[i].id !== id){
					newexLink.push(this.state.externalLink[i])
				}
			}			 
			console.log("delete===>",newexLink);   
            this.setState({externalLink: newexLink})      
		//}         		  
    }

    openEditExLinkModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const contentLink = this.state.externalLink.find((item) => {
			return item.id === id;
		})        
		this.setState({contentLink,curLinkid: id,showAddLinkModal: true, hiddenAddButtonLink: false});        
    }

    searchUsers = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
        console.log("fddsf",this.state.searchFilterTags)
        let search_string = event.target.value;
        if (search_string === "") {
            let tags = this.state.searchFilterTags;            
            this.setState({tags: tags});
        }else {
            let all_tags = this.state.searchFilterTags;
            let filtered_tags = [];
            all_tags.forEach((tag) => {
                if (tag.name.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_tags.push(tag);
                }                
            })            
            let tags = filtered_tags;

            this.setState({tags: tags})
        }
    }

    deleteSection = (section_id) => (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure to delete this section?")) {
            let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
            console.log("sections",sections)
            let sectionArray = []
            for(let i=0;i<sections.length;i++){
                if(sections[i].id !== section_id){
                    if(sections.length === 1){
                        sectionArray = [];
                    }else{
                        sectionArray.push({
                            content: sections[i].content,
                            display: sections[i].display,
                            id: sections[i].id,
                            name: sections[i].name
                        })
                    }
                    
                }                        
            }
            console.log("sectionArray",sectionArray)
            Store.updateStore('sections', sectionArray);
            this.setState({sections: sectionArray});
        }
    }

    closeRegulatoryModal = (event) => {
        this.props.closeModal();
    }

    changeToInput = (data_id) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(data_id)) {
                section.display = "input";
            }
        })
        Store.updateStore('sections', sections);
        this.setState({sections});
    }

    changeInputValue = (status) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
                section.display = "input";
                if (!status) section.display = "label";
            }
        })
        let store_sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        store_sections.forEach((section) => {
            if (section.id === parseInt(event.target.name)) {
                section.name = event.target.value;
            }
        })
        Store.updateStore('sections', store_sections);
        this.setState({sections});
    }
    addSection = (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
        let section_id = 1;
        if (sections.length !== 0) {
            section_id = sections[sections.length - 1].id + 1;
        }
        sections.push({
            id: section_id,
            display: "input",
            name: `Section ${section_id.toString()}`,
            content: ""
        });
        Store.updateStore('sections', sections);
        this.setState({sections});
    }

    selectOrUnselectTopic = (tagid) => {
        //event.preventDefault();
        console.log("Sourav22", tagid)
        let { tags } = this.state;
        tags.forEach((tag) => {
            if (tag.id === tagid) {
                if (tag.selected) {
                    tag.selected = false;
                    tag.styledcomponent = UnselectedPill;
                } else {
                    tag.selected = true;
                    tag.styledcomponent = SelectedPill;
                }
            }
        })

        this.setState({ tags });
    }

    saveRegulatorychangeForLater = (saveChangeValue) =>(event) => {
        event.preventDefault();        
        if(saveChangeValue){
            this.updatesaveandclose(saveChangeValue);
        }else{
            this.saveChange(saveChangeValue);
        }        
    }

    updatesaveandclose(saveForLater) {  
        //console.log("Sourav",this.state.cur_gc_id,0)
        let problems = false
        let message = '';
        if(parseInt(this.state.cur_gc_id) === 0){
            //alert('Please select company');
            message = 'Please select company.';
            problems = true;
        } 
        if(problems){
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{ 
            let tags = [];
            this.state.tags.forEach((tag) => {
                if (tag.selected) tags.push(tag.id);
            })          
            let rufeed = {};
            let companyID = Store.getStoreData('companyid');
            let summary = Store.getStoreData('summary');        
            
            let sections = Store.getStoreData('sections');
            for( let i=0; i<sections.length; i++)
            {
                sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
            }
            let curmonth = parseInt(this.state.issuedate.getMonth());
            let currmonth = curmonth + 1;
            var dateStr = currmonth >= 10 ? this.state.issuedate.getFullYear()+'/'+currmonth+'/'+this.state.issuedate.getDate() : this.state.issuedate.getFullYear()+'/0'+currmonth+'/'+this.state.issuedate.getDate();
            //let id = Utils.genKey(35);  
            let company_id = this.state.companyID;

            let doctypeArray = [];
            for(let i=0;i<this.state.document_types.length;i++){
                if(this.state.document_types[i].selected){
                    doctypeArray.push(this.state.document_types[i].id.toString()) 
                }
            }

            rufeed.tags = tags;
            rufeed.summary = btoa(window.encodeURIComponent(summary));
            rufeed.sections = sections;  
            rufeed.id = this.state.refID;
            rufeed.companyid = companyID; 
            rufeed.documentName = this.state.documentname;
            rufeed.issueDate = dateStr;
            rufeed.uploadDate = this.state.uploaddate;
            rufeed.modificationDate = this.state.modificationdate;
            rufeed.publicResponseDate = this.state.publicresponsedate;
            rufeed.keyDates = this.state.keyDatesValue;
            rufeed.externalLinks = this.state.externalLink;
            // rufeed.documentTypes = this.state.documentype;
            rufeed.documentTypes = doctypeArray;
            rufeed.scaleOfImpact = this.state.scaleOfImpact;
            rufeed.likelyhoodOfImplementation = this.state.likelyhoodOfImplementation;
            rufeed.ragulatorName = this.state.ragulatortypeName;
            rufeed.issuingBodies = [this.state.ragulatortypeName];

            let postData = {"command": "updateSaveAndCloseSelectRUTask", id: this.state.refID, "data": rufeed, task_id: this.state.taskID, group_company_id: company_id};
            console.log("postData===>",postData);
            let api = new APICall();
            this.setState({isLoaderIcon: true})
            api.command(postData, this.aftersaveandcloseData);
        }
    }

    aftercheckvalidationAll = (result, stack) => {  
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    aftersaveandcloseData = (result) => {
        console.log("update data", result);
        let message = '';
        if('error_msg' in result && result.error_msg !== ''){
            //alert(result.error_msg);
            message = result.error_msg;
        }        
        let alert_param = {title: 'Success', message: message, ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})
        // window.location.reload(true);
        // this.setState({isLoaderIcon: false})
    }

    updateafterAlertWithLoading = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
        window.location.reload();     
    }

    saveChange(saveForLater) {   
        let problems = false
        let message = '';   
        if(parseInt(this.state.cur_gc_id) === 0){
            //alert('Please select company');
            message += 'Please select company.';
            problems = true;
        } 
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        // if (tags.length === 0) {            
        //     message += 'Please select atleast one tag.';
        //     problems = true;
        // }          
        if(problems){            
            let alert_param = {title: 'ERROR', message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }else{             
            if (tags.length === 0) {                
                let alert_param = {title: 'Alert', message: 'You have not selected any tags, the regulatory change will be untagged in the Register.', ok_text: 'OK', cancel_text: 'Cancel', confirm: true, 
                                alertHandler: this.processConfirmationAddNewRU, stack: {saveForLater: saveForLater}}
                this.setState({alert_param: alert_param})
            }else{                
                this.processConfirmationAddNewRU(true, {saveForLater: saveForLater})
            }
        }        
    }

    processConfirmationAddNewRU = (result, stack) => { 
        if(!result) {
            this.setState({alert_param: null})
            return
        }        
        let tags = [];
        this.state.tags.forEach((tag) => {
            if (tag.selected) tags.push(tag.id);
        })
        let rufeed = {};
        let companyID = Store.getStoreData('companyid');
        let summary = Store.getStoreData('summary');        
        
        let sections = Store.getStoreData('sections');
        for( let i=0; i<sections.length; i++)
        {
            sections[i].content = btoa(window.encodeURIComponent(sections[i].content));
        }
        let curmonth = parseInt(this.state.issuedate.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? this.state.issuedate.getFullYear()+'/'+currmonth+'/'+this.state.issuedate.getDate() : this.state.issuedate.getFullYear()+'/0'+currmonth+'/'+this.state.issuedate.getDate();
        //let id = Utils.genKey(35);  
        let company_id = this.state.companyID;

        let doctypeArray = [];
        for(let i=0;i<this.state.document_types.length;i++){
            if(this.state.document_types[i].selected){
                doctypeArray.push(this.state.document_types[i].id.toString()) 
            }
        }

        rufeed.tags = tags;
        rufeed.summary = btoa(window.encodeURIComponent(summary));
        rufeed.sections = sections;  
        rufeed.id = this.state.refID;
        rufeed.companyid = companyID; 
        rufeed.documentName = this.state.documentname;
        rufeed.issueDate = dateStr;
        rufeed.uploadDate = this.state.uploaddate;
        rufeed.modificationDate = this.state.modificationdate;
        rufeed.publicResponseDate = this.state.publicresponsedate;
        rufeed.keyDates = this.state.keyDatesValue;
        rufeed.externalLinks = this.state.externalLink;
        // rufeed.documentTypes = this.state.documentype;
        rufeed.documentTypes = doctypeArray;
        rufeed.scaleOfImpact = this.state.scaleOfImpact;
        rufeed.likelyhoodOfImplementation = this.state.likelyhoodOfImplementation;
        rufeed.ragulatorName = this.state.ragulatortypeName;
        rufeed.issuingBodies = [this.state.ragulatortypeName];

        let postData = {"command": "saveRUTask", "id": this.state.refID, "data": rufeed, "selected_topics": tags,'save_for_later' : stack.saveForLater ? 1 : 0,'is_custom' : 1,'job_type' : 'Assign_feedtoClientContract',
        previousSubmitValue: 1, task_id: this.state.taskID,group_company_id: company_id};
        console.log("postdata", postData);     
        let api = new APICall();
        this.setState({isLoaderIcon: true, alert_param: null})
        api.command(postData, this.UpdateFeedFetchData);
    }

    UpdateFeedFetchData = (result) => {
        console.log("update data", result);
        let message = '';
        if('error_msg' in result && result.error_msg !== ''){
            //alert(result.error_msg);
            message = result.error_msg;
        } 
        let alert_param = {title: 'Success', message: message, ok_text: 'OK', confirm: false, 
                           alertHandler: this.updateafterAlertWithLoading, stack: {}}
        this.setState({alert_param: alert_param})      
        // window.location.reload(true);
        // this.setState({isLoaderIcon: false})
    }
    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_companies');
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = true;
        console.log("gc_company", gc_company);

        for(let gc of gc_company) {
            //gc.id = (gc.id * 100000) + gc_company.id;
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
                console.log("gc.id", gc.id);

            }
        }
        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID});
        //console.log("CURGCID", this.state.cur_gc_id);
    }

    showAddKeyDate = () => {    
        //event.preventDefault();
        this.setState({ showAddKeyDateModal: true, hiddenAddButton: false,curKeyid: 0 });
    }
    closeKeyDateModal = () => {
        this.setState({ showAddKeyDateModal: false, hiddenAddButton: true});
    }

    showAddexternalLink = () => {    
        //event.preventDefault();

        this.setState({ showAddLinkModal: true, hiddenAddButtonLink: false,curLinkid: 0 });
    }
    closeExLinkModal = () => {
        this.setState({ showAddLinkModal: false, hiddenAddButtonLink: true});
    }

    modifyEntity = (obj) => {
        console.log("obj:", obj);
        this.setState({[obj.entity]: obj.list});
    }

    render()
    {   
        let rufeed = JSON.parse(Store.getStoreData('cur_mySave_task').task_json).object_data; 

        let uploadDate = this.state.uploaddate;        
        const upload_date_obj = new Date(uploadDate);
		const upload_day = upload_date_obj.getDate();
		const upload_month = upload_date_obj.getMonth()+1 < 10 ? `0${(upload_date_obj.getMonth() + 1).toString()}` : upload_date_obj.getMonth()+1;
		const upload_year = upload_date_obj.getFullYear();
        const upload_date = `${upload_day}/${upload_month}/${upload_year}`;     
        if(!this.state.ready){
            return(<div>Loading...</div>);
        }
        let role = Store.getStoreData('role')        
        let contact_id = Store.getStoreData('loggedincontact')        
        let show_all = (role === 'admin_manager' || role === 'manager') ? true : false
        let gc_users = Store.getStoreData('gc_users')
        return (<div>
            <RegModalContainer>    
                {
                    (() => {
                        if(this.state.isLoaderIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }            
                <RegModalHeaderText>Process Regulatory Change</RegModalHeaderText>
                <RegModalHeaderCloseBtn onClick={this.closeRegulatoryModal}><FaTimes /></RegModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <RegModalContainertop>                    
                    {/* {
                        (() => {
                            if (this.state.gc_companies.length === 0) {
                                return(<RegModalNameLabelDiv>Company Name - </RegModalNameLabelDiv>)
                            }else{
                                return(<RegModalNameLabelDiv>Select Company</RegModalNameLabelDiv>)
                            }
                        })()
                    }
                    
                    {
                        (() => {
                            if (this.state.gc_companies.length === 0) {
                                return(<RegModalNameLabelDiv>{this.state.gc_company[0].company_name}</RegModalNameLabelDiv>)
                            }else{
                                return(<RegModalNameInputDiv>
                                    <MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                    <option key={0} value={0}>Select</option>
                                        {
                                                this.state.gc_company.map((gc) => {
                                                    //if(gc.is_gc) {
                                                        if(show_all) {
                                                            return <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                        }else if(gc.is_gc && role === 'rcm_gatekeeper') {
                                                            if(gc.id in gc_users[contact_id])return <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                        }
                                                    //}                                                
                                            })
                                        }
                                    </MRModalSelect>
                                </RegModalNameInputDiv>)
                            }
                        })()
                    } */}

                    <RegModalNameLabelDiv>Company Name - </RegModalNameLabelDiv>
                    <RegModalNameLabelDiv>{this.state.currentcompanyName}</RegModalNameLabelDiv>
                    <RegModalNameLabelDiv>Name</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <RegModalNameInput style={{width: "98%"}} type="text" name="documentname" value={this.state.documentname} onChange={this.handleChange}  />
                    </RegModalNameInputDiv>

                    <RegModalNameLabelDiv>Regulator Name</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <MRModalSelect style={{width: "99.2%", display: "inline-block"}} name="ragulatortypeName" value={this.state.ragulatortypeName} onChange={this.handleChange}>                        
                            <option value="">Select Regulator</option>
                            {
                                this.state.regulatorType.map((reg, index) => {
                                    return (<option key={index} value={reg.ragulatorname}>{reg.knownAs}</option>);
                                })
                            }                            
                        </MRModalSelect>
                    </RegModalNameInputDiv>
                    <RegModalNameLabelDiv>Regulator Published Date</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        {/* <MRModalSelect style={{width: "20%",display: "inline-block"}} name="documentype" value={this.state.documentype} onChange={this.ondocumentType}>                        
                            <option value="">Select Type</option>
                            {
                                this.state.documentTypes.map((doc, index) => {
                                    return (<option key={index} value={doc.id}>{doc.documentname}</option>);
                                })
                            }                            
                        </MRModalSelect>
                        <label style={{marginTop: "13px",marginLeft: "26px",marginRight: "15px",fontWeight: "600",color: "#252525"}}>Regulator Published Date</label> */}
                        <CSLDateCover style={{display: "inline-block"}}>
                        <DatePicker onChange={this.onIssueDateChange}
                            clearIcon={null}
                            calendarIcon={null}
                            locale={"en-GB"}
                            value={this.state.issuedate}/>
                        </CSLDateCover>
                        <label style={{marginTop: "13px",marginLeft: "26px",marginRight: "15px",fontWeight: "600",color: "#252525"}}>Date</label>
                        <RegModalIssueDateInput defaultValue={upload_date} readOnly/>
                    </RegModalNameInputDiv>

                    <RegModalNameLabelDiv>Scale of Impact</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        <MRModalSelect style={{width: "20%",display: "inline-block"}} name="scaleOfImpact" value={this.state.scaleOfImpact} onChange={this.handleChange}>                        
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>                            
                        </MRModalSelect>
                        <label style={{marginTop: "15px",marginLeft: "20px",marginRight: "20px",fontWeight: "600",color: "#252525"}}>Likelihood of implementation</label>
                        <MRModalSelect style={{width: "20%",display: "inline-block"}} name="likelyhoodOfImplementation" value={this.state.likelyhoodOfImplementation} onChange={this.handleChange}>                        
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>                             
                        </MRModalSelect>
                    </RegModalNameInputDiv>
                </RegModalContainertop>
                <RegModalContainertop style={{paddingRight: "35px"}}>
                    <RegModalNameLabelDiv>Document Types</RegModalNameLabelDiv>
                    <SearchablePills entity="document_types" list={this.state.document_types} modifyEntity={this.modifyEntity} />
                </RegModalContainertop> 
                <RegModalHr />
                <RegModalContainertop>
                    <RegModalNameLabelDiv>Summary</RegModalNameLabelDiv>
                    <RegModalNameInputDiv>
                        {
                            (() => {
                                let summary = window.decodeURIComponent(atob(rufeed.summary));                                
                                console.log("summary", summary);
                                return (<HtmlEditor content={summary} section_id="0"/>);
                            })()
                        }                        
                    </RegModalNameInputDiv>
                </RegModalContainertop>
                {
                    Store.getStoreData('sections').map((section) => {
                        if (section.display === "label") {
                            return (
                                <RegModalContainertop key={section.id}>
                                    <RegModalNameLabelDiv onClick={this.changeToInput(section.id)}>{section.name}</RegModalNameLabelDiv>
                                    <RegModalNameInputDiv>
                                        {/* <HtmlEditor content={section.content} section_id={section.id}/> */}
                                        <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>
                                        {
                                            (() => {
                                                if(Store.getStoreData('istask_action') !== 1){
                                                    return(<div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>);
                                                }
                                            })()
                                        }                                        
                                    </RegModalNameInputDiv>
                                </RegModalContainertop>
                            );
                        } else {
                            return (
                                <RegModalContainertop key={section.id}>
                                    <RegModalNameLabelDiv >
                                        <RegModalNameInput type="text" name={section.id} value={section.name} onChange={this.changeInputValue(true)} onBlur={this.changeInputValue(false)} />
                                    </RegModalNameLabelDiv>
                                    <RegModalNameInputDiv>
                                        {/* <HtmlEditor content={section.content} section_id={section.id}/> */}
                                        <div style={{float: "left",width: "95%"}}><HtmlEditor content={section.content} section_id={section.id}/></div>
                                        {
                                            (() => {
                                                if(Store.getStoreData('istask_action') !== 1){
                                                    return(<div style={{float: "right",fontSize: "15px", cursor: "pointer",paddingTop: "7px"}} onClick={this.deleteSection(section.id)}><FaRegTrashAlt /></div>);
                                                }
                                            })()
                                        }
                                    </RegModalNameInputDiv>
                                </RegModalContainertop>
                            );
                        }
                    })
                }
                <SingleRowColumnContainer>
                        <AddSectionButton onClick={this.addSection}>Add Section</AddSectionButton>
                </SingleRowColumnContainer>    

                <RegModalContainertop>
                    <RegModalNameLabelDiv style={{marginLeft: "5px"}}>Available Tags</RegModalNameLabelDiv>
                    <RegModalTagsDiv>
                    <RegModalNameInput style={{marginTop: "13px",marginLeft: "6px",marginBottom: "11px",height: "33px",width: "98%"}} placeholder="Start typing to search..." onChange={this.searchUsers} type="text" />
                        {
                             (() => {
                                if (this.state.tags.length !== 0) {
                                    //console.log(this.state.tags);                                     
                                    return(<RCMTagsView tags={this.state.tags} selectUnselect={this.selectOrUnselectTopic}/>)                                                                                                         
                                } 
                            })()
                            
                        }
                    </RegModalTagsDiv>
                </RegModalContainertop>
                {
                    (() => {
                        if(this.state.keyDatesValue.length === 0)
                        {
                            return(<SingleRowColumnContainer>
                                <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                    {
                                        (() =>{
                                            if(this.state.hiddenAddButton){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddKeyDateModal) {
                                            return (<AddEditKeyDates  curid={this.state.curKeyid} closeKeyDateModal={this.closeKeyDateModal} refreshKeyValue={this.fetchKeyValue}/>);
                                        }
                                    })()
                                }
                            {/* <AddKeyDates refreshKeyValue={this.fetchKeyValue}/> */}
                            <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>
                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no Key Dates Available</div>
                            </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }else {
                            return(<SingleRowColumnContainer>
                                <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                                    {
                                        (() =>{
                                            if(this.state.hiddenAddButton){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddKeyDateModal) {
                                            return (<AddEditKeyDates  curid={this.state.curKeyid} content={JSON.parse(JSON.stringify(this.state.content))} closeKeyDateModal={this.closeKeyDateModal} refreshEditKeyValue={this.refreshEditKeyValue} refreshKeyValue={this.fetchKeyValue}/>);
                                        }
                                    })()
                                }
                                {/* <AddKeyDates refreshKeyValue={this.fetchKeyValue}/> */}
                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                               
                                    <CSLTable add={false} processData={this.processMykeyData} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.externalLink.length === 0)
                        {
                            return(<SingleRowColumnContainer>
                                <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                    {
                                        (() => {
                                            if(this.state.hiddenAddButtonLink){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddLinkModal) {
                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} closeExLinkModal={this.closeExLinkModal} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                        }
                                    })()
                                }
                            {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                            <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}> 
                                <div style={{textAlign: "center",fontStyle: "italic", color: "#929292"}}>There are currently no External Links Available</div>
                            </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }else {
                            return(<SingleRowColumnContainer>
                                <OverviewInfoHeader style={{width: "unset"}}>
                                    <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>External Links</div>
                                    {
                                        (() => {
                                            if(this.state.hiddenAddButtonLink){
                                                return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddexternalLink}><FaPlusCircle /></div>)
                                            }
                                        })()
                                    }                
                                    <div style={{clear: "both"}}></div>
                                </OverviewInfoHeader>
                                {
                                    (() => {
                                        if (this.state.showAddLinkModal) {
                                            return (<AddEditExternalLinks  curid={this.state.curLinkid} content={JSON.parse(JSON.stringify(this.state.contentLink))} closeExLinkModal={this.closeExLinkModal} fetchEditexternalLinkValue={this.fetchEditexternalLinkValue} refreshExternalLinkValue={this.fetchexternalLinkValue}/>);
                                        }
                                    })()
                                }
                                {/* <AddExternalLinks refreshExternalLinkValue={this.fetchexternalLinkValue}/> */}
                                <OverviewInfoBody style={{width: "unset",marginBottom: "0px"}}>                                   
                                    <CSLTable add={false} processData={this.processMyExternalLink} headerText={''} tableRows={3} refreshCallback={this.refreshCallback} />
                                </OverviewInfoBody>
                            </SingleRowColumnContainer>)
                        }
                    })()
                }
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeRegulatoryModal}>Cancel</RegCancelButton>
                    <RegSubmitButton onClick={this.saveRegulatorychangeForLater(true)} >Save and Close</RegSubmitButton>
                    <RegSubmitButton onClick={this.saveRegulatorychangeForLater(false)}>Submit</RegSubmitButton>
                </RegCancelSubmitButtonOuterContainer>
            </RegModalContainer> 
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>  
        );
    }
}
export default SaveSelectRUModal;