import React from 'react';
import styled from 'styled-components';
import TagManagement from './TagManagement';
import AutoForwordGenaral from './AutoForwordGenaral';
import Store from '../../Common/Store';
import AutoDismissGeneral from './AutoDismissGeneral';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const RegModalNameLabelDiv = styled.div`
    color: #252525;
    font-weight: 600;
	margin-top: 20px;
	margin-bottom: 10px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;    
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #484848;
`;

class General extends React.Component
{
	state = {general: null, tagManagement: false,timespan: "this_month"};

	componentDidMount()
	{
		this.setState({general: this.props.general});
		//console.log("general",this.props.general)
		if(this.props.general.enable_tag_management) {
			this.setState({tagManagement : true})
		}else {
			this.setState({tagManagement : false})
		}
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({general: this.props.general});
		}
	}

	switchRadio = (key) => (event) => {
		//console.log("TAGState===>",key)
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
		// console.log("Radio",general[key])
		if(key === 'enable_tag_management') {
			general[key] === true ? this.setState({tagManagement : true}) : this.setState({tagManagement : false})
		}
		// general[key] === true ? this.setState({tagManagement : true}) : this.setState({tagManagement : false})
		// this.setState({tagManagement : true});
		//console.log("TAGState===>",this.state)

	}

	switchRadioNew = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralnew(general);
	}
	handleChange = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = event.target.value;
		//console.log("general",general)
		this.props.updateGeneralnew(general);
    }

	
	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<GeneralContainer>
				<GeneralInnerContainer>			
					<GeneralLabel>Enable tag management</GeneralLabel>
					{
						
						(() => {
							if (this.state.general.enable_tag_management) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('enable_tag_management')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('enable_tag_management')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					{
						(() => {
							if(Store.getStoreData('has_gc')){
								return(<div>
									<GeneralLabel>Show Parent Company Regulatory Updates</GeneralLabel>
									{
										
										(() => {
											if (this.state.general.show_parent_company_regulatory_updates) {
												return (
													<RadioBlock>
														<SelectedRadio>Yes</SelectedRadio>
														<UnselectedRadio onClick={this.switchRadioNew('show_parent_company_regulatory_updates')}>No</UnselectedRadio>
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio onClick={this.switchRadioNew('show_parent_company_regulatory_updates')}>Yes</UnselectedRadio>
														<SelectedRadio>No</SelectedRadio>
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}							
						})()
					}
					<GeneralLabel>Allow Users to add Regulatory Updates</GeneralLabel>
					{											
						(() => {
							if (this.state.general.enable_add_new_ru) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadioNew('enable_add_new_ru')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadioNew('enable_add_new_ru')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					{/* <GeneralLabel>Enable Data Cards</GeneralLabel>
					{
						
						(() => {
							if (this.state.general.enable_data_cards) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadioNew('enable_data_cards')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadioNew('enable_data_cards')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					} */}
					{
						(() => {
							//if(this.state.general.enable_data_cards){
								return(
									<div>
										<RegModalNameLabelDiv>Default Time Period for KPIs</RegModalNameLabelDiv>
										<MRModalSelect name="timespan" value={this.state.general.select_timespan} onChange={this.handleChange('select_timespan')}>                        
											<option key="0" value="this_month">This month</option>
											<option key="1" value="last_month">Last Month</option>
											<option key="2" value="last_thirty_days">Last 30 days</option>
											<option key="3" value="last_twelve_month">Last 12 months</option>
											<option key="4" value="this_year">This Year</option>
											<option key="5" value="last_year">Last Year</option>	
											<option key="6" value="quarter_one">Quarter One</option>	
											<option key="7" value="quarter_two">Quarter Two</option>	
											<option key="8" value="quarter_three">Quarter Three</option>	
											<option key="9" value="quarter_four">Quarter Four</option>											                          
										</MRModalSelect>
									</div>
								)
							//}
						})()
					}
					{
						(() => {
							if(Store.getStoreData('has_gc')){
								return(<div>
									<GeneralLabel>Assign Automated News Feed to Group Companies</GeneralLabel>
									{
										
										(() => {
											if (this.state.general.assign_automated_news_feed_to_group_companies) {
												return (
													<RadioBlock>
														<SelectedRadio>Yes</SelectedRadio>
														<UnselectedRadio onClick={this.switchRadioNew('assign_automated_news_feed_to_group_companies')}>No</UnselectedRadio>
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio onClick={this.switchRadioNew('assign_automated_news_feed_to_group_companies')}>Yes</UnselectedRadio>
														<SelectedRadio>No</SelectedRadio>
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}							
						})()
					}	
					<GeneralLabel>Automatic Reason for Dismissal</GeneralLabel>
					{
						
						(() => {
							if (this.state.general.automatic_reason_for_dismissal) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadioNew('automatic_reason_for_dismissal')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadioNew('automatic_reason_for_dismissal')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}				
				</GeneralInnerContainer>
				{
						(() => {
							if(this.state.tagManagement) {
								return (
									<TagManagement general={this.state.general} selected_tags={this.props.selected_tags} available_tags={this.props.available_tags} cslsubcribe_tags={this.props.cslsubcribe_tags} allSelectedTagValue={this.props.allSelectedTagValue} updatenewtag={this.props.updatenewtag}></TagManagement>
								)
							}
						})()
					}
			</GeneralContainer>
		);
	}
}

export default General;