import React from 'react';
import styled from 'styled-components';
import WelcomeBanner from '../Components/WelcomeBanner';
import Store from '../Common/Store';
import APICall from './../Common/APICall';
import TaskHeader from './TaskHeader';
import CSLLoader from './Common/CSLLoader';
import AutoForwordFeedModal from './Select/AutoForwordFeedModal';
import AutoForwordFeedModalForGC from './Select/AutoForwordFeedModalForGC';
import AlertBox from './Common/AlertBox';


class FeedIndex extends React.Component
{
    state = { view_count: 0, modal_view: null, isLoadedIcon: false }

    constructor(props) {
		super(props);
    }

    refreshState = (cur_view) => {
        //console.log("cur_view ===>", cur_view)
       this.props.refreshCallback(cur_view);
      }

    componentDidMount = () => {
        let windowUrl = window.location.href;
        let pieces = windowUrl.split("/");
        let feedid = pieces[pieces.length-1];
        console.log("is_task_id_FeedIndex", feedid, windowUrl)
        let role = Store.getStoreData('role')
        if(role === 'no_access'){
            this.setState({ modal_view: 'no_access', isLoadedIcon: false})
        }else{
            if(feedid !== ''){
                let postData = { "command" : "getFeedSelectSide" , "feed_id": feedid};
                let api = new APICall();
                this.setState({isLoadedIcon: true})
                api.command(postData, this.AddFeedtagNew);
            }
        }
    }

    AddFeedtagNew = (result) => {
        console.log("result", result)
        if(Object.keys(result).length !== 0){
            Store.updateStore('cur_autoForwordFeeds', result)
            let role = Store.getStoreData('role');
            let moduleconfig = Store.getStoreData('module_config');
            if(role === 'manager' && Store.getStoreData('has_gc') && 'general' in moduleconfig && moduleconfig.general.assign_automated_news_feed_to_group_companies === true){
                this.setState({ modal_view: 'view_auto_forword_feeds_gc', isLoadedIcon: false})
            }else{
                this.setState({ modal_view: 'view_auto_forword_feeds', isLoadedIcon: false})
            }
        }

    }

    alertHandler = (result, stack) => {
        window.location = '/';
    }

    closeModal = () => {
        window.location = '/'
    }

    render() {
        let windowUrl = window.location.href;
        let pieces = windowUrl.split("/");
        let is_task_id = pieces[pieces.length-1];
        //console.log("is_task_id", is_task_id, windowUrl)
        let feed_id = is_task_id;

        let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
               alertHandler: this.alertHandler, stack: {id: 0}}

        console.log("task_id", feed_id);
        let contact = "";
        if(Store.getStoreData('contact') !== null){
            contact = 'Welcome back , '+Store.getStoreData('contact');
        }
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                {
                    (() => {
                        if(feed_id !== '') {
                            return(<TaskHeader />)
                        }
                    })()
                }
                <WelcomeBanner welcome={contact} />
                {
                    (() => {
                        if(this.state.modal_view !== null){
                            switch(this.state.modal_view) {
                                case 'view_auto_forword_feeds' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%'}}><AutoForwordFeedModal closeModal={this.closeModal}/></div>; break;
                                case 'view_auto_forword_feeds_gc' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%'}}><AutoForwordFeedModalForGC closeModal={this.closeModal}/></div>; break;
                                case 'no_access' : return <AlertBox alertParam = {alert_param}/>; break;
                            }
                        }
                    })()
                }
            </div>
        );
    }
}

export default FeedIndex;
