import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle} from 'react-icons/fa';

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const ScoreContainer = styled.div`
    display: block;
    //box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    //width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #252525;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const FCKTextarea = styled.textarea`
    font-size: 13px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 35px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class AddEditExternalLinks extends React.Component
{
    state = { 
        showModal: false,
        linkURL: '',
        linkDescription: '',
        hiddenAddButton: true,
        ready: false
    }

    componentDidMount()
	{        
        if(this.props.curid !== 0) {
            console.log("content==>",this.props.content)                
            this.setState({ready:true, linkURL: this.props.content.url, linkDescription: this.props.content.description})  
            
        }else{           
            this.setState({ready: true})
        }
    }
    
    showAddexternalLink = (index) => (event) => {    
        event.preventDefault();
        this.setState({ showModal: true, hiddenAddButton: false});
    }
    closeExternalLinkModal = () => {
        this.props.closeExLinkModal()
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});            
    }
    submitExternalLinkValue = (event) => {
        event.preventDefault();
        this.props.refreshExternalLinkValue(this.state.linkURL,this.state.linkDescription)
        this.setState({linkURL: '', linkDescription: ''})
    }

    submitEditExternalLinkValue = (event) => {
        event.preventDefault();
        let externarLinkObject = {};
        externarLinkObject.description = this.state.linkDescription;
        externarLinkObject.url = this.state.linkURL;
        externarLinkObject.id = this.props.curid;
        this.props.fetchEditexternalLinkValue(externarLinkObject)
        this.setState({linkURL: '', linkDescription: ''})
    }

    render() {
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
        return(<div>                
            <ScoreContainer>                
                <ScoreHeaderContainer>Add External Links<HeaderButton onClick={this.closeExternalLinkModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                    <ScoreBodyContainer>
                    <MRModalLabel>URL</MRModalLabel>
                    <MRModalInput style={{width: "98%"}} name="linkURL" onChange={this.handleChange} value={this.state.linkURL}/>
                    <MRModalLabel>Description</MRModalLabel>
                    <FCKTextarea rows="6" style={{width: "98%"}} name="linkDescription" onChange={this.handleChange} value={this.state.linkDescription}/>
                </ScoreBodyContainer>
                <div style={{height: '37px', width: '100%', paddingRight: "12px", marginTop: "12px"}}>  
                    {
                        (() => {
                            if(this.props.curid !== 0) {
                                return(<MRModalNextBtn  onClick={this.submitEditExternalLinkValue}>Edit</MRModalNextBtn>)
                            }else{
                                return(<MRModalNextBtn  onClick={this.submitExternalLinkValue}>Save</MRModalNextBtn>)
                            }
                        })()
                    }                                 
                    {/* <MRModalNextBtn  onClick={this.submitExternalLinkValue}>Save</MRModalNextBtn> */}
                    <MRModalCancelBtn onClick={this.closeExternalLinkModal}>Cancel</MRModalCancelBtn>
                </div>
            </ScoreContainer>
        </div>)
    }
}
export default AddEditExternalLinks