import React from 'react';
import styled from 'styled-components';
import { FaTimes,FaPlusCircle} from 'react-icons/fa';
import DatePicker from 'react-date-picker';

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 6px #a7a6a6;
    width: 96%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;

const MRModalLabel = styled.div`
    color: #252525;
    font-weight: 600;    
    margin-bottom: 6px;
    margin-top: 10px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #4F4F4F;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    padding: 0px 20px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

class AddKeyDates extends React.Component
{
    state = { 
        showModal: false,
        keyDate: new Date(),
        keyDescription: '',
        hiddenAddButton: true
    }

    showAddKeyDate = (index) => (event) => {    
        event.preventDefault();
        this.setState({ showModal: true, hiddenAddButton: false });
    }

    onDateChangekey = (date) => {
        this.setState({keyDate: date});
    }
    closeAddkeyDateModal = () => {
        this.setState({ showModal: false, hiddenAddButton: true})
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});            
    }
    submitKeyDateValue = (event) => {
        event.preventDefault();
        let upload_keydate_obj = this.state.keyDate;
        const upload_day = upload_keydate_obj.getDate() < 10 ? "0"+upload_keydate_obj.getDate().toString() : upload_keydate_obj.getDate();
		const upload_month = upload_keydate_obj.getMonth()+1 < 10 ? `0${(upload_keydate_obj.getMonth() + 1).toString()}` : upload_keydate_obj.getMonth()+1;
		const upload_year = upload_keydate_obj.getFullYear();
        const upload_keydate = `${upload_day}/${upload_month}/${upload_year}`;
        console.log("keyDate", upload_keydate,"keyDescription", this.state.keyDescription)
        this.props.refreshKeyValue(upload_keydate,this.state.keyDescription)
        this.setState({ showModal: false, hiddenAddButton: true, keyDate: new Date(), keyDescription: ''})
    }

    render() {
        return(<div>
            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Key Dates</div>
                {
                    (() =>{
                        if(this.state.hiddenAddButton){
                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddKeyDate(-1)}><FaPlusCircle /></div>)
                        }
                    })()
                }                
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
            {
                (() => {
                    if(this.state.showModal) {
                        return(<div >                            
                            <ScoreContainer>                
                                <ScoreHeaderContainer>Add Key Dates<HeaderButton onClick={this.closeAddkeyDateModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                                 <ScoreBodyContainer>
                                    <MRModalLabel>Date</MRModalLabel>
                                    <CSLDateCover>
                                        <DatePicker
                                            onChange={this.onDateChangekey}
                                            clearIcon={null}
                                            calendarIcon={null}
                                            locale={"en-GB"}
                                            value={this.state.keyDate}
                                            />
                                    </CSLDateCover>
                                    <MRModalLabel>Description</MRModalLabel>
                                    <FCKTextarea rows="6" style={{width: "98%"}} name="keyDescription" onChange={this.handleChange} value={this.state.keyDescription}/>
                                </ScoreBodyContainer>
                                <div style={{height: '37px', width: '100%', paddingRight: "12px", marginTop: "12px"}}>
                                   
                                    <MRModalNextBtn  onClick={this.submitKeyDateValue}>Save</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeAddkeyDateModal}>Cancel</MRModalCancelBtn>
                                </div>
                            </ScoreContainer>
                        </div>)
                    }
                })()
            }
        </div>)
    }
}
export default AddKeyDates;