import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store.js';
import CSLTable from '../../Common/CSLTable';
import AddRegulatory from '../../Modals/AddRegulatory';
import RegulatoryModal from '../../Modals/RegulatoryModal';
const RUPTable = styled.table`
    table-layout: fixed;
    width: 100%;
    margin-top: 15px;
`;
const RUPButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;
const AddRbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
class RUPendingTable extends React.Component
{

    state = {
        isLoaded: false,
        rufeeds: null,
        modal_view: null,
        doctypeObject: null
    };

    constructor(props) {
        super(props);
        this.component_id = Store.registerCallBack('feeds', this.updatefeeds);
    }
    updatefeeds = () => {
        const feedsdata = Store.getStoreData('feeds');
        this.setState({ rufeeds: feedsdata, isLoaded: true});
        console.log('state', this.state);
    }

    componentWillUnmount() {
        Store.deRegisterCallback('updatefeeds', this.component_id);
    }

    componentDidMount() {
        const feedsdata = Store.getStoreData('feeds');
        //const documentType = Store.getStoreData('rutypename');
        const documentType = Store.getStoreData('rutypenameAll');
        let doctype = {};
        for(let i=0; i < documentType.length; i++) {
            doctype[documentType[i].id] = documentType[i].documentname;
        }
        //console.log(doctype);

        this.setState({ rufeeds: feedsdata,doctypeObject: doctype,isLoaded: true});
        //console.log('state', this.state);
    }

    showRegulatoryModal = (changeActionValue) => (event) => {
        event.preventDefault();
        let url = Store.getStoreData('api_url');
        console.log("url",url)

        let postData = { "command" : "getfeed" , "feed_id": event.target.value, 'env' : 'select'};

		let httpVerb = { method: "POST", mode: "cors", headers: {"Content-Type": "application/x-www-form-urlencoded"}, body: "jsondata=" + JSON.stringify(postData) };
        fetch(url, httpVerb)
        .then((res) => res.json())
        .then(result => {
            console.log("result+++",result)
            Store.updateStore('rufeed', JSON.parse(result.feed.feed_update_json));
            Store.updateStore('current_activityLog', result.feed.activity_log);
            Store.updateStore('istask_action', result.feed.change_action);
            Store.updateStore('is_custom', result.feed.is_custom);
            Store.updateStore('istask_dismiss', result.feed.isdismiss);
            Store.updateStore('tags', result.tags);
            Store.updateStore('changeActionValue',changeActionValue)
            this.setState({modal_view: 'process_regulatory_modal'})
        })
    }

    processMyFeedsData = () => {
        //console.log("feedsdata", "feedsdata");
        let ret = {data: [], columns: [], row_count: 0};
        // if(this.state.isLoaded === false) {
        //     return ret;
        // }
        let feedsdata = this.state.rufeeds;
        //console.log("feedsdata=====>",feedsdata);
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Name', accessor: 'document_name', minWidth: 200, headerStyle: {textAlign: 'left'}},
                        {Header: 'Document Types', accessor: 'document_types', width: 120, headerStyle: {textAlign: 'left'}},
                        // {Header: 'Issuing Body', accessor: 'issuing_body', width: 165, headerStyle: {textAlign: 'left'}},
                        {Header: 'Regulator Name', accessor: 'regulator_name', width: 160, headerStyle: {textAlign: 'left'}},
                        {Header: 'Legecy', accessor: 'legecy_value', width: 70, headerStyle: {textAlign: 'left'}},
                        {Header: 'Status', accessor: 'status', width: 100, headerStyle: {textAlign: 'left'}},
                        {Header: 'Action', accessor: 'action', width: 110, headerStyle: {textAlign: 'left'}}];

        for(let d of feedsdata) {
            //let json_feed = JSON.parse(d.feed_json);
            //let update_json_feed = JSON.parse(d.feed_update_json);
            i++;
            let documentTypes = d.documentTypes;
            //console.log("documentTypes======>",documentTypes)
            let issuingBodies = d.issuingBodies;
            // console.log("issuingBodies", issuingBodies);
            let dtype = "";
            let issuingBodie = "";
            let dNames = "";
            if (documentTypes !== null) {
                let i=0;
                for (let doc of documentTypes){
                    i++;
                    if(doc in this.state.doctypeObject){
                        dNames += this.state.doctypeObject[doc]
                        if(i <= documentTypes.length - 1) {
                            dNames += ', '
                        }
                    }
                }
            }
            //console.log("doc",dNames)
            if (issuingBodies !== null) {
                for (let issueb of issuingBodies) {
                    issuingBodie = issuingBodie + issueb +",";
                    issuingBodie = issuingBodie.substring(0, issuingBodie.length - 1)
                }
            }
            let regulatorName = d.ragulatorName;
            //console.log("update_json_feed",update_json_feed.ragulatorName)

            let status = 'Pending';
            //if (d.is_custom === 0) status = "New";
            if (d.change_action === 1) status = "Processed";
            let action = <RUPButton value={d.id} onClick={this.showRegulatoryModal(d.change_action)}>{d.change_action === 0 ? 'Process' : 'Edit'}</RUPButton>;

            //let doctypename = this.state.doctypeObject[dtype]
            let elem = {
                    'index' : i,
                    'document_name' : d.documentName,
                    'document_types' : dNames,
                    'issuing_body' : issuingBodie,
                    'regulator_name' : regulatorName,
                    'status' : status,
                    'legecy_value' : d.is_legecy === 1 ? 'Yes' : 'No',
                    'action' : action
                   };
            ret.data.push(elem);
        }
        ret.row_count = i;
        return ret;
    }

    addregulatory = (index) => (event) => {
        // console.log("INDEX===>",index);
        event.preventDefault();
        // Store.updateStore('fc_index', index);
        this.setState({modal_view: 'add_regulatory_modal'})
    }

    closeModal = () => {
        this.setState({ modal_view: null });
    }

    render()
    {
        //console.log("STATE===>",this.state.modal_view);
        if(this.state.isLoaded === false) {
            return <div>Loading...</div>
        }
        return (
            <IndexContainer>
                <AddRbutton onClick = {this.addregulatory(0)}>Add New</AddRbutton>
                <CSLTable add={false} processData={this.processMyFeedsData} tableRows={10} headerText={'New Regulatory Changes'} />
                {
                  (() => {
                        if(this.state.modal_view !== null){
                            switch(this.state.modal_view){
                                case 'add_regulatory_modal': return <AddRegulatory closeModal={this.closeModal}/>; break;
                                case 'process_regulatory_modal': return <RegulatoryModal closeModal={this.closeModal}/>; break;
                            }
                        }
                        })()
                }
            </IndexContainer>
        );
    }
}
export default RUPendingTable;
