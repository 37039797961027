import React, { Component } from "react";

import styled from "styled-components";
import UIPieChart from "./UIPieChart";




const CardBlock = styled.div`
  width: 319px;
  height: 319px;
  
  border-radius: 3px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 5px;
  margin-left: 28px;
  margin-bottom: 23px;
  float: left;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px;
  }
`;

const CardTitle = styled.div`
  width: 100%;
  height: 19px;
  opacity: 0.58;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Roboto Medium", "Roboto", sans-serif;
  font-weight: 500;
  color: #1a3552;
  text-align: left;
  line-height: normal;
`;
const CardValue = styled.div`
  width: 100%;
  height: 82px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Roboto Bold", "Roboto", sans-serif;

  color: #1a3552;
  text-align: left;
  line-height: normal;
  font-weight: 700;
  font-size: 68px;
`;

const linkStyle = styled.div``;

class UICard extends Component {

  showCardDetails = (id) => (event) => {
    //event.preventDefault();    
    this.props.showCardDetails(id,'')
}

fetchValue = (regName) => {
  console.log("regName===>", regName)
  this.props.showCardDetails(this.props.id, regName)
}

  render() {
    return (
      <CardBlock >
        <div onClick={this.showCardDetails(this.props.id)}>
          <CardTitle style={{margin: "25px 2px 2px 25px", width: "90%"}}>{this.props.cardtitle} </CardTitle>
          <CardValue style={{margin: "11px 2px 2px 25px", width: "90%"}}>{this.props.cardvalue}</CardValue>
        </div>
        <div style={{padding: "0px 0px 0px 20px"}}>
          {
            (() => {                        
                if(this.props.data.length > 1){    
                    return <UIPieChart data={this.props.data} options={this.props.options} fetchValue={this.fetchValue}/>
                }                                            
            })()
          } 
        </div>
        <CardTitle style={{width: "100%"}} onClick={this.showCardDetails(this.props.id)}></CardTitle>        
      </CardBlock>
    );
  }
}

export default UICard;
