import React from 'react';
import styled from 'styled-components';
import AutoForwordGenaral from './AutoForwordGenaral';
import AutoDismissGeneral from './AutoDismissGeneral';
import AutoDismissGeneralDocType from './AutoDismissGeneralDocType';
import AutoDismissSectors from './AutoDismissSectors';
import Store from '../../Common/Store';
import CSLSpinner from '../Common/CSLSpinner';
import APICall from './../../Common/APICall';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
    position: relative;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
class FilterData extends React.Component
{

	state = {general: null, ready: false, isSubscribeToEnhancedDataFeed: 0, currentTagsAll: []};

	componentDidMount()
	{
        this.setCompanySubcribe();        	
	}

    setCompanySubcribe = () => {  
        let postData = { command: "get_current_company_subcribe_dataFeed" };
        let api = new APICall();
        api.command(postData, this.processCompanySubcribeDataFeed);	
    }

    processCompanySubcribeDataFeed = (result) => {
        console.log("result==>", result)
        this.setState({general: this.props.general, isSubscribeToEnhancedDataFeed: result.isSubscribeToEnhancedDataFeed, ready: true, currentTagsAll: result.currentTags});
    }

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {		
            this.setCompanySubcribe(); 	
		    //this.setState({general: this.props.general});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}	

	render()
	{        
        if(!this.state.ready){
            return <GeneralContainer>
            <GeneralInnerContainer style={{height: "368px"}}> <CSLSpinner style={{position: 'absolute'}}/></GeneralInnerContainer>
            </GeneralContainer>
        }
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>								
				<GeneralContainer>
					<GeneralInnerContainer>
                        <GeneralLabel>Filter Regulators</GeneralLabel>
                        {						
                            (() => {
                                if (this.state.general.enable_auto_dismiss) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio onClick={this.switchRadio('enable_auto_dismiss')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio onClick={this.switchRadio('enable_auto_dismiss')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }		
                        {
                            (() => {
                                if(this.state.general.enable_auto_dismiss){
                                    return(<AutoDismissGeneral general={this.state.general} saveAutoDismissValue={this.props.saveAutoDismissValue}/>)
                                }
                            })()
                        }	

                        <GeneralLabel>Filter by Document Type</GeneralLabel>
                        {						
                            (() => {
                                if (this.state.general.filter_by_document_type) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio onClick={this.switchRadio('filter_by_document_type')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio onClick={this.switchRadio('filter_by_document_type')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.general.filter_by_document_type){
                                    return(<AutoDismissGeneralDocType general={this.state.general} saveAutoDismissValueDocType={this.props.saveAutoDismissValueDocType}/>)
                                }
                            })()
                        }
					</GeneralInnerContainer>
				</GeneralContainer>
			</div>
		);
	}
}

export default FilterData;
