		let data = {table_headers: [], data: []};
        data.module = {id: 'rcm', display: 'Regulatory Change'}
		data.table_headers = [
            {
                accessor: "status", nickname: "Status", type: "alpha", width: 0.95,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "name", nickname: "Name", type: "alpha", width: 3.52,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "groupcompanyname", nickname: "Company", type: "alpha", width: 1.36,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "type", nickname: "Type", type: "alpha", width: 1.24,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "regulator", nickname: "Regulator", type: "alpha", width: 0.95,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "tags_name", nickname: "Tag(s)", type: "alpha", width: 1.16,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "sharedinfo", nickname: "Info", type: "numeric", width: 0.62,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "sharedaction", nickname: "Action", type: "numeric", width: 0.62,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "followuptasks", nickname: "Follow- up", type: "numeric", width: 0.8,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "regulator_published_date", nickname: "Reg. Date", type: "date", width: 0.87,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            }
        ];

        data.sortby = {
            fields: [
                {accessor: "status", nickname: "Status", type: "alpha", width: 1.2},
                {accessor: "name", nickname: "Name", type: "alpha", width: 2.6},
                {accessor: "groupcompanyname", nickname: "Company", type: "alpha", width: 1.8},
                {accessor: "type", nickname: "Type", type: "alpha", width: 1.2},
                {accessor: "regulator", nickname: "Regulator", type: "alpha", width: 1.1},
                {accessor: "tags_name", nickname: "Tag(s)", type: "alpha", width: 2},
                {accessor: "sharedinfo", nickname: "Info", type: "numeric", width: 1},
                {accessor: "sharedaction", nickname: "Action", type: "numeric", width: 1},
                {accessor: "followuptasks", nickname: "Followup", type: "numeric", width: 1},
                {accessor: "regulator_published_date", nickname: "Reg. Date", type: "date", width: 1},
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {accessor: "status", nickname: "Status", type: "alpha", width: 1.2},
                {accessor: "name", nickname: "Name", type: "alpha", width: 2.6},
                {accessor: "groupcompanyname", nickname: "Company", type: "alpha", width: 1.8},
                {accessor: "type", nickname: "Type", type: "alpha", width: 1.2},
                {accessor: "regulator", nickname: "Regulator", type: "alpha", width: 1.1},
                {accessor: "tags_name", nickname: "Tag(s)", type: "alpha", width: 2},
                {accessor: "sharedinfo", nickname: "Info", type: "numeric", width: 1},
                {accessor: "sharedaction", nickname: "Action", type: "numeric", width: 1},
                {accessor: "followuptasks", nickname: "Followup", type: "numeric", width: 1},
                {accessor: "regulator_published_date", nickname: "Reg. Date", type: "date", width: 1},
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data