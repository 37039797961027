import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import CSLTable from '../Common/CSLTable';
import UserViewModal from './UserViewModal';
import CSLbucketloader from '../Common/CSLbucketloader';
import Utils from '../../Common/Utils';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 130px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
`; 

const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;    
    margin-top: 30px;  
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  
`;
const HeaderButton = styled.button`
    box-sizing: border-box;
    background-color: #EBEBEB;
    color: #606575;
    border: 1px solid #CECECE;
    width: 100px;
    font-size: 13px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 0 4px #CECECE;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;    
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    width: 97.6%;
    margin: 0 auto;
    margin-bottom: 30px;    
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;
    width: 30%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;

class RegisterUserView extends React.Component
{ 
    state = {
        filter: {
          All: 1,
          Inprogress: 0, 
          Pending: 0,
          Completed: 0,
        },
        ready: false,
        task_list: null,
        modal_view:null,
        all_count: 0,
        pending_count: 0,
        inprogress_count: 0,
        completed_count: 0,
        taskdata: {},
        userViewData: [],
        userName: "",
        allcountinfo: { open: 0,action: 0,dismiss: 0,outstanding:0},
        userid: 0
    };
    
    userView = () => {
        this.props.modalView('adminmanager');
    }

    // componentDidMount() {        
    //   let postData = { "command": "get_rcm_registry_user" };
    //   let api = new APICall();
    //   api.command(postData, this.processRegisterUserIndex);
    // } 

    componentDidMount() { 
      this.processDataRefresh()        
    } 

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.processDataRefresh()
        }
    }

    processDataRefresh = () => {        
      let api = new APICall();
      let postData = {};    
      if(Store.getStoreData('userViewData') === null) {
          postData['command'] = 'get_rcm_registry_user';
          console.log("postdata", postData)
          api.command(postData, this.processregisterVuewUserRCM)
      }  
      this.setState({ready: true})
  }

    processregisterVuewUserRCM = (result) => {
        console.log("result===>",result)
        Utils.log("userViewData", result)
        Store.updateStore('userViewData', result.userViewlist);
        this.setState({view_count: this.state.view_count + 1}); 
    }

    // processRegisterUserIndex = (result) => {
    //   console.log("result",result);  
    //   this.setState({userViewData: result.userViewlist, ready: true})

    // }

    handleBtn = (event) => {
        event.preventDefault();        
    }

    processAcceptanceQueueData = () => {   
        let userView = Store.getStoreData('userViewData') === null ? [] : Store.getStoreData('userViewData');  
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[                    
                    {Header: 'Name', accessor: 'name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Opened', accessor: 'opened', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Actioned', accessor: 'actioned', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Dismissed', accessor: 'dismissed', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Outstanding', accessor: 'outstanding', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Reminders', accessor: 'reminders', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {Header: 'Avg # of days', accessor: 'avgnoofdays', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action', Cell: row => (
                      <div>
                          <MRButton style={{cursor:"pointer"}} onClick={this.goToTaskUrl(row.original.id,row.original.name,row.original.opened,row.original.actioned,row.original.dismissed,row.original.outstanding)}>View</MRButton>
                      </div>
                    ), width: 120, headerStyle: {textAlign: 'center'}},
                      
                    ]; 
            for(let d of userView) {           
              i++;
              let openRU = (d.openedRU.toString().length<2 ? "0"+d.openedRU : d.openedRU)+" of "+(d.totalRU.toString().length<2 ? "0"+d.totalRU : d.totalRU);
              let actionRU = (d.actionedRU.toString().length<2 ? "0"+d.actionedRU : d.actionedRU)+" of "+(d.actiontotalRU.toString().length<2 ? "0"+d.actiontotalRU : d.actiontotalRU);
              let dismissedRU = (d.dismissedRU.toString().length<2 ? "0"+d.dismissedRU : d.dismissedRU)+" of "+(d.totalRU.toString().length<2 ? "0"+d.totalRU : d.totalRU);
              let outstandingRU = (d.outstandingRU.toString().length<2 ? "0"+d.outstandingRU : d.outstandingRU)+" of "+(d.totalRU.toString().length<2 ? "0"+d.totalRU : d.totalRU);
              let remindersRU = d.remindersRU.toString().length<2 ? "0"+d.remindersRU : d.remindersRU;
              let avgNoofDaysRU = d.avgNoofDays;
              let elem = {'index' : i,
                      'id': d.id, 
                      'name' : d.userName,
                      'opened' : openRU,
                      'actioned' : actionRU,
                      'dismissed' : dismissedRU,
                      'outstanding': outstandingRU,
                      'reminders': remindersRU,
                      'avgnoofdays': avgNoofDaysRU                                                             
                      };
              ret.data.push(elem);
          }    
        return ret;      
      }

      goToTaskUrl = (userid,name,openedRU,actionedRU,dismissedRU,outstandingRU) => (event) => {
        event.preventDefault();
        console.log("userid",userid) 
        let allcountinfo = { open: openedRU,action: actionedRU,dismiss: dismissedRU,outstanding:outstandingRU} 
        let postData = { "command": "get_user_view_by_id", contact_id: userid };
        let api = new APICall();
        api.command(postData, this.processUserView(name,allcountinfo,userid));
                 
      } 
      processUserView = (username,allcountinfo,userid) => (result) => {
        console.log("result",result); 
        Store.updateStore('get_cur_user_info',result) 
        this.setState({modal_view: "UserViewModal",userName: username, allcountinfo, userid: userid})  
      }

    closeModal = () => {
        this.setState({ modal_view: null });
      }
    
    render()
    {    
       return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                  {/* {
                    (() =>{

                      if(this.state.filter.All === 1){
                        return(
                          <FilterButton style={{backgroundColor: "#243E57", color: "#EEF2F7", marginRight:"15px"}} name="All" onClick={this.handleBtn}><span style={{float: 'left'}}>&#10003;    </span>All<span style={{float: 'right'}}> {this.state.all_count} </span></FilterButton>
                          )
                        
                        }else{
                          return(
                            <FilterButton style={{backgroundColor: "#EEF2F7", marginRight:"15px"}} name="All" onClick={this.handleBtn}>All<span style={{float: 'right'}}>{this.state.all_count}</span></FilterButton> 
                            )                    
                        }
                    }
                      )()
                  } 
                  {
                    (() =>{
                      if(this.state.filter.Inprogress === 1){
                        return(
                        <FilterButton style={{backgroundColor: "#243E57", color: "#EEF2F7", marginRight:"15px"}} name="Inprogress" onClick={this.handleBtn}><span style={{float: 'left'}}>&#10003;    </span>In Progress<span style={{float: 'right'}}>{this.state.inprogress_count}</span></FilterButton>
                          )
                        
                        }else{
                          return(
                            <FilterButton style={{backgroundColor: "#EEF2F7", marginRight:"15px"}} name="Inprogress" onClick={this.handleBtn}>In Progress<span style={{float: 'right'}}> {this.state.inprogress_count}</span></FilterButton> 
                            )                    
                        }
                    }
                      )()
                  }          
                  {
                    (() =>{
                      if(this.state.filter.Pending === 1){
                        return(
                        <FilterButton style={{backgroundColor: "#243E57", color: "#EEF2F7", marginRight:"15px"}} name="Pending" onClick={this.handleBtn}><span style={{float: 'left'}}>&#10003;    </span>Pending<span style={{float: 'right'}}>{this.state.pending_count}</span></FilterButton>
                          )
                        
                        }else{
                          return(
                            <FilterButton style={{backgroundColor: "#EEF2F7", marginRight:"15px"}} name="Pending" onClick={this.handleBtn}>Pending<span style={{float: 'right'}}> {this.state.pending_count}</span></FilterButton> 
                            )                    
                        }
                    }
                      )()
                  }
                  {
                    (() => {
                      if(this.state.filter.Completed === 1){
                        return (
                        <FilterButton style={{backgroundColor: "#243E57", color: "#EEF2F7", marginRight:"15px"}} name="Completed" onClick={this.handleBtn}><span style={{float: 'left'}}>&#10003;    </span>Completed<span style={{float: 'right'}}> {this.state.completed_count}</span></FilterButton>                   
                         )
                      }else{  
                        return (
                            <FilterButton style={{backgroundColor: "#EEF2F7", marginRight:"15px"}} name="Completed" onClick={this.handleBtn}>Completed<span style={{float: 'right'}}> {this.state.completed_count}</span></FilterButton>                    
                         )
                      }
                    })()
                  }                   */}
                  <RegDismisOuterContainer>
                  
                    <OverviewInfoHeader>
                          <div style={{float: "left", color: "#282828", fontWeight: "600"}}>Regulatory Change Register</div>
                          <div style={{float: "right", color: "#CBCBCB", fontWeight: "600"}}><HeaderButton style={{color: "#D4D5D8"}} disabled>Team View</HeaderButton></div>
                          <div style={{float: "right", color: "#CBCBCB", fontWeight: "600"}}><HeaderButton  >User View</HeaderButton></div>
                          <div style={{float: "right", color: "#CBCBCB", fontWeight: "600"}}><HeaderButton style={{color: "#D4D5D8", cursor: "pointer"}} onClick={this.userView}>Manager View</HeaderButton></div>                                
                          <div style={{clear: "both"}}></div>
                    </OverviewInfoHeader>
                      
                    <OverviewInfoHeader style={{marginTop: "0px",borderBottomLeftRadius: "5px",borderBottomRightRadius: "5px"}}>
                    <div  style={{position: 'relative'}}>
                        {
                            (() => {
                                    if(Store.getStoreData('userViewData') === null) {
                                    return <CSLbucketloader style={{position: 'absolute'}} />
                                    }
                            })()
                        }
                        <div style={{clear: "both", height: '3px'}}></div>
                      <CSLTable add={false} processData={this.processAcceptanceQueueData} tableRows={5} refreshCallback={this.refreshCallback} qcbutton={true} />
                      </div>     
                    </OverviewInfoHeader>  
                    {
                        (() => {
                            if(this.state.modal_view !== null) {
                                switch(this.state.modal_view) {
                                    case 'UserViewModal' : return <UserViewModal userName={this.state.userName} allcountinfo={this.state.allcountinfo} userid={this.state.userid} closeModal={this.closeModal}/>; break;                                    
                                }                        
                            }
                        })()
                    }           
                  </RegDismisOuterContainer>                             
          </div>              
        </div>
        );
    }
   
}

export default RegisterUserView;